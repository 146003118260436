import PropTypes from 'prop-types';
import bankCardPropType from './bankCard';
import subscriptionPropType from './subscription';
import { SUBSCRIPTION_PROVIDER } from '../constants/defaults';

export default PropTypes.shape({
  card: bankCardPropType,
  is_setup: PropTypes.bool.isRequired,
  subscription: subscriptionPropType,
  subscription_id: PropTypes.string,
  type: PropTypes.oneOf(Object.values(SUBSCRIPTION_PROVIDER)).isRequired,
});
