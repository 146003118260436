import { Cluster } from 'src/types';

export const jdbcConnectionString = (cluster: Cluster) =>
  `jdbc:postgresql://${cluster.fqdn.slice(0, -1)}:5432/crate?sslmode=require`;

export const javaCodeSnippet = (cluster: Cluster) => `import java.sql.*;
import java.util.Properties;

public class Main {
    public static void main(String[] args) {
        try {
            Properties properties = new Properties();
            properties.put("user", "${cluster.username}");
            properties.put("password", "<PASSWORD>");
            properties.put("ssl", true);
            Connection conn = DriverManager.getConnection(
                    "${jdbcConnectionString(cluster)}",
                    properties
            );

            Statement statement = conn.createStatement();
            ResultSet resultSet = statement.executeQuery("SELECT name FROM sys.cluster");
            resultSet.next();
            String name = resultSet.getString("name");

            System.out.println(name);
        } catch (SQLException e) {
            e.printStackTrace();
        }
    }
}`;
