import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Modal } from 'antd';
import { Button, Text } from '@crate.io/crate-gc-admin';
import HubspotFormRequestAddPrivateLink from '../../../components/HubspotForms/HubspotFormRequestAddPrivateLink/HubspotFormRequestAddPrivateLink';
import SectionContainer from '../../../components/SectionContainer';
import { useGetClustersId } from '../../../swrHooks';

function RequestPrivateLink() {
  const { formatMessage } = useIntl();
  const [showModal, setShowModal] = useState(false);
  const { clusterId, organizationId } = useParams();
  const { data: cluster } = useGetClustersId(clusterId);

  return (
    <>
      <SectionContainer
        actions={
          <Button kind={Button.kinds.TERTIARY} onClick={() => setShowModal(true)}>
            <FormattedMessage id="cluster.clusterManage.privateLinksButtonText" />
          </Button>
        }
        title={formatMessage({
          id: 'cluster.clusterManage.privateLinksTitle',
        })}
      >
        <Text>
          <FormattedMessage
            id="cluster.clusterManage.privateLinksBodyText"
            values={{
              aws: (
                <a
                  href="https://docs.aws.amazon.com/vpc/latest/privatelink/concepts.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  AWS
                </a>
              ),
              azure: (
                <a
                  href="https://docs.microsoft.com/en-us/azure/private-link/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Azure
                </a>
              ),
              readmore: (
                <a
                  href="https://cratedb.com/docs/cloud/howtos/en/latest/private-endpoints.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
              ),
            }}
          />
        </Text>
      </SectionContainer>

      <Modal
        closable={false}
        footer={null}
        onCancel={() => setShowModal(false)}
        open={showModal}
      >
        <HubspotFormRequestAddPrivateLink
          onFormComplete={() => setShowModal(false)}
          onFormCancel={() => setShowModal(false)}
          hiddenFields={{
            cluster_configuration: [
              `organization: ${organizationId}`,
              `cluster name: ${cluster?.name}`,
              `cluster id: ${cluster?.id}`,
            ].join('\n'),
          }}
        />
      </Modal>
    </>
  );
}

export default RequestPrivateLink;
