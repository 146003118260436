import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@crate.io/crate-gc-admin';
import ClusterHealth from '../ClusterHealth';
import RegionDescription from '../RegionDescription';
import { clusterPropType, regionPropType } from '../../models';

function ClusterHeader({ cluster, region, title }) {
  return (
    <div className="mb-4 flex flex-wrap items-center justify-between gap-4">
      {/* left side: title + health */}
      <div>
        <Heading level={Heading.levels.h2}>{title}</Heading>
        <div className="text-xs text-crate-border-mid">
          ID: <span>{cluster.id}</span>
        </div>

        <ClusterHealth cluster={cluster} />
      </div>

      {/* right side: region */}
      {region && <RegionDescription region={region} />}
    </div>
  );
}

ClusterHeader.propTypes = {
  cluster: clusterPropType.isRequired,
  region: regionPropType.isRequired,
  title: PropTypes.node.isRequired,
};

export default ClusterHeader;
