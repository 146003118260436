import { Spin, Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { NoDataView } from '@crate.io/crate-gc-admin';

function CloudUITable({
  dataSource,
  columns,
  rowKey,
  showHeader,
  rowClassName,
  emptyText,
  size,
  scroll,
  expandable,
  pagination,
  testId,
  summary,
}) {
  const { formatMessage } = useIntl();

  const [tableEmpty, setTableEmpty] = useState(false);

  const showBody = () => {
    if (!dataSource) {
      return (
        <div className="mt-10 text-center">
          <Spin
            data-testid="cloud-ui-table-loading-spinner"
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          />
        </div>
      );
    }
    return '';
  };

  // eslint-disable-next-line react/prop-types
  const customExpandIcon = ({ expanded, onExpand, record }) => {
    return (
      <button
        className="mr-2.5 h-[17px] w-[17px] cursor-pointer p-0 text-neutral-400 transition duration-200 hover:border-crate-blue hover:text-crate-blue"
        onClick={event => onExpand(record, event)}
        type="button"
      >
        {expanded ? '−' : '+'}
      </button>
    );
  };

  const handleChange = (x, searchValue, searchObject, tableContent) => {
    setTableEmpty(tableContent.currentDataSource.length === 0);
  };

  const getDescriptionOfEmptyTable = () => {
    if (tableEmpty) return formatMessage({ id: 'cloudUITable.noResultsFoundText' });
    return emptyText.length > 0
      ? emptyText
      : formatMessage({ id: 'cloudUITable.defaultEmptyText' });
  };

  return (
    <div data-testid={testId}>
      <Table
        bordered={false}
        className={!showHeader && 'without-header'}
        columns={columns}
        dataSource={dataSource}
        onChange={handleChange}
        pagination={pagination}
        rowClassName={rowClassName}
        rowKey={rowKey}
        showHeader={showHeader}
        size={size}
        scroll={scroll}
        expandable={expandable && { ...expandable, expandIcon: customExpandIcon }}
        summary={summary}
      />
      {dataSource &&
      (tableEmpty || dataSource.length === 0) &&
      (!summary || (summary && !summary())) ? (
        <div className="mt-10 text-center">
          <NoDataView description={getDescriptionOfEmptyTable()} />
        </div>
      ) : (
        showBody()
      )}
    </div>
  );
}

CloudUITable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.object),
  emptyText: PropTypes.string,
  rowClassName: PropTypes.func,
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  showHeader: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['default', 'small', 'middle']),
  scroll: PropTypes.object,
  expandable: PropTypes.object,
  pagination: PropTypes.bool,
  testId: PropTypes.string,
  summary: PropTypes.func,
};

CloudUITable.defaultProps = {
  dataSource: null,
  emptyText: '',
  rowClassName: undefined,
  size: 'default',
  scroll: undefined,
  expandable: null,
  pagination: false,
  testId: null,
  summary: null,
};

export default CloudUITable;
