import { useGCLogin } from '@crate.io/crate-gc-admin';
import { apiGet, apiPost } from '../api';
import { getClusterGcUrl } from '../utils/data/cluster';

const useEnsureGcLogin = () => {
  const gcLogin = useGCLogin();

  const ensureGcLogin = async (cluster, sessionTokenKey) => {
    const gcUrl = getClusterGcUrl(cluster);

    const res = await apiPost(
      `${gcUrl}/api/_sql?multi=true&types`,
      { stmt: 'SELECT 1' },
      { credentials: 'include' },
      false,
    );
    if (res.status >= 400) {
      const {
        data: { token, refresh },
      } = await apiGet(`/api/v2/clusters/${cluster.id}/jwt/`);

      await gcLogin({
        token,
        refresh,
        gcUrl,
        sessionTokenKey,
      });
    }
  };

  return ensureGcLogin;
};

export default useEnsureGcLogin;
