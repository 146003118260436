import {
  Connection,
  File as CloudFile,
  ConnectionId,
  OrganizationId,
  VerifyConnectionResponse,
  ClusterId,
  ValueOf,
} from 'src/types';
import {
  CREATE_SCRAM_CONNECTION_DEFAULT_FIELD_VALUES,
  CREATE_X509_CONNECTION_DEFAULT_FIELD_VALUES,
  CreateConnectionForm,
  CreateConnectionScramForm,
  CreateConnectionX509Form,
} from './forms';
import ConstrainStepWidth from 'src/components/StepLayout/ConstrainStepWidth';
import LoadingContainer from 'src/components/LoadingContainer';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import { Button, Label, StatusLight, Text } from '@crate.io/crate-gc-admin';
import { Form, Input, Select } from 'antd';
import {
  AUTH_TYPES,
  AUTH_TYPE_OPTIONS,
  AuthTypeOption,
  CONNECTION_FIELD_NAMES,
  MONGODB_PASSWORD_PLACEHOLDER,
  MONGODB_COMPLETE_SCRAM_CONNECTION_STRING_REGEX,
  MONGODB_USERNAME_PLACEHOLDER,
} from '../../constants';
import INPUT_SANITIZATION from '../../../../../constants/inputSanitization';
import FileInput from 'src/components/FileInput';
import { useGetClustersIdConnections } from 'src/swrHooks';
import { apiPost } from 'src/api';
import { CONNECTION_TYPES } from 'src/constants/connection';

export type ConnectionDetailsStepProps = {
  authType: AuthTypeOption;
  organizationId: OrganizationId;
  clusterId: ClusterId;
  selectedConnection: 'new' | ConnectionId | undefined;
  connectionDetails?: CreateConnectionForm | Connection;
  attachedCertificateFile: CloudFile | File | null;
  setAuthType: (auth: AuthTypeOption) => void;
  setSelectedConnection: (selectedConnection: 'new' | ConnectionId) => void;
  setAttachedCertificateFile: (file: CloudFile | File | null) => void;
  onNext: (result: CreateConnectionForm | Connection) => void;
};

function ConnectionDetailsStep({
  authType,
  organizationId,
  clusterId,
  selectedConnection,
  connectionDetails,
  attachedCertificateFile,
  setAuthType,
  setSelectedConnection,
  setAttachedCertificateFile,
  onNext,
}: ConnectionDetailsStepProps) {
  const { formatMessage } = useIntl();

  // forms
  const [scramForm] = Form.useForm<CreateConnectionScramForm>();
  const [x509Form] = Form.useForm<CreateConnectionX509Form>();

  // state
  const [loadingConnectionStatus, setLoadingConnectionStatus] = useState(false);
  const [connectionStatus, setConnectionStatus] =
    useState<VerifyConnectionResponse>();

  // swr
  const { data: connections, isLoading: isLoadingConnections } =
    useGetClustersIdConnections(organizationId);

  const onScramFormFinish = (values: CreateConnectionScramForm) => {
    onNext(values);
  };

  const onX509FormFinish = (values: CreateConnectionX509Form) => {
    onNext(values);
  };

  const handleChangeScramConnectionString = (connectionString: string) => {
    const connectionStringMatch = connectionString.match(
      MONGODB_COMPLETE_SCRAM_CONNECTION_STRING_REGEX,
    );

    const username = connectionStringMatch?.groups?.username || null;
    const password = connectionStringMatch?.groups?.password || null;

    const fields: Record<string, string> = {};

    if (username && username !== MONGODB_USERNAME_PLACEHOLDER) {
      fields[CONNECTION_FIELD_NAMES.USERNAME] = decodeURIComponent(username);
    }
    if (password && password !== MONGODB_PASSWORD_PLACEHOLDER) {
      fields[CONNECTION_FIELD_NAMES.PASSWORD] = decodeURIComponent(password);
    }
    if (username || password) {
      connectionString = connectionString.replace(`${username}:${password}@`, '');
      fields[CONNECTION_FIELD_NAMES.CONNECTION_STRING] = connectionString;
    }

    scramForm.setFieldsValue({
      ...fields,
      // trim whitespaces from connection string
      [CONNECTION_FIELD_NAMES.CONNECTION_STRING]: connectionString.trim(),
    });
  };

  const handleChangeX509ConnectionString = (connectionString: string) => {
    x509Form.setFieldsValue({
      [CONNECTION_FIELD_NAMES.CONNECTION_STRING]: connectionString.trim(),
    });
  };

  const handleCertificateUpload = async (file: File) => {
    setAttachedCertificateFile(file);
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const certificateString = e.target!.result as string;
      x509Form.setFieldValue(CONNECTION_FIELD_NAMES.CERTIFICATE, certificateString);
    };
    reader.readAsText(file);
  };

  const handleCertificateRemove = () => {
    x509Form.resetFields([CONNECTION_FIELD_NAMES.CERTIFICATE]);
    setAttachedCertificateFile(null);
  };

  const handleTestConnection = async () => {
    // check if forms are valid!
    try {
      const formValidMethod =
        authType === AUTH_TYPES.SCRAM
          ? scramForm.validateFields()
          : x509Form.validateFields();

      await formValidMethod;
    } catch {
      return;
    }

    setLoadingConnectionStatus(true);
    const formValues =
      authType === AUTH_TYPES.SCRAM
        ? {
            auth: AUTH_TYPES.SCRAM,
            connection_string: scramForm.getFieldValue('connection_string'),
            username: scramForm.getFieldValue('username'),
            password: scramForm.getFieldValue('password'),
            default_db_name: scramForm.getFieldValue('default_db_name'),
          }
        : {
            auth: AUTH_TYPES.X509,
            connection_string: x509Form.getFieldValue('connection_string'),
            certificate: btoa(x509Form.getFieldValue('certificate')),
            default_db_name: x509Form.getFieldValue('default_db_name'),
          };

    const { data } = await apiPost<VerifyConnectionResponse>(
      `/api/v2/clusters/${clusterId}/verify-connection/`,
      {
        ...formValues,
        type: CONNECTION_TYPES.MONGODB,
      },
    );

    setLoadingConnectionStatus(false);
    setConnectionStatus(data!);
  };

  const renderTestConnection = () => {
    let status: {
      color: ValueOf<typeof StatusLight.colors>;
      message: string;
      testId: string;
    } = {
      color: StatusLight.colors.GRAY,
      message: formatMessage({
        id: 'cluster.clusterImportIntegration.connectionDetailsStep.connectionStatusUnknown',
      }),
      testId: 'unknown-status',
    };

    if (connectionStatus) {
      if (connectionStatus.connection_valid && connectionStatus.database_exists) {
        // All good
        status = {
          color: StatusLight.colors.GREEN,
          message: formatMessage({
            id: 'common.ok',
          }),
          testId: 'ok-status',
        };
      } else if (
        connectionStatus.connection_valid &&
        !connectionStatus.database_exists
      ) {
        // Connection is valid, but DB does not exist
        status = {
          color: StatusLight.colors.YELLOW,
          message: formatMessage({
            id: 'cluster.clusterImportIntegration.connectionDetailsStep.connectionStatusDbNotExist',
          }),
          testId: 'warning-status',
        };
      } else {
        // not valid
        status = {
          color: StatusLight.colors.RED,
          message: formatMessage(
            {
              id: 'cluster.clusterImportIntegration.connectionDetailsStep.connectionStatusFailed',
            },
            {
              message: connectionStatus.message,
            },
          ),
          testId: 'red-status',
        };
      }
    }

    return (
      <div className="mb-2">
        <div className="font-bold">
          <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.connectionStatus" />
        </div>
        <StatusLight
          testId={status.testId}
          color={status.color}
          message={status.message}
        />
      </div>
    );
  };

  const createConnection = selectedConnection === 'new';

  return (
    <ConstrainStepWidth>
      <LoadingContainer
        loading={isLoadingConnections}
        render={() => {
          if (connections && connections.length === 0) {
            setSelectedConnection('new');
          }

          return (
            <>
              <div className="mb-4">
                <Select
                  value={selectedConnection}
                  disabled={connections?.length === 0}
                  onChange={(value: 'new' | ConnectionId) => {
                    setAttachedCertificateFile(null);
                    setSelectedConnection(value);
                  }}
                  placeholder={
                    <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.selectOrCreateNewConnection" />
                  }
                  data-testid="connection-select"
                  className="mt-[8px] block"
                  options={[
                    {
                      label: formatMessage({
                        id: 'cluster.clusterImportIntegration.connectionDetailsStep.createNewConnection',
                      }),
                      value: 'new',
                    },
                    ...(connections || []).map(connection => {
                      return {
                        label: connection.name,
                        value: connection.id,
                      };
                    }),
                  ]}
                />
              </div>

              {createConnection && (
                <div className="mb-4">
                  <Label>
                    <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.authenticationMethod" />
                  </Label>
                  <Select
                    value={authType}
                    onChange={(value: AuthTypeOption) => {
                      setAuthType(value);
                    }}
                    data-testid="auth-type-select"
                    placeholder={
                      <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.selectAuth" />
                    }
                    className="mt-[8px] block"
                    options={AUTH_TYPE_OPTIONS}
                  />
                </div>
              )}

              {createConnection && authType === AUTH_TYPES.SCRAM && (
                <Form<CreateConnectionScramForm>
                  autoComplete="off"
                  form={scramForm}
                  initialValues={{
                    ...CREATE_SCRAM_CONNECTION_DEFAULT_FIELD_VALUES,
                    ...(connectionDetails || {}),
                  }}
                  layout="vertical"
                  name="mongodb-integration-scram-connection-form"
                  id="mongodb-integration-scram-connection-form"
                  aria-label="mongodb-integration-scram-connection-form"
                  onFinish={onScramFormFinish}
                  requiredMark="optional"
                >
                  {/* hidden auth */}
                  <Form.Item name={[CONNECTION_FIELD_NAMES.AUTH]} hidden>
                    <Input />
                  </Form.Item>

                  <Form.Item<CreateConnectionScramForm>
                    colon={false}
                    label={
                      <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.connectionNameLabel" />
                    }
                    name={[CONNECTION_FIELD_NAMES.NAME]}
                    rules={[
                      {
                        ...INPUT_SANITIZATION.GENERIC_REQUIRED_TEXT,
                        message: formatMessage({
                          id: 'cluster.clusterImportIntegration.connectionDetailsStep.connectionNameValidationText',
                        }),
                      },
                    ]}
                  >
                    <Input
                      placeholder={formatMessage({
                        id: 'cluster.clusterImportIntegration.connectionDetailsStep.connectionNamePlaceholder',
                      })}
                    />
                  </Form.Item>

                  <Form.Item<CreateConnectionScramForm>
                    colon={false}
                    label={
                      <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.connectionStringLabel" />
                    }
                    name={[CONNECTION_FIELD_NAMES.CONNECTION_STRING]}
                    rules={[INPUT_SANITIZATION.MONGODB_SCRAM_CONNECTION_STRING]}
                  >
                    <Input
                      placeholder={formatMessage({
                        id: 'cluster.clusterImportIntegration.connectionDetailsStep.connectionStringUserPassPlaceholder',
                      })}
                      onBlur={e => handleChangeScramConnectionString(e.target.value)}
                    />
                  </Form.Item>

                  <div className="lg:grid lg:grid-cols-2 lg:gap-x-4">
                    <Form.Item<CreateConnectionScramForm>
                      colon={false}
                      label={
                        <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.usernameLabel" />
                      }
                      name={[CONNECTION_FIELD_NAMES.USERNAME]}
                      rules={[
                        {
                          ...INPUT_SANITIZATION.GENERIC_REQUIRED_TEXT,
                          message: formatMessage({
                            id: 'cluster.clusterImportIntegration.connectionDetailsStep.usernameValidationText',
                          }),
                        },
                      ]}
                    >
                      <Input
                        placeholder={formatMessage({
                          id: 'cluster.clusterImportIntegration.connectionDetailsStep.usernamePlaceholder',
                        })}
                      />
                    </Form.Item>

                    <Form.Item<CreateConnectionScramForm>
                      colon={false}
                      label={
                        <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.passwordLabel" />
                      }
                      name={[CONNECTION_FIELD_NAMES.PASSWORD]}
                      rules={[
                        {
                          ...INPUT_SANITIZATION.GENERIC_REQUIRED_TEXT,
                          message: formatMessage({
                            id: 'cluster.clusterImportIntegration.connectionDetailsStep.passwordValidationText',
                          }),
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder={formatMessage({
                          id: 'cluster.clusterImportIntegration.connectionDetailsStep.passwordPlaceholder',
                        })}
                        autoComplete="new-password"
                      />
                    </Form.Item>
                  </div>

                  <Form.Item<CreateConnectionX509Form>
                    colon={false}
                    label={
                      <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.defaultDatabaseNameLabel" />
                    }
                    name={[CONNECTION_FIELD_NAMES.DEFAULT_DATABASE_NAME]}
                    rules={[INPUT_SANITIZATION.MONGODB_DATABASE_NAME]}
                  >
                    <Input
                      placeholder={formatMessage({
                        id: 'cluster.clusterImportIntegration.connectionDetailsStep.defaultDatabaseNamePlaceholder',
                      })}
                    />
                  </Form.Item>

                  {renderTestConnection()}

                  <div className="flex gap-2">
                    <Button
                      kind={Button.kinds.SECONDARY}
                      onClick={handleTestConnection}
                      loading={loadingConnectionStatus}
                    >
                      <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.testConnectionButtonText" />
                    </Button>
                    <Button type={Button.types.SUBMIT}>
                      <FormattedMessage id="common.next" />
                    </Button>
                  </div>
                </Form>
              )}

              {createConnection && authType === AUTH_TYPES.X509 && (
                <Form<CreateConnectionX509Form>
                  autoComplete="off"
                  form={x509Form}
                  initialValues={{
                    ...CREATE_X509_CONNECTION_DEFAULT_FIELD_VALUES,
                    ...(connectionDetails || {}),
                  }}
                  layout="vertical"
                  name="mongodb-integration-x509-connection-form"
                  id="mongodb-integration-x509-connection-form"
                  aria-label="mongodb-integration-x509-connection-form"
                  onFinish={onX509FormFinish}
                  requiredMark="optional"
                >
                  {/* hidden auth */}
                  <Form.Item name={[CONNECTION_FIELD_NAMES.AUTH]} hidden>
                    <Input />
                  </Form.Item>
                  {/* hidden certificate */}
                  <Form.Item name={[CONNECTION_FIELD_NAMES.CERTIFICATE]} hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item<CreateConnectionX509Form>
                    colon={false}
                    label={
                      <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.connectionNameLabel" />
                    }
                    name={[CONNECTION_FIELD_NAMES.NAME]}
                    rules={[
                      {
                        ...INPUT_SANITIZATION.GENERIC_REQUIRED_TEXT,
                        message: formatMessage({
                          id: 'cluster.clusterImportIntegration.connectionDetailsStep.connectionNameValidationText',
                        }),
                      },
                    ]}
                  >
                    <Input
                      placeholder={formatMessage({
                        id: 'cluster.clusterImportIntegration.connectionDetailsStep.connectionNamePlaceholder',
                      })}
                    />
                  </Form.Item>
                  <FileInput
                    file={attachedCertificateFile}
                    accept=".pem"
                    className="mb-4"
                    onChange={handleCertificateUpload}
                    onRemove={handleCertificateRemove}
                    fileDetailsMessage={
                      <Text pale>
                        <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.uploadPemFile" />
                      </Text>
                    }
                  />
                  <Form.Item<CreateConnectionX509Form>
                    colon={false}
                    label={
                      <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.connectionStringLabel" />
                    }
                    name={[CONNECTION_FIELD_NAMES.CONNECTION_STRING]}
                    rules={[INPUT_SANITIZATION.MONGODB_X509_CONNECTION_STRING]}
                  >
                    <Input
                      placeholder={formatMessage({
                        id: 'cluster.clusterImportIntegration.connectionDetailsStep.connectionStringCertificatePlaceholder',
                      })}
                      onBlur={e => handleChangeX509ConnectionString(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item<CreateConnectionX509Form>
                    colon={false}
                    label={
                      <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.defaultDatabaseNameLabel" />
                    }
                    name={[CONNECTION_FIELD_NAMES.DEFAULT_DATABASE_NAME]}
                    rules={[INPUT_SANITIZATION.MONGODB_DATABASE_NAME]}
                  >
                    <Input
                      placeholder={formatMessage({
                        id: 'cluster.clusterImportIntegration.connectionDetailsStep.defaultDatabaseNamePlaceholder',
                      })}
                    />
                  </Form.Item>
                  {renderTestConnection()}
                  <div className="flex gap-2">
                    <Button
                      kind={Button.kinds.SECONDARY}
                      onClick={handleTestConnection}
                      loading={loadingConnectionStatus}
                    >
                      <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.testConnectionButtonText" />
                    </Button>
                    <Button
                      type={Button.types.SUBMIT}
                      disabled={!attachedCertificateFile}
                    >
                      <FormattedMessage id="common.next" />
                    </Button>
                  </div>
                </Form>
              )}

              {!createConnection && (
                <Button
                  type={Button.types.SUBMIT}
                  disabled={typeof selectedConnection === 'undefined'}
                  onClick={() => {
                    onNext(
                      connections!.find(conn => conn.id === selectedConnection)!,
                    );
                  }}
                >
                  <FormattedMessage id="common.next" />
                </Button>
              )}
            </>
          );
        }}
      />
    </ConstrainStepWidth>
  );
}

export default ConnectionDetailsStep;
