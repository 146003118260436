import cx from 'classnames';
import { Heading } from '@crate.io/crate-gc-admin';
import LoadingContainer from '../LoadingContainer';

type ViewContainerProps = {
  extra?: React.ReactNode | React.ReactNode[];
  heading?: string | React.ReactNode;
  loading?: boolean;
  render: () => React.ReactNode;
};

function ViewContainer({
  extra,
  heading = '',
  loading = false,
  render,
}: ViewContainerProps) {
  const styles = cx(
    'flex',
    'flex-wrap',
    'gap-4',
    'items-center',
    'justify-between',
    'my-4',
    'pb-4',
  );

  return (
    <LoadingContainer
      isViewContainer
      loading={loading}
      loaderAlignment={LoadingContainer.loaderAlignment.CENTER}
      render={() => (
        <>
          <div className={styles}>
            <Heading displayAs={Heading.levels.h4} level={Heading.levels.h1}>
              {heading}
            </Heading>
            {extra && extra}
          </div>
          <div className="pb-4">{render()}</div>
        </>
      )}
    />
  );
}

export default ViewContainer;
