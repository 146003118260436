import { PropsWithChildren } from 'react';
import logoShape from '../../assets/global_loading_animation.svg';

export type GlobalLoadingAnimationProps = PropsWithChildren;

function GlobalLoadingAnimation({ children }: GlobalLoadingAnimationProps) {
  return (
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
      <img
        className="mx-auto"
        src={logoShape}
        alt="CrateDB Cloud logo"
        data-testid="global-loading-image"
      />
      {children && <div className="text-center text-neutral-100">{children}</div>}
    </div>
  );
}

export default GlobalLoadingAnimation;
