import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Heading, Loader, Text } from '@crate.io/crate-gc-admin';
import moment from 'moment';
import SubscriptionCard from '../SubscriptionCard';
import stripeLogo from '../../../../assets/logo_stripe_purple.svg';
import { useGetStripeSubscriptionsIdInvoicesUpcoming } from '../../../../swrHooks';
import { fromCents } from '../../../../utils';
import { PRICE_OPTIONS_2DP } from '../../../../constants/defaults';
import { DATE_FORMAT } from '../../../../constants/dates';
import TestSWRIsFetching from '../../../../components/TestSWRIsFetching';

function BankTransferSubscriptionCard({ subscription }) {
  const { formatMessage } = useIntl();

  const { data: upcomingInvoice, isLoading: isUpcomingInvoiceLoading } =
    useGetStripeSubscriptionsIdInvoicesUpcoming(subscription);

  const drawValue = (title, children, extra = null) => (
    <div>
      <div className="flex items-center justify-between gap-4">
        <Heading level={Heading.levels.h5}>
          <FormattedMessage id={title} />
        </Heading>
        {extra}
      </div>
      {children || <Text>n/a</Text>}
    </div>
  );

  return (
    <SubscriptionCard
      logo={
        <img src={stripeLogo} className="mt-1 h-12 w-[68px]" alt="Stripe logo" />
      }
      reference={`ID: ${subscription.id}`}
      subscriptionId={subscription.id}
      title={formatMessage({
        id: 'organization.bankTransferSubscriptionCard.bankTransfer',
      })}
    >
      {isUpcomingInvoiceLoading && (
        <Loader
          align={Loader.alignment.CENTER}
          color={Loader.colors.PRIMARY}
          size={Loader.sizes.SMALL}
        />
      )}
      <div className="space-y-2">
        {subscription.active &&
          drawValue(
            'organization.subscriptionDetail.billingSnapshotTitle',
            upcomingInvoice ? (
              <Text>
                <FormattedNumber
                  value={fromCents(upcomingInvoice.total || 0)}
                  style={PRICE_OPTIONS_2DP.style}
                  currency={upcomingInvoice.currency}
                />
              </Text>
            ) : null,
          )}
        {subscription.active &&
          drawValue(
            'organization.subscriptionDetail.billingPeriodTitle',
            upcomingInvoice ? (
              <Text>
                {moment.utc(upcomingInvoice.period_start).format(DATE_FORMAT)} -{' '}
                {moment.utc(upcomingInvoice.period_end).format(DATE_FORMAT)}
              </Text>
            ) : null,
          )}

        <div>
          <Heading level={Heading.levels.h5}>
            <FormattedMessage id="organization.bankTransferSubscriptionCard.additionalInfo" />
          </Heading>
          <Text>
            <FormattedMessage id="organization.bankTransferSubscriptionCard.conversionRate" />
          </Text>
        </div>
      </div>

      <TestSWRIsFetching fetchStatusList={[isUpcomingInvoiceLoading]} />
    </SubscriptionCard>
  );
}

BankTransferSubscriptionCard.propTypes = {
  subscription: PropTypes.object.isRequired,
};

export default BankTransferSubscriptionCard;
