import { useMemo, useState } from 'react';
import {
  DataTable,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Table,
  useGCContext,
} from '@crate.io/crate-gc-admin';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import { FilterOutlined } from '@ant-design/icons';
import { useGetTablesShards } from 'src/swr';
import LoadingContainer from '../../../components/LoadingContainer';
import { TABLE_HEALTH_STATES, TableHealthState } from 'src/constants/defaults';
import { fromBytes } from 'src/utils';
import { ColumnDef } from '@tanstack/react-table';

type TableShard = {
  schemaName: string;
  tableName: string;
  partitionName: string | null;
  isPartitioned: boolean;
  numberOfReplicas: string;
  numberOfShards: number;
  totalReplica: number;
  numberOfRecords: number;
  sizeInBytes: number;
  startedShards: number;
  missingShards: number;
  unassignedShards: number;
  health: TableHealthState;
};

function TablesShardsMetrics() {
  const { clusterId, crateUrl, crateVersion, gcUrl, sessionTokenKey } =
    useGCContext();
  const { data: tablesShards } = useGetTablesShards({
    clusterId: clusterId!,
    crateUrl: crateUrl!,
    crateVersion: crateVersion!,
    gcUrl: gcUrl!,
    sessionTokenKey,
  });
  const [healthFilter, setHealthFilter] = useState({
    [TABLE_HEALTH_STATES.GREEN]: true,
    [TABLE_HEALTH_STATES.YELLOW]: true,
    [TABLE_HEALTH_STATES.RED]: true,
  });

  const rowCountFormat = Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  });

  const tableData = useMemo(() => {
    const createTableRow = (
      schemaName: string,
      tableName: string,
      partitionName: string | null,
      isPartitioned: boolean,
      numberOfReplicas: string,
      numberOfShards: number,
      startedPrimary: number,
      startedReplica: number,
      unassignedPrimary: number,
      unassignedReplica: number,
      totalPrimary: number,
      totalReplica: number,
      numberOfRecords: number,
      sizeInBytes: number,
    ): TableShard => {
      // calculate shorthand values
      const startedShards = startedPrimary + startedReplica;
      const unassignedShards = unassignedPrimary + unassignedReplica;
      const totalShards = totalPrimary + totalReplica;
      const missingShards = totalShards - startedShards - unassignedShards;

      // calculate health
      let health: TableHealthState = TABLE_HEALTH_STATES.GREEN;
      if (startedReplica < totalReplica) {
        health = TABLE_HEALTH_STATES.YELLOW;
      }
      if (startedPrimary == 0 || startedPrimary < totalPrimary) {
        health = TABLE_HEALTH_STATES.RED;
      }

      return {
        schemaName,
        tableName,
        partitionName,
        isPartitioned,
        numberOfReplicas,
        numberOfShards,
        totalReplica,
        numberOfRecords,
        sizeInBytes,
        startedShards,
        missingShards,
        unassignedShards,
        health,
      };
    };

    const output: TableShard[] = [];

    tablesShards?.rows?.forEach(table => {
      // add the tables
      output.push(
        createTableRow(
          table[0],
          table[1],
          null,
          table[2],
          table[3],
          table[4],
          table[5],
          table[6],
          table[7],
          table[8],
          table[9],
          table[10],
          table[11],
          table[12],
        ),
      );

      // add rows for each partition
      if (table[2]) {
        table[13]?.forEach(partition => {
          output.push(
            createTableRow(
              table[0],
              table[1],
              partition.partition_ident,
              false,
              partition.number_of_replicas,
              partition.number_of_shards,
              partition.started_primary,
              partition.started_replica,
              partition.unassigned_primary,
              partition.unassigned_replica,
              partition.total_primary,
              partition.total_replica,
              partition.num_docs_primary,
              partition.size_primary,
            ),
          );
        });
      }
    });

    return output;
  }, [tablesShards]);

  const summaryData = useMemo(() => {
    const statuses = new Set(tableData.map(row => row.health));
    let status: TableHealthState = TABLE_HEALTH_STATES.GREEN;
    if (statuses.has(TABLE_HEALTH_STATES.YELLOW)) {
      status = TABLE_HEALTH_STATES.YELLOW;
    }
    if (statuses.has(TABLE_HEALTH_STATES.RED)) {
      status = TABLE_HEALTH_STATES.RED;
    }

    const tablesExcludingPartitioned = tableData.filter(row => !row.partitionName);
    return {
      health: status,
      startedShards: tablesExcludingPartitioned.reduce(
        (prev, next) => prev + next.startedShards,
        0,
      ),
      missingShards: tablesExcludingPartitioned.reduce(
        (prev, next) => prev + next.missingShards,
        0,
      ),
      unassignedShards: tablesExcludingPartitioned.reduce(
        (prev, next) => prev + next.unassignedShards,
        0,
      ),
      totalRecords: tablesExcludingPartitioned.reduce(
        (prev, next) => prev + next.numberOfRecords,
        0,
      ),
      totalSizeInBytes: tablesExcludingPartitioned.reduce(
        (prev, next) => prev + next.sizeInBytes,
        0,
      ),
    };
  }, [tableData]);

  const columns: ColumnDef<TableShard>[] = useMemo(
    () => [
      {
        accessorKey: 'schemaName',
        header: 'Schema',
        cell: ({ cell }) => (cell.row.original.partitionName ? '' : cell.getValue()),
      },
      {
        accessorKey: 'tableName',
        header: 'Table / Partition name',
        cell: ({ cell }) =>
          cell.row.original.partitionName ? (
            <div className="pl-4 text-neutral-500">
              {cell.row.original.partitionName}
            </div>
          ) : (
            cell.getValue()
          ),
      },
      {
        accessorKey: 'health',
        header: 'Health',
        cell: ({ cell }) => {
          // hardcoded to ensure tailwind picks up the values :/
          switch (cell.getValue()) {
            case 'RED':
              return (
                <div className="flex items-center gap-2 text-red-600">
                  <div className="h-2 w-2 rounded-full bg-red-600" />
                  <FormattedMessage id="clusterMonitoring.tableHealth.RED" />
                </div>
              );
            case 'YELLOW':
              return (
                <div className="flex items-center gap-2 text-orange-600">
                  <div className="h-2 w-2 rounded-full bg-orange-600" />
                  <FormattedMessage id="clusterMonitoring.tableHealth.YELLOW" />
                </div>
              );
            default:
              return (
                <div className="flex items-center gap-2 text-green-600">
                  <div className="h-2 w-2 rounded-full bg-green-600" />
                  <FormattedMessage id="clusterMonitoring.tableHealth.GREEN" />
                </div>
              );
          }
        },
      },
      { accessorKey: 'numberOfShards', header: 'Shards' },
      { accessorKey: 'numberOfReplicas', header: 'Replicas' },
      { accessorKey: 'startedShards', header: 'Started' },
      {
        accessorKey: 'missingShards',
        header: 'Missing',
        cell: ({ cell }) => {
          const value = cell.getValue();
          return value === 0 ? <div className="text-neutral-400">0</div> : value;
        },
      },
      {
        accessorKey: 'unassignedShards',
        header: 'Underreplicated',
        cell: ({ cell }) => {
          const value = cell.getValue();
          return value === 0 ? <div className="text-neutral-400">0</div> : value;
        },
      },
      {
        accessorKey: 'numberOfRecords',
        header: 'Total records',
        cell: ({ cell }) => rowCountFormat.format(cell.getValue() as number),
      },
      {
        accessorKey: 'sizeInBytes',
        header: 'Size',
        cell: ({ cell }) => (
          <span className="whitespace-nowrap">
            {fromBytes(cell.getValue() as number).format()}
          </span>
        ),
      },
    ],
    [],
  );

  const drawFilterCheckbox = (label: React.ReactNode, status: TableHealthState) => (
    <label className="z-10 flex cursor-pointer items-center gap-1.5 whitespace-nowrap">
      <Checkbox
        checked={healthFilter[status]}
        onChange={() => {
          const newFilter = { ...healthFilter, [status]: !healthFilter[status] };
          if (Object.values(newFilter).filter(bool => bool).length > 0) {
            setHealthFilter({ ...healthFilter, [status]: !healthFilter[status] });
          }
        }}
        data-testid={`filter-checkbox-${status}`}
      />
      <span className="opacity-70">{label}</span>
    </label>
  );

  const drawStatistic = (
    label: React.ReactNode,
    value: number | React.ReactNode,
    testId: string,
  ) => (
    <div>
      <div className="text-sm leading-snug text-neutral-400">{label}</div>
      <div className="text-lg leading-snug" data-testid={`statistic-${testId}`}>
        {value}
      </div>
    </div>
  );

  // underneath their parent table. We should be able to pass a hidePagination
  // parameter to the DataTable component, which I will do after this PR is
  // merged due to time constraints.
  return (
    <LoadingContainer
      loaderAlignment={LoadingContainer.loaderAlignment.CENTER}
      isViewContainer
      loading={!tablesShards}
      render={() => (
        <div className="flex h-full w-full flex-col overflow-hidden">
          {/* statistics block */}
          <div className="grid select-none grid-cols-3 gap-2 py-4">
            {drawStatistic(
              <FormattedMessage id="clusterMonitoring.statistics.health" />,
              <FormattedMessage
                id={`clusterMonitoring.tableHealth.${summaryData.health}`}
              />,
              'health',
            )}
            {drawStatistic(
              <FormattedMessage id="clusterMonitoring.statistics.totalRecords" />,
              rowCountFormat.format(summaryData.totalRecords),
              'totalRecords',
            )}
            {drawStatistic(
              <FormattedMessage id="clusterMonitoring.statistics.totalSize" />,
              fromBytes(summaryData.totalSizeInBytes).format(),
              'totalSize',
            )}
            {drawStatistic(
              <FormattedMessage id="clusterMonitoring.statistics.startedShards" />,
              summaryData.startedShards,
              'startedShards',
            )}
            {drawStatistic(
              <FormattedMessage id="clusterMonitoring.statistics.missingShards" />,
              summaryData.missingShards,
              'missingShards',
            )}
            {drawStatistic(
              <FormattedMessage id="clusterMonitoring.statistics.underreplicatedShards" />,
              summaryData.unassignedShards,
              'underreplicatedShards',
            )}
          </div>

          {/* table */}
          <DataTable
            columns={columns}
            data={tableData.filter(row => healthFilter[row.health])}
            className="relative w-full overflow-auto"
            disablePagination
            stickyHeader
            customTableHeader={
              <Table.Header className="sticky top-0 z-10 bg-white">
                <Table.RowHeader className="!border-0">
                  <Table.Head className="align-bottom" rowSpan={2}>
                    Schema
                  </Table.Head>
                  <Table.Head className="align-bottom" rowSpan={2}>
                    Table / Partition name
                  </Table.Head>
                  <Table.Head className="align-bottom" rowSpan={2}>
                    <div className="flex items-center gap-2">
                      <div>Health</div>
                      <Popover>
                        <PopoverTrigger>
                          <FilterOutlined
                            className="opacity-80 hover:opacity-100"
                            data-testid="health-filter"
                          />
                        </PopoverTrigger>
                        <PopoverContent
                          align="end"
                          className="select-none space-y-1"
                        >
                          {drawFilterCheckbox(
                            <FormattedMessage id="clusterMonitoring.tableHealth.GREEN" />,
                            TABLE_HEALTH_STATES.GREEN,
                          )}
                          {drawFilterCheckbox(
                            <FormattedMessage id="clusterMonitoring.tableHealth.YELLOW" />,
                            TABLE_HEALTH_STATES.YELLOW,
                          )}
                          {drawFilterCheckbox(
                            <FormattedMessage id="clusterMonitoring.tableHealth.RED" />,
                            TABLE_HEALTH_STATES.RED,
                          )}
                        </PopoverContent>
                      </Popover>
                    </div>
                  </Table.Head>
                  <Table.Head className="h-8 align-bottom" colSpan={2}>
                    Configuration
                  </Table.Head>
                  <Table.Head className="h-8 align-bottom" colSpan={3}>
                    Shards
                  </Table.Head>
                  <Table.Head className="align-bottom" rowSpan={2}>
                    Total records
                  </Table.Head>
                  <Table.Head className="align-bottom" rowSpan={2}>
                    Size
                  </Table.Head>
                </Table.RowHeader>
                <Table.RowHeader className="!border-0">
                  <Table.Head className="h-8 align-bottom">Shards</Table.Head>
                  <Table.Head className="h-8 align-bottom">Replicas</Table.Head>
                  <Table.Head className="h-8 align-bottom">Started</Table.Head>
                  <Table.Head className="h-8 align-bottom">Missing</Table.Head>
                  <Table.Head className="h-8 align-bottom">
                    Underreplicated
                  </Table.Head>
                </Table.RowHeader>
                {/* fake bottom border to fix the problem where table borders in a sticky header when border-collapse: collapse are not sticky */}
                <tr className="!border-b-0">
                  <th
                    className="h-[1px] !border-b-0 bg-neutral-200 p-0"
                    colSpan={10}
                  />
                </tr>
              </Table.Header>
            }
          />
        </div>
      )}
    />
  );
}

export default TablesShardsMetrics;
