import { useParams } from 'react-router-dom';
import { CrateTabsShad, Nodes } from '@crate.io/crate-gc-admin';
import { Heading, StatsUpdater } from '@crate.io/crate-gc-admin';
import { FormattedMessage } from 'react-intl';
import SectionContainer from 'src/components/SectionContainer';
import TablesShardsMetrics from './TablesShardsMetrics';
import GrandCentralWrapper from 'src/components/GrandCentralWrapper';

const tabs = [
  {
    label: <FormattedMessage id="clusterMonitoring.tablesTab" />,
    key: 'tables',
    content: <TablesShardsMetrics />,
  },
  {
    label: <FormattedMessage id="clusterMonitoring.nodesTab" />,
    key: 'nodes',
    content: (
      <div className="-mx-4 mt-2">
        <Nodes />
      </div>
    ),
  },
];

function ClusterMonitoring() {
  const { clusterId, organizationId } = useParams();

  return (
    <GrandCentralWrapper clusterId={clusterId!} organizationId={organizationId!}>
      <SectionContainer className="absolute !mb-0 h-full w-full overflow-x-auto rounded-none !pb-0">
        <Heading className="mb-2" level={Heading.levels.h2}>
          <FormattedMessage id="clusterMonitoring.clusterMonitoring" />
        </Heading>
        <div className="grow overflow-hidden">
          <CrateTabsShad items={tabs} stickyTabBar />
        </div>
      </SectionContainer>
      <StatsUpdater />
    </GrandCentralWrapper>
  );
}

export default ClusterMonitoring;
