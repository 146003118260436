import React from 'react';
import PropTypes from 'prop-types';

function CloudUIDrawerFooterControlsContainer({ children }) {
  return (
    <div className="absolute inset-x-0 bottom-0 w-full border-t border-crate-border-light px-6 py-4">
      {children}
    </div>
  );
}

CloudUIDrawerFooterControlsContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    .isRequired,
};

export default CloudUIDrawerFooterControlsContainer;
