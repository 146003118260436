import { useMemo, useState } from 'react';
import { last } from 'lodash/fp';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { CopyOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { format as formatSQL } from 'sql-formatter';
import { Button, CopyToClipboard, Text } from '@crate.io/crate-gc-admin';
import CloudUITable from '../../../../components/CloudUITable';
import DisplayDate from '../../../../components/DisplayDate';
import StatusIndicator from '../../../../components/StatusIndicator/StatusIndicator';
import ImportJobsListActions from './ImportJobsListActions';
import { OPERATION_STATES } from '../../../../constants/defaults';
import ImportingHelpSection from '../ImportingHelpSection';
import CloudUISyntaxHighlighter from '../../../../components/CloudUISyntaxHighlighter';
import { ImportJob, ImportJobWithName } from 'src/types';
import { IMPORT_JOBS_SOURCES } from '../../ClusterImportFile/common/SourceStep/sources';

export type ImportJobsListProps = {
  importJobs: ImportJob[];
  onDelete: (importJob: ImportJob) => void;
  onRetry: (importJob: ImportJob) => void;
};

function ImportJobsList({ importJobs, onDelete, onRetry }: ImportJobsListProps) {
  const { formatNumber, formatMessage } = useIntl();
  const [importJobBeingViewed, setImportJobBeingViewed] =
    useState<ImportJobWithName | null>(null);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);

  const getStatusIndicatorMessage = (importJob: ImportJobWithName) => {
    switch (importJob.status) {
      case OPERATION_STATES.IN_PROGRESS:
        // multi-file import
        if (importJob.progress.total_files > 1) {
          return (
            <FormattedMessage
              id="cluster.clusterImport.quickStatusImportInProgressFilesText"
              values={{
                file: formatNumber(importJob.progress?.processed_files || 0, {
                  notation: 'compact',
                }),
                total: formatNumber(importJob.progress?.total_files || 0, {
                  notation: 'compact',
                }),
              }}
            />
          );
        }

        // single file import
        if (importJob.progress.records > 0) {
          return (
            <FormattedMessage
              id="cluster.clusterImport.quickStatusImportInProgressRecordsText"
              values={{
                records: formatNumber(importJob.progress.records, {
                  notation: 'compact',
                }),
                total: formatNumber(importJob.progress.total_records, {
                  notation: 'compact',
                }),
              }}
            />
          );
        }

        // fallback
        return (
          <FormattedMessage id="cluster.clusterImport.quickStatusSettingUpImportText" />
        );
      case OPERATION_STATES.REGISTERED:
      case OPERATION_STATES.SENT:
        return (
          <FormattedMessage id="cluster.clusterImport.quickStatusSettingUpImportText" />
        );
      case OPERATION_STATES.SUCCEEDED:
        return (
          <FormattedMessage id="cluster.clusterImport.quickStatusImportSucceededText" />
        );
      case OPERATION_STATES.FAILED:
        return (
          <FormattedMessage id="cluster.clusterImport.quickStatusImportFailedText" />
        );
      default:
        return null;
    }
  };

  const formatJobSource = (job: ImportJob) => {
    switch (job.type) {
      case 'azureblob':
        return `blob://${job.azureblob.container_name}/${job.azureblob.blob_name}`;
      case 'file':
        return (
          job.file?.name || (
            <FormattedMessage id="cluster.clusterImport.fileDeletedfailoverText" />
          )
        );
      case 's3':
        return `s3://${job.s3.bucket}/${job.s3.file_path}`;
      case 'url':
        return last(job.url.url.split('/'));
      default:
        return 'Unknown';
    }
  };

  const combinedData = useMemo(
    () =>
      importJobs.map((importJob: ImportJob) => {
        return {
          ...importJob,
          name: formatJobSource(importJob),
        } satisfies ImportJobWithName;
      }),
    [importJobs],
  );

  const handleShowDetails = (importJob: ImportJobWithName) => {
    setImportJobBeingViewed(importJob);
    setShowDetailsDialog(true);
  };

  const handleHideDetails = () => setShowDetailsDialog(false);

  const handleDeleteImportJob = (importJob: ImportJobWithName) => {
    onDelete(importJob);
    setShowDetailsDialog(false);
  };

  const handleRetryImportJob = (importJob: ImportJobWithName) => {
    onRetry(importJob);
    setShowDetailsDialog(false);
  };

  const columns = [
    {
      title: (
        <FormattedMessage id="cluster.clusterImport.fileNameColumnHeaderText" />
      ),
      render: (importJob: ImportJobWithName) => (
        <span className="overflow-hidden text-ellipsis">{importJob.name}</span>
      ),
      width: '35%',
    },
    {
      title: <FormattedMessage id="cluster.clusterImport.typeColumnHeaderText" />,
      responsive: ['lg'],
      render: (importJob: ImportJobWithName) => {
        const selectedSource = IMPORT_JOBS_SOURCES.find(
          el => el.key === importJob.type,
        );
        if (!selectedSource) {
          return 'Unknown';
        }

        return (
          <div className="flex items-center gap-2 text-black">
            {selectedSource.icon}
            <Text pale>
              <FormattedMessage id={selectedSource.title} />
            </Text>
          </div>
        );
      },
      width: '10%',
    },
    {
      dataIndex: ['destination', 'table'],
      responsive: ['xl'],
      title: (
        <FormattedMessage id="cluster.clusterImport.tableNameColumnHeaderText" />
      ),
      width: '15%',
    },
    {
      render: (importJob: ImportJobWithName) => (
        <DisplayDate isoDate={importJob.dc.created} />
      ),
      responsive: ['lg'],
      title: <FormattedMessage id="cluster.clusterImport.dateColumnHeaderText" />,
      width: '20%',
    },
    {
      render: (importJob: ImportJobWithName) => (
        <StatusIndicator
          message={getStatusIndicatorMessage(importJob)!}
          progress={Math.round(importJob.progress.percent)}
          status={importJob.status}
        />
      ),
      title: <FormattedMessage id="cluster.clusterImport.statusColumnHeaderText" />,
      width: '25%',
    },
    {
      render: (importJob: ImportJobWithName) => (
        <ImportJobsListActions
          importJob={importJob}
          onDelete={onDelete}
          onShowDetails={handleShowDetails}
        />
      ),
      width: '5%',
    },
  ];

  return (
    <>
      <CloudUITable
        columns={columns}
        dataSource={combinedData}
        pagination={combinedData.length > 10}
        rowKey="id"
        showHeader
      />
      {importJobBeingViewed && (
        <Modal
          onCancel={handleHideDetails}
          open={showDetailsDialog}
          title={
            <FormattedMessage
              id="cluster.clusterImport.detailsModalTitle"
              values={{
                fileName: importJobBeingViewed.name,
                date: <DisplayDate isoDate={importJobBeingViewed.dc.created} />,
              }}
            />
          }
          footer={
            <>
              <Button kind={Button.kinds.SECONDARY} onClick={handleHideDetails}>
                <FormattedMessage id="common.close" />
              </Button>
              {importJobBeingViewed.status === OPERATION_STATES.SUCCEEDED && (
                <>
                  <Button
                    className="ml-2"
                    onClick={() => handleDeleteImportJob(importJobBeingViewed)}
                  >
                    <FormattedMessage id="cluster.clusterImport.removeFromHistoryButton" />
                  </Button>
                  <Button
                    className="ml-2"
                    onClick={() => handleRetryImportJob(importJobBeingViewed)}
                  >
                    <FormattedMessage id="cluster.clusterImport.importAgainButton" />
                  </Button>
                </>
              )}
              {importJobBeingViewed.status === OPERATION_STATES.FAILED && (
                <>
                  <Button
                    className="ml-2"
                    kind={Button.kinds.SECONDARY}
                    onClick={() => handleDeleteImportJob(importJobBeingViewed)}
                  >
                    <FormattedMessage id="cluster.clusterImport.removeFromHistoryButton" />
                  </Button>
                  <Button
                    className="ml-2"
                    onClick={() => handleRetryImportJob(importJobBeingViewed)}
                  >
                    <FormattedMessage id="common.retry" />
                  </Button>
                </>
              )}
            </>
          }
          width={900}
        >
          <table className="mx-auto w-full max-w-[340px]">
            <tbody>
              <tr>
                <td colSpan={4} className="pb-4">
                  <StatusIndicator
                    message={importJobBeingViewed.progress.message}
                    status={importJobBeingViewed.status}
                  />
                </td>
              </tr>
              <tr>
                <td className="w-2/5" />
                <td className="w-1/5 text-right">
                  <FormattedMessage id="cluster.clusterImport.successful" />
                </td>
                <td className="w-1/5 text-right">
                  <FormattedMessage id="cluster.clusterImport.failed" />
                </td>
                <td className="w-1/5 text-right">
                  <FormattedMessage id="common.total" />
                </td>
              </tr>
              <tr>
                <td>Files</td>
                <td className="text-right">
                  {importJobBeingViewed.progress.total_files -
                    importJobBeingViewed.progress.failed_files}
                </td>
                <td className="text-right">
                  {importJobBeingViewed.progress.failed_files}
                </td>
                <td className="text-right">
                  {importJobBeingViewed.progress.total_files}
                </td>
              </tr>
              <tr>
                <td>Records</td>
                <td className="text-right">
                  {importJobBeingViewed.progress.total_records -
                    importJobBeingViewed.progress.failed_records}
                </td>
                <td className="text-right">
                  {importJobBeingViewed.progress.failed_records}
                </td>
                <td className="text-right">
                  {importJobBeingViewed.progress.total_records}
                </td>
              </tr>
              {importJobBeingViewed.progress.total_files > 1 && (
                <tr>
                  <td colSpan={4} className="text-right">
                    <a
                      href={`/api/v2/clusters/${importJobBeingViewed.cluster_id}/import-jobs/${importJobBeingViewed.id}/progress/?limit=ALL`}
                      target="_blank"
                      rel="noreferrer"
                      download={`import-report-${moment
                        .utc(importJobBeingViewed.dc.created)
                        .format('DD-MM-YYYY')}.json`}
                    >
                      <Button kind={Button.kinds.TERTIARY} size={Button.sizes.SMALL}>
                        <FormattedMessage id="cluster.clusterImport.downloadImportSummaryButton" />
                      </Button>
                    </a>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {importJobBeingViewed.type === 'url' && (
            <div
              data-testid="copy-url-widget"
              className="mt-2 flex rounded-lg border border-neutral-200 bg-white"
            >
              <a
                href={importJobBeingViewed.url.url}
                className="flex-grow truncate px-4 py-3"
              >
                {importJobBeingViewed.url.url}
              </a>
              <CopyToClipboard
                successMessage={formatMessage({
                  id: 'cluster.clusterImport.importFromUrl.copyUrlSuccessMessage',
                })}
                textToCopy={importJobBeingViewed.url.url}
              >
                <div className="border-l border-neutral-200 bg-white px-3 py-2 text-crate-blue hover:text-[#23bfde]">
                  <CopyOutlined className="text-base" />
                </div>
              </CopyToClipboard>
            </div>
          )}
          {importJobBeingViewed.status === OPERATION_STATES.FAILED && (
            <div className="mt-4">
              <div className="mb-2">
                <ImportingHelpSection
                  defaultOpen
                  titleMessage={
                    <FormattedMessage
                      id="cluster.clusterImport.detailStatusImportFailedText"
                      values={{
                        source: (
                          <span className="font-bold">
                            {formatJobSource(importJobBeingViewed)}
                          </span>
                        ),
                      }}
                    />
                  }
                >
                  <Text className="break-words" pale>
                    {importJobBeingViewed.progress.message}
                    {importJobBeingViewed?.progress.records > 0 && (
                      <Text className="mt-2" pale>
                        <FormattedMessage
                          id="cluster.clusterImport.detailStatusImportFailedImportedText"
                          values={{
                            records: formatNumber(
                              importJobBeingViewed?.progress.records,
                              {
                                notation: 'compact',
                              },
                            ),
                          }}
                        />
                      </Text>
                    )}
                    {importJobBeingViewed?.progress.failed_records > 0 && (
                      <Text className="mt-2" pale>
                        <FormattedMessage
                          id="cluster.clusterImport.detailStatusImportFailedFailedText"
                          values={{
                            records: formatNumber(
                              importJobBeingViewed?.progress.failed_records,
                              {
                                notation: 'compact',
                              },
                            ),
                          }}
                        />
                      </Text>
                    )}
                  </Text>
                </ImportingHelpSection>
              </div>
            </div>
          )}
          {importJobBeingViewed.progress?.details?.create_table_sql && (
            <div className="mt-4">
              <div className="mb-2">
                <ImportingHelpSection
                  defaultOpen
                  isWarning={false}
                  titleMessage={
                    <FormattedMessage id="cluster.clusterImport.createdTableLabel" />
                  }
                >
                  <Text className="break-words" pale>
                    <FormattedMessage id="cluster.clusterImport.createdTableHelpText" />
                    <CloudUISyntaxHighlighter language="sql" title="sql">
                      {formatSQL(
                        importJobBeingViewed.progress.details.create_table_sql,
                      )}
                    </CloudUISyntaxHighlighter>
                  </Text>
                </ImportingHelpSection>
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
}

export default ImportJobsList;
