const messages = {
  // docs
  'cluster.clusterImportFile.docsLinkPartial': 'docs',
  'cluster.clusterImportFile.formattingRulesDocsText':
    'See the {link} for more details on how to import data',

  'cluster.clusterImportFile.title': 'Import files',
  'cluster.clusterImportFile.creatingImport': 'Creating import',
  'cluster.clusterImportFile.importCreated': 'Import created',
  'cluster.clusterImportFile.errorCreatingImport': 'Error creating import',

  // source step
  'cluster.clusterImportFile.sourceStep.title': 'Source',
  'cluster.clusterImportFile.sourceStep.file': 'Local File',
  'cluster.clusterImportFile.sourceStep.url': 'URL',
  'cluster.clusterImportFile.sourceStep.awsS3': 'AWS S3',
  'cluster.clusterImportFile.sourceStep.azureStorageContainer': 'Azure Storage',

  'cluster.clusterImportFile.sourceStep.recentFiles': 'Recent files',

  'cluster.clusterImportFile.sourceStep.fileName': 'File name',
  'cluster.clusterImportFile.sourceStep.format': 'Format',
  'cluster.clusterImportFile.sourceStep.compression': 'Compression',
  'cluster.clusterImportFile.sourceStep.formatRequiredText': 'Format is required',
  'cluster.clusterImportFile.sourceStep.urlLabel': 'Import URL',
  'cluster.clusterImportFile.sourceStep.urlPlaceholder':
    'https://s3.amazonaws.com/my.import.data.gz',
  'cluster.clusterImportFile.sourceStep.useDemoDataHelp':
    "Don't have your own data yet? {demoDataButton}",
  'cluster.clusterImportFile.sourceStep.useDemoDataPartial': 'Use our demo data set',
  'cluster.clusterImportFile.sourceStep.demoDataExplanationText':
    'The New York City {link} for July of 2019 will be imported into the given table ' +
    '(about 6.3M records). ' +
    'This may take a few minutes. ' +
    'Follow {blog_link} for some example queries you can run to explore this dataset.',

  // connection details
  'cluster.clusterImportFile.connectionDetailsStep.title': 'Connection',
  'cluster.clusterImportFile.connectionDetailsStep.secretField': 'Secret',
  'cluster.clusterImportFile.connectionDetailsStep.secretName': 'Secret',
  'cluster.clusterImportFile.connectionDetailsStep.endpoint': 'Endpoint',
  'cluster.clusterImportFile.connectionDetailsStep.secretPlaceholder':
    'Choose a secret',
  'cluster.clusterImportFile.connectionDetailsStep.endpointField':
    'Endpoint (for non-AWS S3 buckets only)',
  'cluster.clusterImportFile.connectionDetailsStep.endpointPlaceholder':
    'https://s3.example.com',

  // source details
  'cluster.clusterImportFile.sourceDetailsStep.title': 'Files',
  'cluster.clusterImportFile.sourceDetailsStep.selectFileFormat':
    'Select file format',
  'cluster.clusterImportFile.sourceDetailsStep.maxFileSizeExceededHelp':
    '{bytes} exceeds the maximum allowed size',
  'cluster.clusterImportFile.sourceDetailsStep.reimportedFileLabelText':
    '{name} ({size})',
  'cluster.clusterImportFile.sourceDetailsStep.fileUploadUIHelpText':
    'Drag and drop or',
  'cluster.clusterImportFile.sourceDetailsStep.fileUploadUIButton': 'browse',
  'cluster.clusterImportFile.sourceDetailsStep.allowedFileSizeText':
    'Max file size 1GB. ',
  'cluster.clusterImportFile.sourceDetailsStep.allowedFileFormatsText':
    'CSV (and dialects), JSON-Lines and Parquet files supported',
  'cluster.clusterImportFile.sourceDetailsStep.selectReimportPlaceholderText':
    'Select recently uploaded file',
  'cluster.clusterImportFile.sourceDetailsStep.bucketNameField': 'Bucket name',
  'cluster.clusterImportFile.sourceDetailsStep.cannotListBucketsError':
    'Could not list buckets: {error}',
  'cluster.clusterImportFile.sourceDetailsStep.containerNameField': 'Container name',
  'cluster.clusterImportFile.sourceDetailsStep.cannotListContainersError':
    'Could not list containers: {error}',
  'cluster.clusterImportFile.sourceDetailsStep.pathToFileField': 'Path to file(s)',
  'cluster.clusterImportFile.sourceDetailsStep.pathToFileHelp':
    'Wildcards in folder and filenames are supported, e.g. /path/*/*.json',
  'cluster.clusterImportFile.sourceDetailsStep.pathToFilePlaceholder':
    '/path/to/filename.ext',
  'cluster.clusterImportFile.sourceDetailsStep.cannotListBlobsError':
    'Could not list blobs: {error}',
  'cluster.clusterImportFile.sourceDetailsStep.cannotListPathsError':
    'Could not list S3 objects: {error}',

  // sink details
  'cluster.clusterImportFile.sinkDetailsStep.title': 'Table',
  'cluster.clusterImportFile.sinkDetailsStep.import': 'Import',
  'cluster.clusterImportFile.sinkDetailsStep.tableLabel': 'Table name',
  'cluster.clusterImportFile.sinkDetailsStep.schemaEvolution': 'Schema evolution',
  'cluster.clusterImportFile.sinkDetailsStep.schemaEvolutionHelp':
    'Automatically generates table schemas from imported files. New columns are added to existing tables as needed.',
  'cluster.clusterImportFile.sinkDetailsStep.tableNamePlaceholder': 'my_import_data',

  // summary details
  'cluster.clusterImportFile.summaryStep.title': 'Summary',

  // validation messages
  'cluster.clusterImportFile.s3Source.bucketNameRequiredText':
    'Bucket name is required',
  'cluster.clusterImportFile.sourceDetailsStep.containerNameRequiredText':
    'Container name is required',
  'cluster.clusterImportFile.secretRequiredText': 'Secret is required',
};

export default messages;
