export const getUserOrganizationRole = (user, organizationId) => {
  if (user && organizationId) {
    return user.organization_roles.find(
      role => role.organization_id === organizationId,
    )?.role_fqn;
  }
  return null;
};

export const getUserIsOrganizationAdmin = (user, organizationId) => {
  return getUserOrganizationRole(user, organizationId) === 'org_admin';
};
