import React from 'react';
import { useParams } from 'react-router-dom';
import { Code, Heading, Text } from '@crate.io/crate-gc-admin';
import OneClickImport from '../OneClickImport';
import { useGetClustersId } from '../../../../swrHooks';
import {
  renderAnchor,
  renderAnchorLink,
  renderBullet,
  renderSQL,
} from '../articleComponents';
import { useAnalytics } from '../../../../hooks';
import { TUTORIALS } from '../../../../constants/defaults';
import { USER_TRACKING_EVENTS } from '../../../../constants/segment';

const IMPORT_DATA_POST = [
  {
    destination: {
      create_table: true,
      table: 'devices_info',
      table_definition: [
        {
          name: 'device_id',
          type: 'TEXT',
        },
        {
          name: 'api_version',
          type: 'TEXT',
        },
        {
          name: 'manufacturer',
          type: 'TEXT',
        },
        {
          name: 'model',
          type: 'TEXT',
        },
        {
          name: 'os_name',
          type: 'TEXT',
        },
      ],
    },
    compression: 'gzip',
    format: 'json',
    type: 'url',
    url: {
      url: 'https://github.com/crate/cratedb-datasets/raw/main/cloud-tutorials/devices_info.json.gz',
    },
  },
  {
    destination: {
      create_table: true,
      table: 'devices_readings',
      table_definition: [
        {
          name: 'ts',
          type: 'TIMESTAMP',
          extra: 'WITH TIME ZONE',
        },
        {
          name: 'device_id',
          type: 'TEXT',
        },
        {
          name: 'battery',
          type: 'OBJECT(DYNAMIC)',
          extra: 'AS (level BIGINT, status TEXT, temperature DOUBLE PRECISION)',
        },
        {
          name: 'cpu',
          type: 'OBJECT(DYNAMIC)',
          extra:
            'AS (avg_1min DOUBLE PRECISION, avg_5min DOUBLE PRECISION, avg_15min DOUBLE PRECISION)',
        },
        {
          name: 'memory',
          type: 'OBJECT(DYNAMIC)',
          extra: 'AS (free BIGINT, used BIGINT)',
        },
      ],
    },
    compression: 'gzip',
    format: 'json',
    type: 'url',
    url: {
      url: 'https://github.com/crate/cratedb-datasets/raw/main/cloud-tutorials/devices_readings.json.gz',
    },
  },
];

function AdvancedTimeSeriesArticle() {
  const { trackEvent } = useAnalytics();
  const { clusterId } = useParams();
  const { data: cluster } = useGetClustersId(clusterId);

  const trackRunQuery = id => {
    trackEvent(USER_TRACKING_EVENTS.RUN_QUERY_ADMIN_UI, {
      query: `${TUTORIALS.ADVANCED_TIMESERIES_METADATA} - ${id}`,
    });
  };

  const manualContent = (
    <>
      <Heading className="pb-2 pt-4" level={Heading.levels.h4}>
        Creating the table
      </Heading>
      <Text className="pb-2">
        CrateDB uses SQL, a powerful and familiar language for database management.
        To store the device readings and the device info data, create two tables with
        columns tailored to the datasets using the CREATE TABLE command:
      </Text>
      {renderSQL(
        cluster,
        `CREATE TABLE IF NOT EXISTS doc.devices_readings (
  "ts" TIMESTAMP WITH TIME ZONE,
  "device_id" TEXT,
  "battery" OBJECT(DYNAMIC) AS (
    "level" BIGINT,
    "status" TEXT,
    "temperature" DOUBLE PRECISION
  ),
  "cpu" OBJECT(DYNAMIC) AS (
    "avg_1min" DOUBLE PRECISION,
    "avg_5min" DOUBLE PRECISION,
    "avg_15min" DOUBLE PRECISION
  ),
  "memory" OBJECT(DYNAMIC) AS (
    "free" BIGINT,
    "used" BIGINT
  )
);`,
        () => trackRunQuery(1),
      )}
      {renderSQL(
        cluster,
        `CREATE TABLE IF NOT EXISTS doc.devices_info (
"device_id" TEXT,
"api_version" TEXT,
"manufacturer" TEXT,
"model" TEXT,
"os_name" TEXT
);`,
        () => trackRunQuery(2),
      )}
      <Text>
        Using objects in the <Code>devices_readings</Code> dataset allows for the
        structured and efficient organization of complex, nested data, enhancing both
        data integrity and flexibility.
      </Text>

      {/* inserting data */}
      {renderAnchor('insert-data')}
      <Heading className="pb-2 pt-4" level={Heading.levels.h4}>
        Inserting data
      </Heading>
      <Text>
        Now, insert the data using the <Code>COPY FROM</Code> SQL statement.
      </Text>
      {renderSQL(
        cluster,
        `COPY doc.devices_info
FROM 'https://github.com/crate/cratedb-datasets/raw/main/cloud-tutorials/devices_info.json.gz'
WITH (compression='gzip', empty_string_as_null=true)
RETURN SUMMARY;`,
        () => trackRunQuery(3),
      )}
      {renderSQL(
        cluster,
        `COPY doc.devices_readings
FROM 'https://github.com/crate/cratedb-datasets/raw/main/cloud-tutorials/devices_readings.json.gz'
WITH (compression='gzip', empty_string_as_null=true)
RETURN SUMMARY;`,
        () => trackRunQuery(4),
      )}
    </>
  );

  return (
    <div className="flex items-start" data-testid="article-advanced-time-series">
      <div>
        {renderAnchor('introduction')}
        <Text className="pb-2">
          CrateDB is highly regarded as an optimal database solution for managing
          time-series data thanks to its unique blend of features. It is particularly
          effective when you need to combine time-series data with metadata, for
          instance, in scenarios where data like sensor readings or log entries, need
          to be augmented with additional context for more insightful analysis.
          CrateDB supports effective time-series analysis with fast aggregations, a
          rich set of built-in functions, and <Code>JOIN</Code> operations.
        </Text>
        <Text className="pb-2">
          In this tutorial, we will illustrate how to augment time-series data with
          the metadata to enable more comprehensive analysis. To get started let’s
          use a time-series dataset that captures various device readings, such as
          battery, CPU, and memory information. Each record includes:
        </Text>
        <ol className="list-decimal pb-2 pl-10">
          {renderBullet('ts', 'timestamp when each reading was taken.')}
          {renderBullet('device_id', 'identifier of the device.')}
          {renderBullet(
            'battery',
            'object containing battery level, status, and temperature.',
          )}
          {renderBullet(
            'cpu',
            'object containing average CPU loads over the last 1, 5, and 15 minutes.',
          )}
          {renderBullet(
            'memory',
            'object containing information about the device’s free and used memory.',
          )}
        </ol>
        <Text className="pb-2">
          The second dataset in this tutorial contains metadata information about
          various devices. Each record includes:
        </Text>
        <ol className="list-decimal pb-2 pl-10">
          {renderBullet('device_id', 'identifier of the device.')}
          {renderBullet(
            'api_version',
            'version of the API that the device supports.',
          )}
          {renderBullet('manufacturer', 'name of the manufacturer of the device.')}
          {renderBullet('model', 'model name of the device.')}
          {renderBullet(
            'os_name',
            'the name of the operating system running on the device.',
          )}
        </ol>
        {renderAnchor('create-table')}
        <OneClickImport
          buttonContents={
            <>
              <div className="leading-snug">Import device readings data</div>
              <div className="text-xs leading-snug opacity-80">
                2 tables, 103,000 rows, 18.1MiB
              </div>
            </>
          }
          cluster={cluster}
          importPOST={IMPORT_DATA_POST}
          manualContent={manualContent}
          successMessage={<div>Device readings data imported</div>}
          tutorialTrackingName={TUTORIALS.ADVANCED_TIMESERIES_METADATA}
        />
        {/* sample full-text search queries */}
        {renderAnchor('analyze-data')}
        <Heading className="pb-2 pt-4" level={Heading.levels.h4}>
          Time-series Analysis with Metadata
        </Heading>
        <Text className="pb-2">
          To illustrate <Code>JOIN</Code> operation, the first query retrieves the 30
          rows of combined data from two tables, <Code>devices_readings</Code> and
          <Code>devices_info</Code>, based on a matching <Code>device_id</Code> in
          both. It effectively merges the detailed readings and corresponding device
          information, providing a comprehensive view of each device’s status and
          metrics.
        </Text>
        {renderSQL(
          cluster,
          `SELECT *
FROM doc.devices_readings r
JOIN doc.devices_info i ON r.device_id = i.device_id
LIMIT 30;`,
          () => trackRunQuery(5),
        )}
        <Text className="pb-2">
          The next query illustrates the calculation of summaries for aggregate
          values. In particular, it finds average battery levels (
          <Code>avg_battery_level</Code>) for each day and shows the result in an
          ascending order.
        </Text>
        {renderSQL(
          cluster,
          `SELECT date_trunc('day', ts) AS "day", AVG(battery['level']) AS avg_battery_level
FROM doc.devices_readings
GROUP BY "day"
ORDER BY "day";`,
          () => trackRunQuery(6),
        )}
        <Text className="pb-2">
          Rolling averages are crucial in time-series analysis because they help
          smooth out short-term fluctuations and reveal underlying trends by
          averaging data points over a specified period. This approach is
          particularly effective in mitigating the impact of outliers and noise in
          the data, allowing for a clearer understanding of the true patterns in the
          time series.
        </Text>
        <Text className="pb-2">
          The following example illustrates the average (<Code>AVG</Code>), minimum (
          <Code>MIN</Code>), and maximum (<Code>MAX</Code>) battery temperature over
          a window of the last 100 temperature readings (
          <Code>ROWS BETWEEN 100 PRECEDING AND CURRENT ROW</Code>). The window is
          defined in descending order by timestamp (<Code>ts</Code>) and can be
          adapted to support different use cases.
        </Text>
        {renderSQL(
          cluster,
          `SELECT r.device_id,
    AVG(battery['temperature']) OVER w AS "last 100 temperatures",
    MIN(battery['temperature']) OVER w AS "min temperature",
    MAX(battery['temperature']) OVER w AS "max temperature"
FROM doc.devices_readings r
JOIN doc.devices_info i ON r.device_id = i.device_id
WINDOW w AS (ORDER BY "ts" DESC ROWS BETWEEN 100 PRECEDING AND CURRENT ROW);`,
          () => trackRunQuery(7),
        )}
        <Text className="pb-2">
          The next query shows how to extract the most recent reading for each device
          of the mustang model. The query selects the latest timestamp (
          <Code>MAX(r.ts)</Code>), which represents the most recent reading time, and
          the corresponding latest readings for battery, CPU, and memory (
          <Code>MAX_BY</Code> for each respective component, using the timestamp as
          the determining factor). These results are grouped by{' '}
          <Code>device_id</Code> to ensure that the latest readings for each unique
          device are included. This query is particularly useful for monitoring the
          most current status of specific devices in a fleet.
        </Text>
        {renderSQL(
          cluster,
          `SELECT
    MAX(ts) as time,
    device_id,
    MAX_BY(battery, ts) as battery,
    MAX_BY(cpu, ts) as cpu,
    MAX_BY(memory, ts) as memory
FROM doc.devices_readings
WHERE device_id in (SELECt device_id FROM devices_info WHERE model = 'mustang')
GROUP BY device_id;`,
          () => trackRunQuery(8),
        )}
        <Text className="pb-2">
          Finally, we demonstrate the complex query that illustrates the usage of
          Common Table Expressions (CTEs) to aggregate and analyze device readings
          and information. The query relies on three CTEs to temporarily capture
          data:
        </Text>
        <ul className="list-disc pb-2 pl-10">
          <li className="pl-2 pt-0.5">
            <strong>MaxTimestamp CTE:</strong>{' '}
            <span>
              This CTE finds the most recent timestamp (<Code>MAX(ts)</Code>) in the
              doc.devices_readings table. It’s used to focus the analysis on recent
              data.
            </span>
          </li>
          <li className="pl-2 pt-0.5">
            <strong>DeviceReadingsAgg CTE:</strong>{' '}
            <span>
              This CTE calculates the average battery level and temperature for each
              device, but only for readings taken within the last week (as defined by
              <Code>r.ts &gt;= m.max_ts - INTERVAL &apos;1 week&apos;</Code>).
            </span>
          </li>
          <li className="pl-2 pt-0.5">
            <strong>DeviceModelInfo CTE:</strong>{' '}
            <span>
              This CTE selects details from the <Code>doc.devices_info</Code> table,
              specifically the <Code>device_id</Code>, <Code>manufacturer</Code>,
              <Code>model</Code>, and <Code>api_version</Code>, but only for devices
              with an API version between 21 and 25.
            </span>
          </li>
        </ul>
        <Text className="pb-2">
          The main <Code>SELECT</Code> statement joins the{' '}
          <Code>DeviceReadingsAgg</Code> and <Code>DeviceModelInfo</Code> CTEs, and
          aggregates data to provide the average battery level and temperature for
          each combination of manufacturer, model, and API version. It also proivdes
          the number of readings (<Code>COUNT(*)</Code>) for each grouping.
        </Text>
        <Text className="pb-2">
          Overall, the query aims to provide a detailed analysis of the battery
          performance (both level and temperature) for devices with specific API
          versions, while focusing only on recent data. It allows for a better
          understanding of how different models and manufacturers are performing in
          terms of battery efficiency within a specified API range and time frame.
        </Text>
        {renderSQL(
          cluster,
          `WITH
max_timestamp AS (
    SELECT MAX(ts) AS max_ts
    FROM doc.devices_readings
),
device_readings_agg AS (
    SELECT
        r.device_id,
        AVG(r.battery['level']) AS avg_battery_level,
        AVG(r.battery['temperature']) AS avg_battery_temperature
    FROM
        doc.devices_readings r, max_timestamp m
    WHERE
        r.ts >= m.max_ts - INTERVAL '1 week'
    GROUP BY
        r.device_id
),
device_model_info AS (
    SELECT
        device_id,
        manufacturer,
        model,
        api_version
    FROM
        doc.devices_info
    WHERE
        api_version BETWEEN 21 AND 25
)
SELECT
    info.manufacturer,
    info.model,
    info.api_version,
    AVG(read.avg_battery_level) AS model_avg_battery_level,
    AVG(read.avg_battery_temperature) AS model_avg_battery_temperature,
    COUNT(*) AS readings_count
FROM
    device_readings_agg read
JOIN
    device_model_info info
ON
    read.device_id = info.device_id
GROUP BY
    info.manufacturer,
    info.model,
    info.api_version
ORDER BY
    model_avg_battery_level DESC;`,
          () => trackRunQuery(9),
        )}
        <Text className="pb-2">
          In conclusion, this tutorial has guided you through the process of querying
          and analyzing time-series data with CrateDB, demonstrating how to
          effectively merge device metrics with relevant metadata. These techniques
          and queries are important for unlocking deeper insights into device
          performance, equipping you with the skills needed to harness the full
          potential of time-series data in real-world applications.
        </Text>
      </div>
      <div className="hidden min-w-[250px] pl-8 lg:block">
        {renderAnchorLink('top', 'Introduction')}
        {renderAnchorLink('create-table', 'Creating the table')}
        {renderAnchorLink('analyze-data', 'Time-series Analysis with Metadata')}
      </div>
    </div>
  );
}

export default AdvancedTimeSeriesArticle;
