export default {
  'clusterHealth.messageText': '{status}{lastSeen}',
  'clusterHealth.greenText': 'Healthy',
  'clusterHealth.yellowText': 'Under-replicated',
  'clusterHealth.redText': 'Missing shards',
  'clusterHealth.unreachableText': 'Unreachable',
  'clusterHealth.unknownText': 'Unknown',
  'clusterHealth.suspendedText': 'Suspended',
  'clusterHealth.lastSeenText': ' as of {relativeTime}',

  'clusterHealth.greenTooltipText': 'Your cluster is healthy',
  'clusterHealth.yellowTooltipText':
    'Some of your tables have under-replicated shards. Please log in to your cluster to check.',
  'clusterHealth.redTooltipText':
    'Some of your tables have missing shards. This can happen if you have recently restarted a node. Our support team is already notified and investigating the issue.',
  'clusterHealth.unreachableTooltipText':
    'Your cluster could not be reached and is unavailable. Our support team is already notified and investigating the issue',
  'clusterHealth.unknownTooltipText':
    "We could not determine your cluster's status. This can happen if you have recently started your cluster. Our support team will be notified if the situation persists.",
  'clusterHealth.suspendedTooltipText':
    'Your cluster is suspended and is not available. You are currently only paying for the amount of storage used',
  'clusterHealth.backupScheduleUpdateText':
    "Your cluster's backup schedule is being changed...",
  'clusterHealth.cidrUpdateText':
    "Your cluster's allowed CIDR ranges are being updated...",
  'clusterHealth.changePlanText': "Your cluster's plan is being changed.",
  'clusterHealth.createClusterText': 'Your cluster is being deployed.',
  'clusterHealth.expandStorageText': "Your cluster's storage is being expanded.",
  'clusterHealth.passwordUpdateText': "Your cluster's password is being changed.",
  'clusterHealth.restoreSnapshot':
    'Another cluster is being cloned to this cluster.',
  'clusterHealth.scaleText': 'Your cluster is being scaled.',
  'clusterHealth.suspendText': 'Your cluster is being suspended or resumed.',
  'clusterHealth.upgradeText': 'Your cluster is being upgraded.',
  'clusterHealth.clusterBeingResumed': 'Your cluster is being resumed.',
  'clusterHealth.clusterBeingSuspended': 'Your cluster is being suspended.',
};
