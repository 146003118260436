const orgActions = [
  {
    label: 'organization.auditLogs.createOrganizationOption',
    value: 'organization.create',
  },
  {
    label: 'organization.auditLogs.editOrganizationOption',
    value: 'organization.edit',
  },
  {
    label: 'organization.auditLogs.addUserToOrganizationOption',
    value: 'organization.add_user',
  },
  {
    label: 'organization.auditLogs.updateUserInOrganizationOption',
    value: 'organization.update_user',
  },
  {
    label: 'organization.auditLogs.removeUserFromOrganizationOption',
    value: 'organization.remove_user',
  },
] as const;

const projectActions = [
  {
    label: 'organization.auditLogs.createProjectOption',
    value: 'project.create',
  },
  {
    label: 'organization.auditLogs.editProjectOption',
    value: 'project.edit',
  },
  {
    label: 'organization.auditLogs.addUserToProjectOption',
    value: 'project.add_user',
  },
  {
    label: 'organization.auditLogs.updateUserInProjectOption',
    value: 'project.update_user',
  },
  {
    label: 'organization.auditLogs.removeUserFromProjectOption',
    value: 'project.remove_user',
  },
  {
    label: 'organization.auditLogs.deleteProjectOption',
    value: 'project.delete',
  },
] as const;

const clusterActions = [
  {
    label: 'organization.auditLogs.createClusterOption',
    value: 'product.create_cluster',
  },
  {
    label: 'organization.auditLogs.planClusterOption',
    value: 'cluster.change_compute',
  },
  {
    label: 'organization.auditLogs.storageClusterOption',
    value: 'cluster.expand_storage',
  },
  {
    label: 'organization.auditLogs.scaleClusterOption',
    value: 'product.scale_cluster',
  },
  {
    label: 'organization.auditLogs.upgradeClusterOption',
    value: 'product.upgrade_cluster',
  },
  {
    label: 'organization.auditLogs.restoreSnapshotOption',
    value: 'cluster.restore_snapshot',
  },
  {
    label: 'organization.auditLogs.deleteClusterOption',
    value: 'product.delete_cluster',
  },
  {
    label: 'organization.auditLogs.restartClusterNode',
    value: 'cluster.restart_node',
  },
] as const;

const creditActions = [
  {
    label: 'organization.auditLogs.creditsCreateOption',
    value: 'credit.create',
  },
  {
    label: 'organization.auditLogs.creditsExpireOption',
    value: 'credit.expire',
  },
  {
    label: 'organization.auditLogs.creditsEditOption',
    value: 'credit.edit',
  },
] as const;

const subscriptionsActions = [
  {
    label: 'organization.auditLogs.saasSubscribeOption',
    value: 'saas.subscribe',
  },
  {
    label: 'organization.auditLogs.saasUnsubscribeOption',
    value: 'saas.unsubscribe',
  },
  {
    label: 'organization.auditLogs.saasSubscriptionActiveOption',
    value: 'saas.subscription.active',
  },
  {
    label: 'organization.auditLogs.saasSubscriptionOrgAssignedOption',
    value: 'saas.subscription.org_assigned',
  },
] as const;

export {
  orgActions,
  projectActions,
  clusterActions,
  creditActions,
  subscriptionsActions,
};
