import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from '@crate.io/crate-gc-admin';
import { useGetOrganizationsIdSecrets, useGetUsersMe } from 'src/swrHooks';
import AddSecretDrawer from './AddSecretDrawer';
import CloudUITable from '../../../components/CloudUITable';
import SectionContainer from '../../../components/SectionContainer';
import useMessage from '../../../hooks/useMessage';
import { getUserIsOrganizationAdmin } from '../../../utils/data/user';
import { apiDelete } from '../../../api';

function SecretList({ organizationId }) {
  const { formatMessage } = useIntl();
  const { showSuccessMessage } = useMessage();
  const { data: loggedInUser } = useGetUsersMe();
  const { data: secrets, mutate: mutateSecrets } =
    useGetOrganizationsIdSecrets(organizationId);
  const [addSecretDrawerVisible, setAddSecretDrawerVisible] = useState(false);

  const userIsOrgAdmin = useMemo(
    () => getUserIsOrganizationAdmin(loggedInUser, organizationId),
    [loggedInUser, organizationId],
  );

  const removeKey = async secret => {
    const { success } = await apiDelete(
      `/api/v2/organizations/${organizationId}/secrets/${secret.id}/`,
      {},
    );

    if (success) {
      showSuccessMessage(
        formatMessage(
          { id: 'organization.secrets.secretRemovedText' },
          { name: secret.name },
        ),
      );
      mutateSecrets(secrets.filter(s => s.id !== secret.id));
    }
  };

  const getColumns = () => {
    const columns = [
      {
        title: <FormattedMessage id="organization.secrets.secretNameLabel" />,
        key: 'name',
        dataIndex: 'name',
        width: '30%',
      },
      {
        title: <FormattedMessage id="organization.secrets.typeLabel" />,
        key: 'type',
        dataIndex: 'type',
        width: '30%',
      },
      {
        title: <FormattedMessage id="organization.secrets.descriptionLabel" />,
        key: 'description',
        render: secret => `${secret.description}`,
        width: '40%',
      },
    ];

    if (userIsOrgAdmin) {
      columns.push({
        key: 'actions',
        render: secret => (
          <div className="inline-block">
            <Popconfirm
              cancelText={<FormattedMessage id="common.no" />}
              okText={<FormattedMessage id="common.yes" />}
              onConfirm={() => removeKey(secret)}
              placement="topLeft"
              title={
                <FormattedMessage
                  id="organization.secrets.removeSecretText"
                  values={{ name: secret.name }}
                />
              }
            >
              <button
                aria-label="Delete"
                type="button"
                data-testid={`delete-secret-button-${secret.id}`}
              >
                <DeleteOutlined className="text-crate-blue" />
              </button>
            </Popconfirm>
          </div>
        ),
      });
    }

    return columns;
  };

  return (
    <SectionContainer
      actions={
        userIsOrgAdmin && (
          <Button
            kind={Button.kinds.TERTIARY}
            onClick={() => setAddSecretDrawerVisible(true)}
          >
            <FormattedMessage id="organization.secrets.addSecretButton" />
          </Button>
        )
      }
      testId="secret-list"
      loading={!secrets}
      title="Secrets"
    >
      <CloudUITable
        columns={getColumns()}
        dataSource={secrets}
        emptyText={formatMessage({
          id: 'organization.secrets.noSecretsDefinedText',
        })}
        rowKey="id"
        showHeader
        testId="secrets-table"
      />
      <AddSecretDrawer
        onClose={() => setAddSecretDrawerVisible(false)}
        organizationId={organizationId}
        isVisible={addSecretDrawerVisible}
      />
    </SectionContainer>
  );
}

SecretList.propTypes = {
  organizationId: PropTypes.string.isRequired,
};

export default SecretList;
