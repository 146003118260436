import React from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import NextSteps from './NextSteps';
import ConstrainWidth from '../../../components/ConstrainWidth/ConstrainWidth';
import ClusterCredentials from '../../../components/ClusterCredentials';
import ClusterSummary from '../../../components/ClusterSummary/ClusterSummary';
import LoadingContainer from '../../../components/LoadingContainer';
import SectionContainer from '../../../components/SectionContainer';
import TopBanner from '../../UI/TopBanner';
import { getClusterAsyncDeployInProgress } from '../../../utils/data/cluster';
import { OPERATION_STATES, OPERATION_TYPES } from '../../../constants/defaults';
import { CRATEDB_CLOUD_CLONE_FAILURE_DOCS } from '../../../constants/links';
import {
  useGetClustersId,
  useGetOrganizationsIdRegions,
  useGetProjects,
} from '../../../swrHooks';
import useSessionStore from '../../../state/session';

function ClusterOverview() {
  const { clusterId, organizationId } = useParams();
  const { data: cluster } = useGetClustersId(clusterId);
  const { data: regions } = useGetOrganizationsIdRegions(organizationId);
  const { data: projects } = useGetProjects();
  const clusterPasswords = useSessionStore(state => state.clusterPasswords);
  const clusterPasswordIsAvailable = clusterId in clusterPasswords;

  const restoreSnapshotFailed =
    cluster &&
    cluster.last_async_operation?.status === OPERATION_STATES.FAILED &&
    cluster.last_async_operation?.type === OPERATION_TYPES.RESTORE_SNAPSHOT;

  return (
    <ConstrainWidth>
      <LoadingContainer
        loading={!cluster || !regions || !projects}
        render={() =>
          cluster &&
          regions &&
          projects && (
            <>
              {restoreSnapshotFailed && (
                <TopBanner type={TopBanner.types.WARN}>
                  <FormattedMessage
                    id="cluster.clusterBackups.cloneFromBackupFailedHelp"
                    values={{
                      docsLink: (
                        <a
                          className="text-neutral-100 underline decoration-solid hover:text-neutral-100 hover:decoration-inherit"
                          href={CRATEDB_CLOUD_CLONE_FAILURE_DOCS}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FormattedMessage id="cluster.clusterBackups.cloneFromBackupFailedPartial" />
                        </a>
                      ),
                    }}
                  />
                </TopBanner>
              )}

              {clusterPasswordIsAvailable && (
                <ClusterCredentials
                  cluster={cluster}
                  organizationId={organizationId}
                  password={clusterPasswords[cluster.id]}
                  username="admin"
                />
              )}

              <ClusterSummary
                cluster={cluster}
                isListMode={false}
                organizationId={organizationId}
                projects={projects}
                regions={regions}
              />

              {/* next steps information */}
              <SectionContainer>
                <NextSteps
                  cluster={cluster}
                  loading={getClusterAsyncDeployInProgress()}
                />
              </SectionContainer>
            </>
          )
        }
      />
    </ConstrainWidth>
  );
}

export default ClusterOverview;
