import { INTEGRATION_TYPES } from 'src/constants/integrations';
import {
  FIELD_NAMES,
  IngestionTypeOption,
  AUTH_TYPES,
  TypeMappingOption,
  CONNECTION_FIELD_NAMES,
} from '../../constants';

export type CreateIntegrationMongoDBBody = {
  type: 'mongodb';
  [FIELD_NAMES.NAME]: string;
  [FIELD_NAMES.MONGODB_NAMESPACE]: {
    [FIELD_NAMES.MONGODB_COLLECTION_NAME]: string;
    [FIELD_NAMES.MONGODB_DATABASE_NAME]: string;
  };
  [FIELD_NAMES.DESTINATION_NAMESPACE]: {
    [FIELD_NAMES.TABLE_NAME]: string;
    [FIELD_NAMES.OBJECT_TYPE_STRATEGY]?: TypeMappingOption;
  };
  [FIELD_NAMES.INGESTION_TYPE]?: IngestionTypeOption;
};

export const MONGODB_SOURCE_FORM_DEFAULT_VALUES: CreateIntegrationMongoDBBody = {
  type: INTEGRATION_TYPES.MONGODB,
  [FIELD_NAMES.NAME]: '',
  [FIELD_NAMES.MONGODB_NAMESPACE]: {
    [FIELD_NAMES.MONGODB_COLLECTION_NAME]: '',
    [FIELD_NAMES.MONGODB_DATABASE_NAME]: '',
  },
  [FIELD_NAMES.DESTINATION_NAMESPACE]: {
    [FIELD_NAMES.TABLE_NAME]: '',
  },
};

export type CreateConnectionScramForm = {
  [CONNECTION_FIELD_NAMES.AUTH]: typeof AUTH_TYPES.SCRAM;
  [CONNECTION_FIELD_NAMES.NAME]: string;
  [CONNECTION_FIELD_NAMES.CONNECTION_STRING]: string;
  [CONNECTION_FIELD_NAMES.DEFAULT_DATABASE_NAME]: string;
  [CONNECTION_FIELD_NAMES.USERNAME]: string;
  [CONNECTION_FIELD_NAMES.PASSWORD]: string;
};
export type CreateConnectionX509Form = {
  [CONNECTION_FIELD_NAMES.AUTH]: typeof AUTH_TYPES.X509;
  [CONNECTION_FIELD_NAMES.NAME]: string;
  [CONNECTION_FIELD_NAMES.CONNECTION_STRING]: string;
  [CONNECTION_FIELD_NAMES.DEFAULT_DATABASE_NAME]: string;
  [CONNECTION_FIELD_NAMES.CERTIFICATE]: string;
};

export type CreateConnectionForm =
  | CreateConnectionScramForm
  | CreateConnectionX509Form;
export const CREATE_SCRAM_CONNECTION_DEFAULT_FIELD_VALUES: CreateConnectionScramForm =
  {
    [CONNECTION_FIELD_NAMES.AUTH]: AUTH_TYPES.SCRAM,
    [CONNECTION_FIELD_NAMES.NAME]: '',
    [CONNECTION_FIELD_NAMES.CONNECTION_STRING]: '',
    [CONNECTION_FIELD_NAMES.DEFAULT_DATABASE_NAME]: '',
    [CONNECTION_FIELD_NAMES.USERNAME]: '',
    [CONNECTION_FIELD_NAMES.PASSWORD]: '',
  };
export const CREATE_X509_CONNECTION_DEFAULT_FIELD_VALUES: CreateConnectionX509Form =
  {
    [CONNECTION_FIELD_NAMES.AUTH]: AUTH_TYPES.X509,
    [CONNECTION_FIELD_NAMES.NAME]: '',
    [CONNECTION_FIELD_NAMES.CONNECTION_STRING]: '',
    [CONNECTION_FIELD_NAMES.DEFAULT_DATABASE_NAME]: '',
    [CONNECTION_FIELD_NAMES.CERTIFICATE]: '',
  };
