import {
  SUPPORTED_FILE_FORMATS,
  SUPPORTED_FILE_TYPES,
  SupportedFileFormat,
  SupportedFileType,
} from '../../constants';

// used to map the MIME type (or extension for parquet files)
// of the attached file to Select values in the file import form.
// We get the MIME type (or extension for parquet files)
// from the file object and use this map to automatically
// set the compression or format values, and show the formatting rules
// when the file is attached
export const MIME_TYPES_TO_SUPPORTED_FORMAT: Partial<
  Record<SupportedFileType, SupportedFileFormat>
> = {
  [SUPPORTED_FILE_TYPES.CSV]: SUPPORTED_FILE_FORMATS.CSV,
  [SUPPORTED_FILE_TYPES.GZIP]: SUPPORTED_FILE_FORMATS.GZIP,
  [SUPPORTED_FILE_TYPES.JSON]: SUPPORTED_FILE_FORMATS.JSON,
  [SUPPORTED_FILE_TYPES.PARQUET]: SUPPORTED_FILE_FORMATS.PARQUET, // no MIME type registered for parquet files
} as const;

export const mapMimeTypeToSupportedFormat = (
  mimeType: SupportedFileType,
): SupportedFileFormat => MIME_TYPES_TO_SUPPORTED_FORMAT[mimeType]!;
