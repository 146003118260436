import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Popconfirm } from 'antd';
import ConstrainWidth from 'src/components/ConstrainWidth';
import LoadingContainer from 'src/components/LoadingContainer';
import { apiDelete } from 'src/api';
import {
  useGetClustersIdImportJobs,
  useGetClustersIdIntegrations,
  useGetFeaturesStatus,
} from 'src/swrHooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Heading } from '@crate.io/crate-gc-admin';
import ImportJobsList from './ImportJobsList';
import { ImportJob, Integration } from 'src/types';
import { clusterImportFile, clusterImportIntegration } from 'src/constants/paths';
import SectionContainer from 'src/components/SectionContainer';
import { JOB_ID_QUERY_PARAM } from '../ClusterImportFile/constants';
import IntegrationsList from './IntegrationsList';
import FeatureToggle from 'src/components/FeatureToggle';
import featureToggleNames from 'src/constants/featureToggleNames';
import getFeatureFlag from 'src/utils/data/features';

function ClusterImport() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { clusterId, organizationId, projectId } = useParams();

  // clear completed import jobs
  const [importJobsToBeCleared, setImportJobsToBeCleared] = useState<ImportJob[]>(
    [],
  );

  // feature status
  const { data: featureFlags, isLoading: isLoadingFeaturesStatus } =
    useGetFeaturesStatus();
  const integrationsEnabled = getFeatureFlag(
    featureFlags,
    featureToggleNames.FEATURE_INTEGRATIONS_ENABLED,
  );

  // swr
  const {
    data: importJobs,
    mutate: mutateImportJobs,
    isLoading: isLoadingImportJobs,
  } = useGetClustersIdImportJobs(clusterId!);
  const {
    data: integrations,
    mutate: mutateIntegrations,
    isLoading: isLoadingIntegrations,
  } = useGetClustersIdIntegrations(clusterId!);

  const handleImportJobDelete = async (importJob: ImportJob) => {
    await apiDelete(`/api/v2/clusters/${clusterId}/import-jobs/${importJob.id}/`);

    mutateImportJobs(
      importJobs!.filter(existingImportJob => existingImportJob.id !== importJob.id),
    );
  };

  const handleIntegrationDelete = async (integration: Integration) => {
    await apiDelete(`/api/v2/clusters/${clusterId}/import-jobs/${integration.id}/`);

    mutateIntegrations(
      integrations!.filter(
        existingIntegration => existingIntegration.id !== integration.id,
      ),
    );
  };

  const handleImportJobRetry = (importJob: ImportJob) => {
    navigate(
      `${clusterImportFile.build({
        clusterId,
        organizationId,
        projectId,
      })}?${JOB_ID_QUERY_PARAM}=${importJob.id}`,
    );
  };

  const canClearImportJobs = importJobs?.some(importJob =>
    ['FAILED', 'SUCCEEDED'].includes(importJob.status),
  );
  const clearImportJobs = () => {
    if (canClearImportJobs) {
      const jobsToBeDeleted = importJobs!.filter(importJob =>
        ['FAILED', 'SUCCEEDED'].includes(importJob.status),
      );

      setImportJobsToBeCleared([...jobsToBeDeleted]);
      jobsToBeDeleted.forEach(async importJob => handleImportJobDelete(importJob));
    }
  };

  return (
    <ConstrainWidth>
      <SectionContainer className="absolute h-full w-full overflow-x-auto rounded-none">
        <LoadingContainer
          loading={
            isLoadingImportJobs || isLoadingIntegrations || isLoadingFeaturesStatus
          }
          render={() => {
            return (
              <div className="flex flex-col gap-2">
                <FeatureToggle
                  featureName={featureToggleNames.FEATURE_INTEGRATIONS_ENABLED}
                >
                  <div className="flex items-center justify-between pb-4">
                    <Heading level={Heading.levels.h2}>
                      <FormattedMessage id="cluster.clusterImport.streamingIntegrations" />
                    </Heading>

                    <div className="flex gap-2">
                      <FeatureToggle
                        featureName={featureToggleNames.FEATURE_INTEGRATIONS_ENABLED}
                      >
                        <Button
                          onClick={() => {
                            navigate(
                              clusterImportIntegration.build({
                                clusterId,
                                organizationId,
                                projectId,
                              }),
                            );
                          }}
                        >
                          <FormattedMessage id="cluster.clusterImport.addNewIntegrationButton" />
                        </Button>
                      </FeatureToggle>

                      <Button
                        onClick={() => {
                          navigate(
                            clusterImportFile.build({
                              clusterId,
                              organizationId,
                              projectId,
                            }),
                          );
                        }}
                      >
                        <FormattedMessage id="cluster.clusterImport.addNewImportButton" />
                      </Button>
                    </div>
                  </div>

                  <div data-testid="integration-list-wrapper">
                    <IntegrationsList
                      integrations={integrations!}
                      clusterId={clusterId!}
                      organizationId={organizationId!}
                      projectId={projectId!}
                      onDelete={handleIntegrationDelete}
                    />
                  </div>
                </FeatureToggle>

                <div>
                  <div className="flex items-center justify-between pb-4">
                    <Heading level={Heading.levels.h2}>
                      <FormattedMessage id="cluster.clusterImport.fileImports" />
                    </Heading>

                    <div className="flex gap-2">
                      {canClearImportJobs && (
                        <Popconfirm
                          title={formatMessage({
                            id: 'cluster.clusterImport.clearAllButtonConfirmText',
                          })}
                          placement="bottomRight"
                          onConfirm={() => clearImportJobs()}
                        >
                          <Button
                            disabled={importJobsToBeCleared.length > 0}
                            type={Button.types.BUTTON}
                            kind={Button.kinds.TERTIARY}
                          >
                            <FormattedMessage id="cluster.clusterImport.clearAllButton" />
                          </Button>
                        </Popconfirm>
                      )}

                      {!integrationsEnabled && (
                        <Button
                          onClick={() => {
                            navigate(
                              clusterImportFile.build({
                                clusterId,
                                organizationId,
                                projectId,
                              }),
                            );
                          }}
                        >
                          <FormattedMessage id="cluster.clusterImport.addNewImportButton" />
                        </Button>
                      )}
                    </div>
                  </div>

                  <div data-testid="import-job-list-wrapper">
                    <ImportJobsList
                      importJobs={importJobs || []}
                      onDelete={handleImportJobDelete}
                      onRetry={handleImportJobRetry}
                    />
                  </div>
                </div>
              </div>
            );
          }}
        />
      </SectionContainer>
    </ConstrainWidth>
  );
}
export default ClusterImport;
