import { DownOutlined, UpOutlined } from '@ant-design/icons';
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function NumberStepper({
  initialValue,
  min,
  max,
  step,
  id,
  onValueChange,
  formatter,
  parser,
  testId,
}) {
  const numberInput = useRef(null);
  const [value, setValue] = useState(parseInt(initialValue, 10));
  const [isValid, setIsValid] = useState(true);

  const updateValidity = () => {
    if (
      numberInput.current.value === '' ||
      Number.isNaN(Number(numberInput.current.value))
    ) {
      setIsValid(false);
      return;
    }

    const inputValue = parseInt(numberInput.current.value, 10);

    if (
      inputValue < formatter(parseInt(min, 10)) ||
      inputValue > formatter(parseInt(max, 10))
    ) {
      setIsValid(false);
      return;
    }

    if ((inputValue - formatter(parseInt(min, 10))) % parseInt(step, 10) > 0) {
      setIsValid(false);
      return;
    }

    setIsValid(true);
    onValueChange(parser(inputValue));
    setValue(parser(inputValue));
  };

  const updateManual = evt => {
    setValue(evt.target.value);
    updateValidity();
  };

  return (
    <div
      className={cx(
        'border-2',
        'border-crate-border-light',
        'flex',
        'overflow-hidden',
        'rounded',
        'w-[100px]',
        {
          'bg-white': isValid,
          'bg-red-600': !isValid,
        },
      )}
    >
      <input
        type="number"
        value={isValid ? formatter(value) : value}
        min={formatter(min)}
        max={formatter(max)}
        step={step}
        ref={numberInput}
        onChange={updateManual}
        data-testid={testId}
        id={id}
        style={{ MozAppearance: 'textfield' }} // this must be kept inline for firefox
        className={cx(
          'bg-transparent',
          'border-0',
          'flex-auto',
          'mx-1.5',
          'my-0.5',
          'outline-0',
          'w-[60px]',
          { 'text-black': isValid, 'text-white': !isValid },
        )}
      />
      <div className="flex flex-col border-l border-crate-border-light">
        <button
          onClick={() => {
            numberInput.current.stepUp();
            updateValidity();
          }}
          data-testid="step-up-button"
          type="button"
          className="align-center flex h-[17px] w-[22px] cursor-pointer items-center justify-center border-0 border-b border-crate-border-light bg-white"
        >
          <UpOutlined className="text-xs" />
        </button>
        <button
          onClick={() => {
            numberInput.current.stepDown();
            updateValidity();
          }}
          data-testid="step-down-button"
          type="button"
          className="align-center flex h-[17px] w-[22px] cursor-pointer items-center justify-center border-0 bg-white"
        >
          <DownOutlined className="text-xs" />
        </button>
      </div>
    </div>
  );
}

NumberStepper.propTypes = {
  initialValue: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  formatter: PropTypes.func.isRequired,
  parser: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

NumberStepper.defaultProps = {
  testId: 'stepper-input',
};

export default NumberStepper;
