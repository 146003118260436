import { FileAddOutlined, LinkOutlined } from '@ant-design/icons';
import logoAmazonS3 from '../../../../../assets/logo_amazon_s3.png';
import logoAzureStorage from '../../../../../assets/logo_azure_2021.svg';
import { ImportJobType } from 'src/types';

export const IMPORT_JOBS_SOURCES = [
  {
    title: 'cluster.clusterImportFile.sourceStep.file',
    image: <FileAddOutlined className="text-4xl" />,
    icon: <FileAddOutlined />,
    key: 'file' as ImportJobType,
  },
  {
    title: 'cluster.clusterImportFile.sourceStep.url',
    image: <LinkOutlined className="text-4xl" />,
    icon: <LinkOutlined />,
    key: 'url' as ImportJobType,
  },
  {
    title: 'cluster.clusterImportFile.sourceStep.awsS3',
    image: <img src={logoAmazonS3} alt="s3 bucket logo" className="size-10" />,
    icon: (
      <img src={logoAmazonS3} alt="s3 bucket icon" className="inline-flex size-4" />
    ),
    key: 's3' as ImportJobType,
  },
  {
    title: 'cluster.clusterImportFile.sourceStep.azureStorageContainer',
    image: (
      <img
        src={logoAzureStorage}
        alt="azure blob storage logo"
        className="size-10"
      />
    ),
    icon: (
      <img
        src={logoAzureStorage}
        alt="azure blob storage icon"
        className="inline-flex size-4"
      />
    ),
    key: 'azureblob' as ImportJobType,
  },
];
