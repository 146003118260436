import { useEffect } from 'react';
import { useGetOrganizationsId, useGetUsersMe } from '../../swrHooks';
import usePersistentStore from '../../state/persistent';
import { getSegmentProps } from '../../constants/segment';

function SegmentLoader() {
  const { currentOrganizationId } = usePersistentStore();
  const { data: user } = useGetUsersMe();
  const { data: organization } = useGetOrganizationsId(currentOrganizationId);

  useEffect(() => {
    if (organization && user) {
      window.analytics.identify(user.uid, getSegmentProps(user, organization));
    }
  }, [organization, user]);

  return null;
}

export default SegmentLoader;
