export default {
  AT: 'ATU12345678',
  BE: 'BE0123456789',
  BG: 'BG0123456789',
  CY: 'CY12345678Z',
  CZ: 'CZ1234567890',
  DE: 'DE123456789',
  DK: 'DK12345678',
  EE: 'EE123456789',
  ES: 'ESA1234567Z',
  FI: 'FI12345678',
  FR: 'FRAB123456789',
  GR: 'EL123456789',
  HR: 'HR12345678912',
  HU: 'HU12345678912',
  IE: 'IE1234567AB',
  IT: 'IT12345678912',
  LT: 'LT123456789123',
  LU: 'LU12345678',
  LV: 'LV12345678912',
  MT: 'MT12345678',
  NL: 'NL123456789B12',
  PL: 'PL1234567890',
  PT: 'PT123456789',
  RO: 'RO1234567891',
  SE: 'SE123456789123',
  SK: 'SI12345678',
  SI: 'SK1234567891',
} as const;
