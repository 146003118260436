import {
  CLUSTER_BACKUPS_LAST_2_DAYS,
  CLUSTER_BACKUPS_LAST_3_DAYS,
  CLUSTER_BACKUPS_LAST_WEEK,
  CLUSTER_BACKUPS_LAST_2_WEEKS,
  CLUSTER_BACKUPS_ALL,
} from '../../../constants/defaults';

const getTimeFilters = formatMessage => [
  {
    value: CLUSTER_BACKUPS_LAST_2_DAYS,
    displayName: formatMessage(
      { id: 'cluster.clusterBackups.lastDaysOption' },
      { num: 2 },
    ),
  },
  {
    value: CLUSTER_BACKUPS_LAST_3_DAYS,
    displayName: formatMessage(
      { id: 'cluster.clusterBackups.lastDaysOption' },
      { num: 3 },
    ),
  },
  {
    value: CLUSTER_BACKUPS_LAST_WEEK,
    displayName: formatMessage({ id: 'cluster.clusterBackups.lastWeekOption' }),
  },
  {
    value: CLUSTER_BACKUPS_LAST_2_WEEKS,
    displayName: formatMessage(
      { id: 'cluster.clusterBackups.lastWeeksOption' },
      { num: 2 },
    ),
  },
  {
    value: CLUSTER_BACKUPS_ALL,
    displayName: formatMessage({ id: 'cluster.clusterBackups.allOption' }),
  },
];

export default getTimeFilters;
