import Cookies from 'js-cookie';
import { callback } from '../constants/auth';
import { LOGIN_OPTIONS_COOKIE } from '../constants/defaults';
import { expiring24hCookieOptions } from '../constants/cookieOptions';

export const getCookie = key => {
  return Cookies.get(key);
};

export const deleteCookie = (key, config) => {
  Cookies.remove(key, config);
};

export const setCookie = (key, value, config) => {
  Cookies.set(key, value, config);
};

export const login = (identityProvider, redirectUri) => {
  const loginUri = callback.login_uri(identityProvider, redirectUri);
  window.location.assign(loginUri);
};

export const logout = () => {
  deleteCookie(LOGIN_OPTIONS_COOKIE, expiring24hCookieOptions);
};
