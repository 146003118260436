import React, { PropsWithChildren } from 'react';
import cx from 'classnames';
import { Heading, Text } from '@crate.io/crate-gc-admin';
import LoadingContainer from '../LoadingContainer';
import { LoadingContainerAlignment } from '../LoadingContainer/LoadingContainer';

export type SectionContainerProps = PropsWithChildren<{
  actions?: React.ReactNode;
  className?: string;
  description?: React.ReactNode;
  loading?: boolean;
  title?: React.ReactNode;
  testId?: string;
}>;

function SectionContainer({
  actions,
  children,
  className,
  description,
  loading = false,
  testId,
  title,
}: SectionContainerProps) {
  const loaderAlignment: LoadingContainerAlignment = title ? 'left' : 'center';

  const classes = cx(
    'bg-white',
    'flex',
    'flex-col',
    'mb-4',
    'p-6',
    'rounded-lg',
    'self-stretch',
    'w-full',
    {
      // vertically and horizontally align the LoadingContainer in the middle
      flex: loading && loaderAlignment === LoadingContainer.loaderAlignment.CENTER,
      'items-center':
        loading && loaderAlignment === LoadingContainer.loaderAlignment.CENTER,
      'justify-around':
        loading && loaderAlignment === LoadingContainer.loaderAlignment.CENTER,
    },
    className,
  );

  return (
    <section className={classes} data-testid={testId}>
      {title && (
        <div>
          <div className="mb-4 flex flex-wrap items-center justify-between gap-4">
            <Heading level={Heading.levels.h2}>{title}</Heading>
            {actions && actions}
          </div>
          {description && (
            <div className="mb-4">
              <Text pale>{description}</Text>
            </div>
          )}
        </div>
      )}
      <LoadingContainer
        loading={loading}
        loaderAlignment={loaderAlignment}
        render={() => children}
      />
    </section>
  );
}

export default SectionContainer;
