import { ValueOf } from 'src/types';

export const DEPLOY_OPTIONS = {
  CPU: {
    initialValue: 2,
    min: 1,
    max: 16,
    step: 1,
  },
  RAM: {
    initialValue: 1024 ** 3 * 8, // 8GiB
    min: 1024 ** 3 * 4, // 4GiB
    max: 1024 ** 3 * 128, // 128GiB
    step: 1,
  },
  STORAGE: {
    initialValue: 1024 ** 3 * 16, // 16GiB
    min: 1024 ** 3 * 4, // 4GiB
    max: 1024 ** 4 * 2, // 2TiB
    step: 1,
  },
  NODES: {
    initialValue: 1,
    min: 0,
    max: 9,
    step: 2,
  },
} as const;

export const CLOUD_GROUPS = {
  SHARED: 'shared',
  DEDICATED: 'dedicated',
  ENTERPRISE: 'enterprise',
} as const;
export type CloudGroup = ValueOf<typeof CLOUD_GROUPS>;

export const CRFREE_USAGE_REASONS = {
  IOT_DATA: 'IoT Data Storage',
  LOG_ANALYSIS: 'Log Analysis',
  TIME_SERIES: 'Time-Series Analytics',
  GEOSPATIAL: 'Geospatial Data Analysis',
  VECTOR_SEARCH: 'Vector Search',
  OTHER: 'Other',
} as const;
