import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { IDENTITY_PROVIDER } from '../../../constants/defaults';
import { USER_TRACKING_EVENTS } from '../../../constants/segment';
import { login } from '../../../utils/auth';
import { useAnalytics } from '../../../hooks';

function LoginWithCognitoButton({ redirectUri }) {
  const { trackEvent } = useAnalytics();

  const handleClick = () => {
    trackEvent(USER_TRACKING_EVENTS.USER_LOGGED_IN, {
      identity_provider: IDENTITY_PROVIDER.COGNITO,
    });
    login(IDENTITY_PROVIDER.COGNITO, redirectUri);
  };

  return (
    <button
      className="w-full justify-center rounded bg-crate-blue p-1.5 text-white"
      type="button"
      onClick={handleClick}
    >
      <FormattedMessage id="account.login.cognitoSignInButton" />
    </button>
  );
}

LoginWithCognitoButton.propTypes = {
  redirectUri: PropTypes.string.isRequired,
};

export default LoginWithCognitoButton;
