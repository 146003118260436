import React from 'react';
import ConstrainWidth from '../../../components/ConstrainWidth';
import ClusterCredentials from './ClusterCredentials';
import DeleteCluster from './DeleteCluster';
import DeletionProtection from './DeletionProtection';
import IPAllowlist from './IPAllowlist';
import RequestPrivateLink from './RequestPrivateLink';
import SuspendResume from './SuspendResume';
import UpgradeCluster from './UpgradeCluster';

function ClusterManage() {
  return (
    <ConstrainWidth>
      <div className="grid grid-cols-1 gap-x-4 lg:grid-cols-2">
        <ClusterCredentials />
        <DeletionProtection />
        <div className="lg:col-span-2">
          <UpgradeCluster />
        </div>
        <div className="lg:col-span-2">
          <IPAllowlist />
        </div>
        <div className="lg:col-span-2">
          <RequestPrivateLink />
        </div>
        <SuspendResume />
        <DeleteCluster />
      </div>
    </ConstrainWidth>
  );
}

export default ClusterManage;
