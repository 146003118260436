import PropTypes from 'prop-types';

export default PropTypes.shape({
  dc: PropTypes.shape({
    created: PropTypes.string.isRequired,
    modified: PropTypes.string.isRequired,
  }),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  organization_id: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  backup_location: PropTypes.shape({
    location_type: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
  }),
});
