import { ValueOf } from 'src/types';

const featureToggleNames = {
  FEATURE_EDGE_REGIONS: 'FEATURE_EDGE_REGIONS',
  FEATURE_USE_PAYMENT_INTENT: 'FEATURE_USE_PAYMENT_INTENT',
  FEATURE_MONTHLY_CONSUMPTION: 'FEATURE_MONTHLY_CONSUMPTION',
  FEATURE_GRAND_CENTRAL_ENABLED: 'FEATURE_GRAND_CENTRAL_ENABLED',
  FEATURE_INTEGRATIONS_ENABLED: 'FEATURE_INTEGRATIONS_ENABLED',
} as const;
export type FeatureToggleName = ValueOf<typeof featureToggleNames>;

export default featureToggleNames;
