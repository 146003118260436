import PropTypes from 'prop-types';
import { OPERATION_STATES } from '../constants/defaults';

export default PropTypes.shape({
  cluster_id: PropTypes.string.isRequired,
  compression: PropTypes.string.isRequired,
  file: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  format: PropTypes.string.isRequired,
  url: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  dc: PropTypes.shape({
    created: PropTypes.string.isRequired,
    modified: PropTypes.string.isRequired,
  }),
  destination: PropTypes.shape({
    create_table: PropTypes.bool.isRequired,
    table: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.keys(OPERATION_STATES)).isRequired,
  type: PropTypes.oneOf(['file', 's3', 'url']),
  progress: PropTypes.shape({
    bytes: PropTypes.number.isRequired,
    records: PropTypes.number.isRequired,
  }).isRequired,
});
