import commonEN from './constants/commonMessages/messages.en';
import accountEN from './features/account/messages.en';
import clustersEN from './features/clusters/messages.en';
import organizationEN from './features/organization/messages.en';
import deployEN from './features/deploy/messages.en';
import componentsEN from './components/messages.en';

const rootMessages = {
  en: {
    ...commonEN,
    ...accountEN,
    ...clustersEN,
    ...organizationEN,
    ...deployEN,
    ...componentsEN,
  },
} as const;

export default rootMessages;
