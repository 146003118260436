import { SUPPORTED_FILE_FORMATS, SupportedFileFormat } from './fileCompressionTypes';
import { CompressionOption } from './options';

export const FIELD_NAMES = {
  COMPRESSION_OPTIONS: 'compression',
  CREATE_TABLE: 'create_table',
  DESTINATION_NAMESPACE: 'destination',
  FORMAT_OPTIONS: 'format',
  TABLE_NAME: 'table',
  TYPE: 'type',
  SCHEMA_EVOLUTION: 'create_table',

  // url namespace
  URL_NAMESPACE: 'url',
  URL: 'url',

  // file namespace
  FILE_NAMESPACE: 'file',
  ID: 'id',

  // azure namespace
  AZURE_NAMESPACE: 'azureblob',
  AZURE_BLOB_NAME: 'blob_name',
  AZURE_CONTAINER_NAME: 'container_name',
  AZURE_SECRET_ID: 'secret_id',

  // s3 namespace
  S3_NAMESPACE: 's3',
  S3_BUCKET: 'bucket',
  S3_ENDPOINT: 'endpoint',
  S3_FILE_PATH: 'file_path',
  S3_SECRET_ID: 'secret_id',
} as const;

export const DEFAULT_FIELD_VALUES = {
  [FIELD_NAMES.COMPRESSION_OPTIONS]: 'none' as CompressionOption,
  [FIELD_NAMES.FORMAT_OPTIONS]: SUPPORTED_FILE_FORMATS.JSON as SupportedFileFormat,
  [FIELD_NAMES.TABLE_NAME]: '',
  [FIELD_NAMES.SCHEMA_EVOLUTION]: false,
} as const;

export type DefaultFieldsType = typeof DEFAULT_FIELD_VALUES;

export type SinkDetailsStepForm = {
  [FIELD_NAMES.DESTINATION_NAMESPACE]: {
    [FIELD_NAMES.CREATE_TABLE]: boolean;
    [FIELD_NAMES.TABLE_NAME]: string;
  };
};
export const SINK_DEFAULT_FIELD_VALUES: SinkDetailsStepForm = {
  [FIELD_NAMES.DESTINATION_NAMESPACE]: {
    [FIELD_NAMES.CREATE_TABLE]: true,
    [FIELD_NAMES.TABLE_NAME]: '',
  },
};
