import {
  CompressionOption,
  FIELD_NAMES,
  SINK_DEFAULT_FIELD_VALUES,
  SinkDetailsStepForm,
  SupportedFileFormat,
} from '../../constants';

export type CreateImportJobUrlBody = {
  type: 'url';
  [FIELD_NAMES.URL_NAMESPACE]: {
    [FIELD_NAMES.URL]: string;
  };
  [FIELD_NAMES.COMPRESSION_OPTIONS]: CompressionOption;
  [FIELD_NAMES.FORMAT_OPTIONS]?: SupportedFileFormat;
} & SinkDetailsStepForm;

export const URL_SOURCE_FORM_DEFAULT_VALUES: CreateImportJobUrlBody = {
  type: 'url',
  [FIELD_NAMES.URL_NAMESPACE]: {
    [FIELD_NAMES.URL]: '',
  },
  [FIELD_NAMES.COMPRESSION_OPTIONS]: 'none',
  [FIELD_NAMES.FORMAT_OPTIONS]: undefined,
  ...SINK_DEFAULT_FIELD_VALUES,
};
