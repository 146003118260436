import PropTypes from 'prop-types';
import React from 'react';
import { Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import {
  DEFAULT_DRAWER_CLOSABLE,
  DEFAULT_DRAWER_PLACEMENT,
  DEFAULT_DRAWER_WIDTH,
} from '../../constants/defaults';

function CloudUIDrawer({ title, visible, onClose, children }) {
  return (
    <Drawer
      className="custom-drawer"
      closable={DEFAULT_DRAWER_CLOSABLE}
      onClose={onClose}
      placement={DEFAULT_DRAWER_PLACEMENT}
      title={title}
      open={visible}
      width={DEFAULT_DRAWER_WIDTH}
      forceRender
    >
      <button
        className="absolute right-6 top-4"
        type="button"
        onClick={onClose}
        data-testid="cloud-ui-drawer-close-btn"
      >
        <CloseOutlined className="text-lg" />
      </button>
      {children}
    </Drawer>
  );
}

CloudUIDrawer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    .isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default CloudUIDrawer;
