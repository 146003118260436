import { planTypes } from '../constants/intercom';

const getIntercomPlan = plan => {
  if (typeof plan === 'number' && plan > 0 && plan < 7) {
    return planTypes[plan - 1];
  }
  return 'Plan Unknown';
};

export default getIntercomPlan;
