import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Tabs } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Loader, Text } from '@crate.io/crate-gc-admin';
import { useGetClustersIdImportJobs } from '../../../swrHooks';
import { apiPost } from '../../../api';
import { clusterPropType } from '../../../models';
import { useAnalytics } from '../../../hooks';
import { USER_TRACKING_EVENTS } from '../../../constants/segment';

const IMPORT_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
};

function OneClickImport({
  buttonContents,
  cluster,
  importPOST,
  manualContent,
  successMessage,
  tutorialTrackingName,
}) {
  const { trackEvent } = useAnalytics();
  const [importIDs, setImportIDs] = useState([]);
  const [importStatus, setImportStatus] = useState(IMPORT_STATUS.NOT_STARTED);
  const { data: importJobs, mutate: mutateImportJobs } = useGetClustersIdImportJobs(
    cluster?.id,
  );

  const importData = async () => {
    if (importStatus === IMPORT_STATUS.NOT_STARTED) {
      setImportStatus(IMPORT_STATUS.IN_PROGRESS);

      trackEvent(USER_TRACKING_EVENTS.TUTORIAL_IMPORT, {
        tutorial: tutorialTrackingName,
      });

      const currImportIDs = [];
      importPOST.forEach(async postData => {
        const { success, data } = await apiPost(
          `/api/v2/clusters/${cluster.id}/import-jobs/`,
          postData,
        );

        if (success) {
          currImportIDs.push({ id: data.id, status: data.status });
          setImportIDs(currImportIDs);
          mutateImportJobs();
        } else {
          setImportStatus(IMPORT_STATUS.FAILED);
        }
      });
    }
  };

  // check if import job is completed
  useEffect(() => {
    if (importIDs.length > 0 && importJobs) {
      // extract a list of statuses from the import jobs
      const currImportIDs = importIDs.map(id => id.id);
      const uniqueImportJobStatuses = [
        ...new Set(
          importJobs
            .filter(job => currImportIDs.includes(job.id))
            .map(job => job.status),
        ),
      ];

      // if any one of the import jobs fails, mark the whole import as failed
      if (uniqueImportJobStatuses.includes(IMPORT_STATUS.FAILED)) {
        setImportStatus(IMPORT_STATUS.FAILED);
      }

      // if all jobs have succeeded, mark the whole import as succeeded
      if (
        uniqueImportJobStatuses.length === 1 &&
        uniqueImportJobStatuses[0] === IMPORT_STATUS.SUCCEEDED
      ) {
        setImportStatus(IMPORT_STATUS.SUCCEEDED);
      }
    }
  }, [importIDs, importJobs]);

  return (
    <div className="mt-4 rounded-lg bg-crate-blue/[.06] px-4 pb-2">
      {!cluster && (
        <div className="flex flex-col items-center gap-4">
          <Loader color={Loader.colors.PRIMARY} />
        </div>
      )}
      {cluster && (
        <Tabs
          items={[
            {
              key: 'auto',
              label: <FormattedMessage id="clusterLearn.oneClick.oneClickTab" />,
              children: (
                <>
                  <Text className="pb-2">
                    <FormattedMessage id="clusterLearn.oneClick.introText" />
                  </Text>
                  <div className="flex justify-center p-4">
                    {importStatus === IMPORT_STATUS.NOT_STARTED && (
                      <Button
                        className="py-2"
                        onClick={importData}
                        type={Button.types.BUTTON}
                      >
                        {buttonContents}
                      </Button>
                    )}
                    {importStatus === IMPORT_STATUS.IN_PROGRESS && (
                      <div className="flex flex-col items-center gap-4">
                        <Loader color={Loader.colors.PRIMARY} />
                        <Text>
                          <FormattedMessage id="clusterLearn.oneClick.inProgressText" />
                        </Text>
                      </div>
                    )}
                    {importStatus === IMPORT_STATUS.SUCCEEDED && (
                      <div className="flex flex-col items-center gap-2">
                        <CheckOutlined className="text-3xl text-crate-blue" />
                        {successMessage}
                      </div>
                    )}
                    {importStatus === 'FAILED' && (
                      <div className="flex flex-col items-center gap-2">
                        <CloseOutlined className="text-3xl text-crate-border-dark" />
                        <Text>
                          <FormattedMessage id="clusterLearn.oneClick.errorText" />
                        </Text>
                      </div>
                    )}
                  </div>
                </>
              ),
            },
            {
              key: 'manual',
              label: <FormattedMessage id="clusterLearn.oneClick.manualTab" />,
              children: manualContent,
            },
          ]}
        />
      )}
    </div>
  );
}

OneClickImport.propTypes = {
  buttonContents: PropTypes.node.isRequired,
  cluster: clusterPropType,
  importPOST: PropTypes.arrayOf(PropTypes.object).isRequired,
  manualContent: PropTypes.node.isRequired,
  successMessage: PropTypes.node.isRequired,
  tutorialTrackingName: PropTypes.string,
};

OneClickImport.defaultProps = {
  cluster: null,
  tutorialTrackingName: null,
};

export default OneClickImport;
