import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { uniqueNamesGenerator } from 'unique-names-generator';
import { shallow } from 'zustand/shallow';
import { FormattedMessage, useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { Modal } from 'antd';
import { Button } from '@crate.io/crate-gc-admin';
import ChoosePaymentMethod from './ChoosePaymentMethod';
import ClusterSpecification from './ClusterSpecification';
import HubspotFormExceedQuotaDeployNew from '../../components/HubspotForms/HubspotFormExceedQuotaDeployNew';
import ConstrainWidth from '../../components/ConstrainWidth/ConstrainWidth';
import CreditsRemaining from '../../components/CreditsRemaining';
import PaymentEntryForm from '../../components/PaymentEntryForm';
import PricingBreakdown from '../../components/PricingBreakdown/PricingBreakdown';
import SectionContainer from '../../components/SectionContainer';
import UsageQuestionnaire from './UsageQuestionnaire';
import ViewContainer from '../../components/ViewContainer';
import TestSWRIsFetching from '../../components/TestSWRIsFetching';
import { colors, starWars } from '../../constants/uniqueNameGenerator';
import {
  generateSecurePassword,
  toGibiBytes,
  toTibiBytes,
  validateURLSafe,
} from '../../utils';
import {
  useGetMetaCratedbversions,
  useGetProducts,
  useGetProductsClustersPrice,
  useGetOrganizationsIdClusters,
  useGetOrganizationsIdPaymentmethods,
  useGetOrganizationsIdRegions,
  useGetOrganizationsIdRemainingbudget,
  useGetOrganizationsIdCustomer,
} from '../../swrHooks';
import { useAnalytics } from '../../hooks';
import useMessage from '../../hooks/useMessage';
import useDeployStore from './state';
import { apiPost } from '../../api';
import { clusterOverview, organizationClustersList } from '../../constants/paths';
import {
  FREE_TIER_CLUSTER_PRODUCT_NAME,
  PAYMENT_METHODS_TYPE,
} from '../../constants/defaults';
import { CLOUD_GROUPS } from '../../constants/deploy';
import { USER_TRACKING_EVENTS } from '../../constants/segment';
import useSessionStore from '../../state/session';
import CustomerForm from '../../components/CustomerForm';
import isEU from '../../utils/isEu';

const createUniqueClusterName = () =>
  uniqueNamesGenerator({
    dictionaries: [colors, starWars],
    separator: '-',
  })
    .toLowerCase()
    .replace(/ /g, '-');

function DeployCluster() {
  const formRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { trackEvent } = useAnalytics();
  const { formatMessage } = useIntl();
  const { showErrorMessage } = useMessage();
  const { organizationId } = useParams();
  const setClusterPassword = useSessionStore(state => state.setClusterPassword);
  const [showBudgetExceededModal, setShowBudgetExceededModal] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [password, setPassword] = useState(null);
  const [showUsageQuestionnaire, setShowUsageQuestionnaire] = useState(false);
  const inputCustomerForm = useRef(false);

  // state
  const {
    cloudGroup,
    cloudNodeCount,
    cloudProduct,
    cloudStorage,
    clusterName,
    clusterNameIsInitialized,
    clusterNameIsUnique,
    clusterNameIsValid,
    deployInProgress,
    deployRegion,
    edgeBackupAccessID,
    edgeBackupAccessKey,
    edgeBackupEndpointURL,
    edgeBackupS3BucketName,
    edgeCPUCount,
    edgeNodeCount,
    edgeRAM,
    edgeStorage,
    edgeUseDefaultBackupLocation,
    paymentMethod,
    setClusterName,
    setClusterNameIsInitialized,
    setClusterNameIsUnique,
    setClusterNameIsValid,
    setDeployInProgress,
    setEdgeBackupIsValid,
    setEdgeBackupIsValidating,
    resetState,
  } = useDeployStore(state => state, shallow);

  // swr
  const {
    data: clusters,
    mutate: mutateClusters,
    isValidating: clustersValidating,
  } = useGetOrganizationsIdClusters(organizationId);
  const { data: regions } = useGetOrganizationsIdRegions(organizationId);
  const { data: remainingBudget } =
    useGetOrganizationsIdRemainingbudget(organizationId);
  const { data: products } = useGetProducts();
  const { data: price, isLoading: priceIsLoading } = useGetProductsClustersPrice(
    organizationId,
    deployRegion,
    cloudProduct,
    cloudNodeCount - 1,
    cloudStorage,
  );
  const { data: paymentMethods } =
    useGetOrganizationsIdPaymentmethods(organizationId);
  const { data: versions } = useGetMetaCratedbversions();
  const { data: customer } = useGetOrganizationsIdCustomer(organizationId);

  const validateClusterName = useMemo(
    () =>
      debounce(async (controller, name) => {
        // show error if the cluster name is invalid
        const isValid = validateURLSafe(name);
        setClusterNameIsValid(isValid);
        if (!isValid) return;

        // show error if this cluster name already exists
        // within this organization
        const isUniqueToOrg = !clusters
          .map(cluster => cluster.name.toLowerCase())
          .includes(clusterName.toLowerCase());
        if (!isUniqueToOrg) {
          setClusterNameIsUnique(false);
          return;
        }

        // check if this cluster name already exists in the database
        // try/catch here to prevent errors when controller.abort is called
        try {
          const response = await fetch(`/api/v2/clusters/name/${name}/`, {
            method: 'HEAD',
            signal: controller.signal,
          });

          // if this is a duplicate, generate a new name (if machine created)
          // or show an error if human created
          if (response.status !== 404) {
            if (!clusterNameIsInitialized) {
              setClusterName(createUniqueClusterName());
            } else {
              setClusterNameIsUnique(false);
            }
            return;
          }
        } catch (error) {
          return;
        }

        // at this point, the cluster name is valid, unique and should
        // be marked as initialized
        setClusterNameIsUnique(true);
        setClusterNameIsInitialized(true);
      }, 250),
    [
      clusterName,
      clusters,
      clusterNameIsInitialized,
      setClusterName,
      setClusterNameIsInitialized,
      setClusterNameIsUnique,
      setClusterNameIsValid,
    ],
  );

  // reset all state when the user navigates to and from this page
  useEffect(() => {
    const resetDeploy = () => {
      resetState();
      setPassword(generateSecurePassword());
    };

    resetDeploy();

    return () => {
      resetDeploy();
    };
  }, [location, resetState]);

  // handle cluster name change / set initial cluster name
  useEffect(() => {
    const controller = new AbortController();

    if (clusters) {
      if (clusterName === null) {
        // if clusterName is null, i.e. when this route is first loaded,
        // generate a new name, which will re-run this useEffect
        setClusterName(createUniqueClusterName());
      } else {
        validateClusterName(controller, clusterName);
      }
    }

    // cancel the previous api call when the useEffect fires again
    // to prevent a race condition
    return () => {
      controller.abort();
    };
  }, [clusters, clusterName, setClusterName, validateClusterName]);

  const validateCustomBackupLocation = async () => {
    // no need to send to the api if any of the required fields are empty
    if (!edgeBackupS3BucketName || !edgeBackupAccessID || !edgeBackupAccessKey) {
      return false;
    }

    setEdgeBackupIsValidating(true);

    const payload = {
      location: edgeBackupS3BucketName,
      location_type: 's3',
      credentials: {
        access_key_id: edgeBackupAccessID,
        secret_access_key: edgeBackupAccessKey,
      },
    };
    if (edgeBackupEndpointURL) {
      payload.additional_config = {
        endpoint_url: edgeBackupEndpointURL,
      };
    }
    const response = await apiPost(
      `/api/v2/regions/${deployRegion.name}/verify-backup-location/`,
      payload,
    );

    const isValid = response.success && response.data.s3_location_valid;
    setEdgeBackupIsValid(isValid);
    setEdgeBackupIsValidating(false);
    return isValid;
  };

  const getLatestStableVersion = () =>
    versions.crate_versions.stable.sort((a, b) => {
      if (a.major !== b.major) {
        return b.major - a.major;
      }
      if (a.minor !== b.minor) {
        return b.minor - a.minor;
      }
      return b.hotfix - a.hotfix;
    })[0].version;

  const deployCluster = async usageQuestionnaireResults => {
    if (priceIsLoading) {
      return;
    }

    // create a base object, with items that are common to
    // cloud and edge deployments
    const clusterSpecification = {
      cluster: {
        channel: 'stable',
        crate_version: getLatestStableVersion(),
        name: clusterName,
        password,
        username: 'admin',
      },
      project: {
        name: clusterName,
        region: deployRegion.name,
      },
      subscription_id: paymentMethod?.subscription_id
        ? paymentMethod.subscription_id
        : 'stripe',
    };

    // add cloud-specific items
    if (cloudProduct) {
      clusterSpecification.cluster = {
        ...clusterSpecification.cluster,
        product_name: cloudProduct.name,
        product_tier: cloudProduct.tier,
        product_unit: cloudProduct.scaling.filter(
          scaleUnit => scaleUnit.nodes === cloudNodeCount,
        )[0].value,
        hardware_specs:
          cloudProduct.name !== FREE_TIER_CLUSTER_PRODUCT_NAME
            ? {
                disk_size_per_node_bytes: cloudStorage,
              }
            : null,
      };

      // force the subscription type to be free_tier for CRFREE
      if (cloudProduct.name === FREE_TIER_CLUSTER_PRODUCT_NAME) {
        clusterSpecification.subscription_id = 'free_tier';
      }
    }

    // add edge-specific items
    if (deployRegion.is_edge_region) {
      clusterSpecification.cluster = {
        ...clusterSpecification.cluster,
        product_name: 'edge',
        product_tier: 'basic',
        product_unit: Math.floor(edgeNodeCount / 2),
        hardware_specs: {
          cpus_per_node: edgeCPUCount,
          disk_size_per_node_bytes: edgeStorage,
          disk_type: 'premium',
          disks_per_node: 1,
          memory_per_node_bytes: edgeRAM,
        },
      };

      // custom backup location credentials
      clusterSpecification.project = {
        ...clusterSpecification.project,
        backup_location: edgeUseDefaultBackupLocation
          ? null
          : {
              location_type: 's3',
              location: edgeBackupS3BucketName,
              credentials: {
                access_key_id: edgeBackupAccessID,
                secret_access_key: edgeBackupAccessKey,
              },
            },
      };

      // ensure the backup location is valid before proceeding
      if (clusterSpecification.project.backup_location) {
        const isValid = await validateCustomBackupLocation();
        if (!isValid) {
          showErrorMessage(
            formatMessage({
              id: 'deploy.backupConfiguration.deployFailedInvalidBackupLocationText',
            }),
          );
          return;
        }
      }

      // custom backup location endpoint url
      if (edgeUseDefaultBackupLocation && !!edgeBackupEndpointURL) {
        clusterSpecification.project.backup_location.additional_config = {
          endpoint_url: edgeBackupEndpointURL,
        };
      }
    }

    // if this is a CRFREE cluster, show the usage questionnaire
    // if it hasn't been completed yet
    if (
      !deployRegion.is_edge_region &&
      cloudProduct?.name === FREE_TIER_CLUSTER_PRODUCT_NAME &&
      !usageQuestionnaireResults
    ) {
      setShowUsageQuestionnaire(true);
      return;
    }

    setDeployInProgress(true);

    const { data: cluster, success } = await apiPost(
      `/api/v2/organizations/${organizationId}/clusters/`,
      clusterSpecification,
    );

    if (success) {
      // mutate clusters and projects to ensure the cluster list
      // updates in a timely manner
      mutateClusters([...clusters, cluster]);
      setClusterPassword(cluster.id, password);

      // send tracking event
      trackEvent(USER_TRACKING_EVENTS.DEPLOY_NEW_CLUSTER, usageQuestionnaireResults);

      // redirect immediately to cluster detail page
      navigate(
        clusterOverview.build({
          clusterId: cluster.id,
          projectId: cluster.project_id,
          organizationId,
        }),
      );
    }

    setDeployInProgress(false);
  };

  const navigateBackToContext = () =>
    navigate(
      organizationClustersList.build({
        organizationId,
      }),
    );

  const nextButtonHandler = async () => {
    // if total price exceeds the available remaining budget, show
    // a modal and bail out
    if (
      price.total_price.price_per_month > remainingBudget.remaining_budget_per_month
    ) {
      setShowBudgetExceededModal(true);
      return;
    }

    // if the user selected a free product, go ahead and deploy
    if (
      deployRegion.is_edge_region ||
      cloudProduct.name === FREE_TIER_CLUSTER_PRODUCT_NAME
    ) {
      deployCluster();
      return;
    }

    // from cluster config
    if (stepIndex === 0) {
      // if billing info are filled, go to payment method selection
      if (customer.email) {
        setStepIndex(2);
      } else {
        // otherwise go to set billing info (and update the inputCustomerForm ref)
        inputCustomerForm.current = true;
        setStepIndex(1);
      }
    }

    // from billing info form, go to the payment method selection
    if (stepIndex === 1) {
      setStepIndex(2);
    }

    // from payment method selection, deploy or enter credit card
    // details, depending on selected method status
    if (stepIndex === 2) {
      if (paymentMethod.is_setup) {
        deployCluster();
      } else {
        setStepIndex(3);
      }
    }

    // ensure the card is active before continuing
    if (stepIndex === 3) {
      formRef.current.submit();
    }
  };

  const prevButtonHandler = () => {
    // from payment method selection
    if (stepIndex === 2) {
      // if user has set billing info, go to billing info page
      if (inputCustomerForm.current) {
        setStepIndex(1);
      } else {
        // otherwise go to cluster config
        setStepIndex(0);
      }
      return;
    }

    // just go back
    setStepIndex(stepIndex - 1);
  };

  const renderButtons = () => {
    let isDisabled = !(
      deployRegion &&
      clusterNameIsInitialized &&
      clusterNameIsUnique &&
      clusterNameIsValid
    );

    if (!isDisabled && !deployRegion.is_edge_region) {
      isDisabled = !(cloudProduct && cloudStorage && cloudNodeCount);
    }

    if (!isDisabled && priceIsLoading) {
      isDisabled = true;
    }

    // When in the billing info form
    if (!isDisabled && stepIndex === 1) {
      // Always disable the deploy button
      // since there is the "Save" button on the bottom of the form
      isDisabled = true;
    }
    // When in the payment method screen
    if (!isDisabled && stepIndex === 2) {
      // disable the deploy button only if user has not selected
      // the payment method yet
      isDisabled = !paymentMethod;
    }

    if (!isDisabled && cloudGroup === CLOUD_GROUPS.ENTERPRISE) {
      isDisabled = true;
    }

    return (
      <div className="mt-4 flex flex-row justify-between">
        {stepIndex > 0 && (
          <div className="mr-2 basis-1/3">
            <Button
              className="w-full"
              onClick={prevButtonHandler}
              kind={Button.kinds.SECONDARY}
              types={Button.types.BUTTON}
            >
              <FormattedMessage id="common.back" />
            </Button>
          </div>
        )}
        <Button
          className="w-full"
          disabled={isDisabled}
          onClick={nextButtonHandler}
          kind={Button.kinds.PRIMARY}
          type={Button.types.BUTTON}
          id="deploy-cluster-button"
        >
          <FormattedMessage id="deploy.deployOverview.deployClusterButton" />
        </Button>
      </div>
    );
  };

  const renderMessages = () => {
    if (cloudProduct?.name === FREE_TIER_CLUSTER_PRODUCT_NAME) {
      return (
        <div className="space-y-2 rounded bg-crate-gray30 p-3 text-white">
          <p>
            <FormattedMessage id="deploy.managedConfiguration.crfreeRulesText1" />
          </p>
          <p>
            <FormattedMessage id="deploy.managedConfiguration.crfreeRulesText2" />
          </p>
          <p>
            <FormattedMessage id="deploy.managedConfiguration.crfreeRulesText3" />
          </p>
        </div>
      );
    }

    if (
      (paymentMethod?.type === PAYMENT_METHODS_TYPE.STRIPE &&
        isEU(customer.address.country)) ||
      paymentMethod?.type === PAYMENT_METHODS_TYPE.STRIPE_BANK_TRANSFER
    ) {
      return (
        <div className="space-y-2 rounded bg-crate-gray30 p-3 text-white">
          <p>
            <FormattedMessage
              id={
                paymentMethod?.type === PAYMENT_METHODS_TYPE.STRIPE
                  ? 'deploy.choosePaymentProvider.creditCardConversionRate'
                  : 'deploy.choosePaymentProvider.bankTransferConversionRate'
              }
            />
          </p>
        </div>
      );
    }

    return null;
  };

  const steps = [
    <ClusterSpecification
      clusters={clusters}
      organizationId={organizationId}
      products={products}
      regions={regions}
      validateCustomBackupLocation={validateCustomBackupLocation}
      onNameSubmit={nextButtonHandler}
    />,
    <CustomerForm onCancel={prevButtonHandler} onSuccess={nextButtonHandler} />,
    <ChoosePaymentMethod
      paymentMethods={paymentMethods}
      regionProvider={deployRegion?.name.split('.').slice(-1)[0]}
    />,
    <PaymentEntryForm
      formRef={formRef}
      onSubmitCallback={async success => {
        if (success) {
          deployCluster();
        }
      }}
    />,
  ];

  if (deployInProgress) {
    return <ViewContainer loading render={() => null} />;
  }

  if (showUsageQuestionnaire) {
    return <UsageQuestionnaire submitHandler={deployCluster} />;
  }

  const getComputeText = () => {
    if (!deployRegion) {
      return '';
    }

    let prefix = '';
    let cpu = 1;
    let ram = 1;
    let nodeCount = 1;

    if (deployRegion.is_edge_region) {
      // edge
      cpu = edgeCPUCount;
      ram = edgeRAM;
      nodeCount = edgeNodeCount;
    } else {
      // cloud
      if (!cloudProduct || !cloudNodeCount) {
        return '';
      }

      if (cloudProduct.tags.includes('shared')) {
        prefix = 'Up to ';
      }
      nodeCount = cloudNodeCount;
      cpu = cloudProduct.specs.cpu_cores;
      ram = cloudProduct.specs.ram_bytes;
    }

    return `${prefix}${cpu * nodeCount} vCPU, ${toGibiBytes(ram * nodeCount)} GiB RAM total`;
  };

  const getStorageText = () => {
    if (!deployRegion) {
      return '';
    }

    let storage = 1;
    let nodeCount = 1;

    if (deployRegion.is_edge_region) {
      // edge
      storage = edgeStorage;
      nodeCount = edgeNodeCount;
    } else {
      // cloud
      if (!cloudStorage || !cloudNodeCount) {
        return '';
      }

      storage = cloudStorage;
      nodeCount = cloudNodeCount;
    }

    const tib = toTibiBytes(storage * nodeCount);
    return tib >= 1
      ? `${tib} TiB total`
      : `${toGibiBytes(storage * nodeCount)} GiB total`;
  };

  return (
    <ConstrainWidth>
      <ViewContainer
        extra={
          <Button
            disabled={deployInProgress}
            kind={Button.kinds.TERTIARY}
            onClick={navigateBackToContext}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
        }
        heading={
          [
            <FormattedMessage id="deploy.deployOverview.containerStep0Title" />,
            <FormattedMessage id="deploy.deployOverview.containerStep1Title" />,
            <FormattedMessage id="deploy.deployOverview.containerStep2Title" />,
          ][stepIndex]
        }
        render={() => (
          <div className="grid grid-cols-12 gap-x-4 gap-y-2">
            {/* left column */}
            <div
              className="col-span-12 flex lg:col-span-7"
              data-testid="deploy-left-column"
            >
              <SectionContainer
                loading={
                  !(
                    clusterNameIsInitialized &&
                    clusters &&
                    products &&
                    regions &&
                    versions &&
                    remainingBudget
                  )
                }
              >
                {steps[stepIndex]}
              </SectionContainer>
            </div>

            {/* right column */}
            <div
              className={`col-span-12 lg:col-span-5 ${cloudGroup === CLOUD_GROUPS.ENTERPRISE ? 'opacity-40' : ''}`}
              data-testid="deploy-right-column"
            >
              {(deployRegion?.is_edge_region ||
                cloudProduct?.name !== FREE_TIER_CLUSTER_PRODUCT_NAME) && (
                <>
                  <SectionContainer>
                    <PricingBreakdown
                      computeText={getComputeText()}
                      loading={!price || priceIsLoading}
                      price={price}
                      storageText={getStorageText()}
                    />
                  </SectionContainer>
                  <CreditsRemaining />
                </>
              )}
              {renderMessages()}
              {renderButtons()}
            </div>
          </div>
        )}
      />
      <Modal
        closable={false}
        footer={null}
        open={showBudgetExceededModal}
        title={
          remainingBudget?.remaining_budget_per_month > 0 ? (
            <FormattedMessage
              id="deploy.quota.quotaExceededPriceTitle"
              values={{ quota: remainingBudget?.remaining_budget_per_month }}
            />
          ) : (
            <FormattedMessage id="deploy.quota.quotaExceededTitle" />
          )
        }
      >
        <HubspotFormExceedQuotaDeployNew
          onFormComplete={() => setShowBudgetExceededModal(false)}
          onFormCancel={() => setShowBudgetExceededModal(false)}
          hiddenFields={{
            cluster_configuration: [
              `organization: ${organizationId}`,
              `product: ${cloudProduct?.label}`,
              `region: ${deployRegion?.name}`,
              `storage: ${toGibiBytes(cloudStorage)} GiB`,
              `nodes: x${cloudNodeCount}`,
              `price per month: $${price?.total_price?.price_per_month}`,
            ].join('\n'),
          }}
        />
      </Modal>
      <TestSWRIsFetching fetchStatusList={[clustersValidating]} />
    </ConstrainWidth>
  );
}

export default DeployCluster;
