import { SUPPORTED_FILE_FORMATS } from './fileCompressionTypes';
import { FILE_COMPRESSION_OPTIONS } from './fileTypes';

export const COMPRESSION_OPTIONS = [
  {
    label: 'none',
    value: 'none' as const,
  },
  {
    label: 'Gzip',
    value: FILE_COMPRESSION_OPTIONS.GZIP,
  },
];
export type CompressionOption = (typeof COMPRESSION_OPTIONS)[number]['value'];

export const FORMAT_OPTIONS = [
  {
    label: 'CSV and variants' as const,
    value: SUPPORTED_FILE_FORMATS.CSV,
  },
  {
    label: 'Parquet' as const,
    value: SUPPORTED_FILE_FORMATS.PARQUET,
  },
  {
    label: 'JSON' as const,
    value: SUPPORTED_FILE_FORMATS.JSON,
  },
];
export type FormatOptionsValue = (typeof FORMAT_OPTIONS)[number]['value'];
