import { Button, Text } from '@crate.io/crate-gc-admin';
import { Modal } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import StatusIndicator from 'src/components/StatusIndicator';
import { OPERATION_STATES } from 'src/constants/defaults';
import { Integration, IntegrationMongoDBCdcProgress } from 'src/types';
import ImportingHelpSection from '../ImportingHelpSection';
import CloudUITable from 'src/components/CloudUITable';
import { INGESTION_TYPES } from '../../ClusterImportIntegration/constants';

export type IntegrationsListDetailsModalProps = {
  integration: Integration;
  show: boolean;
  getStatusIndicatorMessage: (integration: Integration) => void;
  handleClose: () => void;
};

type TableItem = {
  operation: string;
  failed: number;
  succeeded: number;
  processed: number;
};

function IntegrationsListDetailsModal({
  integration,
  show,
  getStatusIndicatorMessage,
  handleClose,
}: IntegrationsListDetailsModalProps) {
  const { formatNumber, formatMessage } = useIntl();

  const mapCdcProgressToTableItem = (cdcProgress: IntegrationMongoDBCdcProgress) => {
    return [
      {
        operation: formatMessage({
          id: 'cluster.clusterImport.insert',
        }),
        failed: cdcProgress.failed.insert,
        succeeded: cdcProgress.succeeded.insert,
        processed: cdcProgress.processed.insert,
      },
      {
        operation: formatMessage({
          id: 'cluster.clusterImport.update',
        }),
        failed: cdcProgress.failed.update,
        succeeded: cdcProgress.succeeded.update,
        processed: cdcProgress.processed.update,
      },
      {
        operation: formatMessage({
          id: 'cluster.clusterImport.delete',
        }),
        failed: cdcProgress.failed.delete,
        succeeded: cdcProgress.succeeded.delete,
        processed: cdcProgress.processed.delete,
      },
    ] satisfies TableItem[];
  };

  const metricsColumns = [
    {
      title: <FormattedMessage id="cluster.clusterImport.operationType" />,
      dataIndex: ['operation'],
      width: '25%',
    },
    {
      title: <FormattedMessage id="cluster.clusterImport.processed" />,
      dataIndex: ['processed'],
      width: '25%',
    },
    {
      title: <FormattedMessage id="cluster.clusterImport.successful" />,
      dataIndex: ['succeeded'],
      width: '25%',
    },
    {
      title: <FormattedMessage id="cluster.clusterImport.failed" />,
      dataIndex: ['failed'],
      width: '25%',
    },
  ];

  return (
    <Modal
      onCancel={handleClose}
      open={show}
      title={
        <div>
          <FormattedMessage
            id="cluster.clusterImport.detailsIntegrationModalTitle"
            values={{
              fileName: integration.name,
            }}
          />
          <div className="text-xs text-crate-border-mid">
            ID: <span>{integration.id}</span>
          </div>
        </div>
      }
      footer={
        <>
          <Button kind={Button.kinds.SECONDARY} onClick={handleClose}>
            <FormattedMessage id="common.close" />
          </Button>
        </>
      }
      width={900}
    >
      <div className="flex items-center gap-2">
        <div className="font-bold">
          <FormattedMessage id="cluster.clusterImport.statusColumnHeaderText" />:
        </div>
        <StatusIndicator
          message={getStatusIndicatorMessage(integration)!}
          progress={Math.round(integration.progress.percent)}
          status={integration.status}
        />
      </div>

      {(integration.ingestion_type === INGESTION_TYPES.IMPORT_AND_CDC ||
        integration.ingestion_type === INGESTION_TYPES.IMPORT_ONLY) && (
        <div className="mt-4">
          <div className="mb-2 font-bold">
            <FormattedMessage id="cluster.clusterImport.detailsIntegrationModalInitialLoadTitle" />
          </div>
          <CloudUITable
            columns={metricsColumns}
            dataSource={[
              {
                operation: formatMessage({
                  id: 'cluster.clusterImport.insert',
                }),
                failed: integration.progress.failed_records,
                succeeded: integration.progress.records,
                processed:
                  integration.progress.records + integration.progress.failed_records,
              },
            ]}
            pagination={false}
            rowKey="operation"
            showHeader
          />
        </div>
      )}

      {integration.cdc_progress && (
        <div className="mt-4">
          <div className="mb-2 font-bold">
            <FormattedMessage id="cluster.clusterImport.detailsIntegrationModalCdcTitle" />
          </div>
          <CloudUITable
            columns={metricsColumns}
            dataSource={mapCdcProgressToTableItem(integration.cdc_progress)}
            pagination={false}
            rowKey="operation"
            showHeader
          />
        </div>
      )}

      {integration.status === OPERATION_STATES.FAILED && (
        <div className="mt-4">
          <div className="mb-2">
            <ImportingHelpSection
              defaultOpen
              titleMessage={
                <FormattedMessage
                  id="cluster.clusterImport.detailStatusImportFailedText"
                  values={{
                    source: <span className="font-bold">{integration.name}</span>,
                  }}
                />
              }
            >
              <Text className="break-words" pale>
                {integration.progress.message}
                {integration.progress.records > 0 && (
                  <Text className="mt-2" pale>
                    <FormattedMessage
                      id="cluster.clusterImport.detailStatusImportFailedImportedText"
                      values={{
                        records: formatNumber(integration.progress.records, {
                          notation: 'compact',
                        }),
                      }}
                    />
                  </Text>
                )}
                {integration.progress.failed_records > 0 && (
                  <Text className="mt-2" pale>
                    <FormattedMessage
                      id="cluster.clusterImport.detailStatusImportFailedFailedText"
                      values={{
                        records: formatNumber(integration.progress.failed_records, {
                          notation: 'compact',
                        }),
                      }}
                    />
                  </Text>
                )}
              </Text>
            </ImportingHelpSection>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default IntegrationsListDetailsModal;
