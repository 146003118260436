import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useGetProductsClustersPrice } from '../../../swrHooks';
import { toGibiBytes } from '../../../utils';
import { clusterPropType, productPropType, regionPropType } from '../../../models';
import { PRICE_OPTIONS_3DP } from '../../../constants/defaults';

function ScaleComputeOption({
  cluster,
  currentClusterProduct,
  isDisabled,
  isReadonly,
  product,
  region,
  selectedClusterProduct,
  setSelectedClusterProduct,
}) {
  const { formatNumber } = useIntl();
  const { organizationId } = useParams();
  const { data: price } = useGetProductsClustersPrice(
    organizationId,
    region,
    product,
    0,
    cluster.hardware_specs.disk_size_per_node_bytes,
  );

  const isCurrentProduct = product.name === currentClusterProduct?.name;
  const isSelectedProduct = product.name === selectedClusterProduct?.name;

  const STYLE = {
    CURRENT:
      'bg-crate-border-light/25 border-t border-crate-border-light flex items-center justify-between min-h-[36px] text-left w-full',
    SELECTED:
      'bg-crate-blue/10 border-t border-crate-border-light flex items-center justify-between min-h-[36px] text-left w-full',
    UNSELECTED:
      'bg-white border-t border-crate-border-light cursor-pointer flex items-center justify-between min-h-[36px] text-left w-full hover:bg-crate-blue/10',
    READONLY:
      'bg-white border-t border-crate-border-light cursor-default flex items-center justify-between min-h-[36px] text-left w-full',
  };

  const clickHandler = () => {
    if (isReadonly) {
      return;
    }

    if (isDisabled || isCurrentProduct || isSelectedProduct) {
      setSelectedClusterProduct(null);
      return;
    }

    setSelectedClusterProduct(product);
  };

  // get style for this option
  let buttonStyle = STYLE.READONLY;
  if (!isDisabled) {
    if (isCurrentProduct) {
      buttonStyle = STYLE.CURRENT;
    } else if (!isReadonly) {
      buttonStyle = isSelectedProduct ? STYLE.SELECTED : STYLE.UNSELECTED;
    }
  }

  return (
    <button className={buttonStyle} type="button" onClick={clickHandler}>
      <div className="flex w-[87%] items-center">
        <div className="w-[14%] px-2 text-xs uppercase text-crate-border-mid">
          {product.name}
        </div>
        <div className="w-[19%] px-1 leading-tight">
          {product.tags.includes('shared') && (
            <span className="whitespace-nowrap text-xs opacity-80">
              <FormattedMessage id="deploy.productConfigutation.upTo" />{' '}
            </span>
          )}
          <span>
            {product.specs.cpu_cores}&nbsp;
            <FormattedMessage id="common.vcpu" />
          </span>
        </div>
        <div className="w-[23%] px-1 leading-tight">
          {toGibiBytes(product.specs.ram_bytes)}{' '}
          <FormattedMessage id="deploy.productConfiguration.gibRamLabel" />
        </div>
        {price ? (
          <div className="w-[31%] p-1 leading-tight">
            {formatNumber(price.cluster_price.price_per_hour, PRICE_OPTIONS_3DP)}{' '}
            <span className="whitespace-nowrap text-xs opacity-80">
              <FormattedMessage id="cluster.clusterScale.perNodePerHour" />
            </span>
          </div>
        ) : null}
      </div>
      <div className="flex w-[13%] items-center justify-end px-2">
        {isCurrentProduct && (
          <div className="inline-block rounded bg-crate-blue px-1.5 py-1 text-[10px] font-semibold uppercase leading-tight text-white">
            current
          </div>
        )}
        {!isCurrentProduct && isSelectedProduct && (
          <CheckCircleOutlined
            className="text-lg text-crate-blue"
            data-testid="selected-product-icon"
          />
        )}
      </div>
    </button>
  );
}

ScaleComputeOption.propTypes = {
  cluster: clusterPropType.isRequired,
  currentClusterProduct: productPropType.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  product: productPropType.isRequired,
  region: regionPropType.isRequired,
  selectedClusterProduct: productPropType,
  setSelectedClusterProduct: PropTypes.func.isRequired,
};

ScaleComputeOption.defaultProps = {
  selectedClusterProduct: null,
};

export default ScaleComputeOption;
