const appId = 'bda97f31bea8f639d9e93721d781cef31ff00628';

const planTypes = [
  'HOSTED_BUSINESS_HRS_CET',
  'HOSTED_BUSINESS_HRS_PST',
  'HOSTED_24x7',
  'ON_PREMISE_BUSINESS_HRS_CET',
  'ON_PREMISE_BUSINESS_HRS_PST',
  'ON_PREMISE_24x7',
] as const;

export { appId, planTypes };
