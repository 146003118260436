function useEnvironment() {
  // return two properties
  // [isProduction: Boolean, environment: OneOf(['local', 'dev', 'prod'])]

  const { hostname } = window.location;

  if (hostname === 'localhost' || hostname === '0.0.0.0') {
    return [false, 'local'];
  }
  if (/\.cratedb-dev\.cloud$/.test(hostname)) {
    return [false, 'dev'];
  }
  if (/\.cratedb\.cloud$/.test(hostname)) {
    return [true, 'prod'];
  }
  throw new Error(
    `Unknown environment: Hostname (${hostname}) is not mapped to an environment.`,
  );
}

export default useEnvironment;
