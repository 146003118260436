export const INTEGRATION_TYPE = {
  REGULAR: 'REGULAR',
  MANAGED: 'MANAGED',
};

export const INTEGRATION_STATUS = {
  DEVELOPMENT: 'DEVELOPMENT',
  PLANNED: 'PLANNED',
};

export const DEBEZIUM_INTEGRATION_LINK =
  'https://community.cratedb.com/t/replicating-data-to-cratedb-with-debezium-and-kafka/1388';
