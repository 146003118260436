import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, message, Form } from 'antd';
import { Button, NoDataView, Text } from '@crate.io/crate-gc-admin';
import TestSWRIsFetching from '../../../components/TestSWRIsFetching';
import useMessage from '../../../hooks/useMessage';
import { apiPost } from '../../../api';
import { useGetOrganizations, useGetUsersMe } from '../../../swrHooks';
import { DEFAULT_PLAN_TYPE } from '../../../constants/defaults';
import { deployOrganizationCluster } from '../../../constants/paths';
import INPUT_SANITIZATION from '../../../constants/inputSanitization';

function CreateOrganization() {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { data: user, mutate: mutateUser } = useGetUsersMe();
  const {
    data: organizations,
    isValidating: isOrganizationsValidating,
    mutate: mutateOrganizations,
  } = useGetOrganizations();
  const textInput = useRef(null);
  const { showLoadingMessage, showSuccessMessage } = useMessage();

  const handleFormFinish = async ({ organizationName }) => {
    // create the organization
    showLoadingMessage(
      formatMessage(
        { id: 'account.createOrganization.creationInProgress' },
        { name: organizationName },
      ),
    );

    const { success, data: newOrganization } = await apiPost(
      '/api/v2/organizations/',
      {
        name: organizationName,
        plan_type: DEFAULT_PLAN_TYPE,
      },
    );

    message.destroy();

    if (success) {
      showSuccessMessage(
        formatMessage(
          { id: 'account.createOrganization.createdSuccessfully' },
          { name: newOrganization.name },
        ),
      );
      mutateUser();
      mutateOrganizations([...organizations, newOrganization]);

      navigate(
        deployOrganizationCluster.build({ organizationId: newOrganization.id }),
      );
    }
  };

  return (
    <div className="mx-auto max-w-[356px] md:absolute md:left-1/2 md:top-1/2 md:w-[356px] md:-translate-x-1/2 md:-translate-y-1/2">
      {user && organizations && (
        <NoDataView
          description={formatMessage({
            id:
              organizations?.length > 0
                ? 'account.createOrganization.addNewOrganization'
                : 'account.createOrganization.noOrganizationYetText',
          })}
        >
          <Form
            layout="horizontal"
            onFinish={handleFormFinish}
            aria-label="create organization form"
          >
            <Form.Item
              name="organizationName"
              rules={[INPUT_SANITIZATION.ORGANIZATION_NAME]}
            >
              <Input
                placeholder={formatMessage({
                  id: 'account.createOrganization.formInputPlaceholder',
                })}
                ref={textInput}
                name="create_organization"
                data-testid="organization-name-input"
              />
            </Form.Item>
            <Form.Item>
              <Button kind={Button.kinds.PRIMARY} type={Button.types.SUBMIT}>
                <FormattedMessage id="account.createOrganization.formSubmitButton" />
              </Button>
            </Form.Item>
          </Form>
          <div className="mx-auto mt-4 max-w-[236px]">
            <Text pale>
              <FormattedMessage id="account.createOrganization.formNonAdminOrText" />
            </Text>
            <Text pale>
              <FormattedMessage id="account.createOrganization.formNonAdminHelp" />
            </Text>
          </div>
        </NoDataView>
      )}

      <TestSWRIsFetching fetchStatusList={[isOrganizationsValidating]} />
    </div>
  );
}

export default CreateOrganization;
