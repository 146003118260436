import ConstrainStepWidth from 'src/components/StepLayout/ConstrainStepWidth';
import { CreateIntegrationMongoDBBody } from './forms';
import { Form, Input, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { FIELD_NAMES, INGESTION_TYPE_OPTIONS } from '../../constants';
import { Button } from '@crate.io/crate-gc-admin';
import INPUT_SANITIZATION from '../../../../../constants/inputSanitization';

export type IntegrationSettingsStepProps = {
  defaultValues: CreateIntegrationMongoDBBody;
  isResuming: boolean;
  onNext: (result: CreateIntegrationMongoDBBody) => void;
};

function IntegrationSettingsStep({
  defaultValues,
  isResuming,
  onNext,
}: IntegrationSettingsStepProps) {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<CreateIntegrationMongoDBBody>();

  const onFormFinish = (values: CreateIntegrationMongoDBBody) => {
    onNext(values);
  };

  return (
    <ConstrainStepWidth>
      <Form<CreateIntegrationMongoDBBody>
        autoComplete="off"
        form={form}
        initialValues={{
          ...defaultValues,
        }}
        layout="vertical"
        name="mongodb-integration-settings-form"
        id="mongodb-integration-settings-form"
        aria-label="mongodb-integration-settings-form"
        onFinish={onFormFinish}
        requiredMark="optional"
      >
        <Form.Item<CreateIntegrationMongoDBBody>
          colon={false}
          label={
            <FormattedMessage id="cluster.clusterImportIntegration.integrationSettingsStep.nameLabel" />
          }
          name={[FIELD_NAMES.NAME]}
          rules={[
            {
              ...INPUT_SANITIZATION.GENERIC_REQUIRED_TEXT,
              message: formatMessage({
                id: 'cluster.clusterImportIntegration.integrationSettingsStep.nameValidationText',
              }),
            },
          ]}
        >
          <Input
            placeholder={formatMessage({
              id: 'cluster.clusterImportIntegration.integrationSettingsStep.namePlaceholder',
            })}
          />
        </Form.Item>

        <Form.Item<CreateIntegrationMongoDBBody>
          colon={false}
          label={
            <FormattedMessage id="cluster.clusterImportIntegration.integrationSettingsStep.mode" />
          }
          name={[FIELD_NAMES.INGESTION_TYPE]}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'cluster.clusterImportIntegration.integrationSettingsStep.modeRequiredText',
              }),
            },
          ]}
        >
          <Select
            data-testid="ingestion-type-select"
            options={INGESTION_TYPE_OPTIONS}
            placeholder={
              <FormattedMessage id="cluster.clusterImportIntegration.integrationSettingsStep.selectMode" />
            }
          />
        </Form.Item>

        <div className="flex">
          <Button type={Button.types.SUBMIT}>
            <FormattedMessage
              id={
                isResuming
                  ? 'cluster.clusterImportIntegration.integrationSettingsStep.resumeIntegration'
                  : 'cluster.clusterImportIntegration.integrationSettingsStep.createIntegration'
              }
            />
          </Button>
        </div>
      </Form>
    </ConstrainStepWidth>
  );
}

export default IntegrationSettingsStep;
