import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash/fp';
import { FormattedMessage } from 'react-intl';
import { Button, NoDataView } from '@crate.io/crate-gc-admin';
import { useAnalytics } from '../../../hooks';
import ConstrainWidth from '../../../components/ConstrainWidth/ConstrainWidth';
import ClusterSummary from '../../../components/ClusterSummary/ClusterSummary';
import ViewContainer from '../../../components/ViewContainer';
import {
  useGetOrganizationsIdClusters,
  useGetOrganizationsIdRegions,
  useGetProjects,
  useGetUsersMe,
} from '../../../swrHooks';
import { getUserIsOrganizationAdmin } from '../../../utils/data/user';
import { SINGLE_SCREEN_DEPLOYMENT_ANALYTICS_ORIGIN } from '../../../constants/defaults';
import { deployOrganizationCluster } from '../../../constants/paths';
import { USER_TRACKING_EVENTS } from '../../../constants/segment';

function ClustersList() {
  const { organizationId } = useParams();
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const { data: clusters } = useGetOrganizationsIdClusters(organizationId, {
    refreshInterval: 30 * 1000,
  });
  const { data: regions } = useGetOrganizationsIdRegions(organizationId);
  const { data: projects } = useGetProjects();
  const { data: user } = useGetUsersMe();

  const userIsOrgAdmin = useMemo(
    () => getUserIsOrganizationAdmin(user, organizationId),
    [user, organizationId],
  );

  const handleClickSingleScreenDeploy = () => {
    navigate(
      deployOrganizationCluster.build({
        organizationId,
      }),
    );

    trackEvent(USER_TRACKING_EVENTS.CLICKED_DASHBOARD_DEPLOY_CLUSTER, {
      origin: 'clustersList',
      destination: SINGLE_SCREEN_DEPLOYMENT_ANALYTICS_ORIGIN,
    });
  };

  return (
    <ConstrainWidth>
      <ViewContainer
        loading={!clusters || !projects || !regions || !user}
        extra={
          clusters?.length > 0 &&
          userIsOrgAdmin && (
            <Button onClick={handleClickSingleScreenDeploy}>
              <FormattedMessage id="organization.clustersList.deployClusterButton" />
            </Button>
          )
        }
        heading={<FormattedMessage id="organization.clustersList.pageTitle" />}
        render={() => (
          <div>
            {!isEmpty(clusters) ? (
              clusters.map(cluster => (
                <ClusterSummary
                  cluster={cluster}
                  isListMode
                  key={`cluster_${cluster.id}`}
                  organizationId={organizationId}
                  projects={projects}
                  regions={regions}
                />
              ))
            ) : (
              <div className="mt-8">
                <NoDataView
                  description={
                    <FormattedMessage id="organization.clustersList.clustersListEmptyHelp" />
                  }
                >
                  {userIsOrgAdmin && (
                    <Button onClick={handleClickSingleScreenDeploy}>
                      <FormattedMessage id="organization.clustersList.deployClusterButton" />
                    </Button>
                  )}
                </NoDataView>
              </div>
            )}
          </div>
        )}
      />
    </ConstrainWidth>
  );
}

export default ClustersList;
