import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import NotificationBanner from '../../../components/NotificationBanner';
import { usePortal } from '../../../hooks';
import BANNER_TYPES from './constants';

function TopBanner({ type, children }) {
  const target = usePortal({
    id: 'top-banner',
    classNames: 'absolute mt-12 top-0 left-0 right-0',
    insertMethod: 'prepend',
  });

  return createPortal(
    <NotificationBanner type={type}>{children}</NotificationBanner>,
    target,
  );
}

TopBanner.types = BANNER_TYPES;

TopBanner.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    .isRequired,
  type: PropTypes.oneOf([TopBanner.types.INFO, TopBanner.types.WARN]).isRequired,
};

export default TopBanner;
