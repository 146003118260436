import PropTypes from 'prop-types';
import { FILE_STATES } from '../constants/defaults';

export default PropTypes.shape({
  file_size: PropTypes.number,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  organization_id: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(FILE_STATES)),
  upload_url: PropTypes.string.isRequired,
});
