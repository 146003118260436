import { PropsWithChildren } from 'react';
import getFeatureFlag from '../../utils/data/features';
import { useGetFeaturesStatus } from '../../swrHooks';
import { FeatureToggleName } from 'src/constants/featureToggleNames';

export type FeatureToggleProps = PropsWithChildren<{
  featureName: string;
  showOnlyWhenDisabled?: boolean;
}>;

function FeatureToggle({
  featureName,
  showOnlyWhenDisabled = false,
  children,
}: FeatureToggleProps) {
  const { data: featureFlags } = useGetFeaturesStatus();
  const featureEnabled = getFeatureFlag(
    featureFlags,
    featureName as FeatureToggleName,
  );

  if (
    featureFlags &&
    (featureEnabled || (showOnlyWhenDisabled && !featureEnabled))
  ) {
    return children;
  }

  return null;
}

export default FeatureToggle;
