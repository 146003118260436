import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { accountRoutes } from '../../constants/routes';

import Login from '../../features/account/Login';
import GlobalLoading from '../GlobalLoading';
import { useGetOrganizations, useGetUsersMe } from '../../swrHooks';
import usePersistentStore from '../../state/persistent';

function InitializeApp({ children }) {
  const location = useLocation();
  const [appIsInitialized, setAppIsInitialized] = useState(false);
  const { setCurrentOrganizationId } = usePersistentStore();
  const { data: organizations } = useGetOrganizations();
  const { data: user, isLoading: userIsLoading } = useGetUsersMe();

  // reduce the routes down to the one that matches the current URL
  // and get the route params in an object, ordinarily we can use the
  // useParams hook provided by react-router for this
  // but we are outside the scope of the router here.
  const { organizationId } = useMemo(
    () =>
      accountRoutes.reduce(
        (accumulator, currentRoute) =>
          currentRoute.path.test(location.pathname) || accumulator,
        {},
      ),
    [location],
  );

  // the appIsInitialized variable tells us if SWR has completed
  // trying to load the user for the first time. the API with either
  // return a user or a 401 error if the user is not logged in.
  // SWR will attempt to reload the user periodically after a 401 error
  // so we cannot use SWR's "isLoading" mechanism to determine if the
  // we should show the global loading spinner.
  useEffect(() => {
    if (!appIsInitialized && !userIsLoading) {
      setAppIsInitialized(true);
    }
  }, [appIsInitialized, userIsLoading]);

  // store the organizationID in localStorage if it exists in the URL
  useEffect(() => {
    if (organizationId) {
      setCurrentOrganizationId(organizationId);
    }
  }, [organizationId, setCurrentOrganizationId]);

  // scroll to top of page on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // show the appropriate page content
  if (!appIsInitialized || (user && !organizations)) {
    return <GlobalLoading />;
  }
  return user ? children : <Login />;
}

InitializeApp.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    .isRequired,
};

export default InitializeApp;
