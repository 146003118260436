import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { IDENTITY_PROVIDER } from '../../../constants/defaults';
import { USER_TRACKING_EVENTS } from '../../../constants/segment';
import { login } from '../../../utils/auth';
import { useAnalytics } from '../../../hooks';
import loginMicrosoftIcon from '../../../assets/microsoft_logo.svg';

function LoginWithAzureButton({ redirectUri }) {
  const { trackEvent } = useAnalytics();

  const handleClick = () => {
    trackEvent(USER_TRACKING_EVENTS.USER_LOGGED_IN, {
      identity_provider: IDENTITY_PROVIDER.AZURE_AD,
    });
    login(IDENTITY_PROVIDER.AZURE_AD, redirectUri);
  };

  return (
    <button
      className="flex w-full justify-center rounded border border-gray-400 p-1.5 hover:border-gray-600"
      type="button"
      onClick={handleClick}
    >
      <img
        className="pr-4"
        src={loginMicrosoftIcon}
        alt="Microsoft logo"
        data-testid="login-microsoft-logo"
      />
      <span>
        <FormattedMessage id="account.login.azureSignInButton" />
      </span>
    </button>
  );
}

LoginWithAzureButton.propTypes = {
  redirectUri: PropTypes.string.isRequired,
};

export default LoginWithAzureButton;
