export const FIELD_NAMES = {
  NAME: 'name',
  COMPRESSION_OPTIONS: 'compression',
  CREATE_TABLE: 'create_table',
  DESTINATION_NAMESPACE: 'destination',
  FORMAT_OPTIONS: 'format',
  TABLE_NAME: 'table',
  TYPE: 'type',
  INGESTION_TYPE: 'ingestion_type',
  OBJECT_TYPE_STRATEGY: 'object_type_strategy',

  // mongodb namespace
  MONGODB_NAMESPACE: 'mongodb',
  MONGODB_CONNECTION_ID: 'connection_id',
  MONGODB_COLLECTION_NAME: 'collection_name',
  MONGODB_DATABASE_NAME: 'database_name',
} as const;

export const CONNECTION_FIELD_NAMES = {
  AUTH: 'auth',
  NAME: 'name',
  CONNECTION_STRING: 'connection_string',
  DEFAULT_DATABASE_NAME: 'default_db_name',

  // SCRAM
  USERNAME: 'username',
  PASSWORD: 'password',

  // X509
  CERTIFICATE: 'certificate',
} as const;
