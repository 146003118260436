import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useGetProductsClustersPrice } from '../../../swrHooks';
import { fromBytes } from '../../../utils';
import { productPropType, regionPropType } from '../../../models';
import { PRICE_OPTIONS_3DP } from '../../../constants/defaults';

function StorageOption({ bytes, product, region }) {
  const { formatNumber } = useIntl();
  const { organizationId } = useParams();
  const { data: price } = useGetProductsClustersPrice(
    organizationId,
    region,
    product,
    0,
    bytes,
  );

  return (
    <div className="flex h-8 items-center text-sm">
      <span className={`pr-2 ${price?.storage_price && 'w-[100px]'}`}>
        {fromBytes(bytes).format()}
      </span>
      {price && !price.storage_price && (
        <span
          className="ml-1 flex h-5 items-center rounded bg-[#28BDDA] px-3 py-1 font-bold uppercase tracking-wider text-white"
          style={{ fontSize: 9.5 }}
          data-testid="badge-free"
        >
          <FormattedMessage id="common.free" />
        </span>
      )}
      {price && price.storage_price && (
        <span>
          {formatNumber(price.storage_price.price_per_hour, PRICE_OPTIONS_3DP)}{' '}
          <span className="text-xs opacity-80">per hour</span>
        </span>
      )}
    </div>
  );
}

StorageOption.propTypes = {
  bytes: PropTypes.number.isRequired,
  product: productPropType,
  region: regionPropType,
};

StorageOption.defaultProps = {
  product: null,
  region: null,
};

export default StorageOption;
