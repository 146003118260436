import {
  CompressionOption,
  FIELD_NAMES,
  SINK_DEFAULT_FIELD_VALUES,
  SinkDetailsStepForm,
  SupportedFileFormat,
} from '../../constants';

export type CreateImportJobS3Body = {
  type: 's3';
  [FIELD_NAMES.S3_NAMESPACE]: {
    [FIELD_NAMES.S3_BUCKET]: string;
    [FIELD_NAMES.S3_ENDPOINT]?: string;
    [FIELD_NAMES.S3_FILE_PATH]: string;
    [FIELD_NAMES.S3_SECRET_ID]: string;
  };
  [FIELD_NAMES.COMPRESSION_OPTIONS]: CompressionOption;
  [FIELD_NAMES.FORMAT_OPTIONS]?: SupportedFileFormat;
} & SinkDetailsStepForm;

export const S3_SOURCE_FORM_DEFAULT_VALUES: CreateImportJobS3Body = {
  type: 's3',
  [FIELD_NAMES.S3_NAMESPACE]: {
    [FIELD_NAMES.S3_BUCKET]: '',
    [FIELD_NAMES.S3_ENDPOINT]: undefined,
    [FIELD_NAMES.S3_FILE_PATH]: '',
    [FIELD_NAMES.S3_SECRET_ID]: '',
  },
  [FIELD_NAMES.COMPRESSION_OPTIONS]: 'none',
  [FIELD_NAMES.FORMAT_OPTIONS]: undefined,
  ...SINK_DEFAULT_FIELD_VALUES,
};
