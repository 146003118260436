import { Text } from '@crate.io/crate-gc-admin';
import { FIELD_NAMES, FORMAT_OPTIONS, COMPRESSION_OPTIONS } from '../../constants';
import { useIntl } from 'react-intl';
import { last } from 'lodash';
import { CreateImportJobS3Body } from './form';

export type SourceDetailsStepSummaryProps = {
  values: CreateImportJobS3Body;
};

function SourceDetailsStepSummary({ values }: SourceDetailsStepSummaryProps) {
  const { formatMessage } = useIntl();
  const fileName = last(
    values[FIELD_NAMES.S3_NAMESPACE][FIELD_NAMES.S3_FILE_PATH].split('/'),
  )!;
  const format = FORMAT_OPTIONS.find(
    option => option.value === values.format,
  )!.label;
  const compression = COMPRESSION_OPTIONS.find(
    option => option.value === values.compression,
  )!.label;

  const renderValue = (label: string, value: string) => {
    return (
      <div className="flex gap-2">
        <Text className="font-bold">{label}:</Text>
        <Text>{value}</Text>
      </div>
    );
  };

  return (
    <div>
      {renderValue(
        formatMessage({
          id: 'cluster.clusterImportFile.sourceStep.fileName',
        }),
        fileName,
      )}
      {renderValue(
        formatMessage({
          id: 'cluster.clusterImportFile.sourceStep.format',
        }),
        format,
      )}
      {renderValue(
        formatMessage({
          id: 'cluster.clusterImportFile.sourceStep.compression',
        }),
        compression,
      )}
    </div>
  );
}

export default SourceDetailsStepSummary;
