import { Form, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  COMPRESSION_OPTIONS,
  DefaultFieldsType,
  FIELD_NAMES,
  FORMAT_OPTIONS,
} from '../../constants';

function FileDetailsFields() {
  const { formatMessage } = useIntl();

  return (
    <div className="lg:grid lg:grid-cols-2 lg:gap-x-4">
      {/* format */}
      <Form.Item<DefaultFieldsType>
        label={<FormattedMessage id="cluster.clusterImportFile.sourceStep.format" />}
        name={FIELD_NAMES.FORMAT_OPTIONS}
        rules={[
          {
            required: true,
            message: formatMessage({
              id: 'cluster.clusterImportFile.sourceStep.formatRequiredText',
            }),
          },
        ]}
      >
        <Select
          data-testid="format-select"
          options={FORMAT_OPTIONS}
          placeholder={
            <FormattedMessage id="cluster.clusterImportFile.sourceDetailsStep.selectFileFormat" />
          }
        />
      </Form.Item>

      {/* compression */}
      <Form.Item<DefaultFieldsType>
        label={
          <FormattedMessage id="cluster.clusterImportFile.sourceStep.compression" />
        }
        name={FIELD_NAMES.COMPRESSION_OPTIONS}
      >
        <Select data-testid="compression-select" options={COMPRESSION_OPTIONS} />
      </Form.Item>
    </div>
  );
}

export default FileDetailsFields;
