import PropTypes from 'prop-types';

export const priceEntry = PropTypes.shape({
  price_per_hour: PropTypes.number.isRequired,
  price_per_month: PropTypes.number.isRequired,
  promotion_price: PropTypes.shape({
    price_per_hour: PropTypes.number,
    price_per_month: PropTypes.number,
    promotion_duration: PropTypes.number,
  }),
});

export const price = PropTypes.shape({
  cluster_price: priceEntry,
  storage_price: priceEntry,
  total_price: priceEntry,
});
