import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import SectionContainer from '../../../components/SectionContainer';
import CloudUITable from '../../../components/CloudUITable';
import DisplayDate from '../../../components/DisplayDate';
import { ORGANIZATION_ROLE_FQNS } from '../../../constants/defaults';
import {
  createOrganization,
  organizationClustersList,
  organizationSettings,
} from '../../../constants/paths';
import { useGetOrganizations } from '../../../swrHooks';
import useMessage from '../../../hooks/useMessage';
import { apiDelete } from '../../../api';

function OrganizationList() {
  const { formatMessage } = useIntl();
  const { showLoadingMessage } = useMessage();
  const { data: organizations } = useGetOrganizations();

  const changeOrganization = newOrganizationId => {
    window.location.assign(
      organizationClustersList.build({
        organizationId: newOrganizationId,
      }),
    );
  };

  const deleteOrganization = async organizationToDelete => {
    showLoadingMessage(
      formatMessage(
        { id: 'account.deleteOrganization.deletionInProgress' },
        { name: organizationToDelete.name },
      ),
    );

    const { success } = await apiDelete(
      `/api/v2/organizations/${organizationToDelete.id}/`,
    );

    if (success) {
      // an organization has just been deleted so now reload the console:
      // - SWR will potentially still be polling for data related to the
      //   deleted organization (showing error messages every time)
      // - if the org list is now empty, we need to push them back through the
      //   create org flow
      // potentially we could so something nicer here in future, but this is
      // by far the simplest and most reliable solution for handling a use-case
      // that happens very rarely
      window.location.assign('/');
    }
  };

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: organization => (
        <button
          className="text-left text-crate-blue"
          onClick={() => changeOrganization(organization.id)}
          type="button"
        >
          {organization.name}
        </button>
      ),
    },
    {
      title: 'Role',
      key: 'role',
      render: organization => ORGANIZATION_ROLE_FQNS[organization.role_fqn],
    },
    {
      title: 'Created',
      key: 'created',
      render: organization => <DisplayDate isoDate={organization.dc.created} />,
    },
    {
      title: 'Action',
      key: 'action',
      render: organization => (
        <>
          <div className="mr-2 inline-block" data-testid="edit-organization-link">
            <Link
              aria-label={formatMessage({ id: 'common.edit' })}
              to={organizationSettings.build({ organizationId: organization.id })}
            >
              <EditOutlined className="text-lg text-crate-blue" />
            </Link>
          </div>
          <div className="mr-2 inline-block">
            <button
              aria-label={formatMessage({ id: 'common.delete' })}
              data-testid="delete-organization-button"
              disabled={organization.project_count > 0}
              onClick={() => deleteOrganization(organization)}
              type="button"
            >
              <DeleteOutlined
                className={cx('text-lg', {
                  'text-crate-blue': organization.project_count < 1,
                  'text-crate-border-mid': organization.project_count > 0,
                })}
              />
            </button>
          </div>
        </>
      ),
    },
  ];

  return (
    <SectionContainer
      title={<FormattedMessage id="account.accountSettings.organizationsTitle" />}
      actions={
        <Link to={createOrganization.path} className="text-base text-crate-blue">
          <FormattedMessage id="account.accountSettings.createOrganizationText" />
        </Link>
      }
    >
      <CloudUITable
        columns={columns}
        dataSource={organizations}
        emptyText={formatMessage({
          id: 'account.createOrganization.noOrganizationYetText',
        })}
        rowKey="id"
        showHeader
      />
    </SectionContainer>
  );
}

export default OrganizationList;
