import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import ErrorScreen from '../../../components/ErrorScreen';

function AzureUnauthorized() {
  const navigate = useNavigate();

  return (
    <ErrorScreen
      buttonLabel={<FormattedMessage id="azureUnauthorized.button" />}
      message={<FormattedMessage id="azureUnauthorized.text" />}
      onClick={() => navigate('/')}
    />
  );
}

export default AzureUnauthorized;
