import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input } from 'antd';
import { Button } from '@crate.io/crate-gc-admin';
import { postHubspotForm } from '../../../api';
import { HUBSPOT_FORMS } from '../../../constants/hubspot';
import { useGetUsersMe } from '../../../swrHooks';
import useMessage from '../../../hooks/useMessage';
import INPUT_SANITIZATION from '../../../constants/inputSanitization';

function HubspotFormExceedQuotaScaleExisting({
  onFormCancel,
  onFormComplete,
  initialValues,
  hiddenFields,
}) {
  const { data: user } = useGetUsersMe();
  const { formatMessage } = useIntl();
  const { showErrorMessage, showSuccessMessage } = useMessage();

  const onFinish = async values => {
    const response = await postHubspotForm(
      HUBSPOT_FORMS.CLOUD_CONSOLE_EXCEED_QUOTA_SCALE_EXISTING,
      {
        ...values,
        ...hiddenFields,
      },
    );

    if (response.success) {
      showSuccessMessage(formatMessage({ id: 'hubspot.common.messageSuccess' }));
      onFormComplete();
    } else {
      showErrorMessage(formatMessage({ id: 'hubspot.common.messageFail' }));
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Form
      name="hubspot-exceed-quota-scale-existing-form"
      aria-label="hubspot-exceed-quota-scale-existing-form"
      data-testid="hubspot-exceed-quota-scale-existing-form"
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 19 }}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{ email: user.email, name: user.name, ...initialValues }}
    >
      <p className="pb-4">
        <FormattedMessage id="deploy.quota.quotaExceededDescriptionText" />
      </p>

      {/* name */}
      <Form.Item
        label={<FormattedMessage id="hubspot.common.name" />}
        name="name"
        rules={[INPUT_SANITIZATION.HUBSPOT_NAME]}
      >
        <Input />
      </Form.Item>

      {/* email */}
      <Form.Item
        label={<FormattedMessage id="common.email" />}
        name="email"
        rules={[INPUT_SANITIZATION.GENERIC_EMAIL]}
      >
        <Input />
      </Form.Item>

      {/* phone */}
      <Form.Item
        label={<FormattedMessage id="hubspot.common.phone" />}
        name="phone"
        rules={[INPUT_SANITIZATION.PHONE_OPTIONAL]}
      >
        <Input
          placeholder={formatMessage({
            id: 'hubspot.common.phoneIncludeCountryCode',
          })}
        />
      </Form.Item>

      {/* message */}
      <Form.Item
        label={<FormattedMessage id="hubspot.common.message" />}
        name="tell_us"
        rules={[INPUT_SANITIZATION.HUBSPOT_MESSAGE]}
      >
        <Input.TextArea
          autoSize={{ minRows: 4, maxRows: 8 }}
          placeholder={formatMessage({
            id: 'hubspot.common.optional',
          })}
        />
      </Form.Item>

      <div className="flex justify-end">
        <Button
          onClick={onFormCancel}
          kind={Button.kinds.SECONDARY}
          size={Button.sizes.SMALL}
          type={Button.types.BUTTON}
        >
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          size={Button.sizes.SMALL}
          type={Button.types.SUBMIT}
          className="ml-2"
        >
          <FormattedMessage id="common.ok" />
        </Button>
      </div>
    </Form>
  );
}

HubspotFormExceedQuotaScaleExisting.propTypes = {
  hiddenFields: PropTypes.object,
  initialValues: PropTypes.object,
  onFormCancel: PropTypes.func.isRequired,
  onFormComplete: PropTypes.func.isRequired,
};

HubspotFormExceedQuotaScaleExisting.defaultProps = {
  hiddenFields: null,
  initialValues: null,
};

export default HubspotFormExceedQuotaScaleExisting;
