export const createFullClusterRestoreBackupSnippet = ({
  repository,
  snapshot,
}: {
  repository: string;
  snapshot: string;
}) =>
  `RESTORE SNAPSHOT ${repository}."${snapshot}" ALL WITH (wait_for_completion = true);`;

export const createTablesRestoreBackupSnippet = ({
  repository,
  snapshot,
  tables,
}: {
  repository: string;
  snapshot: string;
  tables: string[];
}) =>
  `RESTORE SNAPSHOT ${repository}."${snapshot}" TABLE ${tables.join(
    ', ',
  )} WITH (wait_for_completion = true);`;
