import { useRef, useEffect } from 'react';

const prependToggleClasses = {
  default: 'pt-16',
  active: 'pt-24',
};

//
// Creates the element to be used as the portal
//
function createPortalRoot(id, classNames) {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  rootContainer.classList.add(...classNames.split(' '));

  return rootContainer;
}

//
// prepend or append element
//
function insertPortalRoot(rootElem, insertMethod) {
  const mainElement = document.querySelector('main');

  if (insertMethod === 'prepend') {
    mainElement.classList.remove(prependToggleClasses.default);
    mainElement.classList.add(prependToggleClasses.active);
  }

  mainElement[insertMethod](rootElem);
}

function usePortal({ id, classNames, insertMethod = 'append' }) {
  const rootElemRef = useRef(null);

  useEffect(function setupElement() {
    const existingPortalRoot = document.querySelector(id);
    const portalRoot = existingPortalRoot || createPortalRoot(id, classNames);
    const mainElement = document.querySelector('main');

    if (!existingPortalRoot) {
      insertPortalRoot(portalRoot, insertMethod);
    }

    portalRoot.appendChild(rootElemRef.current);

    return function removeElement() {
      if (insertMethod === 'prepend') {
        mainElement.classList.remove(prependToggleClasses.active);
        mainElement.classList.add(prependToggleClasses.default);
      }

      rootElemRef.current.remove();
      if (!portalRoot.childElementCount) {
        portalRoot.remove();
      }
    };
  });

  function getRootElem() {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  }

  return getRootElem();
}

export default usePortal;
