import React, { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { CaretDownOutlined, UserOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { Button, Text } from '@crate.io/crate-gc-admin';
import {
  useGetOrganizations,
  useGetOrganizationsId,
  useGetUsersMe,
} from '../../swrHooks';
import { logout } from '../../utils/auth';
import { getUserOrganizationRole } from '../../utils/data/user';
import usePersistentStore from '../../state/persistent';
import { callback } from '../../constants/auth';
import { ORGANIZATION_ROLE_FQNS } from '../../constants/defaults';
import { accountSettings, organizationClustersList } from '../../constants/paths';

function TopBar() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { currentOrganizationId: organizationId, sidemenuIsCollapsed } =
    usePersistentStore();
  const { data: organization } = useGetOrganizationsId(organizationId);
  const { data: organizations } = useGetOrganizations();
  const { data: user } = useGetUsersMe();

  useEffect(() => {
    const onScroll = () => {
      if (dropdownOpen) {
        setDropdownOpen(false);
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [dropdownOpen]);

  const userRole = useMemo(() => {
    return organization ? getUserOrganizationRole(user, organization.id) : null;
  }, [organization, user]);

  const changeOrganization = targetOrganizationId => {
    window.location.assign(
      organizationClustersList.build({
        organizationId: targetOrganizationId,
      }),
    );
  };

  const logoutUser = () => {
    logout();
    window.location.assign(callback.logout_uri());
  };

  const styles = cx(
    'bg-neutral-800',
    'ease duration-200',
    'fixed',
    'h-12',
    'text-white',
    'transition-all',
    'z-50',
    {
      'ml-20': sidemenuIsCollapsed,
      'w-[calc(100%-80px)]': sidemenuIsCollapsed,
      'ml-56': !sidemenuIsCollapsed,
      'w-[calc(100%-224px)]': !sidemenuIsCollapsed,
    },
  );

  return (
    <>
      <div className={styles}>
        <div className="flex h-full flex-row items-center justify-end px-4 md:px-10">
          <button
            className="flex cursor-pointer select-none flex-row items-center"
            onClick={() => setDropdownOpen(!dropdownOpen)}
            type="button"
          >
            <span>{user?.name || user?.username || user?.email}</span>
            {user && organizations?.length > 1 && (
              <>
                <span className="px-3 opacity-30">|</span>
                <span>{organization?.name}</span>
              </>
            )}
            <div className="mx-2 rounded-full bg-crate-border-dark">
              <UserOutlined className="flex h-8 w-8 items-center justify-center" />
            </div>
            <CaretDownOutlined />
          </button>
        </div>
      </div>
      {dropdownOpen && (
        <div
          className="absolute right-[41px] top-[44px] z-50 rounded bg-white drop-shadow-2xl"
          role="menu"
        >
          <div className="min-w-[200px] border-b p-4 py-2.5">
            <Link
              className="group block text-inherit transition-none hover:text-crate-blue"
              to={accountSettings.path}
              onClick={() => setDropdownOpen(false)}
            >
              <div>{user?.name || user?.username || user?.email}</div>
              {userRole && (
                <Text pale className="group-hover:text-crate-blue">
                  {ORGANIZATION_ROLE_FQNS[userRole]}
                </Text>
              )}
            </Link>
          </div>
          {organizations?.length > 1 && (
            <div className="space-y-3 border-b px-4 py-3">
              <div className="text-[10px] font-bold uppercase leading-none text-crate-border-mid">
                <FormattedMessage id="sideMenu.topBar.switchToText" />
              </div>
              {organizations
                ?.filter(org => org.id !== organizationId)
                .sort((a, b) =>
                  a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
                )
                .map(org => (
                  <button
                    className="block hover:text-crate-blue"
                    onClick={() => changeOrganization(org.id)}
                    type="button"
                    key={`org-${org.id}`}
                  >
                    {org.name}
                  </button>
                ))}
            </div>
          )}
          <div className="px-4 py-2.5">
            <Button
              className="w-full"
              onClick={logoutUser}
              kind={Button.kinds.SECONDARY}
              size={Button.sizes.SMALL}
              type={Button.types.BUTTON}
            >
              <FormattedMessage id="sideMenu.topBar.logoutLink" />
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default TopBar;
