import { FREE_TIER_CLUSTER_PRODUCT_NAME } from '../constants/defaults';

const getTierName = tier => {
  if (!tier) {
    return '--';
  }
  return tier.charAt(0).toUpperCase() + tier.slice(1);
};

export const getPlanName = plan => {
  if (!plan || !plan.name) {
    return '--';
  }
  const product = plan.label || plan.name.toUpperCase();
  let planName = `${product} ${getTierName(plan.tier)}`;
  if (plan.scaleUnit) {
    planName = `${planName} ${plan.scaleUnit}`;
  }
  return planName;
};

export const sortPlans = (a, b) => {
  if (a.name === FREE_TIER_CLUSTER_PRODUCT_NAME) return -1;
  if (b.name === FREE_TIER_CLUSTER_PRODUCT_NAME) return 1;
  if (a.tags.includes('shared') && !b.tags.includes('shared')) return -1;
  if (b.tags.includes('shared') && !a.tags.includes('shared')) return 1;
  // If it is any of the CR*/S* plans, order by the number, ascending
  const matchA = a.name.match('[a-zA-Z]+(\\d+)');
  const matchB = b.name.match('[a-zA-Z]+(\\d+)');
  if (matchA && matchB) {
    const numA = Number(matchA[1]);
    const numB = Number(matchB[1]);
    if (numA < numB) return -1;
    if (numB < numA) return 1;
  }
  return 0;
};
