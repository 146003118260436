import { compare } from 'compare-versions';
import { jwtDecode } from 'jwt-decode';
import { useGCLogin } from '@crate.io/crate-gc-admin';
import { CRATE_AUTHENTICATE_VIA_JWT_MIN_VERSION } from 'src/constants/versions';

export type JWTFetchArgs = {
  clusterId: string;
  crateUrl: string;
  crateVersion: string;
  gcUrl: string;
  sql: string;
  sessionTokenKey: string;
};

export default async (_url: string, args: JWTFetchArgs) => {
  // skip all the JWT stuff if we're running in jest
  // and use the url identifier to fetch the dummy response
  // within a try/catch so it works safely in the browser
  try {
    if (process.env.JEST_WORKER_ID !== undefined) {
      const jestResponse = await fetch(_url);
      return await jestResponse.json();
    }
  } catch {
    // do nothing
  }

  const gcLogin = useGCLogin();

  // check if the crate version supports JWT authentication
  // if this is a nightly build, ignore everything but the version itself
  const crateAcceptsJwt = compare(
    args.crateVersion.includes('-')
      ? args.crateVersion.split('-')[1]
      : args.crateVersion,
    CRATE_AUTHENTICATE_VIA_JWT_MIN_VERSION,
    '>=',
  );

  const retrieveNewToken = async () => {
    const res1 = await fetch(`${args.gcUrl}/api/_sql?multi=true&types`, {
      body: JSON.stringify({ stmt: 'SELECT 1' }),
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      method: 'POST',
    });
    if (res1.status >= 400) {
      const res2 = await fetch(`/api/v2/clusters/${args.clusterId}/jwt/`);
      const { token, refresh } = await res2.json();

      await gcLogin({
        token,
        refresh,
        gcUrl: args.gcUrl,
        sessionTokenKey: args.sessionTokenKey,
      });
    }
  };

  // retrieve existing token from sessionstorage + refresh if necessary, or
  // get a new token if none exists
  const getToken = async () => {
    let token = sessionStorage.getItem(args.sessionTokenKey);

    if (crateAcceptsJwt) {
      let refreshToken = token === null;

      // decode the token
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          if (decodedToken.exp) {
            // check for token expiry
            const exp = decodedToken.exp * 1000;
            const now = new Date().getTime();
            refreshToken = exp < now;
          } else {
            // token is malformed
            refreshToken = true;
          }
        } catch {
          // token is malformed
          refreshToken = true;
        }
      }

      if (refreshToken) {
        await retrieveNewToken();
        token = sessionStorage.getItem(args.sessionTokenKey);
      }
    }

    return token;
  };

  const token = await getToken();

  // if the cluster understands direct JWT authentication, use it
  if (crateAcceptsJwt) {
    const res = await fetch(`${args.crateUrl}/_sql?error_trace&types`, {
      body: JSON.stringify({ stmt: args.sql }),
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
    });

    const data = await res.text();
    return JSON.parse(data);
  }

  // otherwise, query via the cloud api
  // TODO: this needs to be tested on a cluster that doesn't support JWT
  const res = await fetch(`${args.gcUrl}/api/_sql?error_trace&types`, {
    body: JSON.stringify({ stmt: args.sql }),
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
  });

  const data = await res.text();
  return JSON.parse(data);
};
