const messages = {
  // aws redirect modal
  'subscriptions.awsRedirect.title':
    'You are about to redirected to Amazon Web Services',
  'subscriptions.awsRedirect.description1Text':
    'Please use your AWS account to create a new subscription. Once you have finished, come back here to the CrateDB Cloud console to begin using your new payment method.',
  'subscriptions.awsRedirect.description2Text':
    'Please note: it may take a few minutes for your new subscription to become fully active',
  'subscriptions.awsRedirect.confirmButton': 'Create subscription',

  // azure redirect modal
  'subscriptions.azureRedirect.title':
    'You are about to redirected to Microsoft Azure',
  'subscriptions.azureRedirect.description1Text':
    'Please use your Microsoft account to create a new subscription. Once you have finished, come back here to the CrateDB Cloud console to begin using your new payment method.',
  'subscriptions.azureRedirect.description2Text':
    'Please note: it may take a few minutes for your new subscription to become fully active',
  'subscriptions.azureRedirect.confirmButton': 'Create subscription',

  // GCP Redirect modal
  'subscriptions.gcpRedirect.title':
    'You are about to redirected to Google Cloud Platform',
  'subscriptions.gcpRedirect.description1Text':
    'Please use your Google account to create a new subscription. Once you have finished, come back here to the CrateDB Cloud console to begin using your new payment method.',
  'subscriptions.gcpRedirect.description2Text':
    'Please note: it may take a few minutes for your new subscription to become fully active',
  'subscriptions.gcpRedirect.confirmButton': 'Create subscription',

  // Enable Bank Transfer modal
  'subscriptions.enableBankTransferModal.title': 'Enable pay via bank transfer',
  'subscriptions.enableBankTransferModal.requestEnableBankTransferModalText':
    'Please submit this form to request enable pay via bank transfer. This will be passed to our customer service team who will deal with your request as soon as possible.',

  // azure
  'subscriptions.azure.alreadyExistsText': 'You already have an Azure subscription.',
  'subscriptions.azure.useThisSubscriptionText':
    'Pay using your Azure subscription.',

  // aws
  'subscriptions.aws.alreadyExistsText': 'You already have an AWS subscription.',
  'subscriptions.aws.useThisSubscriptionText': 'Pay using your AWS subscription.',

  // GCP
  'subscriptions.gcp.alreadyExistsText':
    'You already have a Google Cloud subscription.',
  'subscriptions.gcp.useThisSubscriptionText':
    'Pay using your Google Cloud subscription.',

  // bank transfer
  'subscriptions.bankTransfer.useThisSubscriptionText': 'Pay via bank transfer.',
  'subscriptions.bankTransfer.thisSubscriptionIsDisabledText':
    'Pay via bank transfer is disabled.',
  'subscriptions.bankTransfer.askToEnable': 'Ask to enable',
};

export default messages;
