import PropTypes from 'prop-types';
import { OPERATION_STATES } from '../constants/defaults';

export default PropTypes.shape({
  cluster_id: PropTypes.string.isRequired,
  dc: PropTypes.shape({
    created: PropTypes.string.isRequired,
    modified: PropTypes.string.isRequired,
  }),
  destination: PropTypes.shape({
    file: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  id: PropTypes.string.isRequired,
  progress: PropTypes.shape({
    bytes: PropTypes.number.isRequired,
    failed_records: PropTypes.number.isRequired,
    records: PropTypes.number.isRequired,
  }).isRequired,
  source: PropTypes.shape({
    table: PropTypes.string.isRequired,
  }).isRequired,
  status: PropTypes.oneOf(Object.keys(OPERATION_STATES)).isRequired,
});
