import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Collapse } from 'antd';
import { FormattedMessage } from 'react-intl';
import { WarningOutlined } from '@ant-design/icons';

const ALIGNMENT_OPTIONS = {
  DIALOG: 'DIALOG',
  SECTION: 'SECTION',
};
const alignmentOptionsMap = {
  [ALIGNMENT_OPTIONS.DIALOG]: 'pl-0',
  [ALIGNMENT_OPTIONS.SECTION]: 'pl-3',
};

const { Panel } = Collapse;

function ImportingHelpSection({
  alignment,
  defaultOpen,
  titleMessage,
  isWarning,
  children,
}) {
  const [helpIsVisible, setHelpIsVisible] = useState(false);

  useEffect(() => {
    setHelpIsVisible(!!defaultOpen);
  }, [defaultOpen]);

  return (
    <Collapse
      defaultActiveKey={defaultOpen ? '1' : undefined}
      onChange={() => setHelpIsVisible(!helpIsVisible)}
    >
      <Panel
        header={
          <div
            className={cx(
              'flex',
              'items-center',
              'justify-between',
              alignmentOptionsMap[alignment],
            )}
          >
            <div className="flex items-center">
              {isWarning && (
                <WarningOutlined
                  className="mr-2 text-base text-red-500"
                  data-testid="error-icon"
                />
              )}
              <div className="text-neutral-500">{titleMessage}</div>
            </div>
            <div className="text-crate-blue">
              {helpIsVisible ? (
                <FormattedMessage id="cluster.clusterImport.helpCollapseOpenButton" />
              ) : (
                <FormattedMessage id="cluster.clusterImport.helpCollapseClosedButton" />
              )}
            </div>
          </div>
        }
        key="1"
        showArrow={false}
      >
        {children}
      </Panel>
    </Collapse>
  );
}

ImportingHelpSection.alignment = ALIGNMENT_OPTIONS;

ImportingHelpSection.propTypes = {
  // the alignment prop
  // is a workaround for the scenario when the ImportingHelpSection
  // component is displayed underneath the attached file and we
  // want the icons of both components to neatly align, vertically
  alignment: PropTypes.oneOf(Object.values(ALIGNMENT_OPTIONS)),
  defaultOpen: PropTypes.bool,
  titleMessage: PropTypes.node.isRequired,
  isWarning: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    .isRequired,
};

ImportingHelpSection.defaultProps = {
  alignment: ALIGNMENT_OPTIONS.DIALOG,
  defaultOpen: null,
  isWarning: true,
};

export default ImportingHelpSection;
