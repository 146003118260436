import PropTypes from 'prop-types';

export default PropTypes.shape({
  brand: PropTypes.string,
  country: PropTypes.string,
  default: PropTypes.bool,
  exp_month: PropTypes.number,
  exp_year: PropTypes.number,
  id: PropTypes.string,
  last4: PropTypes.string,
});
