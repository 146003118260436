const messages = {
  // deploy overview
  'deploy.deployOverview.containerStep0Title': 'Configure your new cluster',
  'deploy.deployOverview.containerStep1Title':
    'Choose a payment method for your new cluster',
  'deploy.deployOverview.containerStep2Title': 'New cluster payment details',
  'deploy.deployOverview.deployClusterButton': 'Deploy Cluster',
  'deploy.deployOverview.clusterBeingDeployedText':
    'Your new cluster is being deployed',

  // deploy types
  'deploy.deployTypes.planTitle': 'Plan',
  'deploy.deployTypes.sharedTitle': 'Shared',
  'deploy.deployTypes.sharedDescription':
    'Single-node, suitable for non-critical use cases with up to 8 shared vCPUs',
  'deploy.deployTypes.sharedRulesText':
    'Shared tier clusters operate on a single node without replication, sharing vCPUs with other clusters. ' +
    'Performance may vary depending on the overall load, and usage is based on a fair-use principle.',
  'deploy.deployTypes.dedicatedTitle': 'Dedicated',
  'deploy.deployTypes.dedicatedDescription':
    'Scalable up to 9 nodes, ideal for production workloads with up to 144 vCPUs',
  'deploy.deployTypes.dedicatedRulesText':
    'Dedicated tier clusters operate on 1 - 9 nodes with cross-zone replication available for clusters ' +
    'using 3 or more nodes. These nodes are provisioned with higher compute allocations, ensuring stable ' +
    'and consistent performance.',
  'deploy.deployTypes.enterpriseTitle': 'Custom',
  'deploy.deployTypes.enterpriseDescription':
    'Customizable for large-scale needs, offers any cluster size and custom compute options',
  'deploy.deployTypes.enterpriseRulesText':
    'Dedicated custom tier clusters offer flexibility beyond predefined sizes, allowing users to define custom ' +
    'clusters with the possibility of having up to hundreds of nodes. These clusters can also be configured ' +
    'with dedicated master nodes and hot-cold setups. To explore and define your custom cluster, we invite you ' +
    'to reach out to our sales and customer engineering teams using the form below.',
  'deploy.deployTypes.learnMore': 'Learn more about cluster types',

  // cluster specifiction
  'deploy.clusterSpecification.clusterNameLabel': 'Cluster name',
  'deploy.clusterSpecification.clusterNameLabelHelp':
    'Cluster names cannot be changed after the cluster has been deployed',
  'deploy.clusterSpecification.clusterNamePlaceholder':
    'Enter a url-safe cluster name',
  'deploy.clusterSpecification.clusterNameUniqueValidationError':
    'The cluster name "{name}" has already been taken, please choose another name.',
  'deploy.clusterSpecification.clusterNameMissingValidationError':
    'Please enter a cluster name.',
  'deploy.clusterSpecification.clusterNameURLSafeValidationError':
    'Only lowercase letters, numbers, and dashes are allowed. Begin with a letter and end with a letter or a number. Minimum 3 characters.',

  // backup configuration
  'deploy.backupConfiguration.backupLocationText': 'Backup location',
  'deploy.backupConfiguration.defaultLabel': 'Default',
  'deploy.backupConfiguration.defaultHelp': 'Backups are managed by CrateDB Cloud',
  'deploy.backupConfiguration.customLabel': 'Custom',
  'deploy.backupConfiguration.customHelp':
    'Backups are stored in your s3-compatible location',
  'deploy.backupConfiguration.backupBucketNameLabel': 'S3 Bucket Name',
  'deploy.backupConfiguration.backupBucketNameValidationHelp':
    'Please enter a bucket name.',
  'deploy.backupConfiguration.backupBucketNameInvalidValidationHelp':
    'Please enter a valid bucket name.',
  'deploy.backupConfiguration.backupBucketNamePlaceholder':
    'Enter an Amazon S3 bucket name',
  'deploy.backupConfiguration.backupAccessKeyIdLabel': 'Access Key ID',
  'deploy.backupConfiguration.backupAccessKeyIdValidationHelp':
    'Please enter an Access Key Id',
  'deploy.backupConfiguration.backupAccessKeyIdPlaceholder':
    'Enter the access key to your backup bucket',
  'deploy.backupConfiguration.backupSecretAccessKeyLabel': 'Secret Access Key',
  'deploy.backupConfiguration.backupSecretAccessKeyValidationHelp':
    'Please enter a Secret Access Key.',
  'deploy.backupConfiguration.backupEndpointLabel': 'Endpoint URL',
  'deploy.backupConfiguration.backupEndpointInputTooltip':
    'Enter an endpoint URL here if your backup location is not hosted on Amazon S3. Ensure the bucket name is not included in the URL.',
  'deploy.backupConfiguration.backupEndpointPlaceholder':
    'Enter a Non-S3 Endpoint URL',
  'deploy.backupConfiguration.backupTestConnectionButton': 'Test connection',
  'deploy.backupConfiguration.backupTestConnectionTestingButton': 'Testing',
  'deploy.backupConfiguration.backupTestConnectionSuccessText':
    'Connection successful',
  'deploy.backupConfiguration.backupTestConnectionFailureText': 'Connection failed',
  'deploy.backupConfiguration.backupTestConnectionFailureHelp':
    'Please check your credentials or the bucket permissions.',
  'deploy.backupConfiguration.deployFailedInvalidBackupLocationText':
    'The custom backup location details you provided are invalid. Please check your credentials or the bucket permissions.',

  // edge configuration
  'deploy.edgeConfiguration.numberOfCPUsLabel': 'Number of CPUs',
  'deploy.edgeConfiguration.ramInGBLabel': 'RAM in GiB',
  'deploy.edgeConfiguration.storageInGBLabel': 'Storage in GiB',
  'deploy.edgeConfiguration.numberOfNodesLabel': 'Number of nodes',

  // cloud configuration
  'deploy.managedConfiguration.nodeComputeSize': 'Node compute size',
  'deploy.managedConfiguration.numberOfNodesLabel': 'Number of nodes',
  'deploy.managedConfiguration.numberofNodesHelp':
    'Choose 3 or more nodes for high availability',
  'deploy.managedConfiguration.crfreeRulesText1':
    'Free tier clusters are always free and do not require a payment method.',
  'deploy.managedConfiguration.crfreeRulesText2':
    'They will be suspended if they are unused for 4 days, and deleted after an additional 10 days of inactivity. These clusters cannot be scaled or modified.',
  'deploy.managedConfiguration.crfreeRulesText3':
    'Free tier clusters are ideal for evaluating CrateDB or for small side projects.',
  'deploy.managedConfiguration.storagePerNode': 'Node storage size',
  'deploy.managedConfiguration.storagePerNodeHelp':
    'Storage can be increased after your cluster has been deployed, but not decreased',
  'deploy.managedConfiguration.storageWarning':
    'We recommend selecting more CPU and RAM for this storage size.',

  // region selector
  'deploy.regionSelector.addEdgeRegion': 'Add a new edge region',
  'deploy.regionSelector.requestNewRegion': 'Request a new region',
  'deploy.regionSelector.unavailable': 'Unavailable region',
  'deploy.regionSelector.deployCloudCluster': 'Deploy cloud cluster',
  'deploy.regionSelector.deployEdgeCluster': 'Deploy edge cluster',

  // management settings
  'deploy.managementSettings.freeTrialAltText': 'free trial',
  'deploy.managementSettings.discountAltText': 'discount',

  // choose payment method
  'deploy.choosePaymentProvider.choosePaymentMethodTitle':
    'Add a new payment method',
  'deploy.choosePaymentProvider.choosePaymentMethodText':
    '* Free trial clusters will not be charged until the cluster becomes billable',
  'deploy.choosePaymentProvider.useCreditCardText': 'Pay with credit or debit card',
  'deploy.choosePaymentProvider.useBankTransferText': 'Pay via bank transfer',
  'deploy.choosePaymentProvider.pendingCloudSubscriptionSectionText':
    'Your marketplace account is set up for cluster billing. Please wait while the marketplace activates your subscription.',
  'deploy.choosePaymentProvider.pristineCloudSubscriptionText':
    'Subscribe via {provider} Marketplace',
  'deploy.choosePaymentProvider.crateContractText': 'Pay via invoice',
  'deploy.choosePaymentProvider.bankTransferConversionRate':
    'Usage linked to this subscription is charged in Euros, with a conversion rate of $1 = €0.9243.',
  'deploy.choosePaymentProvider.creditCardConversionRate':
    'Usage linked to this subscription is charged in Euros, with a conversion rate of $1 = €0.9243.',

  // product configuration
  'deploy.productConfiguration.nodeSpecLabel': 'Node specification',
  'deploy.productConfiguration.scaleUnitTitle': 'Cluster scale',
  'deploy.productConfiguration.cpuLabel': 'vCPU',
  'deploy.productConfiguration.sharedCPUTitle': 'Shared CPU cores',
  'deploy.productConfiguration.sharedCPU1Text':
    'Up to {cores} cores of a shared CPU will be allocated to your cluster.',
  'deploy.productConfiguration.sharedCPU2Text':
    'This means that performance is not guaranteed, but we can keep the costs down.',
  'deploy.productConfiguration.nodesLabel': 'Nodes',
  'deploy.productConfiguration.ramLabel': 'RAM',
  'deploy.productConfiguration.gibRamLabel': 'GiB RAM',
  'deploy.productConfiguration.storageLabel': 'Storage',
  'deploy.productConfiguration.clusterScaleHelp': 'Choose number of nodes:',
  'deploy.productConfigutation.upTo': 'Up to',

  // budget quota
  'deploy.quota.quotaExceededTitle': `This cluster configuration exceeds your monthly remaining quota`,
  'deploy.quota.quotaExceededPriceTitle': `This cluster configuration exceeds your remaining quota of \${quota} per month`,
  'deploy.quota.quotaExceededDescriptionText':
    'If you would like to increase your available quota, please contact our support team using the form below.',

  // usage questionnaire
  'deploy.questionnaire.tellUseCase': 'Tell us about your use case',
  'deploy.questionnaire.usageReason': 'What will you be using this cluster for?',

  // post-deploy
  'deploy.postDeploy.clusterCredentialsText':
    'Please view your cluster credentials below. This is the last time these credentials will be available to view. ' +
    'However, you can set a new password at any time in the cluster settings page.',
};

export default messages;
