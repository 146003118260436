import { IdentityProvider } from './defaults';

const restApiPrefix = '/oauth2';
const scheme = 'https://';

export const auth = {
  hostname: scheme.concat(window.location.hostname),
};

export const backendURI = () => {
  // used by tests
  if (auth.hostname === 'https://localhost' && window.location.port === '') {
    return 'https://localhost';
  }

  if (
    auth.hostname.indexOf('localhost') !== -1 ||
    auth.hostname.indexOf('0.0.0.0') !== -1
  ) {
    return 'http://localhost:8000';
  }
  return auth.hostname;
};

export const callback = {
  login_uri: (idp: IdentityProvider, redirect: string) =>
    `${backendURI() + restApiPrefix}/${idp}/login?rd=${redirect}`,
  logout_uri: () => `${backendURI() + restApiPrefix}/logout?rd=/`,
};
