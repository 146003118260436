import { Form, Input } from 'antd';
import { Button } from '@crate.io/crate-gc-admin';
import { FormattedMessage, useIntl } from 'react-intl';
import { CreateIntegrationMongoDBBody } from './forms';
import ConstrainStepWidth from 'src/components/StepLayout/ConstrainStepWidth';
import INPUT_SANITIZATION from '../../../../../constants/inputSanitization';
import { FIELD_NAMES } from '../../constants';

export type CollectionDetailsStepProps = {
  defaultValues: CreateIntegrationMongoDBBody;
  onNext: (result: CreateIntegrationMongoDBBody) => void;
};

function CollectionDetailsStep({
  defaultValues,
  onNext,
}: CollectionDetailsStepProps) {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<CreateIntegrationMongoDBBody>();

  const onFormFinish = (values: CreateIntegrationMongoDBBody) => {
    onNext(values);
  };

  return (
    <ConstrainStepWidth>
      <Form<CreateIntegrationMongoDBBody>
        autoComplete="off"
        form={form}
        initialValues={{
          ...defaultValues,
        }}
        layout="vertical"
        name="mongodb-integration-collection-form"
        id="mongodb-integration-collection-form"
        aria-label="mongodb-integration-collection-form"
        onFinish={onFormFinish}
        requiredMark="optional"
      >
        <Form.Item<CreateIntegrationMongoDBBody>
          colon={false}
          label={
            <FormattedMessage id="cluster.clusterImportIntegration.collectionDetailsStep.databaseLabel" />
          }
          name={[FIELD_NAMES.MONGODB_NAMESPACE, FIELD_NAMES.MONGODB_DATABASE_NAME]}
          rules={[INPUT_SANITIZATION.MONGODB_DATABASE_NAME]}
        >
          <Input
            placeholder={formatMessage({
              id: 'cluster.clusterImportIntegration.collectionDetailsStep.databasePlaceholder',
            })}
          />
        </Form.Item>

        <Form.Item<CreateIntegrationMongoDBBody>
          colon={false}
          label={
            <FormattedMessage id="cluster.clusterImportIntegration.collectionDetailsStep.collectionLabel" />
          }
          name={[FIELD_NAMES.MONGODB_NAMESPACE, FIELD_NAMES.MONGODB_COLLECTION_NAME]}
          rules={[INPUT_SANITIZATION.MONGODB_COLLECTION_NAME]}
        >
          <Input
            placeholder={formatMessage({
              id: 'cluster.clusterImportIntegration.collectionDetailsStep.collectionPlaceholder',
            })}
          />
        </Form.Item>

        <div className="flex">
          <Button type={Button.types.SUBMIT}>
            <FormattedMessage id="common.next" />
          </Button>
        </div>
      </Form>
    </ConstrainStepWidth>
  );
}

export default CollectionDetailsStep;
