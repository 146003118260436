import PropTypes from 'prop-types';

export default PropTypes.shape({
  description: PropTypes.string,
  discount_percent: PropTypes.number.isRequired,
  id: PropTypes.string,
  plan_name: PropTypes.string.isRequired,
  plan_tier: PropTypes.string,
  scale_unit: PropTypes.number.isRequired,
  valid_to: PropTypes.string,
  duration_months: PropTypes.number,
});
