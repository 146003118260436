import { INTEGRATION_TYPES } from 'src/constants/integrations';
import logoMongoDB from '../../../../../assets/logo_mongodb.svg';
import logoAmazonS3 from '../../../../../assets/logo_amazon_s3.png';
import logoAmazonDynamoDB from '../../../../../assets/logo_dynamodb.png';
import { IMPORT_JOB_TYPES } from 'src/constants/importJobs';
import { IntegrationType } from 'src/types';

export const INTEGRATION_SOURCES = [
  {
    title: 'cluster.clusterImportIntegration.sourceStep.mongoDb',
    image: <img src={logoMongoDB} alt="mongodb logo" className="size-10" />,
    icon: (
      <img src={logoMongoDB} alt="mongodb icon" className="inline-flex size-4" />
    ),
    disabled: false,
    key: INTEGRATION_TYPES.MONGODB satisfies IntegrationType,
  },
  {
    title: 'cluster.clusterImportIntegration.sourceStep.s3',
    image: <img src={logoAmazonS3} alt="s3 logo" className="size-10" />,
    icon: <img src={logoAmazonS3} alt="s3 icon" className="inline-flex size-4" />,
    disabled: true,
    key: IMPORT_JOB_TYPES.S3,
  },
  {
    title: 'cluster.clusterImportIntegration.sourceStep.dynamoDb',
    image: <img src={logoAmazonDynamoDB} alt="DynamoDB logo" className="size-10" />,
    icon: (
      <img
        src={logoAmazonDynamoDB}
        alt="DynamoDB icon"
        className="inline-flex size-4"
      />
    ),
    disabled: true,
    key: 'dynamoDb',
  },
] as const;
