import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Heading, Text } from '@crate.io/crate-gc-admin';
import SubscriptionCard from '../SubscriptionCard';
import awsLogo from '../../../../assets/logo_aws.svg';
import azureLogo from '../../../../assets/logo_azure_2021.svg';
import gcpLogo from '../../../../assets/logo_gcp.svg';
import {
  AWS_MARKETPLACE_SUBSCRIPTIONS,
  AZURE_MARKETPLACE_SUBSCRIPTIONS,
  GCP_MARKETPLACE_SUBSCRIPTIONS,
} from '../../../../constants/links';

function MarketplaceSubscriptionCard({ provider, subscription }) {
  const { formatMessage } = useIntl();
  const providerLookup = {
    aws: {
      name: formatMessage({
        id: 'organization.marketplaceSubscription.awsMarketplace',
      }),
      logo: <img src={awsLogo} className="-mt-2 h-12 w-12" alt="AWS logo" />,
      text: 'organization.subscriptionDetail.marketplaceManageAWS',
      url: AWS_MARKETPLACE_SUBSCRIPTIONS,
    },
    azure: {
      name: formatMessage({
        id: 'organization.marketplaceSubscription.azureMarketplace',
      }),
      logo: <img src={azureLogo} className="h-10 w-12" alt="Azure logo" />,
      text: 'organization.subscriptionDetail.marketplaceManageAzure',
      url: AZURE_MARKETPLACE_SUBSCRIPTIONS,
    },
    gcp: {
      name: formatMessage({
        id: 'organization.marketplaceSubscription.gcpMarketplace',
      }),
      logo: <img src={gcpLogo} className="h-8 w-12" alt="GCP logo" />,
      text: 'organization.subscriptionDetail.marketplaceManageGCP',
      url: GCP_MARKETPLACE_SUBSCRIPTIONS,
    },
  };

  return (
    <SubscriptionCard
      logo={providerLookup[provider].logo}
      reference={`ID: ${subscription.id}`}
      subscriptionId={subscription.id}
      title={providerLookup[provider].name}
    >
      <div className="space-y-4">
        <div>
          <div className="flex items-center justify-between gap-4">
            <Heading level={Heading.levels.h5}>
              <FormattedMessage id="organization.marketplaceSubscription.referenceNumber" />
            </Heading>
          </div>
          <Text>{subscription.reference}</Text>
        </div>
        <div data-testid="additional-info-wrapper">
          <div className="flex items-center justify-between gap-4">
            <Heading level={Heading.levels.h5}>
              <FormattedMessage id="organization.marketplaceSubscription.additionalInfo" />
            </Heading>
          </div>
          <Text>
            <FormattedMessage id="organization.subscriptionDetail.marketplaceManage1" />
            <a href={providerLookup[provider].url} target="_blank" rel="noreferrer">
              <FormattedMessage id={providerLookup[provider].text} />
            </a>
            <FormattedMessage id="organization.subscriptionDetail.marketplaceManage2" />
          </Text>
        </div>
      </div>
    </SubscriptionCard>
  );
}

MarketplaceSubscriptionCard.propTypes = {
  provider: PropTypes.string.isRequired,
  subscription: PropTypes.object.isRequired,
};

export default MarketplaceSubscriptionCard;
