import useSWR from 'swr';
import swrJWTFetch, { JWTFetchArgs } from '../../utils/swrJWTFetch';

export type TableShard = [
  string, // schema_name
  string, // table_name
  boolean, // is_partitioned
  string, // number_of_replicas
  number, // number_of_shards
  number, // started_primary
  number, // started_replica
  number, // unassigned_primary
  number, // unassigned_replica
  number, // total_primary
  number, // total_replica
  number, // num_docs_primary
  number, // size_primary
  (
    | {
        partition_ident: string;
        number_of_replicas: string;
        number_of_shards: number;
        started_primary: number;
        started_replica: number;
        unassigned_primary: number;
        unassigned_replica: number;
        total_primary: number;
        total_replica: number;
        num_docs_primary: number;
        size_primary: number;
      }[]
    | null
  ),
];

type TablesShards = {
  cols: string[];
  rows: TableShard[];
};

const QUERY = `
  WITH base_shards AS (
    SELECT
      s.schema_name,
      s.table_name,
      arbitrary(t.partitioned_by) IS NOT NULL AS is_partitioned,
      arbitrary(t.number_of_replicas) AS number_of_replicas,
      arbitrary(t.number_of_shards) AS number_of_shards,
      COUNT(*) FILTER (WHERE s.primary AND s.routing_state IN ('STARTED','RELOCATING')) AS started_primary,
      COUNT(*) FILTER (WHERE NOT s.primary AND s.routing_state IN ('STARTED','RELOCATING')) AS started_replica,
      COUNT(*) FILTER (WHERE s.primary AND s.routing_state IN ('UNASSIGNED')) AS unassigned_primary,
      COUNT(*) FILTER (WHERE NOT s.primary AND s.routing_state IN ('UNASSIGNED')) AS unassigned_replica,
      COUNT(*) FILTER (WHERE s.primary) AS total_primary,
      COUNT(*) FILTER (WHERE NOT s.primary) AS total_replica,
      SUM(s.num_docs) FILTER (WHERE s.primary) AS num_docs_primary,
      SUM(s.size) FILTER (WHERE s.primary) AS size_primary
    FROM sys.shards s
    JOIN information_schema.tables t
      ON s.schema_name = t.table_schema
     AND s.table_name = t.table_name
    GROUP BY s.schema_name, s.table_name
  ), partitioned_shards AS (
    SELECT
      s.schema_name,
      s.table_name,
      s.partition_ident,
      arbitrary(tp.number_of_replicas) AS number_of_replicas,
      arbitrary(tp.number_of_shards) AS number_of_shards,
      COUNT(*) FILTER (WHERE s.primary AND s.routing_state IN ('STARTED','RELOCATING')) AS started_primary,
      COUNT(*) FILTER (WHERE NOT s.primary AND s.routing_state IN ('STARTED','RELOCATING')) AS started_replica,
      COUNT(*) FILTER (WHERE s.primary AND s.routing_state IN ('UNASSIGNED')) AS unassigned_primary,
      COUNT(*) FILTER (WHERE NOT s.primary AND s.routing_state IN ('UNASSIGNED')) AS unassigned_replica,
      COUNT(*) FILTER (WHERE s.primary) AS total_primary,
      COUNT(*) FILTER (WHERE NOT s.primary) AS total_replica,
      SUM(s.num_docs) FILTER (WHERE s.primary) AS num_docs_primary,
      SUM(s.size) FILTER (WHERE s.primary) AS size_primary
    FROM sys.shards s
    JOIN information_schema.table_partitions tp
      ON s.schema_name = tp.table_schema
     AND s.table_name = tp.table_name
     AND s.partition_ident = tp.partition_ident
    WHERE s.partition_ident <> ''
    GROUP BY s.schema_name, s.table_name, s.partition_ident
  ), partitioned_shards_agg AS (
    SELECT
      schema_name,
      table_name,
      ARRAY_AGG(
        {
          partition_ident = partition_ident,
          number_of_replicas = number_of_replicas,
          number_of_shards = number_of_shards,
          started_primary = started_primary,
          started_replica = started_replica,
          unassigned_primary = unassigned_primary,
          unassigned_replica = unassigned_replica,
          total_primary = total_primary,
          total_replica = total_replica,
          num_docs_primary = num_docs_primary,
          size_primary = size_primary
        }
      ) AS partitions
    FROM partitioned_shards
    GROUP BY schema_name, table_name
  )

  SELECT
    base_shards.*,
    partitioned_shards_agg.partitions
  FROM base_shards
  LEFT JOIN partitioned_shards_agg
    USING (schema_name, table_name)
  ORDER BY schema_name, table_name;
`;

const useGetTablesShards = (args: {
  clusterId: string;
  crateUrl: string;
  crateVersion: string;
  gcUrl: string;
  sessionTokenKey: string;
}) => {
  return useSWR<TablesShards>(
    [
      '/use-get-tables-shards',
      {
        clusterId: args.clusterId,
        crateUrl: args.crateUrl,
        crateVersion: args.crateVersion,
        gcUrl: args.gcUrl,
        sql: QUERY,
        sessionTokenKey: args.sessionTokenKey,
      },
    ],
    ([url, args]: [string, JWTFetchArgs]) => swrJWTFetch(url, args),
    {
      refreshInterval: 10 * 1000,
    },
  );
};

export default useGetTablesShards;
