const messages = {
  'common.back': 'Back',
  'common.beta': 'Beta',
  'common.cancel': 'Cancel',
  'common.close': 'Close',
  'common.compute': 'Compute',
  'common.confirm': 'Confirm',
  'common.continue': 'Continue',
  'common.copy': 'Copy',
  'common.cpu': 'CPU',
  'common.cratedb': 'CrateDB',
  'common.delete': 'Delete',
  'common.deploy': 'Deploy',
  'common.disabled': 'Disabled',
  'common.download': 'Download',
  'common.edit': 'Edit',
  'common.email': 'Email',
  'common.enabled': 'Enabled',
  'common.free': 'Free',
  'common.hide': 'Hide',
  'common.hours': 'Hours',
  'common.hourly': 'Hourly',
  'common.id': 'ID',
  'common.manage': 'Manage',
  'common.modify': 'Modify',
  'common.monthly': 'Monthly',
  'common.name': 'Name',
  'common.next': 'Next',
  'common.no': 'No',
  'common.off': 'Off',
  'common.ok': 'OK',
  'common.on': 'On',
  'common.or': 'Or',
  'common.password': 'Password',
  'common.previous': 'Previous',
  'common.ram': 'RAM',
  'common.ref': 'Ref', // abbreviation of reference
  'common.region': 'Region',
  'common.reset': 'Reset',
  'common.retry': 'Retry',
  'common.save': 'Save',
  'common.search': 'Search',
  'common.settings': 'Settings',
  'common.show': 'Show',
  'common.storage': 'Storage',
  'common.total': 'Total',
  'common.update': 'Update',
  'common.username': 'Username',
  'common.userid': 'User-ID',
  'common.vcpu': 'vCPU',
  'common.view': 'View',
  'common.yes': 'Yes',
} as const;

export default messages;
