import CardSelector from 'src/components/CardSelector';
import { ImportJobType } from 'src/types';
import { IMPORT_JOBS_SOURCES } from './sources';

export type SourceStepProps = {
  sourceType: ImportJobType | null;
  onChangeSourceType: (type: ImportJobType) => void;
};

function SourceStep({ sourceType, onChangeSourceType }: SourceStepProps) {
  return (
    <div>
      {/* source type */}
      <CardSelector<ImportJobType>
        value={sourceType ? sourceType : undefined}
        onChange={onChangeSourceType}
        items={IMPORT_JOBS_SOURCES}
      />
    </div>
  );
}

export default SourceStep;
