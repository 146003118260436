const messages = {
  'hubspot.common.message': 'Message',
  'hubspot.common.name': 'Name',
  'hubspot.common.firstName': 'First name',
  'hubspot.common.lastName': 'Last name',
  'hubspot.common.optional': 'Optional',
  'hubspot.common.phone': 'Phone',
  'hubspot.common.phoneIncludeCountryCode':
    'Optional. Please include your country code.',
  'hubspot.common.messageSuccess': 'Your message has been received successfully.',
  'hubspot.common.messageFail':
    'There was a problem sending your message. Please try again later.',

  // put form specific items under here
  'hubspot.enterpriseForm.tellUsUseCase': 'Tell us about your use case',
  'hubspot.enterpriseForm.thankYou':
    'Thank you for contacting us. Someone from our team will contact you shortly.',
};

export default messages;
