import { Text } from '@crate.io/crate-gc-admin';
import { ImportJobType } from 'src/types';
import { IMPORT_JOBS_SOURCES } from './sources';
import { FormattedMessage } from 'react-intl';

export type SourceStepSummaryProps = {
  sourceType: ImportJobType;
};

function SourceStepSummary({ sourceType }: SourceStepSummaryProps) {
  const selectedSource = IMPORT_JOBS_SOURCES.find(el => el.key === sourceType)!;

  return (
    <div className="flex items-center gap-2 text-black">
      {selectedSource.icon}
      <Text pale>
        <FormattedMessage id={selectedSource.title} />
      </Text>
    </div>
  );
}

export default SourceStepSummary;
