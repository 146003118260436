import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form, Modal, Select } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { Button, Text } from '@crate.io/crate-gc-admin';
import HubspotFormRequestNewRegion from '../../../components/HubspotForms/HubspotFormRequestNewRegion';
import { QUERY_PARAM_KEY_REGION, REGION_STATES } from '../../../constants/defaults';
import { regionPropType } from '../../../models';
import { getUserIsOrganizationAdmin } from '../../../utils/data/user';
import useDeployStore from '../state';
import { useGetUsersMe } from '../../../swrHooks';
import { organizationRegions } from '../../../constants/paths';
import edgeLogo from '../../../assets/logo_edge.svg';
import gcpLogo from '../../../assets/logo_gcp.svg';
import awsLogo from '../../../assets/logo_aws.svg';
import azureLogo from '../../../assets/logo_azure_2021.svg';

function RegionSelector({ organizationId, regions }) {
  const location = useLocation();
  const [showRequestRegionModal, setShowRequestRegionModal] = useState(false);
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const deployRegion = useDeployStore(state => state.deployRegion);
  const deployEdgeMode = useDeployStore(state => state.deployEdgeMode);
  const setDeployEdgeMode = useDeployStore(state => state.setDeployEdgeMode);
  const setDeployRegion = useDeployStore(state => state.setDeployRegion);
  const { data: user } = useGetUsersMe();
  const userIsOrgAdmin = getUserIsOrganizationAdmin(user, organizationId);

  const availableRegions = regions
    .filter(region => !region.deprecated && region.is_edge_region === deployEdgeMode)
    .sort((a, b) =>
      a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1,
    );

  const setDefaultRegion = edgeMode => {
    setDeployRegion(
      regions.find(
        region =>
          !region.deprecated &&
          region.is_edge_region === edgeMode &&
          region.status !== REGION_STATES.DOWN,
      ),
    );
  };

  const changeRegionMode = edgeMode => {
    setDeployEdgeMode(edgeMode);
    setDefaultRegion(edgeMode);
  };

  const drawLinks = () => {
    if (deployEdgeMode) {
      return (
        <>
          {userIsOrgAdmin && (
            <>
              <Link to={organizationRegions.build({ organizationId })}>
                <FormattedMessage id="deploy.regionSelector.addEdgeRegion" />
              </Link>
              <span className="text-crate-border-light">|</span>
            </>
          )}
          <Button
            className="py-0 text-sm"
            onClick={() => changeRegionMode(false)}
            kind={Button.kinds.TERTIARY}
          >
            <FormattedMessage id="deploy.regionSelector.deployCloudCluster" />
          </Button>
        </>
      );
    }

    const validEdgeRegionExists = regions.some(
      region =>
        !region.deprecated &&
        region.is_edge_region &&
        region.status !== REGION_STATES.DOWN,
    );

    return (
      <>
        <Button
          className="py-0"
          onClick={() => setShowRequestRegionModal(true)}
          kind={Button.kinds.TERTIARY}
          size={Button.sizes.SMALL}
        >
          <FormattedMessage id="deploy.regionSelector.requestNewRegion" />
        </Button>
        {validEdgeRegionExists && (
          <>
            <span className="text-crate-border-light">|</span>
            <Button
              className="py-0"
              onClick={() => changeRegionMode(true)}
              kind={Button.kinds.TERTIARY}
              size={Button.sizes.SMALL}
            >
              <FormattedMessage id="deploy.regionSelector.deployEdgeCluster" />
            </Button>
          </>
        )}
      </>
    );
  };

  const drawRegion = region => {
    let img = edgeLogo;
    let alt = 'Edge region logo';
    let css = 'h-12 w-12';
    if (region.name.endsWith('aws')) {
      img = awsLogo;
      alt = 'Amazon Web Services logo';
    } else if (region.name.endsWith('azure')) {
      img = azureLogo;
      alt = 'Azure logo';
      css = 'h-10 m-1 w-10';
    } else if (region.name.endsWith('gcp')) {
      img = gcpLogo;
      alt = 'Google Cloud Platform logo';
      css = 'h-8 mx-0.5 w-12';
    }
    if (region.status === REGION_STATES.DOWN) {
      css += ' grayscale opacity-50';
    }

    return (
      <div className="crate-fat-dropdown-option">
        <div className="flex flex-row items-center font-normal">
          <img src={img} className={css} alt={alt} />
          <div className="ml-4 flex flex-col justify-center">
            <Text>{region.description}</Text>
            <Text pale>{region.name}</Text>
          </div>
        </div>
      </div>
    );
  };

  // set default region if one is not already set
  useEffect(() => {
    if (!deployRegion && availableRegions) {
      // if a reqion name is passed in in the querystring, and
      // it exists in the list, use that as the default region
      const deeplinkedRegionName = searchParams.get(QUERY_PARAM_KEY_REGION);
      if (deeplinkedRegionName) {
        const deeplinkedRegion = regions.find(
          ({ name }) => name === deeplinkedRegionName,
        );

        if (deeplinkedRegion) {
          setDeployRegion(deeplinkedRegion);
          setDeployEdgeMode(deeplinkedRegion.is_edge_region);
          return;
        }
      }

      // pick a random region from the list of available regions
      const availableNonEdgeRegions = availableRegions.filter(
        region => !region.is_edge_region && region.status !== REGION_STATES.DOWN,
      );
      setDeployRegion(
        availableNonEdgeRegions[
          Math.floor(Math.random() * availableNonEdgeRegions.length)
        ],
      );
    }
  }, [
    availableRegions,
    regions,
    searchParams,
    deployRegion,
    setDeployEdgeMode,
    setDeployRegion,
  ]);

  return (
    <>
      <Form.Item label={<FormattedMessage id="common.region" />}>
        <Select
          className="crate-fat-dropdown"
          onChange={region =>
            setDeployRegion(availableRegions.find(({ name }) => name === region))
          }
          value={deployRegion?.name}
          options={availableRegions?.map(region => ({
            label: drawRegion(region),
            value: region.name,
            disabled: region.status === REGION_STATES.DOWN,
          }))}
          data-testid="region-selector"
        />
      </Form.Item>

      <div className="-mt-6 flex items-center justify-end gap-2">{drawLinks()}</div>

      <Modal
        closable={false}
        footer={null}
        onCancel={() => setShowRequestRegionModal(false)}
        open={showRequestRegionModal}
      >
        <HubspotFormRequestNewRegion
          onFormComplete={() => setShowRequestRegionModal(false)}
          onFormCancel={() => setShowRequestRegionModal(false)}
          hiddenFields={{
            cluster_configuration: `organization: ${organizationId}`,
          }}
        />
      </Modal>
    </>
  );
}

RegionSelector.propTypes = {
  organizationId: PropTypes.string.isRequired,
  regions: PropTypes.arrayOf(regionPropType),
};

RegionSelector.defaultProps = {
  regions: null,
};

export default RegionSelector;
