import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  BulbOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import NOTIFICATION_TYPES from './constants';

function NotificationAside({ type, message, link }) {
  const typeMap = {
    [NOTIFICATION_TYPES.TOOLTIP]: {
      icon: <BulbOutlined className="left-0 mr-2 text-base" />,
      bgClass: 'bg-white',
      fontClass: 'font-bold',
    },
    [NOTIFICATION_TYPES.INFO]: {
      icon: <InfoCircleOutlined className="left-0 mr-2 text-base text-amber-600" />,
      bgClass: 'bg-white',
      fontClass: '',
    },
    [NOTIFICATION_TYPES.WARN]: {
      icon: <WarningOutlined className="left-0 mr-2 text-base text-amber-600" />,
      bgClass: 'bg-amber-200',
      fontClass: '',
    },
  };

  return (
    <div className={cx('p-4', 'rounded-lg', 'w-full', typeMap[type].bgClass)}>
      <div
        className={cx('flex', 'items-center', {
          'justify-between': type !== NOTIFICATION_TYPES.TOOLTIP,
        })}
      >
        <div className="mr-2 flex items-center">
          {typeMap[type].icon}
          <div className={`mr-1 ${typeMap[type].fontClass} `}>{message}</div>
        </div>
        {link && link}
      </div>
    </div>
  );
}

NotificationAside.types = NOTIFICATION_TYPES;

NotificationAside.propTypes = {
  type: PropTypes.oneOf(Object.values(NOTIFICATION_TYPES)).isRequired,
  // Accept a string for message and not a node
  // to ensure consistent presentation
  message: PropTypes.string.isRequired,
  link: PropTypes.node,
};

NotificationAside.defaultProps = {
  link: null,
};

export default NotificationAside;
