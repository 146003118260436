import PropTypes from 'prop-types';

export default PropTypes.shape({
  total: PropTypes.number,
  subtotal: PropTypes.number,
  total_discount_amount: PropTypes.number,
  period_start: PropTypes.string.isRequired,
  period_end: PropTypes.string.isRequired,
  discounts: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
});
