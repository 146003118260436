import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input } from 'antd';
import { Button, SubmitButtonGroup } from '@crate.io/crate-gc-admin';
import SettingsOption from '../../../components/SettingsOption';
import SectionContainer from '../../../components/SectionContainer';
import { accountSettings } from '../../../constants/paths';
import { expiring24hCookieOptions } from '../../../constants/cookieOptions';
import { deleteCookie, getCookie, setCookie } from '../../../utils/auth';
import { useGetUsersMe } from '../../../swrHooks';
import { EMAIL_CONFIRMATION_PENDING_COOKIE } from '../../../constants/defaults';
import { apiPatch, apiPut } from '../../../api';
import useMessage from '../../../hooks/useMessage';
import TestSWRIsFetching from '../../../components/TestSWRIsFetching';
import INPUT_SANITIZATION from '../../../constants/inputSanitization';

function UserCredentials() {
  const location = useLocation();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { showLoadingMessage, showSuccessMessage } = useMessage();

  const [editing, setEditing] = useState(false);
  const [verified, setVerified] = useState(false);
  const {
    data: user,
    mutate: mutateUser,
    isValidating: isUserValidating,
  } = useGetUsersMe();
  const emailConfirmationPendingCookie = getCookie(
    EMAIL_CONFIRMATION_PENDING_COOKIE,
  );

  const verifyToken = useCallback(
    async urlSearchParams => {
      const token = urlSearchParams.get('token');
      if (token && !verified) {
        setVerified(true);
        showLoadingMessage(formatMessage({ id: 'account.user.verifyEmailAddress' }));
        const { success, data } = await apiPut('/api/v2/users/me/confirm-email/', {
          token,
        });

        if (success) {
          showSuccessMessage(
            formatMessage({ id: 'account.user.emailAddressConfirmed' }),
          );

          mutateUser(data);

          // delete the cookie and redirect to account settings page
          deleteCookie(EMAIL_CONFIRMATION_PENDING_COOKIE, expiring24hCookieOptions);
          navigate(accountSettings.build());
        }
      }
    },
    [
      formatMessage,
      mutateUser,
      navigate,
      showLoadingMessage,
      showSuccessMessage,
      verified,
    ],
  );

  // verify token in the url
  useEffect(() => {
    verifyToken(new URLSearchParams(location.search));
  }, [location, verifyToken]);

  const handleFormFinish = async ({ email }) => {
    setEditing(false);
    showLoadingMessage(formatMessage({ id: 'account.user.updatingEmailAddress' }));
    const { success, data } = await apiPatch('/api/v2/users/me/', {
      email,
    });

    if (success) {
      showSuccessMessage(data.message);
      setCookie(EMAIL_CONFIRMATION_PENDING_COOKIE, email, expiring24hCookieOptions);
    }
  };

  const renderActions = () => {
    if (editing) {
      return (
        <SubmitButtonGroup
          confirmLabel={<FormattedMessage id="common.save" />}
          form="update-user-form"
          onCancel={() => setEditing(false)}
        />
      );
    }
    return (
      <Button onClick={() => setEditing(true)} kind={Button.kinds.TERTIARY}>
        <FormattedMessage id="common.edit" />
      </Button>
    );
  };

  const renderEmail = () => (
    <Form.Item
      hasFeedback
      name="email"
      rules={[INPUT_SANITIZATION.GENERIC_EMAIL]}
      initialValue={user?.email || ''}
      data-testid="email-input"
    >
      <Input />
    </Form.Item>
  );

  return (
    <SectionContainer
      title={<FormattedMessage id="account.accountSettings.credentialsTitle" />}
      actions={renderActions()}
    >
      <Form
        id="update-user-form"
        aria-label="update user data form"
        layout="horizontal"
        onFinish={handleFormFinish}
      >
        <div className="flex flex-col gap-4 xl:grid xl:grid-cols-12">
          <div className="xl:col-span-3">
            <SettingsOption
              label="username"
              title={formatMessage({ id: 'common.username' })}
              read={user?.username || '-'}
            />
          </div>
          <div className="xl:col-span-3">
            <SettingsOption
              label="email"
              className={editing ? 'editing' : ''}
              title={formatMessage({ id: 'common.email' })}
              read={emailConfirmationPendingCookie || user?.email || '-'}
              edit={renderEmail()}
              isEditing={editing}
            />
          </div>
          <div className="xl:col-span-4">
            <SettingsOption
              label="userid"
              title={formatMessage({ id: 'common.userid' })}
              read={user?.uid || '-'}
            />
          </div>
          <div className="xl:col-span-2">
            <SettingsOption
              label="authMethod"
              title={formatMessage({
                id: 'account.accountSettings.authMethod',
              })}
              read={formatMessage({
                id: `account.accountSettings.authMethod.${user?.idp}`,
                defaultMessage: '-',
              })}
            />
          </div>
        </div>
      </Form>
      <TestSWRIsFetching fetchStatusList={[isUserValidating]} />
    </SectionContainer>
  );
}

export default UserCredentials;
