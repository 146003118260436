import PropTypes from 'prop-types';
import hardwareSpecifications from './hardwareSpecifications';

export default PropTypes.shape({
  description: PropTypes.string.isRequired,
  guarantees_per_dtu: PropTypes.shape({
    disk_space_bytes: PropTypes.number.isRequired,
    inserts_second: PropTypes.number.isRequired,
    queries_second: PropTypes.number.isRequired,
  }),
  kind: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price_per_dtu_minute: PropTypes.number.isRequired,
  scale_summary: PropTypes.string.isRequired,
  tier: PropTypes.string.isRequired,
  scaling: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      disk_space_bytes: PropTypes.number.isRequired,
      dtus: PropTypes.number.isRequired,
      inserts_second: PropTypes.number.isRequired,
      price_per_minute: PropTypes.number.isRequired,
      queries_second: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  specs: hardwareSpecifications.isRequired,
});
