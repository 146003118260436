import { useParams } from 'react-router-dom';
import { Heading, Users } from '@crate.io/crate-gc-admin';
import SectionContainer from 'src/components/SectionContainer';
import GrandCentralWrapper from 'src/components/GrandCentralWrapper';
import { FormattedMessage } from 'react-intl';

function ClusterUsers() {
  const { clusterId, organizationId } = useParams();

  return (
    <GrandCentralWrapper clusterId={clusterId!} organizationId={organizationId!}>
      <SectionContainer className="absolute !mb-0 h-full w-full overflow-x-auto rounded-none !pb-0">
        <Heading className="mb-2" level={Heading.levels.h2}>
          <FormattedMessage id="clusterUsers.clusterUsers" />
        </Heading>

        <Users />
      </SectionContainer>
    </GrandCentralWrapper>
  );
}

export default ClusterUsers;
