import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { useGetProductsClustersPrice } from '../../../swrHooks';
import useDeployStore from '../state';
import { toGibiBytes } from '../../../utils';
import { productPropType, regionPropType } from '../../../models';
import { PRICE_OPTIONS_3DP } from '../../../constants/defaults';

function ProductOption({ product, region, isSelected }) {
  const { formatNumber } = useIntl();
  const setCloudProduct = useDeployStore(state => state.setCloudProduct);
  const { organizationId } = useParams();
  const { data: price } = useGetProductsClustersPrice(
    organizationId,
    region,
    product,
    0,
    8589934592, // 8 GiB
  );

  const selectedStyle =
    'bg-crate-blue/10 border-t border-crate-border-light flex items-center justify-between min-h-[36px] text-left w-full';
  const unSelectedStyle =
    'bg-white border-t border-crate-border-light cursor-pointer flex items-center justify-between min-h-[36px] text-left w-full hover:bg-crate-blue/10';

  const renderPrice = () => {
    if (price) {
      if (price.cluster_price.price_per_hour === 0) {
        return (
          <span
            className="ml-1 h-5 rounded bg-[#28BDDA] px-3 py-1 font-bold uppercase tracking-wider text-white"
            style={{ fontSize: 9.5 }}
            data-testid="badge-free"
          >
            <FormattedMessage id="common.free" />
          </span>
        );
      }

      return (
        <div className="w-[27%] p-1 leading-tight">
          {formatNumber(price.cluster_price.price_per_hour, PRICE_OPTIONS_3DP)}{' '}
          <span className="whitespace-nowrap text-xs opacity-80">per hour</span>
        </div>
      );
    }

    return null;
  };

  return (
    <button
      className={isSelected ? selectedStyle : unSelectedStyle}
      onClick={() => setCloudProduct(product)}
      type="button"
    >
      <div className="flex w-[86%] items-center">
        <div className="w-[18%] px-2 text-xs uppercase text-crate-border-mid">
          {product.name}
        </div>
        <div className="w-[28%] px-1 leading-tight">
          {product.tags.includes('shared') && (
            <span className="whitespace-nowrap text-xs opacity-80">
              <FormattedMessage id="deploy.productConfigutation.upTo" />{' '}
            </span>
          )}
          <span>{product.specs.cpu_cores}&nbsp;vCPU</span>
        </div>
        <div className="w-[27%] px-1 leading-tight">
          {toGibiBytes(product.specs.ram_bytes)}{' '}
          <FormattedMessage id="deploy.productConfiguration.gibRamLabel" />
        </div>
        {renderPrice()}
      </div>
      <div className="flex w-1/6 items-center justify-end px-2">
        {isSelected && (
          <CheckCircleOutlined
            className="text-lg text-crate-blue"
            data-testid="selected-product-icon"
          />
        )}
      </div>
    </button>
  );
}

ProductOption.propTypes = {
  product: productPropType.isRequired,
  region: regionPropType.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default ProductOption;
