import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Modal } from 'antd';
import {
  Button,
  Heading,
  Loader,
  SubmitButtonGroup,
  Text,
} from '@crate.io/crate-gc-admin';
import moment from 'moment';
import SubscriptionCard from '../SubscriptionCard';
import PaymentEntryForm from '../../../../components/PaymentEntryForm';
import Tag from '../../../../components/Tag';
import amexLogo from '../../../../assets/logo_amex.svg';
import discoverLogo from '../../../../assets/logo_discover.svg';
import jcbLogo from '../../../../assets/logo_jcb.svg';
import mastercardLogo from '../../../../assets/logo_mastercard.svg';
import stripeLogo from '../../../../assets/logo_stripe_purple.svg';
import unionpayLogo from '../../../../assets/logo_unionpay.svg';
import visaLogo from '../../../../assets/logo_visa.svg';
import {
  useGetStripeOrganizationsIdCards,
  useGetStripeSubscriptionsIdInvoicesUpcoming,
} from '../../../../swrHooks';
import { PRICE_OPTIONS_2DP } from '../../../../constants/defaults';
import { DATE_FORMAT } from '../../../../constants/dates';
import useMessage from '../../../../hooks/useMessage';
import { fromCents } from '../../../../utils';
import { apiDelete, apiPatch } from '../../../../api';
import { EUR_CURRENCY } from '../../../../constants/currencies';

function CreditCardSubscriptionCard({ subscription }) {
  const { organizationId } = useParams();
  const { showLoadingMessage, showSuccessMessage } = useMessage();
  const { formatMessage } = useIntl();
  const [showAddCardModal, setShowAddCardModal] = useState(false);

  const {
    data: bankCards,
    isLoading: isBankCardsLoading,
    mutate: mutateBankCards,
  } = useGetStripeOrganizationsIdCards(organizationId);
  const { data: upcomingInvoice, isLoading: isUpcomingInvoiceLoading } =
    useGetStripeSubscriptionsIdInvoicesUpcoming(subscription);

  const drawCard = (card, faded = false) => {
    const cardLogos = {
      amex: amexLogo,
      discover: discoverLogo,
      jcb: jcbLogo,
      mastercard: mastercardLogo,
      unionpay: unionpayLogo,
      visa: visaLogo,
    };

    return (
      <div className="flex items-center gap-4">
        <div className="w-12">
          <img
            src={cardLogos[card.brand]}
            className={`h-10 w-12 object-contain ${faded ? 'opacity-50 grayscale' : ''}`}
            alt="Payment method logo"
          />
        </div>
        <div>
          <Text className="whitespace-nowrap text-xs leading-snug">
            <FormattedMessage
              id="organization.creditCardSubscription.cardEndingIn"
              values={{
                last4: card.last4,
              }}
            />
          </Text>
          <Text className="whitespace-nowrap text-xs leading-snug">
            <FormattedMessage
              id="organization.creditCardSubscription.expires"
              values={{
                expire: `${card.exp_month}/${card.exp_year.toString().substring(2)}`,
              }}
            />
          </Text>
        </div>
      </div>
    );
  };

  const drawValue = (title, children, extra = null) => (
    <div>
      <div className="flex items-center justify-between gap-4">
        <Heading level={Heading.levels.h5}>
          <FormattedMessage id={title} />
        </Heading>
        {extra}
      </div>
      {children || <Text>n/a</Text>}
    </div>
  );

  const handleSetAsDefault = async card => {
    showLoadingMessage(
      formatMessage(
        { id: 'organization.paymentMethods.updatingBankCardText' },
        { last4: card.last4 },
      ),
    );

    const { success } = await apiPatch(
      `/api/v2/stripe/organizations/${organizationId}/cards/${card.id}/`,
      {
        default: true,
      },
    );

    if (success) {
      await mutateBankCards();
      showSuccessMessage(
        formatMessage(
          { id: 'organization.paymentMethods.updatingBankCardSuccessText' },
          { last4: card.last4 },
        ),
      );
    }
  };

  const handleDelete = async card => {
    showLoadingMessage(
      formatMessage(
        { id: 'organization.paymentMethods.deletingBankCardText' },
        { last4: card.last4 },
      ),
    );
    const { success } = await apiDelete(
      `/api/v2/stripe/organizations/${organizationId}/cards/${card.id}/`,
    );

    if (success) {
      await mutateBankCards();
      showSuccessMessage(
        formatMessage(
          { id: 'organization.paymentMethods.deletingBankCardSuccessText' },
          { last4: card.last4 },
        ),
      );
    }
  };

  const defaultCard = bankCards?.find(card => card.default);

  return (
    <>
      <SubscriptionCard
        logo={
          <img src={stripeLogo} className="-mt-2 h-12 w-[68px]" alt="Stripe logo" />
        }
        reference={`ID: ${subscription.id}`}
        subscriptionId={subscription.id}
        title={formatMessage({
          id: 'organization.creditCardSubscription.creditOrDebitCard',
        })}
      >
        {(isBankCardsLoading || isUpcomingInvoiceLoading) && (
          <Loader
            align={Loader.alignment.CENTER}
            color={Loader.colors.PRIMARY}
            size={Loader.sizes.SMALL}
          />
        )}
        {!isBankCardsLoading && !isUpcomingInvoiceLoading && (
          <div className="space-y-2">
            {subscription.active &&
              drawValue(
                'organization.subscriptionDetail.billingSnapshotTitle',
                upcomingInvoice ? (
                  <Text>
                    <FormattedNumber
                      value={fromCents(upcomingInvoice.total || 0)}
                      style={PRICE_OPTIONS_2DP.style}
                      currency={upcomingInvoice.currency}
                    />
                  </Text>
                ) : null,
              )}
            {subscription.active &&
              drawValue(
                'organization.subscriptionDetail.billingPeriodTitle',
                upcomingInvoice ? (
                  <Text>
                    {moment.utc(upcomingInvoice.period_start).format(DATE_FORMAT)} -{' '}
                    {moment.utc(upcomingInvoice.period_end).format(DATE_FORMAT)}
                  </Text>
                ) : null,
              )}

            {upcomingInvoice.currency === EUR_CURRENCY && (
              <div>
                <Heading level={Heading.levels.h5}>
                  <FormattedMessage id="organization.creditCardSubscription.additionalInfo" />
                </Heading>
                <Text>
                  <FormattedMessage id="organization.creditCardSubscription.conversionRate" />
                </Text>
              </div>
            )}

            {drawValue(
              'organization.subscriptionDetail.paymentCardTitle',
              <div className="space-y-2">
                {defaultCard && (
                  <div
                    className="flex items-center justify-between gap-4"
                    data-testid="default-card"
                  >
                    {drawCard(defaultCard)}
                    {bankCards.length > 1 && (
                      <Tag
                        label={
                          <FormattedMessage id="organization.paymentMethods.bankCardDefaultText" />
                        }
                      />
                    )}
                  </div>
                )}
                {bankCards &&
                  bankCards
                    .filter(card => !card.default)
                    .map(card => (
                      <div
                        className="flex items-center justify-between gap-4"
                        key={card.id}
                      >
                        {drawCard(card, true)}
                        <div className="flex items-center justify-between gap-4">
                          <Button
                            kind={Button.kinds.TERTIARY}
                            size={Button.sizes.SMALL}
                            onClick={() => handleSetAsDefault(card)}
                          >
                            <FormattedMessage id="organization.paymentMethods.bankCardMakeDefaultText" />
                          </Button>
                          <Button
                            className="ml-1"
                            kind={Button.kinds.TERTIARY}
                            onClick={() => handleDelete(card)}
                            size={Button.sizes.SMALL}
                          >
                            <FormattedMessage id="common.delete" />
                          </Button>
                        </div>
                      </div>
                    ))}
              </div>,
              <div className="flex justify-between gap-4">
                <button
                  className="text-sm text-crate-blue outline-none"
                  onClick={() => setShowAddCardModal(true)}
                  type="button"
                >
                  <FormattedMessage id="organization.creditCardSubscription.addNewCard" />
                </button>
              </div>,
            )}
          </div>
        )}
      </SubscriptionCard>

      {/* add new card modal */}
      <Modal
        footer={null}
        onCancel={() => {
          setShowAddCardModal(false);
        }}
        open={showAddCardModal}
        width={600}
      >
        <Heading className="mb-8" level="h3">
          <FormattedMessage id="organization.creditCardSubscription.addNewCreditOrDebitCard" />
        </Heading>
        <PaymentEntryForm
          footer={
            <div>
              <SubmitButtonGroup
                confirmLabel={<FormattedMessage id="common.save" />}
                onCancel={() => setShowAddCardModal(false)}
              />
            </div>
          }
          onSubmitCallback={async success => {
            if (success) {
              await mutateBankCards();
            }
            setShowAddCardModal(false);
          }}
        />
      </Modal>
    </>
  );
}

CreditCardSubscriptionCard.propTypes = {
  subscription: PropTypes.object.isRequired,
};

export default CreditCardSubscriptionCard;
