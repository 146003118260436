import CardSelector from 'src/components/CardSelector';
import { IntegrationType } from 'src/types';
import { INTEGRATION_SOURCES } from './sources';

export type SourceStepProps = {
  sourceType: IntegrationType | null;
  onChangeSourceType: (type: IntegrationType) => void;
};

function SourceStep({ sourceType, onChangeSourceType }: SourceStepProps) {
  return (
    <div>
      {/* source type */}
      <CardSelector<IntegrationType>
        value={sourceType ? sourceType : undefined}
        onChange={onChangeSourceType}
        items={INTEGRATION_SOURCES}
      />
    </div>
  );
}

export default SourceStep;
