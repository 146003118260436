export default {
  // form labels
  'paymentEntryForm.creditCardLabel': 'Credit Card number',
  'paymentEntryForm.expiryLabel': 'Date of expiry',
  'paymentEntryForm.cvcLabel': 'CVC',
  'paymentEntryForm.cardNameLabel': 'Cardholder name',
  'paymentEntryForm.line1Label': 'Billing address line 1',
  'paymentEntryForm.line2Label': 'Billing address line 2',
  'paymentEntryForm.cityLabel': 'City',
  'paymentEntryForm.postal_codeLabel': 'Postal Code',
  'paymentEntryForm.countryLabel': 'Country',

  // validation errors
  'paymentEntryForm.incomplete_numberError': 'Your card number is incomplete',
  'paymentEntryForm.invalid_numberError': 'Please enter a valid card number',
  'paymentEntryForm.incomplete_expiryError': 'Please enter a valid expiry date',
  'paymentEntryForm.invalid_expiry_yearError': 'Please enter a valid expiry date',
  'paymentEntryForm.invalid_expiry_month_pastError':
    "Your card's expiration month is in the past",
  'paymentEntryForm.invalid_expiry_year_pastError':
    "Your card's expiration year is in the past",
  'paymentEntryForm.incomplete_cvcError': "Your card's security code is incomplete",
  'paymentEntryForm.cardNameError':
    'Please enter the name as it appears on your card',
  'paymentEntryForm.line1Error':
    'Please enter the first line of your billing address',
  'paymentEntryForm.cityError': 'Please enter your city',
  'paymentEntryForm.postal_codeError': 'Please enter the postal code',
  'paymentEntryForm.countryError': 'Please enter the country',
  'paymentEntryForm.uncheckedMandateOptInError':
    'Please accept the authorization mandate',
  'paymentEntryForm.uncheckedPaymentOptInError':
    'Please accept the verification payment',

  // terms
  'paymentEntryForm.paymentEntryMandateTermsText':
    'I authorize Crate.io AT GmbH to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of my agreement with you.',

  'paymentEntryForm.paymentEntryTestPaymentText':
    'I authorize Crate.io AT GmbH to take a $1.00 test payment from my card for verification purposes (the payment is refunded immediately).',
};
