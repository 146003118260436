type FileUploaderProps = {
  attachedFile: File;
  onUpdate: (percentComplete: number) => void;
  onComplete: () => void;
  request: XMLHttpRequest;
  url: string;
};

function fileUploader({
  attachedFile,
  onUpdate,
  onComplete,
  request,
  url,
}: FileUploaderProps) {
  return new Promise<number>((resolve, reject) => {
    const updateProgress = (event: ProgressEvent<XMLHttpRequestEventTarget>) => {
      onUpdate(event.loaded);
    };
    const completeUpload = () => {
      onComplete();
      resolve(100);
    };
    const failUpload = () => reject();
    const cancelUpload = () => {
      reject();
    };

    request.upload.addEventListener('progress', updateProgress);
    request.upload.addEventListener('load', completeUpload);
    request.upload.addEventListener('error', failUpload);
    request.upload.addEventListener('abort', cancelUpload);

    request.open('put', url);
    request.send(attachedFile);
  });
}

export default fileUploader;
