import { useState } from 'react';
import { SourceStep, SourceStepSummary } from '../common/SourceStep';
import StepLayout from 'src/components/StepLayout';
import { ImportJobS3, ImportJobType } from 'src/types';
import { useAnalytics } from 'src/hooks';
import useMessage from 'src/hooks/useMessage';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateImportJobS3Body, S3_SOURCE_FORM_DEFAULT_VALUES } from './steps/form';
import { useGetClustersIdImportJobs } from 'src/swrHooks';
import { USER_TRACKING_EVENTS } from 'src/constants/segment';
import { apiPost } from 'src/api';
import { clusterImport } from 'src/constants/paths';
import SourceDetailsStep from './steps/SourceDetailsStep';
import SinkDetailsStep from '../common/SinkDetailsStep';
import { SinkDetailsStepForm } from '../constants';
import ConnectionDetailsStep from './steps/ConnectionDetailsStep';
import SourceDetailsStepSummary from './steps/SourceDetailsStepSummary';
import ConnectionDetailsStepSummary from './steps/ConnectionDetailsStepSummary';
import TestSWRIsFetching from 'src/components/TestSWRIsFetching';

export type S3SourceProps = {
  job?: ImportJobS3;
  sourceType: ImportJobType;
  setSourceType: (type: ImportJobType | null) => void;
};

function S3Source({ job, sourceType, setSourceType }: S3SourceProps) {
  const { trackEvent } = useAnalytics();
  const { showSuccessMessage, showErrorMessage, showLoadingMessage } = useMessage();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { clusterId, organizationId, projectId } = useParams();

  const [stepIndex, setStepIndex] = useState(1);
  const [form, setForm] = useState<CreateImportJobS3Body>(
    job || S3_SOURCE_FORM_DEFAULT_VALUES,
  );
  const [isCreatingImportJob, setIsCreatingImportJob] = useState(false);

  // swr
  const {
    data: importJobs,
    mutate: mutateImportJobs,
    isLoading: isLoadingJobs,
  } = useGetClustersIdImportJobs(clusterId!);

  const onNextStep = () => {
    setStepIndex(stepIndex => stepIndex + 1);
  };

  const onFinish = async (form: CreateImportJobS3Body) => {
    setIsCreatingImportJob(true);
    showLoadingMessage(
      formatMessage({
        id: 'cluster.clusterImportFile.creatingImport',
      }),
    );

    trackEvent(USER_TRACKING_EVENTS.CLICKED_IMPORT_DATA);

    const { success, data } = await apiPost<ImportJobS3>(
      `/api/v2/clusters/${clusterId}/import-jobs/`,
      {
        compression: form.compression,
        destination: form.destination,
        format: form.format,
        s3: form.s3,
        type: form.type,
      },
    );

    setIsCreatingImportJob(false);
    if (success) {
      await mutateImportJobs([data!, ...(importJobs || [])]);

      showSuccessMessage(
        formatMessage({
          id: 'cluster.clusterImportFile.importCreated',
        }),
      );

      // go back to job list
      navigate(
        clusterImport.build({
          clusterId,
          organizationId,
          projectId,
        }),
      );
    } else {
      // show error message
      showErrorMessage(
        formatMessage({
          id: 'cluster.clusterImportFile.errorCreatingImport',
        }),
      );
    }
  };

  return (
    <>
      <StepLayout
        stepIndex={stepIndex}
        setStepIndex={index => {
          if (index === 0) {
            // if we are going back to source step
            // then reset the source type
            setSourceType(null);
            setStepIndex(1);
          } else {
            setStepIndex(index);
          }
        }}
        steps={[
          {
            title: (
              <FormattedMessage id="cluster.clusterImportFile.sourceStep.title" />
            ),
            renderContent: () => (
              <SourceStep
                sourceType={sourceType}
                onChangeSourceType={setSourceType}
              />
            ),
            summaryContent: () => <SourceStepSummary sourceType={sourceType} />,
          },
          {
            title: (
              <FormattedMessage id="cluster.clusterImportFile.connectionDetailsStep.title" />
            ),
            renderContent: () => (
              <ConnectionDetailsStep
                defaultValues={form}
                onNext={(result: CreateImportJobS3Body) => {
                  setForm({
                    ...form,
                    ...result,
                    s3: {
                      ...form.s3,
                      ...result.s3,
                    },
                  });
                  onNextStep();
                }}
              />
            ),
            summaryContent: () => (
              <ConnectionDetailsStepSummary
                values={form}
                organizationId={organizationId!}
              />
            ),
          },
          {
            title: (
              <FormattedMessage id="cluster.clusterImportFile.sourceDetailsStep.title" />
            ),
            renderContent: () => (
              <SourceDetailsStep
                defaultValues={form}
                onNext={(result: CreateImportJobS3Body) => {
                  setForm({
                    ...form,
                    ...result,
                    s3: {
                      ...form.s3,
                      ...result.s3,
                    },
                  });
                  onNextStep();
                }}
              />
            ),
            summaryContent: () => <SourceDetailsStepSummary values={form} />,
          },
          {
            title: (
              <FormattedMessage id="cluster.clusterImportFile.sinkDetailsStep.title" />
            ),
            renderContent: () => (
              <SinkDetailsStep
                isSubmitting={isCreatingImportJob}
                defaultValues={form}
                onConfirm={(result: SinkDetailsStepForm) => {
                  const finalForm = {
                    ...form,
                    ...result,
                  };
                  setForm(finalForm);
                  onFinish(finalForm);
                }}
              />
            ),
          },
        ]}
      />
      <TestSWRIsFetching fetchStatusList={[isLoadingJobs]} />
    </>
  );
}

export default S3Source;
