import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import colors from './constants';

function Tag({ label, color }) {
  return (
    <span
      className={cx(
        color,
        'font-bold',
        'px-3',
        'py-1',
        'rounded-full',
        'text-xs',
        'uppercase',
        'text-white',
        'tracking-wide',
        {
          'text-white': color === colors.PRIMARY,
          'text-[#222]': color === colors.SECONDARY,
        },
      )}
    >
      {label}
    </span>
  );
}

Tag.colors = colors;

Tag.propTypes = {
  color: PropTypes.oneOf(Object.values(colors)),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    .isRequired,
};

Tag.defaultProps = {
  color: colors.PRIMARY,
};

export default Tag;
