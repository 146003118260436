import React from 'react';
import { FormattedMessage } from 'react-intl';
import ConstrainWidth from '../../../components/ConstrainWidth';
import ViewContainer from '../../../components/ViewContainer';
import UserCredentials from './UserCredentials';
import OrganizationList from './OrganizationList';
import TopBanner from '../../UI/TopBanner';
import ApiKeyList from './ApiKeyList';
import DeleteAccount from './DeleteAccount';
import { useGetUsersMe } from '../../../swrHooks';
import BANNER_TYPES from '../../../components/NotificationBanner/constants';
import { EMAIL_CONFIRMATION_PENDING_COOKIE } from '../../../constants/defaults';
import { getCookie } from '../../../utils/auth';

function AccountSettings() {
  const { data: user } = useGetUsersMe();
  const emailConfirmationPendingCookie = getCookie(
    EMAIL_CONFIRMATION_PENDING_COOKIE,
  );

  const getNotification = () => {
    return emailConfirmationPendingCookie ? (
      <FormattedMessage id="account.notifications.confirmEmailAddressText" />
    ) : (
      <FormattedMessage id="account.notifications.setEmailAddressText" />
    );
  };

  return (
    <ConstrainWidth>
      <ViewContainer
        heading={<FormattedMessage id="account.accountSettings.accountTitle" />}
        render={() => (
          <>
            {user && (emailConfirmationPendingCookie || !user.email) && (
              <TopBanner type={BANNER_TYPES.WARN}>{getNotification()}</TopBanner>
            )}
            <UserCredentials />
            <OrganizationList />
            <ApiKeyList />
            <DeleteAccount />
          </>
        )}
      />
    </ConstrainWidth>
  );
}

export default AccountSettings;
