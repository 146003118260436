import React from 'react';
import PropTypes from 'prop-types';
import {
  ConnectionStatus,
  GCContextProvider,
  SchemaTreeContextProvider,
  Loader,
} from '@crate.io/crate-gc-admin';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import {
  useGCGetHealth,
  useGetClustersId,
  useGetMetaIpaddress,
} from '../../swrHooks';
import { getClusterGcUrl, isIpInClusterIpWhiteList } from '../../utils/data/cluster';
import { useEnsureGcLogin } from '../../hooks';
import { clusterManage } from '../../constants/paths';
import GRANDCENTRAL_HEALTH from '../../constants/grandCentral';
import { CRATEDB_SUPPORT } from '../../constants/links';

function GrandCentralWrapper({ clusterId, organizationId, children }) {
  const sessionTokenKey = `grand-central-token.${clusterId}`;
  const ensureGcLogin = useEnsureGcLogin();
  const { data: cluster, isLoading: clusterLoading } = useGetClustersId(clusterId);
  const {
    data: grandCentralHealth,
    isLoading: healthLoading,
    error: healthError,
  } = useGCGetHealth(cluster ? getClusterGcUrl(cluster) : null);
  const { data: userIPAddress, isLoading: ipLoading } = useGetMetaIpaddress();

  if (clusterLoading || ipLoading || (!grandCentralHealth && !healthError)) {
    return <Loader className="p-6" color={Loader.colors.PRIMARY} />;
  }

  const renderIpNotInAllowlistPage = () => {
    return (
      <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center gap-1 text-center">
        <div className="text-xl font-bold">
          <FormattedMessage id="grandCentralWrapper.ipNotInAllowlist" />
        </div>
        <div>
          <FormattedMessage id="grandCentralWrapper.yourIpAddress" /> (
          {userIPAddress.ip}){' '}
          <FormattedMessage id="grandCentralWrapper.notInClusterAllowList" />{' '}
          <div>
            <Link
              to={clusterManage.build({
                clusterId: cluster.id,
                projectId: cluster.project_id,
                organizationId,
              })}
            >
              <FormattedMessage id="grandCentralWrapper.manageAllowList" />
            </Link>{' '}
            <FormattedMessage id="grandCentralWrapper.or" />{' '}
            <a href={CRATEDB_SUPPORT}>
              <FormattedMessage id="grandCentralWrapper.contactOurSupportTeam" />
            </a>{' '}
            <FormattedMessage id="grandCentralWrapper.forAssistance" />
          </div>
        </div>
      </div>
    );
  };

  const renderUnhealthyPage = () => {
    return (
      <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center gap-1 text-center">
        <div className="text-xl font-bold">
          <FormattedMessage id="grandCentralWrapper.serviceCurrentlyUnavailable" />
        </div>
        <div>
          <FormattedMessage id="grandCentralWrapper.ifIssuePersists" />
          <a href={CRATEDB_SUPPORT}>
            <FormattedMessage id="grandCentralWrapper.contactOurSupportTeam" />
          </a>{' '}
          <FormattedMessage id="grandCentralWrapper.forAssistance" />
        </div>
        <div
          className={cx({
            invisible: !healthLoading,
          })}
        >
          <Loader />
        </div>
      </div>
    );
  };

  // only display the "add user's ip" button if the user's address does not already exist in the list
  const ipNotInWhitelist = !isIpInClusterIpWhiteList(cluster, userIPAddress);

  const renderPage = () => {
    // gc unhealthy
    if (
      !grandCentralHealth ||
      grandCentralHealth.health !== GRANDCENTRAL_HEALTH.OK ||
      healthError
    ) {
      // ip not in whitelist
      if (ipNotInWhitelist) {
        return renderIpNotInAllowlistPage();
      }

      return renderUnhealthyPage();
    }

    // everything ok
    return children;
  };

  return (
    <GCContextProvider
      gcStatus={ConnectionStatus.CONNECTED}
      gcUrl={getClusterGcUrl(cluster)}
      crateUrl={`https://${cluster.fqdn.replace(/\.$/, ':4200')}`}
      crateVersion={cluster?.crate_version}
      headings={false}
      sessionTokenKey={sessionTokenKey}
      onGcApiJwtExpire={async () => {
        await ensureGcLogin(cluster, sessionTokenKey);
      }}
      clusterId={clusterId}
    >
      <SchemaTreeContextProvider>
        <div className="h-full w-full" data-testid="grand-central-wrapper">
          {renderPage()}
        </div>
      </SchemaTreeContextProvider>
    </GCContextProvider>
  );
}

GrandCentralWrapper.propTypes = {
  clusterId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default GrandCentralWrapper;
