import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input, message, Switch } from 'antd';
import {
  AVAILABLE_LIGHT_COLORS,
  Button,
  COLOR_STYLES_MAP,
  SolidLight,
  SubmitButtonGroup,
} from '@crate.io/crate-gc-admin';
import SectionContainer from '../../../components/SectionContainer';
import useMessage from '../../../hooks/useMessage';
import {
  useGetOrganizations,
  useGetOrganizationsId,
  useGetUsersMe,
} from '../../../swrHooks';
import { apiPut } from '../../../api';
import { getUserIsOrganizationAdmin } from '../../../utils/data/user';
import INPUT_SANITIZATION from '../../../constants/inputSanitization';

function GeneralSettings({ organizationId }) {
  const { formatMessage } = useIntl();
  const { showLoadingMessage, showSuccessMessage } = useMessage();
  const { data: loggedInUser } = useGetUsersMe();
  const { data: organization, mutate: mutateOrganization } =
    useGetOrganizationsId(organizationId);
  const { mutate: mutateAllOrganizations } = useGetOrganizations();
  const [isEditingSettings, setIsEditingSettings] = useState(false);

  const handleFormFinish = async form => {
    showLoadingMessage(
      formatMessage({
        id: 'organization.organizationSettings.organizationUpdatingText',
      }),
    );

    const { success, data: updatedOrganization } = await apiPut(
      `/api/v2/organizations/${organizationId}/`,
      form,
    );

    if (success) {
      message.destroy();

      showSuccessMessage(
        formatMessage({
          id: 'organization.organizationSettings.organizationUpdatedText',
        }),
      );
      mutateOrganization(updatedOrganization);
      mutateAllOrganizations();
      setIsEditingSettings(false);
    }
  };

  const renderOrganizationName = () => {
    if (isEditingSettings) {
      return (
        <Form.Item
          hasFeedback
          initialValue={organization?.name}
          name="name"
          rules={[INPUT_SANITIZATION.ORGANIZATION_NAME]}
        >
          <Input data-testid="organization-name-input" />
        </Form.Item>
      );
    }

    return <div>{organization?.name}</div>;
  };

  const renderNotificationEmail = () => {
    if (isEditingSettings) {
      return (
        <Form.Item
          hasFeedback
          initialValue={organization?.email}
          name="email"
          rules={[INPUT_SANITIZATION.GENERIC_EMAIL]}
        >
          <Input data-testid="notification-email-input" />
        </Form.Item>
      );
    }

    return <div>{organization?.email}</div>;
  };

  const renderNotificationStatus = () => {
    if (isEditingSettings) {
      return (
        <div className="pt-1">
          <Form.Item name="notifications_enabled" noStyle valuePropName="checked">
            <Switch
              defaultChecked={organization?.notifications_enabled}
              data-testid="notification-status-switch"
            />
          </Form.Item>
        </div>
      );
    }

    return (
      <div className="flex items-center">
        <SolidLight
          className={
            COLOR_STYLES_MAP[
              AVAILABLE_LIGHT_COLORS[
                organization?.notifications_enabled ? 'GREEN' : 'RED'
              ]
            ]
          }
        />
        <div className="pl-1">
          <FormattedMessage
            id={
              organization?.notifications_enabled
                ? 'common.enabled'
                : 'common.disabled'
            }
          />
        </div>
      </div>
    );
  };

  const renderSettingsActions = () => {
    if (!getUserIsOrganizationAdmin(loggedInUser, organizationId)) {
      return null;
    }

    return isEditingSettings ? (
      <SubmitButtonGroup
        confirmLabel={formatMessage({ id: 'common.save' })}
        form="update-organization-form"
        onCancel={() => setIsEditingSettings(false)}
      />
    ) : (
      <Button
        kind={Button.kinds.TERTIARY}
        onClick={() => setIsEditingSettings(true)}
      >
        <FormattedMessage id="common.edit" />
      </Button>
    );
  };

  return (
    <SectionContainer
      actions={renderSettingsActions()}
      loading={!organization || !loggedInUser}
      title={
        <FormattedMessage id="organization.organizationSettings.settingsSectionTitle" />
      }
      testId="general-settings"
    >
      <Form id="update-organization-form" onFinish={handleFormFinish}>
        <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
          <div className="col-span-2">
            <div className="pb-1 text-[10px] font-bold uppercase text-crate-border-mid">
              <FormattedMessage id="organization.organizationSettings.organizationLabel" />
            </div>

            {renderOrganizationName()}
            <div className="pt-1 text-xs text-crate-border-mid">
              <FormattedMessage id="organization.organizationSettings.organizationIDLabel" />
              <span>{organization?.id}</span>
            </div>
          </div>
          <div>
            <div className="pb-1 text-[10px] font-bold uppercase text-crate-border-mid">
              <FormattedMessage id="organization.organizationSettings.notificationEmailLabel" />
            </div>
            {renderNotificationEmail()}
          </div>
          <div>
            <div className="pb-1 text-[10px] font-bold uppercase text-crate-border-mid">
              <FormattedMessage id="organization.organizationSettings.notificationStatusLabel" />
            </div>
            {renderNotificationStatus()}
          </div>
        </div>
      </Form>
    </SectionContainer>
  );
}

GeneralSettings.propTypes = {
  organizationId: PropTypes.string.isRequired,
};

export default GeneralSettings;
