import PropTypes from 'prop-types';
import { CREDITS_STATES } from '../constants/defaults';

export default PropTypes.shape({
  remaining_amount: PropTypes.number.isRequired,
  expiration_date: PropTypes.string.isRequired,
  status: PropTypes.oneOf([CREDITS_STATES.ACTIVE, CREDITS_STATES.EXPIRED]),
  original_amount: PropTypes.number.isRequired,
  start_date: PropTypes.string.isRequired,
});
