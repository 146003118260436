const messages = {
  'account.accountSettings.accountTitle': 'Account',
  'account.accountSettings.credentialsTitle': 'Credentials',
  'account.accountSettings.organizationsTitle': 'Organizations',
  'account.accountSettings.createOrganizationText': 'Create new organization',
  'account.accountSettings.enterValidEmailError':
    'Please enter a valid email address.',
  'account.accountSettings.enterNewEmailError':
    'Please enter a different email address to the one you have set currently.',
  'account.accountSettings.authMethod': 'Authentication',
  'account.accountSettings.authMethod.azuread': 'Microsoft',
  'account.accountSettings.authMethod.cognito': 'Password',
  'account.accountSettings.authMethod.github': 'GitHub',
  'account.accountSettings.authMethod.google': 'Google',

  'account.createOrganization.noOrganizationYetText':
    'Create your first organization. Enter your company or organization name in the box below.',
  'account.createOrganization.addNewOrganization': 'Add a new organization',
  'account.createOrganization.formInputPlaceholder': 'Organization or Company name',
  'account.createOrganization.formInputHelp': 'Please enter an organization name.',
  'account.createOrganization.formSubmitButton': 'Create organization',
  'account.createOrganization.formNonAdminOrText': 'or',
  'account.createOrganization.formNonAdminHelp':
    'request access from one of your organization administrators.',
  'account.createOrganization.creationInProgress': 'Creating organization "{name}"',
  'account.createOrganization.createdSuccessfully':
    'Organization "{name}" was created successfully.',

  'account.deleteOrganization.deletionInProgress': 'Deleting organization "{name}"',
  'account.deleteOrganization.deletedSuccessfully':
    'Organization "{name}" was deleted successfully',

  'account.login.signInTitle': 'CrateDB Cloud Sign in',
  'account.login.signInMultiOptionText':
    'Use one of the services below to sign in to or register with CrateDB Cloud:',
  'account.login.signInSingleOptionText': 'Sign in or register with CrateDB Cloud:',
  'account.login.azureSignInButton': 'Sign in with Microsoft',
  'account.login.cognitoSignInButton': 'Username & Password',
  'account.login.githubSignInButton': 'Sign in with GitHub',
  'account.login.googleSignInButton': 'Sign in with Google',
  'account.login.disclaimerText': 'By using CrateDB Cloud you agree to our',
  'account.login.disclaimerTermsOfServicePartial':
    ' Master Hosted Service Agreement ',
  'account.login.disclaimerPartial': 'and our',
  'account.login.disclaimerPrivacyPolicyPartial': ' Privacy Policy',

  'account.login.strapline': 'The power of CrateDB',
  'account.login.strapSubline': 'Fully-managed in the Cloud',
  'account.login.bulletPoint1Text':
    'Multiple compute plans available for projects of all sizes',
  'account.login.bulletPoint1Subpoint1Text': 'Free plan - no credit card required',
  'account.login.bulletPoint1Subpoint2Text':
    'Shared plan for non-critical workloads',
  'account.login.bulletPoint1Subpoint3Text':
    'Dedicated plan for production workloads',
  'account.login.bulletPoint1Subpoint4Text':
    'Custom plan for large production workloads',
  'account.login.bulletPoint2Text':
    'Deploy on your preferred cloud provider: AWS, Azure, or GCP',
  'account.login.bulletPoint3Text': 'ISO/IEC 27001 certified and GDPR compliant',

  'account.notifications.confirmEmailAddressText':
    'Please confirm your email address.',
  'account.notifications.setEmailAddressText': 'Please set your email address.',
  'account.notifications.setEmailAddressSettingsText':
    'Please set your email address in your ',
  'account.notifications.setEmailAddressSettingsPartial': 'account settings',

  'account.selectOrganization.formText':
    'Please select which organization you would like to link with your {provider} account:',
  'account.selectOrganization.formSubmitButton': 'Select',

  'account.apiKeys.noApiKeysYetText': 'You do not have any API keys',
  'account.apiKeys.apiKeysTitle': 'API keys',
  'account.apiKeys.generateNewApiKeyText': 'Generate new key',
  'account.apiKeys.apiKeysSectionDescriptionText':
    'Use API keys for programmatic access to the CrateDB Cloud API.  The API key and secret act as HTTP Basic Auth credentials. ' +
    'Please note that keys automatically deactivate after 90 days of inactivity. ' +
    'Please refer to the {docslink} for details. Please note that activating or de-activating a key can take up to 15 minutes to take effect.',
  'account.apiKeys.apiKeysSectionDocsLinkText': 'CrateDB Cloud API documentation',
  'account.apiKeys.creationInProgressTitle': 'Your API key was created',
  'account.apiKeys.credentialsHelp':
    'You can view and download your API key and secret below. This is the only time the secret will be available to view.',
  'account.apiKeys.username': 'Key',
  'account.apiKeys.password': 'Secret',
  'account.apiKeys.deleteApiKeyTitle': 'Deleting an API key',
  'account.apiKeys.deleteApiKeyConfirmationText':
    'Please confirm deletion of this key by entering it below.',
  'account.apiKeys.activateLinkText': 'activate',
  'account.apiKeys.deactivateLinkText': 'deactivate',
  'account.apiKeys.keyNeverUsedText': 'Never',

  'account.deleteAccount.deleteAccountTitle': 'Delete account',
  'account.deleteAccount.deleteAccountText':
    'In order to delete your account we kindly ask you to confirm the action by sumitting a request. Our customer service team will notify you on completion. Please ensure your contact email is up-to-date.',
  'account.deleteAccount.submitRequestButton': 'Submit request',
  'account.deleteAccount.deleteFormText':
    'If you would like to close your account, please contact our support team using the form below.',

  'account.subscription.marketplaceSubscriptionConnected':
    'Marketplace subscription connected',

  'account.user.verifyEmailAddress': 'Verifying email address',
  'account.user.emailAddressConfirmed': 'Email address confirmed',
  'account.user.updatingEmailAddress': 'Updating email address',
  'account.user.confirmEmailAddress': 'Confirm email address',
};

export default messages;
