import React from 'react';
import { FormattedMessage } from 'react-intl';
import edgeLogo from '../../assets/logo_edge.svg';
import gcpLogo from '../../assets/logo_gcp.svg';
import awsLogo from '../../assets/logo_aws.svg';
import azureLogo from '../../assets/logo_azure_2021.svg';
import { regionPropType } from '../../models';

function RegionDescription({ region }) {
  const regionIcon = {
    alt: 'Edge region logo',
    css: 'h-12 mr-0.5 w-12',
    logo: edgeLogo,
  };
  if (region?.name.endsWith('aws')) {
    regionIcon.alt = 'Amazon Web Services logo';
    regionIcon.logo = awsLogo;
  } else if (region?.name.endsWith('azure')) {
    regionIcon.alt = 'Azure logo';
    regionIcon.css = 'h-12 mr-0.5 w-10';
    regionIcon.logo = azureLogo;
  } else if (region?.name.endsWith('gcp')) {
    regionIcon.alt = 'Google Cloud Platform logo';
    regionIcon.css = 'h-8 mx-0.5 w-12';
    regionIcon.logo = gcpLogo;
  }

  return (
    <div className="flex flex-row items-center">
      <img src={regionIcon.logo} className={regionIcon.css} alt={regionIcon.alt} />
      <div className="ml-2 flex flex-col">
        <div className="whitespace-nowrap font-bold">{region?.description}</div>
        {region?.is_edge_region ? (
          <FormattedMessage id="organization.regions.edgeRegionText" />
        ) : (
          <div>{region?.name}</div>
        )}
      </div>
    </div>
  );
}

RegionDescription.propTypes = {
  region: regionPropType.isRequired,
};

export default RegionDescription;
