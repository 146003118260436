import React from 'react';
import PropTypes from 'prop-types';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import cx from 'classnames';
import BANNER_TYPES from './constants';

function NotificationBanner({ type, children }) {
  const supportedStatesIconMap = {
    [BANNER_TYPES.INFO]: (
      <InfoCircleOutlined className="absolute left-0 mr-2 text-base text-neutral-100" />
    ),
    [BANNER_TYPES.WARN]: (
      <ExclamationCircleOutlined className="absolute left-0 mr-2 text-base text-neutral-100" />
    ),
  };

  // height is hardcoded here to match the exact height of the Crate logo
  // when the sidemenu is expanded
  const styles = cx('h-[51px]', 'text-neutral-100', {
    'bg-orange-600': type === BANNER_TYPES.WARN,
    'bg-crate-blue': type === BANNER_TYPES.INFO,
  });

  return (
    <div role="status" className={styles}>
      <div className="h-full max-w-[1200px] px-4 md:px-10">
        <div className="relative flex h-full items-center pl-6">
          {supportedStatesIconMap[type]}
          <div className="text-sm leading-4">{children}</div>
        </div>
      </div>
    </div>
  );
}

NotificationBanner.types = BANNER_TYPES;

NotificationBanner.propTypes = {
  type: PropTypes.oneOf([BANNER_TYPES.INFO, BANNER_TYPES.WARN]).isRequired,
  children: PropTypes.node.isRequired,
};

export default NotificationBanner;
