import { DeleteOutlined } from '@ant-design/icons';
import { Button } from '@crate.io/crate-gc-admin';
import { Popconfirm } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { apiDelete } from 'src/api';
import CloudUITable from 'src/components/CloudUITable';
import SectionContainer from 'src/components/SectionContainer';
import { AUTH_TYPES } from 'src/features/clusters/ClusterImportIntegration/constants';
import useMessage from 'src/hooks/useMessage';
import {
  useGetClustersIdConnections,
  useGetOrganizationsIdSecrets,
  useGetUsersMe,
} from 'src/swrHooks';
import { getUserIsOrganizationAdmin } from 'src/utils/data/user';
import { Connection, OrganizationId } from 'src/types';

type ConnectionListProps = {
  organizationId: OrganizationId;
};

const ConnectionList = ({ organizationId }: ConnectionListProps) => {
  const { formatMessage } = useIntl();
  const { showSuccessMessage } = useMessage();
  const { data: loggedInUser } = useGetUsersMe();

  // swr
  const {
    data: connections,
    isLoading: isLoadingConnections,
    mutate: mutateConnections,
  } = useGetClustersIdConnections(organizationId);
  const { mutate: mutateSecrets } = useGetOrganizationsIdSecrets(organizationId);

  const userIsOrgAdmin = useMemo(
    () => getUserIsOrganizationAdmin(loggedInUser, organizationId),
    [loggedInUser, organizationId],
  );

  const removeConnection = async (connection: Connection) => {
    const { success } = await apiDelete(
      `/api/v2/organizations/${organizationId}/connections/${connection.id}/`,
    );

    if (success) {
      showSuccessMessage(
        formatMessage(
          { id: 'organization.connections.connectionRemovedText' },
          { name: connection.name },
        ),
      );
      mutateConnections((connections || []).filter(c => c.id !== connection.id));
      mutateSecrets();
    }
  };

  const getColumns = () => {
    const columns: ColumnType<Connection>[] = [
      {
        title: (
          <FormattedMessage id="organization.connections.connectionNameLabel" />
        ),
        key: 'name',
        dataIndex: 'name',
        width: '30%',
      },
      {
        title: <FormattedMessage id="organization.connections.typeLabel" />,
        key: 'type',
        dataIndex: 'type',
        width: '20%',
      },
      {
        title: (
          <FormattedMessage id="organization.connections.authenticationMethodLabel" />
        ),
        key: 'auth',
        render: (connection: Connection) => {
          return connection.auth === AUTH_TYPES.SCRAM ? (
            <FormattedMessage id="organization.connections.scramLabel" />
          ) : (
            <FormattedMessage id="organization.connections.x509Label" />
          );
        },
        width: '20%',
      },
      {
        title: <FormattedMessage id="organization.connections.defaultDbNameLabel" />,
        key: 'default_db_name',
        dataIndex: 'default_db_name',
        width: '30%',
      },
    ];

    if (userIsOrgAdmin) {
      columns.push({
        key: 'actions',
        render: (connection: Connection) => (
          <div className="inline-block">
            <Popconfirm
              cancelText={<FormattedMessage id="common.no" />}
              okText={<FormattedMessage id="common.yes" />}
              onConfirm={() => removeConnection(connection)}
              placement="topLeft"
              title={
                <FormattedMessage
                  id="organization.connections.removeConnectionText"
                  values={{ name: connection.name }}
                />
              }
            >
              <Button
                kind={Button.kinds.TERTIARY}
                id={`delete-connection-button-${connection.id}`}
                disabled={connection.running_integrations.length > 0}
                className="!leading-3"
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        ),
      });
    }

    return columns;
  };

  return (
    <SectionContainer
      testId="connection-list"
      loading={isLoadingConnections}
      title="Connections"
    >
      <CloudUITable
        columns={getColumns()}
        dataSource={connections}
        emptyText={formatMessage({
          id: 'organization.connections.noConnectionsDefinedText',
        })}
        rowKey="id"
        showHeader
        testId="connections-table"
      />
    </SectionContainer>
  );
};

export default ConnectionList;
