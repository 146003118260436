export const getClusterProducts = products => {
  if (products) {
    return products.filter(product => product.kind === 'cluster');
  }

  return null;
};

export const getClusterStripeProducts = products => {
  if (products) {
    return getClusterProducts(products).filter(
      product => product.offer === 'stripe' && product.kind === 'cluster',
    );
  }

  return null;
};
