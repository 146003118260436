import { AutoComplete, Form, Input } from 'antd';
import { Button, Text } from '@crate.io/crate-gc-admin';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  useGetIntegrationsAzureBlobStorageContainers,
  useGetIntegrationsAzureBlobs,
} from 'src/swrHooks';
import {
  DEFAULT_FIELD_VALUES,
  FIELD_NAMES,
  FILE_COMPRESSION_OPTIONS,
  SupportedFileFormat,
} from '../../constants';
import INPUT_SANITIZATION from '../../../../../constants/inputSanitization';
import { useMemo, useState } from 'react';
import { FILE_NAME_REGEXP } from 'src/constants/regex';
import useSupportedFileTypes from '../../common/hooks/useSupportedFileTypes';
import FileDetailsFields from '../../common/FileDetailsForm';
import { CreateImportJobAzureBody } from './form';
import { CRATEDB_CLOUD_IMPORT_AZURE_DOCS } from 'src/constants/links';
import ConstrainStepWidth from '../../../../../components/StepLayout/ConstrainStepWidth';

export type SourceDetailsStepProps = {
  defaultValues: CreateImportJobAzureBody;
  onNext: (result: CreateImportJobAzureBody) => void;
};

function SourceDetailsStep({ defaultValues, onNext }: SourceDetailsStepProps) {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<CreateImportJobAzureBody>();
  const { supportedFileFormats, fileCompressionFormats } = useSupportedFileTypes();

  // form data
  const secretId = Form.useWatch(
    [FIELD_NAMES.AZURE_NAMESPACE, FIELD_NAMES.AZURE_SECRET_ID],
    form,
  );
  const containerName = Form.useWatch(
    [FIELD_NAMES.AZURE_NAMESPACE, FIELD_NAMES.AZURE_CONTAINER_NAME],
    form,
  );

  // state
  const [searchPathText, setSearchPathText] = useState<string>('');
  const [pathPrefix, setPathPrefix] = useState<string>('');

  // swr
  const { data: containerNames, isValidating: loadingContainerNames } =
    useGetIntegrationsAzureBlobStorageContainers(secretId);
  const { data: paths, isValidating: loadingPaths } = useGetIntegrationsAzureBlobs(
    secretId,
    containerName,
    pathPrefix,
  );
  const onFormFinish = (values: CreateImportJobAzureBody) => {
    onNext(values);
  };

  const getContainerNames = () => {
    if (!containerNames) {
      return [];
    }

    return (containerNames.containers || []).map(container => ({
      label: container.name,
      value: container.name,
    }));
  };

  const pathOptions = useMemo(() => {
    if (!paths) {
      return [];
    }

    let filteredPaths = 'blobs' in paths ? paths.blobs : [];
    if (searchPathText) {
      filteredPaths = filteredPaths.filter(path => path.startsWith(searchPathText));
    }

    // Retrieve again the list of objects names when:
    // - searchText is not the last used prefix which means that we don't have a relevant list of files
    // - the list of objects might not contain all possible options (because of the 1k limit)
    if (
      !searchPathText.startsWith(pathPrefix ?? '') ||
      (paths.blobs.length >= 1000 && filteredPaths.length <= 8)
    ) {
      setPathPrefix(searchPathText);
      filteredPaths = 'blobs' in paths ? paths.blobs : [];
    }

    return filteredPaths.slice(0, 8).map(object => ({
      label: object,
      value: object,
    }));
  }, [paths, pathPrefix]);

  const prefillFileSpecs = (value: string) => {
    const enteredPath = value.toLowerCase();
    const fileMatch = enteredPath.match(FILE_NAME_REGEXP);

    const fileFormat = fileMatch?.groups?.format || '';
    const fileCompression = fileMatch?.groups?.compression || '';

    if (
      (supportedFileFormats as string[]).includes(fileFormat) &&
      !(fileCompressionFormats as string[]).includes(fileFormat)
    ) {
      form.setFieldsValue({
        [FIELD_NAMES.FORMAT_OPTIONS]: fileFormat as SupportedFileFormat,
        [FIELD_NAMES.COMPRESSION_OPTIONS]: fileCompression
          ? FILE_COMPRESSION_OPTIONS.GZIP
          : DEFAULT_FIELD_VALUES[FIELD_NAMES.COMPRESSION_OPTIONS],
      });
    }
  };

  return (
    <ConstrainStepWidth>
      <Form<CreateImportJobAzureBody>
        autoComplete="off"
        form={form}
        initialValues={{
          ...defaultValues,
        }}
        layout="vertical"
        name="import-data-from-azureblob-form"
        id="import-data-from-azureblob-form"
        aria-label="import data from azureblob form"
        onFinish={onFormFinish}
        requiredMark="optional"
      >
        {/* hidden secret id */}
        <Form.Item
          name={[FIELD_NAMES.AZURE_NAMESPACE, FIELD_NAMES.AZURE_SECRET_ID]}
          hidden
        >
          <Input />
        </Form.Item>

        <Text pale className="mb-2">
          <FormattedMessage
            id="cluster.clusterImportFile.formattingRulesDocsText"
            values={{
              link: (
                <a
                  href={CRATEDB_CLOUD_IMPORT_AZURE_DOCS}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="cluster.clusterImportFile.docsLinkPartial" />
                </a>
              ),
            }}
          />
        </Text>

        <div>
          {/* container name */}
          <Form.Item
            className="col-span-2"
            colon={false}
            label={
              <FormattedMessage id="cluster.clusterImportFile.sourceDetailsStep.containerNameField" />
            }
            validateStatus={loadingContainerNames ? 'validating' : 'success'}
            hasFeedback={loadingContainerNames}
            name={[FIELD_NAMES.AZURE_NAMESPACE, FIELD_NAMES.AZURE_CONTAINER_NAME]}
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: 'cluster.clusterImportFile.sourceDetailsStep.containerNameRequiredText',
                }),
              },
            ]}
          >
            <AutoComplete
              options={getContainerNames()}
              filterOption={(inputValue, option) =>
                option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              notFoundContent={
                containerNames?.error && (
                  <FormattedMessage
                    id="cluster.clusterImportFile.sourceDetailsStep.cannotListContainersError"
                    values={{ error: containerNames.error }}
                  />
                )
              }
              placeholder={formatMessage({
                id: 'cluster.clusterImportFile.sourceDetailsStep.containerNameField',
              })}
            />
          </Form.Item>

          {/* file path */}
          <Form.Item
            className="col-span-2"
            colon={false}
            label={
              <FormattedMessage id="cluster.clusterImportFile.sourceDetailsStep.pathToFileField" />
            }
            extra={
              <FormattedMessage id="cluster.clusterImportFile.sourceDetailsStep.pathToFileHelp" />
            }
            validateStatus={loadingPaths ? 'validating' : 'success'}
            hasFeedback={loadingPaths}
            name={[FIELD_NAMES.AZURE_NAMESPACE, FIELD_NAMES.AZURE_BLOB_NAME]}
            rules={[INPUT_SANITIZATION.GENERIC_FILE_PATH]}
          >
            <AutoComplete
              options={pathOptions}
              onSearch={setSearchPathText}
              onChange={(value: string) => {
                setSearchPathText(value);
                prefillFileSpecs(value);
              }}
              placeholder={formatMessage({
                id: 'cluster.clusterImportFile.sourceDetailsStep.pathToFilePlaceholder',
              })}
              filterOption={(inputValue, option) =>
                option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              notFoundContent={
                paths?.error && (
                  <FormattedMessage
                    id="cluster.clusterImportFile.sourceDetailsStep.cannotListBlobsError"
                    values={{ error: paths.error }}
                  />
                )
              }
            />
          </Form.Item>

          <FileDetailsFields />
        </div>

        <div className="mt-4 flex">
          <Button type={Button.types.SUBMIT}>
            <FormattedMessage id="common.next" />
          </Button>
        </div>
      </Form>
    </ConstrainStepWidth>
  );
}

export default SourceDetailsStep;
