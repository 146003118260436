import { Cluster } from 'src/types';

export const edgeK8sCodeSnippet = (cluster: Cluster) =>
  `https://crate-${cluster.id}.${cluster.project_id}.svc.cluster.local:4200`;

export const edgeK8sOutsideClusterCodeSnippet = (cluster: Cluster) =>
  `kubectl -n ${cluster.project_id} port-forward crate-data-hot-${cluster.id}-0 4200`;

export const edgeK8sExposedNodeCodeSnippet = (cluster: Cluster) =>
  `kubectl -n ${cluster.project_id} get svc crate-${cluster.id} -o json | jq '.spec.ports[] | select(.name == "http") | .nodePort'`;
