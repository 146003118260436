import React from 'react';
import { useParams } from 'react-router-dom';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import { SQLConsole } from '@crate.io/crate-gc-admin';
import { useAnalytics } from '../../../hooks';
import SectionContainer from '../../../components/SectionContainer';
import GrandCentralWrapper from '../../../components/GrandCentralWrapper';
import { USER_TRACKING_EVENTS } from '../../../constants/segment';

function ClusterSQL() {
  const { clusterId, organizationId } = useParams();
  const { trackEvent } = useAnalytics();

  const onQuery = () => {
    trackEvent(USER_TRACKING_EVENTS.CLICKED_SQL_CONSOLE_QUERY);
  };

  const onViewHistory = () => {
    trackEvent(USER_TRACKING_EVENTS.CLICKED_SQL_CONSOLE_HISTORY);
  };

  return (
    <GrandCentralWrapper clusterId={clusterId} organizationId={organizationId}>
      <SectionContainer className="absolute h-full w-full rounded-none !p-0">
        <SQLConsole onQuery={onQuery} onViewHistory={onViewHistory} />
      </SectionContainer>
    </GrandCentralWrapper>
  );
}

export default ClusterSQL;
