import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import countries from 'i18n-iso-countries';
import { Form, Input, Select } from 'antd';
import { Button, Heading, Loader } from '@crate.io/crate-gc-admin';
import { FormattedMessage, useIntl } from 'react-intl';
import INPUT_SANITIZATION from '../../constants/inputSanitization';
import { useGetOrganizationsIdCustomer } from '../../swrHooks';
import isEu from '../../utils/isEu';
import { apiPut } from '../../api';

function CustomerForm({ onCancel, onSuccess }) {
  const [form] = Form.useForm();
  const selectedCountry = Form.useWatch('country', form);
  const { formatMessage } = useIntl();
  const { organizationId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const mappedCountries = useMemo(
    () =>
      Object.entries(countries.getNames('en', { select: 'official' })).flatMap(
        ([value, label]) => ({ value, label }),
      ),
    [],
  );

  const {
    data: customer,
    mutate: mutateCustomer,
    isLoading: loadingCustomer,
  } = useGetOrganizationsIdCustomer(organizationId);

  const handleFormFinish = async values => {
    setIsSubmitting(true);
    const { country } = values;

    const { success } = await apiPut(
      `/api/v2/organizations/${organizationId}/customer/`,
      {
        ...values,
        tax_id_type: isEu(country) ? 'eu_vat' : null,
      },
    );

    if (success) {
      mutateCustomer();
      if (onSuccess) {
        setIsSubmitting(false);
        onSuccess();
      }
    } else {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (!isEu(selectedCountry)) {
      form.setFieldValue('tax_id', '');
    }
  }, [form, selectedCountry]);

  if (loadingCustomer) {
    return <Loader align={Loader.alignment.CENTER} color={Loader.colors.PRIMARY} />;
  }

  return (
    <Form
      disabled={isSubmitting}
      form={form}
      name="billing"
      layout="vertical"
      role="form"
      onFinish={handleFormFinish}
      initialValues={{
        city: customer?.address?.city,
        country: customer?.address?.country,
        email: customer?.email,
        line1: customer?.address?.line1,
        line2: customer?.address?.line2,
        name: customer?.name,
        phone: customer?.phone,
        postal_code: customer?.address?.postal_code,
        tax_id: customer?.tax?.tax_id,
        tax_id_type: customer?.tax?.tax_id_type,
      }}
    >
      <Heading className="mb-4" level="h3">
        <FormattedMessage id="customerForm.enterBillingInformation" />
      </Heading>
      <Form.Item
        name="name"
        label={formatMessage({
          id: 'customerForm.companyName',
        })}
        colon={false}
        rules={[
          {
            message: formatMessage({
              id: 'customerForm.companyName.enterContactName',
            }),
            required: true,
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="line1"
        label={formatMessage({
          id: 'customerForm.addressLine1',
        })}
        colon={false}
        rules={[
          {
            max: 150,
            message: formatMessage({
              id: 'paymentEntryForm.line1Error',
            }),
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="line2"
        label={formatMessage({
          id: 'customerForm.addressLine2',
        })}
        colon={false}
        rules={[
          {
            max: 150,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <div className="grid grid-cols-2 gap-4">
        <Form.Item
          name="city"
          label={formatMessage({
            id: 'paymentEntryForm.cityLabel',
          })}
          colon={false}
          rules={[
            {
              max: 50,
              message: formatMessage({
                id: 'paymentEntryForm.cityError',
              }),
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="postal_code"
          label={formatMessage({
            id: 'paymentEntryForm.postal_codeLabel',
          })}
          colon={false}
          rules={[
            {
              max: 16,
              message: formatMessage({
                id: 'paymentEntryForm.postal_codeError',
              }),
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </div>
      <Form.Item
        name="country"
        label={formatMessage({
          id: 'paymentEntryForm.countryLabel',
        })}
        colon={false}
        rules={[
          {
            required: true,
            message: formatMessage({
              id: 'paymentEntryForm.countryError',
            }),
          },
        ]}
      >
        <Select
          showSearch
          filterOption={(input, option) =>
            (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())
          }
          autoComplete="none"
          virtual={false}
          options={mappedCountries.map(country => {
            return { label: country.label, value: country.value };
          })}
        />
      </Form.Item>
      <div className="grid grid-cols-2 gap-4">
        <Form.Item
          name="email"
          label={<FormattedMessage id="common.email" />}
          colon={false}
          rules={[INPUT_SANITIZATION.GENERIC_EMAIL]}
          type="email"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label={<FormattedMessage id="hubspot.common.phone" />}
          colon={false}
          rules={[INPUT_SANITIZATION.PHONE_REQUIRED]}
        >
          <Input
            placeholder={formatMessage({
              id: 'customerForm.includingCountryCode',
            })}
          />
        </Form.Item>
      </div>
      {isEu(selectedCountry) && (
        <Form.Item
          name="tax_id"
          label={formatMessage({
            id: 'customerForm.vatId',
          })}
          colon={false}
        >
          <Input />
        </Form.Item>
      )}

      <div className="flex justify-between">
        <Button
          kind={Button.kinds.SECONDARY}
          type={Button.types.BUTTON}
          onClick={onCancel}
        >
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button kind={Button.kinds.PRIMARY} type={Button.types.SUBMIT}>
          <FormattedMessage id="common.save" />
        </Button>
      </div>
    </Form>
  );
}

CustomerForm.propTypes = {
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

CustomerForm.defaultProps = {
  onCancel: null,
  onSuccess: null,
};

export default CustomerForm;
