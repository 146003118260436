const colors = {
  // neutral pallette
  gray60: '#222222',
  gray50: '#333333',
  gray40: '#666666',
  gray30: '#777777',
  gray22: '#BBBBBB',
  gray20: '#CCCCCC',
  gray15: '#E1E1E1',
  gray10: '#EEEEEE',
  gray05: '#F6F6F6',
  disabledGray: '#fafafa',
  black: '#000000',
  blue: '#19c0ea',
  lightblue: '#E3EAEC',
  white65: '#FFFFFFA6',
  white: '#FFFFFF',
  orange: '#EAA900',
  red: '#F5222D',
  crateBlue: '#19c0ea',
  crateBlueDarkMode: '#55D4F5',
};

module.exports = colors;
