import React from 'react';
import PropTypes from 'prop-types';

function CustomPreTag({ children }) {
  return <pre className="rounded-lg bg-neutral-100 p-2">{children}</pre>;
}

export default CustomPreTag;

CustomPreTag.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    .isRequired,
};
