import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Text } from '@crate.io/crate-gc-admin';
import crateLogo from '../../../assets/logo_shape.svg';
import { PAYMENT_PROVIDER_BUTTON_TEST_ID } from '../../../constants/defaults';
import { PaymentMethod } from 'src/types';

export type ContractSubscriptionButtonProps = {
  isSelected: boolean;
  onClick: (paymentMethod: PaymentMethod) => void;
  paymentMethod: PaymentMethod;
};

function ContractSubscriptionButton({
  isSelected,
  onClick,
  paymentMethod,
}: ContractSubscriptionButtonProps) {
  const styles = cx(
    'border border-crate-border-mid duration-300 flex items-center mb-2 p-6 rounded text-neutral-800 transition-opacity w-full disabled:text-neutral-400 disabled:border-neutral-400',
    {
      'bg-crate-blue/10': isSelected,
      'hover:border-crate-blue hover:bg-crate-blue/5': !isSelected,
    },
  );

  // nicer styling can be applied in the follow-up ticket where
  // contract subscription types are introduced properly
  return (
    <button
      className={styles}
      data-testid={PAYMENT_PROVIDER_BUTTON_TEST_ID.CONTRACT}
      onClick={() => onClick(paymentMethod)}
      type="button"
    >
      <div className="flex items-center">
        <img
          src={crateLogo}
          className="mr-1 h-10 w-16 object-contain"
          alt="Contract payment method logo"
          data-testid="payment-method-logo"
        />
        <Text className="ml-4 text-left" truncate>
          <FormattedMessage id="deploy.choosePaymentProvider.crateContractText" />
        </Text>
      </div>
    </button>
  );
}

export default ContractSubscriptionButton;
