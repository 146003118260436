import { useMemo } from 'react';
import {
  SUPPORTED_FILE_COMPRESSION_FORMATS,
  SUPPORTED_FILE_COMPRESSION_TYPES,
  SUPPORTED_FILE_FORMATS,
  SUPPORTED_FILE_TYPES,
  SupportedFileCompressionFormat,
  SupportedFileCompressionType,
  SupportedFileFormat,
  SupportedFileType,
  UNSUPPORTED_FILE_TYPES,
  UnsupportedFileFormat,
} from '../../constants';

function useSupportedFileTypes() {
  const supportedFileTypes = useMemo<SupportedFileType[]>(
    () => Object.values(SUPPORTED_FILE_TYPES),
    [],
  );

  const supportedFileFormats = useMemo<SupportedFileFormat[]>(
    () => Object.values(SUPPORTED_FILE_FORMATS),
    [],
  );
  const unsupportedFileTypes = useMemo<UnsupportedFileFormat[]>(
    () => Object.values(UNSUPPORTED_FILE_TYPES),
    [],
  );

  const fileCompressionTypes = useMemo<SupportedFileCompressionType[]>(
    () => Object.values(SUPPORTED_FILE_COMPRESSION_TYPES),
    [],
  );
  const fileCompressionFormats = useMemo<SupportedFileCompressionFormat[]>(
    () => Object.values(SUPPORTED_FILE_COMPRESSION_FORMATS),
    [],
  );

  return {
    supportedFileTypes,
    supportedFileFormats,
    unsupportedFileTypes,
    fileCompressionTypes,
    fileCompressionFormats,
  };
}

export default useSupportedFileTypes;
