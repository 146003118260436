const messages = {
  'customerForm.enterBillingInformation': 'Enter your billing information',

  'customerForm.companyName': 'Company name',
  'customerForm.companyName.enterContactName':
    'Please enter a contact name for your organization',

  'customerForm.addressLine1': 'Address line 1',
  'customerForm.addressLine2': 'Address line 2',

  'customerForm.includingCountryCode': 'Including country code',
  'customerForm.vatId': 'VAT ID',
};

export default messages;
