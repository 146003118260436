import React, { useState } from 'react';
import { Input, message, Select, Form } from 'antd';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Loader, SubmitButtonGroup } from '@crate.io/crate-gc-admin';
import { rolePropType } from '../../../models';
import CloudUIDrawer, {
  CloudUIDrawerFooterControlsContainer,
} from '../../../components/CloudUIDrawer';
import { uuidRegexp } from '../../../utils/uuid';
import validator from '../../../utils/validator';
import {
  useGetOrganizationsIdInvitations,
  useGetOrganizationsIdUsers,
} from '../../../swrHooks';
import { apiPost } from '../../../api';
import useMessage from '../../../hooks/useMessage';

function AddUserDrawer({ onClose, organizationId, roles, isVisible }) {
  const { formatMessage } = useIntl();
  const { showSuccessMessage } = useMessage();
  const [form] = Form.useForm();
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const { resetFields, setFieldsValue } = form;
  const { mutate: mutateUsers } = useGetOrganizationsIdUsers(organizationId);
  const { mutate: mutateInvitations } =
    useGetOrganizationsIdInvitations(organizationId);

  const handleClose = () => {
    resetFields(['user']);
    setFieldsValue({
      role: roles[0].id,
    });
    onClose();
  };

  const handleFormFinish = async ({ user, role }) => {
    // prevent the user submitting the form multiple times
    if (!submitInProgress) {
      setSubmitInProgress(true);

      const { success } = await apiPost(
        `/api/v2/organizations/${organizationId}/users/`,
        { role_fqn: role, user },
      );

      if (success) {
        message.destroy();

        showSuccessMessage(
          formatMessage(
            { id: 'organization.addUserToOrganization.userInvitedText' },
            { email: user },
          ),
        );
        mutateUsers();
        mutateInvitations();
        handleClose();
      }

      setSubmitInProgress(false);
    }
  };

  const emailOrUuidValidator = (_, value) => {
    if (validator.validateEmail(value || '') || (value || '').match(uuidRegexp)) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error(
        formatMessage({ id: 'organization.addUserToOrganization.invalidEmailHelp' }),
      ),
    );
  };

  return roles ? (
    <CloudUIDrawer
      onClose={handleClose}
      title={formatMessage({
        id: 'organization.addUserToOrganization.addNewUserTitle',
      })}
      visible={isVisible}
    >
      {submitInProgress ? (
        <div className="flex h-full w-full items-center justify-center">
          <Loader color={Loader.colors.PRIMARY} />
        </div>
      ) : (
        <Form
          layout="vertical"
          aria-label="add a user to organization form"
          form={form}
          onFinish={handleFormFinish}
          initialValues={{ role: roles[0].id }}
        >
          <Form.Item
            colon={false}
            name="user"
            label={formatMessage({
              id: 'organization.addUserToOrganization.emailInputLabel',
            })}
            hasFeedback
            rules={[
              { validator: emailOrUuidValidator },
              {
                required: true,
                message: formatMessage({
                  id: 'organization.addUserToOrganization.emptyEmailHelp',
                }),
              },
            ]}
          >
            <Input
              placeholder={formatMessage({
                id: 'organization.addUserToOrganization.emailInputPlaceholder',
              })}
            />
          </Form.Item>

          <Form.Item
            colon={false}
            name="role"
            label={formatMessage({
              id: 'organization.addUserToOrganization.chooseRoleLabel',
            })}
          >
            <Select
              options={roles
                .filter(role => !role.id.startsWith('project')) // hack to hide project admin/member roles
                .map(role => ({ label: role.name, value: role.id }))}
            />
          </Form.Item>

          <CloudUIDrawerFooterControlsContainer>
            <SubmitButtonGroup
              confirmLabel={formatMessage({ id: 'common.save' })}
              onCancel={handleClose}
            />
          </CloudUIDrawerFooterControlsContainer>
        </Form>
      )}
    </CloudUIDrawer>
  ) : null;
}

AddUserDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(rolePropType).isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default AddUserDrawer;
