import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@crate.io/crate-gc-admin';

function NotificationDescription({ messages }) {
  return Array.isArray(messages) ? (
    <div className="mt-2">
      {messages.map(message => (
        <div key={message.keyName} className="mb-4">
          <Text className="font-bold">{`${message.keyName}: `}</Text>
          <Text>{message.text}</Text>
        </div>
      ))}
    </div>
  ) : (
    messages
  );
}

NotificationDescription.propTypes = {
  messages: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object).isRequired,
    PropTypes.string,
  ]),
};

NotificationDescription.defaultProps = {
  messages: null,
};

export default NotificationDescription;
