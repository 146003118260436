import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { useIntl } from 'react-intl';
import GlobalLoading from '../../../components/GlobalLoading';
import { apiPost } from '../../../api';
import useMessage from '../../../hooks/useMessage';
import { organizationClustersList } from '../../../constants/paths';

function AcceptInvite() {
  const navigate = useNavigate();
  const location = useLocation();
  const { formatMessage } = useIntl();
  const { showErrorMessage, showLoadingMessage, showSuccessMessage } = useMessage();
  const token = new URLSearchParams(location.search).get('token');

  useEffect(() => {
    const acceptInvitation = async () => {
      showLoadingMessage(
        formatMessage({ id: 'organization.organizationUsers.inviteAddInProgress' }),
      );

      const { success, data } = await apiPost('/api/v2/users/me/accept-invite/', {
        token,
      });

      message.destroy();

      if (success) {
        showSuccessMessage(
          formatMessage({ id: 'organization.organizationUsers.inviteAddSuccess' }),
        );
        navigate(
          organizationClustersList.build({ organizationId: data.organization_id }),
        );
      } else {
        showErrorMessage(
          formatMessage({ id: 'organization.organizationUsers.inviteAddFailure' }),
        );
      }
    };

    acceptInvitation();
  }, [
    formatMessage,
    navigate,
    showErrorMessage,
    showLoadingMessage,
    showSuccessMessage,
    token,
  ]);

  return <GlobalLoading />;
}

export default AcceptInvite;
