const sizes = {
  1000: ['B', 'kB', 'MB', 'GB', 'TB'],
  1024: ['B', 'KiB', 'MiB', 'GiB', 'TiB'],
};

const bases = {
  binary: 1024,
  decimal: 1000,
};

const formatterPrototype = {
  format() {
    return `${Math.round(this.value * 10) / 10} ${this.denomination}`;
  },
};

const formatter = (value, denomination) =>
  Object.assign(Object.create(formatterPrototype), {
    value,
    denomination,
  });
// from raw bytes to denominated value
const calculateDenominationByExponent = (exponent, basis) => bytes =>
  parseFloat((bytes / basis ** exponent).toFixed(2));

// from denominated value to raw bytes
const calculateRawBytesByDenomination = (denomination, basis) => value => {
  const exponent = sizes[basis].indexOf(denomination);
  return value * basis ** exponent;
};

// higher order function to convert raw values to formatted values
export const fromBytes = (bytes, options = { basis: 'binary' }) => {
  if (bytes === 0) {
    return formatter(0, 'B');
  }

  const basis = options.basis in bases ? bases[options.basis] : bases.binary;
  const exponent = Math.floor(Math.log(bytes) / Math.log(basis));
  const value = calculateDenominationByExponent(exponent, basis)(bytes);
  const denomination = sizes[basis][exponent];

  return formatter(value, denomination);
};

// higher order function to convert formatted values to raw values
export const toBytes =
  denomination =>
  (value, options = { basis: 'binary' }) => {
    const basis = options.basis in bases ? bases[options.basis] : bases.binary;

    return calculateRawBytesByDenomination(denomination, basis)(value);
  };

// Short hand functions with a simpler interface for specific use cases
export const toMegaBytes = calculateDenominationByExponent(2, bases.binary);
export const toGibiBytes = calculateDenominationByExponent(3, bases.binary);
export const toGigaBytes = calculateDenominationByExponent(3, bases.decimal);
export const toTibiBytes = calculateDenominationByExponent(4, bases.binary);
export const fromGibiBytes = calculateRawBytesByDenomination('GiB', bases.binary);
export const fromGigaBytes = calculateRawBytesByDenomination('GB', bases.decimal);
