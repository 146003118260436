import { PropsWithChildren } from 'react';
import GlobalLoadingAnimation from './GlobalLoadingAnimation';

export type GlobalLoadingProps = PropsWithChildren;

function GlobalLoading({ children }: GlobalLoadingProps) {
  return (
    <div
      className="fixed left-0 top-0 z-50 h-screen w-screen bg-neutral-800"
      data-testid="loading"
    >
      <GlobalLoadingAnimation>{children}</GlobalLoadingAnimation>
    </div>
  );
}

export default GlobalLoading;
