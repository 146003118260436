import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CheckCircleOutlined } from '@ant-design/icons';
import useDeployStore from '../state';
import { CLOUD_GROUPS } from '../../../constants/deploy';

function GroupSelector() {
  const { formatMessage } = useIntl();
  const cloudGroup = useDeployStore(state => state.cloudGroup);
  const setCloudGroup = useDeployStore(state => state.setCloudGroup);

  const selectedStyle =
    'bg-crate-blue/10 border-2 border-crate-blue flex flex-col items-stretch p-2 rounded text-left w-1/3';
  const unSelectedStyle =
    'bg-white border-2 border-crate-border-light flex flex-col items-stretch p-2 rounded text-left w-1/3 hover:border-crate-blue';

  const renderButton = (label, sublabel, value) => {
    const isActive = cloudGroup === value;

    return (
      <button
        className={isActive ? selectedStyle : unSelectedStyle}
        onClick={() => setCloudGroup(value)}
        type="button"
        data-testid={`${value}-button-${isActive ? 'selected' : 'unselected'}`}
      >
        <div className="flex items-center justify-between">
          <span className="font-semibold">{label}</span>
          {isActive && <CheckCircleOutlined className="text-lg text-crate-blue" />}
        </div>
        <div className="py-0.5 leading-snug opacity-60">{sublabel}</div>
      </button>
    );
  };

  return (
    <>
      <div className="mb-1 flex w-full items-stretch justify-between gap-2">
        {renderButton(
          formatMessage({ id: 'deploy.deployTypes.sharedTitle' }),
          formatMessage({ id: 'deploy.deployTypes.sharedDescription' }),
          CLOUD_GROUPS.SHARED,
        )}
        {renderButton(
          formatMessage({ id: 'deploy.deployTypes.dedicatedTitle' }),
          formatMessage({ id: 'deploy.deployTypes.dedicatedDescription' }),
          CLOUD_GROUPS.DEDICATED,
        )}
        {renderButton(
          formatMessage({ id: 'deploy.deployTypes.enterpriseTitle' }),
          formatMessage({ id: 'deploy.deployTypes.enterpriseDescription' }),
          CLOUD_GROUPS.ENTERPRISE,
        )}
      </div>
      <div className="pb-4 pt-0.5">
        <span className="opacity-60">
          {cloudGroup === CLOUD_GROUPS.SHARED && (
            <FormattedMessage id="deploy.deployTypes.sharedRulesText" />
          )}
          {cloudGroup === CLOUD_GROUPS.DEDICATED && (
            <FormattedMessage id="deploy.deployTypes.dedicatedRulesText" />
          )}
          {cloudGroup === CLOUD_GROUPS.ENTERPRISE && (
            <FormattedMessage id="deploy.deployTypes.enterpriseRulesText" />
          )}
        </span>{' '}
        <a
          href="https://cratedb.com/docs/cloud/en/latest/reference/services.html"
          target="_blank"
          rel="noreferrer"
          className="whitespace-nowrap"
        >
          <FormattedMessage id="deploy.deployTypes.learnMore" />
        </a>
      </div>
    </>
  );
}

export default GroupSelector;
