import { create } from 'zustand';
import { DEPLOY_OPTIONS, CLOUD_GROUPS } from '../../constants/deploy';

const initialState = {
  // cluster name
  clusterName: null,
  clusterNameIsInitialized: false,
  clusterNameIsUnique: true,
  clusterNameIsValid: true,

  // region
  deployRegion: null,

  // cloud configuration
  cloudGroup: CLOUD_GROUPS.SHARED,
  cloudNodeCount: null,
  cloudProduct: null,
  cloudStorage: null,

  // edge configuration
  edgeCPUCount: DEPLOY_OPTIONS.CPU.initialValue,
  edgeNodeCount: DEPLOY_OPTIONS.NODES.initialValue,
  edgeRAM: DEPLOY_OPTIONS.RAM.initialValue,
  edgeStorage: DEPLOY_OPTIONS.STORAGE.initialValue,

  // edge custom backup location
  edgeUseDefaultBackupLocation: true,
  edgeBackupAccessID: '',
  edgeBackupAccessKey: '',
  edgeBackupEndpointURL: '',
  edgeBackupHasValidated: false,
  edgeBackupIsValid: false,
  edgeBackupIsValidating: false,
  edgeBackupS3BucketName: '',

  // payment method
  paymentMethod: null,

  // ui
  deployEdgeMode: false,
  deployInProgress: false,
};

export default create(set => ({
  ...initialState,

  // cluster name
  setClusterName: name => set({ clusterName: name }),
  setClusterNameIsInitialized: isInitialized =>
    set({ clusterNameIsInitialized: isInitialized }),
  setClusterNameIsUnique: isUnique => set({ clusterNameIsUnique: isUnique }),
  setClusterNameIsValid: isValid => set({ clusterNameIsValid: isValid }),

  // region
  setDeployRegion: region => set({ deployRegion: region }),

  // cloud configuration
  setCloudGroup: group => set({ cloudGroup: group }),
  setCloudNodeCount: nodeCount => set({ cloudNodeCount: nodeCount }),
  setCloudProduct: product => set({ cloudProduct: product }),
  setCloudStorage: storage => set({ cloudStorage: storage }),

  // edge configuration
  setEdgeCPUCount: cpuCount => set({ edgeCPUCount: cpuCount }),
  setEdgeNodeCount: nodeCount => set({ edgeNodeCount: nodeCount }),
  setEdgeRAM: ram => set({ edgeRAM: ram }),
  setEdgeStorage: storage => set({ edgeStorage: storage }),

  // edge custom backup location
  setEdgeUseDefaultBackupLocation: useDefault =>
    set({
      edgeUseDefaultBackupLocation: useDefault,
      edgeBackupIsValid: false,
      edgeBackupHasValidated: false,
    }),
  setEdgeBackupAccessID: name =>
    set({
      edgeBackupAccessID: name,
      edgeBackupIsValid: false,
      edgeBackupHasValidated: false,
    }),
  setEdgeBackupAccessKey: name =>
    set({
      edgeBackupAccessKey: name,
      edgeBackupIsValid: false,
      edgeBackupHasValidated: false,
    }),
  setEdgeBackupEndpointURL: name =>
    set({
      edgeBackupEndpointURL: name,
      edgeBackupIsValid: false,
      edgeBackupHasValidated: false,
    }),
  setEdgeBackupS3BucketName: name =>
    set({
      edgeBackupS3BucketName: name,
      edgeBackupIsValid: false,
      edgeBackupHasValidated: false,
    }),
  setEdgeBackupIsValid: isValid =>
    set({
      edgeBackupIsValid: isValid,
      edgeBackupHasValidated: true,
    }),
  setEdgeBackupIsValidating: isValidating =>
    set({ edgeBackupIsValidating: isValidating }),

  // payment method
  setPaymentMethod: method => set({ paymentMethod: method }),

  // ui
  setDeployEdgeMode: edgeMode => set({ deployEdgeMode: edgeMode }),
  setDeployInProgress: inProgress => set({ deployInProgress: inProgress }),

  // reset entire state
  resetState: () => {
    set(initialState);
  },
}));
