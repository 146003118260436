import { PropsWithChildren } from 'react';

export type ConstrainStepWidthProps = PropsWithChildren<{
  testId?: string;
}>;

function ConstrainStepWidth({ children, testId }: ConstrainStepWidthProps) {
  return (
    <div className="max-w-[600px]" data-testid={testId}>
      {children}
    </div>
  );
}

export default ConstrainStepWidth;
