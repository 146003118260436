const generateSecurePassword = () => {
  const numbers = '0123456789';
  const uppercase = 'BCDFGHJKLMNPQRSTVWXYZ';
  const lowercase = 'bcdfghjklmnpqrstvwxyz';
  const symbols = '-._()!*,'; // url safe
  const allCharacters =
    numbers + numbers + uppercase + lowercase + symbols + symbols + symbols;

  const getChar = str => {
    return str.charAt(Math.floor(Math.random() * str.length));
  };

  // ensure we have at least one of each character type
  const password = [
    getChar(numbers),
    getChar(uppercase),
    getChar(lowercase),
    getChar(symbols),
  ];

  while (password.length < 24) {
    password.push(getChar(allCharacters));
  }

  // shuffle the output
  return password
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
    .join('');
};

export default generateSecurePassword;
