import { Button, Heading } from '@crate.io/crate-gc-admin';
import { Steps } from 'antd';
import React from 'react';

export type StepItem = {
  title: React.ReactNode;
  renderContent: () => React.ReactNode;
  summaryContent?: () => React.ReactNode;
};

export type StepLayoutProps = {
  steps: StepItem[];
  stepIndex: number;
  setStepIndex: (index: number) => void;
};

function StepLayout({ steps = [], stepIndex, setStepIndex }: StepLayoutProps) {
  const renderStepTitle = (step: StepItem, index: number) => {
    const { title } = step;
    return (
      <div>
        {index < stepIndex ? (
          <Button
            kind={Button.kinds.TERTIARY}
            className="font-bold !leading-3"
            onClick={() => {
              setStepIndex(index);
            }}
          >
            {title}
          </Button>
        ) : (
          <Heading level={Heading.levels.h4} className="mt-1">
            {title}
          </Heading>
        )}
      </div>
    );
  };

  const renderStepContent = (step: StepItem, index: number) => {
    if (index > stepIndex) {
      return null;
    }

    return (
      <div>
        {stepIndex === index
          ? step.renderContent()
          : index < stepIndex && step.summaryContent
            ? step.summaryContent()
            : ''}
      </div>
    );
  };

  return (
    <div>
      <Steps
        items={steps.map((step, index) => {
          return {
            title: renderStepTitle(step, index),
            description: renderStepContent(step, index),
          };
        })}
        current={stepIndex}
        direction="vertical"
      />
    </div>
  );
}

export default StepLayout;
