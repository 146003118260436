import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Loader, Text } from '@crate.io/crate-gc-admin';
import { Modal, Select } from 'antd';
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import ConstrainWidth from '../../../components/ConstrainWidth';
import CreditsRemaining from '../../../components/CreditsRemaining';
import HubspotFormExceedQuotaScaleExisting from '../../../components/HubspotForms/HubspotFormExceedQuotaScaleExisting';
import NotificationAside from '../../../components/NotificationAside';
import PricingBreakdown from '../../../components/PricingBreakdown';
import ScaleComputeOption from './ScaleComputeOption';
import ScaleStorageOption from './ScaleStorageOption';
import SectionContainer from '../../../components/SectionContainer';
import ViewContainerActions from '../../../components/ViewContainerActions';
import {
  useGetClustersId,
  useGetClustersIdAvailableproducts,
  useGetOrganizationsIdClusters,
  useGetOrganizationsIdRegions,
  useGetOrganizationsIdRemainingbudgetExcludeclusterid,
  useGetProducts,
  useGetProductsClustersPrice,
  useGetProjects,
} from '../../../swrHooks';
import { apiPut } from '../../../api';
import { clusterBackup } from '../../../constants/paths';
import { USER_TRACKING_EVENTS } from '../../../constants/segment';
import { useAnalytics } from '../../../hooks';
import { fromBytes, toGibiBytes } from '../../../utils';
import {
  getClusterAsyncInProgress,
  getClusterRegion,
} from '../../../utils/data/cluster';
import { getClusterStripeProducts } from '../../../utils/data/product';
import showStorageWarning from '../../deploy/showStorageWarning';
import { PRICE_OPTIONS_3DP } from '../../../constants/defaults';

function ClusterScale() {
  const { trackEvent } = useAnalytics();
  const { formatMessage, formatNumber } = useIntl();
  const [selectedClusterProduct, setSelectedClusterProduct] = useState(null);
  const [selectedStorageBytes, setSelectedStorageBytes] = useState(null);
  const [selectedNodeCount, setSelectedNodeCount] = useState(null);
  const [computeTier, setComputeTier] = useState(null); // 'shared' or 'dedicated'
  const [showBudgetExceededModal, setShowBudgetExceededModal] = useState(false);
  const [scaleButtonClicked, setScaleButtonClicked] = useState(false); // mask the awkward pause between clicking the scale button and seeing the spinner
  const { clusterId, organizationId, projectId } = useParams();
  const { data: cluster, mutate: mutateCluster } = useGetClustersId(clusterId);
  const { data: products } = useGetProducts();
  const { data: projects } = useGetProjects();
  const { data: regions } = useGetOrganizationsIdRegions(organizationId);
  const { data: remainingBudget } =
    useGetOrganizationsIdRemainingbudgetExcludeclusterid(organizationId, clusterId);
  const { mutate: mutateClusters } = useGetOrganizationsIdClusters(organizationId);
  const { data: alternateClusterProducts, mutate: mutateAlternateClusterProducts } =
    useGetClustersIdAvailableproducts(clusterId);

  const WARNINGS = {
    ASYNC_OPERATION_IN_PROGRESS:
      'An operation is currently being performed on this cluster. Please wait for it to complete before scaling.',
    CPU_STORAGE_DISPARITY: formatMessage({
      id: 'deploy.managedConfiguration.storageWarning',
    }),
  };

  const asyncOperationInProgress = getClusterAsyncInProgress(cluster);
  const isScalingInProgress = ['CHANGE_COMPUTE', 'EXPAND_STORAGE', 'SCALE'].includes(
    cluster?.health.running_operation,
  );
  const isCRFREE = cluster?.product_name === 'crfree';

  const region = useMemo(
    () => getClusterRegion(cluster, projects, regions),
    [cluster, projects, regions],
  );

  // The allAvailableClusterProducts variable is used to store a list of every
  // stripe product. This is used a) to derive the clusterProduct variable below,
  // and b) is required by the ScaleUnitSettings component which needs all product
  // info to know the min/max values to accurately draw the cluster scale charts.
  const allAvailableClusterProducts = useMemo(
    () => getClusterStripeProducts(products),
    [products],
  );

  // set the current cluster product
  const currentClusterProduct = useMemo(() => {
    if (!allAvailableClusterProducts || !cluster) {
      return null;
    }

    return allAvailableClusterProducts.find(
      availableProduct =>
        availableProduct.kind === 'cluster' &&
        availableProduct.name === cluster.product_name &&
        availableProduct.tier === cluster.product_tier,
    );
  }, [allAvailableClusterProducts, cluster]);

  // set the compute tier based on the current cluster product
  if (currentClusterProduct && !computeTier) {
    setComputeTier(
      currentClusterProduct.tags.includes('shared') ? 'shared' : 'dedicated',
    );
  }

  // get available options per dimension
  const availableClusterProducts = useMemo(() => {
    // isScalingInProgress is used here to rebuild the product
    // options after scaling has completed
    if (
      !currentClusterProduct ||
      !alternateClusterProducts ||
      !computeTier ||
      isScalingInProgress
    ) {
      return null;
    }

    return [currentClusterProduct, ...alternateClusterProducts]
      .filter(product => {
        return product.tags.includes(computeTier);
      })
      .sort((a, b) => {
        // sort by cpu or name
        if (a.specs.cpu_cores === b.specs.cpu_cores) {
          return a.name > b.name ? 1 : -1;
        }
        return a.specs.cpu_cores > b.specs.cpu_cores ? 1 : -1;
      });
  }, [
    alternateClusterProducts,
    computeTier,
    currentClusterProduct,
    isScalingInProgress,
  ]);

  const availableStorageOptions = useMemo(() => {
    // isScalingInProgress is used here to rebuild the storage
    // options after scaling has completed
    if (!cluster || !currentClusterProduct || isScalingInProgress) {
      return null;
    }

    // This function produces a list of options for storage values,
    // i.e. 32GiB, 64GiB, 128Gib, 256Gib, etc.
    //
    // It is possible, however, that the current storage size value does
    // not exist in this list, e.g. a non-standard storage value set using
    // croud. In which case, that number is added to the list in the
    // appropriate location.

    const currentBytes = cluster.hardware_specs.disk_size_per_node_bytes;

    // create an array derived from the min-max range
    const options = [];
    let storage = currentClusterProduct.specs.storage_minimum_bytes;
    while (storage <= currentClusterProduct.specs.storage_maximum_bytes) {
      options.push(storage);
      storage *= 2;
    }

    // if current storage size isn't already in the options array, add it
    // and sort the list
    if (!options.includes(currentBytes)) {
      options.push(currentBytes);
      options.sort((a, b) => a - b); // reminder: sort() sorts as strings by default, not numbers
    }

    // convert this to a list of options, and filter out the options smaller
    // than the current storage size
    return options
      .map(bytes => ({
        label: fromBytes(bytes).format(),
        bytes,
      }))
      .filter(option => {
        // strip out smaller scaling options, can go up but not down
        return option.bytes >= currentBytes;
      });
  }, [cluster, currentClusterProduct, isScalingInProgress]);

  const availableNodeCountOptions = useMemo(() => {
    // isScalingInProgress is used here to rebuild the node count
    // options after scaling has completed
    if (!currentClusterProduct || isScalingInProgress) {
      return null;
    }

    return currentClusterProduct?.scaling.filter(scale => {
      if (cluster.num_nodes > 1 && scale.nodes === 1) {
        return false;
      }

      return true;
    });
  }, [cluster, currentClusterProduct, isScalingInProgress]);

  const getScaleOptionIndex = numberOfNodes => {
    if (!currentClusterProduct || !numberOfNodes) {
      return null;
    }

    return currentClusterProduct.scaling.findIndex(
      scaleOption => scaleOption.nodes === numberOfNodes,
    );
  };

  const proposedClusterSpecs = {
    product: selectedClusterProduct || currentClusterProduct,
    nodeCount: selectedNodeCount
      ? getScaleOptionIndex(selectedNodeCount)
      : getScaleOptionIndex(cluster?.num_nodes),
    storageBytes:
      selectedStorageBytes || cluster?.hardware_specs.disk_size_per_node_bytes,
  };

  // get prices
  const { data: currentConfigurationPrice } = useGetProductsClustersPrice(
    organizationId,
    region,
    currentClusterProduct,
    getScaleOptionIndex(cluster?.num_nodes),
    cluster?.hardware_specs.disk_size_per_node_bytes,
  );
  const { data: selectedConfigurationPrice } = useGetProductsClustersPrice(
    organizationId,
    region,
    proposedClusterSpecs.product,
    proposedClusterSpecs.nodeCount,
    proposedClusterSpecs.storageBytes,
  );

  const getNodePriceDiff = nodeCount => {
    if (!currentConfigurationPrice || !cluster) {
      return null;
    }

    return (
      (currentConfigurationPrice.total_price.price_per_hour / cluster.num_nodes) *
      (nodeCount - cluster.num_nodes)
    );
  };

  const getComputeText = existingSpec => {
    if (!cluster || !currentClusterProduct) {
      return '';
    }

    let prefix = currentClusterProduct.tags.includes('dedicated') ? '' : 'Up to ';
    let nodeCount = cluster.num_nodes;
    let cpusPerNode = cluster.hardware_specs.cpus_per_node;
    let ramPerNode = toGibiBytes(cluster.hardware_specs.memory_per_node_bytes);

    if (!existingSpec) {
      if (selectedClusterProduct) {
        prefix = selectedClusterProduct.tags.includes('dedicated') ? '' : 'Up to ';
        cpusPerNode = selectedClusterProduct.specs.cpu_cores;
        ramPerNode = toGibiBytes(selectedClusterProduct.specs.ram_bytes);
      }
      if (selectedNodeCount) {
        nodeCount = selectedNodeCount;
      }
    }

    return `${prefix}${nodeCount * cpusPerNode} vCPU, ${nodeCount * ramPerNode} GiB RAM total`;
  };

  const getStorageText = existingSpec => {
    if (!cluster || !currentClusterProduct) {
      return '';
    }

    let nodeCount = cluster.num_nodes;
    let storagePerNode = toGibiBytes(
      cluster.hardware_specs.disk_size_per_node_bytes,
    );

    if (!existingSpec) {
      if (selectedStorageBytes) {
        storagePerNode = toGibiBytes(selectedStorageBytes);
      }
      if (selectedNodeCount) {
        nodeCount = selectedNodeCount;
      }
    }

    return `${nodeCount * storagePerNode} GiB total`;
  };

  const getWarningMessage = () => {
    // async operation warning
    if (asyncOperationInProgress) {
      return WARNINGS.ASYNC_OPERATION_IN_PROGRESS;
    }

    // too much storage for cpu warning
    if (
      cluster &&
      selectedStorageBytes &&
      showStorageWarning(
        selectedStorageBytes,
        cluster.hardware_specs.cpu_cores,
        cluster.hardware_specs.memory_per_node_bytes,
      )
    ) {
      return WARNINGS.CPU_STORAGE_DISPARITY;
    }

    return null;
  };

  // shorthand vars
  const isComputeDisabled =
    selectedStorageBytes !== null || selectedNodeCount !== null;
  const isStorageDisabled =
    selectedClusterProduct !== null || selectedNodeCount !== null;
  const isNodeCountDisabled =
    selectedClusterProduct !== null || selectedStorageBytes !== null;
  const warningMessage = getWarningMessage();
  const monthlyBudgetExceeded =
    selectedConfigurationPrice &&
    remainingBudget &&
    selectedConfigurationPrice.total_price.price_per_month >
      remainingBudget.remaining_budget_per_month;
  const isSubmitDisabled =
    !selectedConfigurationPrice ||
    asyncOperationInProgress ||
    isCRFREE ||
    (!selectedClusterProduct && !selectedStorageBytes && !selectedNodeCount);

  const handleNodeCountChange = nodeCount => {
    if (isNodeCountDisabled || nodeCount === cluster.num_nodes) {
      setSelectedNodeCount(null);
      return;
    }

    setSelectedNodeCount(nodeCount);
  };

  const handleStorageChange = bytes => {
    if (
      isStorageDisabled ||
      bytes === cluster.hardware_specs.disk_size_per_node_bytes
    ) {
      setSelectedStorageBytes(null);
      return;
    }

    setSelectedStorageBytes(bytes);
  };

  const renderDivider = () => {
    return (
      <div
        className={`relative -top-2 text-center uppercase ${isComputeDisabled || isStorageDisabled || isNodeCountDisabled ? 'opacity-15' : 'opacity-60'}`}
      >
        <FormattedMessage id="common.or" />
      </div>
    );
  };

  const renderTierButtons = () => {
    // don't show the tier buttons if the cluster is dedicated, they
    // can't scale down to shared
    if (currentClusterProduct.tags.includes('dedicated')) {
      return null;
    }

    const selectedStyle =
      'bg-crate-blue/10 border-2 border-crate-blue flex flex-col items-stretch p-2 rounded text-left w-1/2';
    const unSelectedStyle =
      'bg-white border-2 border-crate-border-light flex flex-col items-stretch p-2 rounded text-left w-1/2 hover:border-crate-blue';
    const isShared = computeTier === 'shared';

    return (
      <div className="mb-1 flex w-full items-stretch justify-between gap-2">
        <button
          className={isShared ? selectedStyle : unSelectedStyle}
          onClick={() => setComputeTier('shared')}
          type="button"
        >
          <div className="flex items-center justify-between">
            <span className="font-semibold">
              <FormattedMessage id="deploy.deployTypes.sharedTitle" />
            </span>
            {isShared && <CheckCircleOutlined className="text-lg text-crate-blue" />}
          </div>
          <div className="py-0.5 leading-snug opacity-60">
            <FormattedMessage id="deploy.deployTypes.sharedDescription" />
          </div>
        </button>
        <button
          className={!isShared ? selectedStyle : unSelectedStyle}
          onClick={() => setComputeTier('dedicated')}
          type="button"
        >
          <div className="flex items-center justify-between">
            <span className="font-semibold">
              <FormattedMessage id="deploy.deployTypes.dedicatedTitle" />
            </span>
            {!isShared && (
              <CheckCircleOutlined className="text-lg text-crate-blue" />
            )}
          </div>
          <div className="py-0.5 leading-snug opacity-60">
            <FormattedMessage id="deploy.deployTypes.dedicatedDescription" />
          </div>
        </button>
      </div>
    );
  };

  const submitHandler = async () => {
    if (monthlyBudgetExceeded) {
      setShowBudgetExceededModal(true);
      return;
    }

    // send api calls
    let success = false;
    setScaleButtonClicked(true);
    if (selectedClusterProduct) {
      trackEvent(USER_TRACKING_EVENTS.CLICKED_CLUSTER_CHANGE_COMPUTE);
      ({ success } = await apiPut(`/api/v2/clusters/${clusterId}/product/`, {
        product_name: selectedClusterProduct.name,
      }));
    } else if (selectedStorageBytes) {
      trackEvent(USER_TRACKING_EVENTS.CLICKED_CLUSTER_STORAGE_RESIZE);
      ({ success } = await apiPut(`/api/v2/clusters/${clusterId}/storage/`, {
        disk_size_per_node_bytes: selectedStorageBytes,
      }));
    } else if (selectedNodeCount) {
      trackEvent(USER_TRACKING_EVENTS.CLICKED_UPGRADE_TRIGGERED);
      ({ success } = await apiPut(`/api/v2/clusters/${clusterId}/scale/`, {
        product_unit: getScaleOptionIndex(selectedNodeCount),
      }));
    }

    // mutate the cluster and cluster list so that the async
    // operation shows instantly
    if (success) {
      mutateCluster();
      mutateClusters();
    } else {
      setScaleButtonClicked(false);
    }
  };

  // reset the form if the cluster is being scaled
  // put this in a useEffect in case the scale was initiated outside of this page
  useEffect(() => {
    setSelectedClusterProduct(null);
    setSelectedNodeCount(null);
    setSelectedStorageBytes(null);
    setComputeTier(null);
    mutateAlternateClusterProducts();
    setScaleButtonClicked(false);
  }, [isScalingInProgress, mutateAlternateClusterProducts]);

  if (isScalingInProgress || scaleButtonClicked) {
    return (
      <div className="flex h-screen max-h-96 w-full flex-col justify-center gap-8 text-center">
        <Loader
          align={Loader.alignment.CENTER}
          color={Loader.colors.PRIMARY}
          size={Loader.sizes.MEDIUM}
        />
        <Text pale>
          <FormattedMessage id="cluster.clusterScale.clusterScaleInProgress" />
        </Text>
      </div>
    );
  }

  return (
    <>
      <ConstrainWidth>
        <ViewContainerActions>
          {isCRFREE && (
            <NotificationAside
              type={NotificationAside.types.INFO}
              message={formatMessage({ id: 'cluster.clusterScale.crfreeInfoText' })}
              link={
                <Link
                  to={`${clusterBackup.build({
                    organizationId,
                    projectId,
                    clusterId,
                  })}`}
                >
                  <FormattedMessage id="cluster.clusterScale.cloneToUpgradedClusterText" />
                </Link>
              }
            />
          )}
          {!isCRFREE && (
            <NotificationAside
              type={NotificationAside.types.INFO}
              message={formatMessage({
                id: 'cluster.clusterScale.nonCrfreeInfoText',
              })}
            />
          )}
        </ViewContainerActions>

        <div className="grid grid-cols-12 gap-x-4 gap-y-2">
          {/* left column */}
          <div className="col-span-12 lg:col-span-7" data-testid="scale-left-column">
            {/* compute */}
            <SectionContainer>
              <div className="pb-2">
                <FormattedMessage id="cluster.clusterScale.nodeComputeSizeTitle" />
              </div>
              {availableClusterProducts &&
              cluster &&
              currentClusterProduct &&
              region ? (
                <div
                  data-testid="compute-options"
                  className={
                    isComputeDisabled
                      ? 'border-b border-crate-border-light opacity-40'
                      : 'border-b border-crate-border-light'
                  }
                >
                  {renderTierButtons()}
                  <div className="pb-4">
                    <Text pale>
                      {computeTier === 'shared' && (
                        <FormattedMessage id="deploy.deployTypes.sharedRulesText" />
                      )}
                      {computeTier === 'dedicated' && (
                        <FormattedMessage id="deploy.deployTypes.dedicatedRulesText" />
                      )}
                    </Text>{' '}
                    <a
                      className="inline-block"
                      href="https://cratedb.com/docs/cloud/en/latest/reference/services.html"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <FormattedMessage id="cluster.clusterScale.learnMoreAboutClusterTypes" />
                    </a>
                  </div>
                  {availableClusterProducts?.map(product => (
                    <ScaleComputeOption
                      cluster={cluster}
                      currentClusterProduct={currentClusterProduct}
                      product={product}
                      region={region}
                      selectedClusterProduct={selectedClusterProduct}
                      isDisabled={isComputeDisabled}
                      isReadonly={isCRFREE}
                      setSelectedClusterProduct={setSelectedClusterProduct}
                      key={product.name}
                    />
                  ))}
                </div>
              ) : (
                <div className="my-4 text-center">
                  <LoadingOutlined className="text-2xl" />
                </div>
              )}
            </SectionContainer>

            {renderDivider()}

            {/* storage */}
            <SectionContainer>
              <div className="pb-2">
                <FormattedMessage id="cluster.clusterScale.nodeStorageSizeTitle" />
              </div>
              {cluster &&
              currentClusterProduct &&
              availableStorageOptions &&
              region ? (
                <div className={isStorageDisabled ? 'opacity-40' : ''}>
                  <Select
                    className="w-full"
                    disabled={isStorageDisabled}
                    onChange={handleStorageChange}
                    value={
                      selectedStorageBytes ||
                      cluster.hardware_specs.disk_size_per_node_bytes
                    }
                  >
                    {availableStorageOptions.map(option => (
                      <Select.Option key={option.bytes} value={option.bytes}>
                        <ScaleStorageOption
                          bytes={option.bytes}
                          cluster={cluster}
                          currentClusterProduct={currentClusterProduct}
                          label={option.label}
                          region={region}
                          selectedStorageBytes={selectedStorageBytes}
                        />
                      </Select.Option>
                    ))}
                  </Select>
                  {isCRFREE ? (
                    <div className="pt-0.5 text-right text-xs opacity-50">
                      <FormattedMessage id="cluster.clusterScale.crfreeIncreaseStorageInfoText" />
                    </div>
                  ) : (
                    <div className="pt-0.5 text-right text-xs opacity-50">
                      <FormattedMessage id="cluster.clusterScale.storageCannotBeDecreasedInfoText" />
                    </div>
                  )}
                </div>
              ) : (
                <div className="my-4 text-center">
                  <LoadingOutlined className="text-2xl" />
                </div>
              )}
            </SectionContainer>

            {renderDivider()}

            {/* node count */}
            <SectionContainer>
              <div className="pb-2">
                <FormattedMessage id="cluster.clusterScale.numberOfNodesText" />
              </div>
              {availableNodeCountOptions && cluster && region ? (
                <div className={isNodeCountDisabled ? 'opacity-40' : ''}>
                  <Select
                    className="w-full"
                    disabled={isNodeCountDisabled}
                    onChange={handleNodeCountChange}
                    value={selectedNodeCount || cluster.num_nodes}
                  >
                    {availableNodeCountOptions.map(scaleOption => {
                      const priceDiff = getNodePriceDiff(scaleOption.nodes);

                      return (
                        <Select.Option
                          key={scaleOption.description}
                          value={scaleOption.nodes}
                        >
                          <button
                            className="flex w-full items-center justify-between text-left"
                            type="button"
                          >
                            <div className="flex w-[87%] items-center">
                              <div className="w-[20%] px-2">
                                x{scaleOption.nodes}
                              </div>
                              {priceDiff !== 0 && (
                                <div className="w-[80%] px-2">
                                  {priceDiff > 0 && '+'}
                                  {formatNumber(priceDiff, PRICE_OPTIONS_3DP)}{' '}
                                  <span className="whitespace-nowrap text-xs opacity-80">
                                    <FormattedMessage id="cluster.clusterScale.perHour" />
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="flex w-[13%] items-center justify-end px-2">
                              {cluster?.num_nodes === scaleOption.nodes && (
                                <div className="inline-block rounded bg-crate-blue px-1.5 py-1 text-[10px] font-semibold uppercase leading-tight text-white">
                                  current
                                </div>
                              )}
                              {selectedNodeCount === scaleOption.nodes && (
                                <CheckCircleOutlined
                                  className="text-lg text-crate-blue"
                                  data-testid="selected-product-icon"
                                />
                              )}
                            </div>
                          </button>
                        </Select.Option>
                      );
                    })}
                  </Select>
                  {availableNodeCountOptions.length === 1 && (
                    <div className="pt-0.5 text-right text-xs opacity-50">
                      <FormattedMessage id="cluster.clusterScale.increaseNodesRequiresDedicates" />
                    </div>
                  )}
                </div>
              ) : (
                <div className="my-4 text-center">
                  <LoadingOutlined className="text-2xl" />
                </div>
              )}
            </SectionContainer>
          </div>

          {/* right column */}
          <div
            className="col-span-12 lg:col-span-5"
            data-testid="scale-right-column"
          >
            <SectionContainer>
              <PricingBreakdown
                computeText={getComputeText(true)}
                loading={false}
                price={currentConfigurationPrice}
                storageText={getStorageText(true)}
                title={formatMessage({
                  id: 'cluster.clusterScale.currentClusterPrice',
                })}
              />
            </SectionContainer>
            {(selectedClusterProduct ||
              selectedStorageBytes ||
              selectedNodeCount) && (
              <SectionContainer className="border-4 border-crate-blue">
                <PricingBreakdown
                  computeText={getComputeText(false)}
                  loading={false}
                  price={selectedConfigurationPrice}
                  storageText={getStorageText(false)}
                  title={formatMessage({
                    id: 'cluster.clusterScale.newClusterPrice',
                  })}
                />
              </SectionContainer>
            )}
            <CreditsRemaining />
            {warningMessage && (
              <div className="mb-4">
                <NotificationAside
                  type={NotificationAside.types.WARN}
                  message={warningMessage}
                />
              </div>
            )}
            <Button
              className="w-full"
              disabled={isSubmitDisabled}
              onClick={submitHandler}
              kind={Button.kinds.PRIMARY}
              type={Button.types.BUTTON}
            >
              <FormattedMessage id="cluster.clusterScale.scaleClusterButton" />
            </Button>
          </div>
        </div>
      </ConstrainWidth>

      {/* budget exceeded modal */}
      <Modal
        closable={false}
        footer={null}
        title={
          remainingBudget?.remaining_budget_per_month > 0 ? (
            <FormattedMessage
              id="deploy.quota.quotaExceededPriceTitle"
              values={{ quota: remainingBudget.remaining_budget_per_month }}
            />
          ) : (
            <FormattedMessage id="deploy.quota.quotaExceededTitle" />
          )
        }
        open={showBudgetExceededModal}
      >
        <HubspotFormExceedQuotaScaleExisting
          onFormComplete={() => setShowBudgetExceededModal(false)}
          onFormCancel={() => setShowBudgetExceededModal(false)}
          hiddenFields={{
            cluster_configuration: [
              `organization: ${organizationId}`,
              `cluster: ${cluster?.id} (${cluster?.name})`,
              `product: ${proposedClusterSpecs?.label}`,
              `storage: ${toGibiBytes(proposedClusterSpecs?.storageBytes)} GiB`,
              `nodes: x${proposedClusterSpecs.nodeCount}`,
              `price per month: $${selectedConfigurationPrice?.total_price.price_per_month}`,
            ].join('\n'),
          }}
        />
      </Modal>
    </>
  );
}

export default ClusterScale;
