import { FileId } from 'src/types';
import {
  CompressionOption,
  FIELD_NAMES,
  SINK_DEFAULT_FIELD_VALUES,
  SinkDetailsStepForm,
  SupportedFileFormat,
} from '../../constants';

export type CreateImportJobFileBody = {
  type: 'file';
  [FIELD_NAMES.FILE_NAMESPACE]: {
    [FIELD_NAMES.ID]?: FileId;
  };
  [FIELD_NAMES.COMPRESSION_OPTIONS]: CompressionOption;
  [FIELD_NAMES.FORMAT_OPTIONS]?: SupportedFileFormat;
} & SinkDetailsStepForm;

export const FILE_SOURCE_FORM_DEFAULT_VALUES: CreateImportJobFileBody = {
  type: 'file',
  [FIELD_NAMES.FILE_NAMESPACE]: {
    [FIELD_NAMES.ID]: undefined,
  },
  [FIELD_NAMES.COMPRESSION_OPTIONS]: 'none',
  [FIELD_NAMES.FORMAT_OPTIONS]: undefined,
  ...SINK_DEFAULT_FIELD_VALUES,
};
