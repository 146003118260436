import PropTypes from 'prop-types';

export default PropTypes.shape({
  path: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }),
  component: PropTypes.func.isRequired,
  displayName: PropTypes.node.isRequired,
  icon: PropTypes.node,
  navigationContext: PropTypes.oneOf([
    'toggleContextLinks',
    'projectLinks',
    'accountLinks',
    'organizationLinks',
    'cluster',
  ]),
});
