export type TestSWRIsFetchingProps = {
  fetchStatusList: boolean[];
  loadingTestId?: string;
  loadedTestId?: string;
};

// this component is purely used for testing.
//
// when testing, we sometimes need to wait for SWR to have finished
// fetching data from the API (msw).
//
// gotcha! SWR's isLoading param is true only when there's an ongoing
// request AND no "loaded data". calling mutate() on loaded will not
// cause isLoading to become true. do not use isLoading here, instead
// use isValidating which is true during any SWR request.
//
// when testing, we sometimes have problems when jest closes a test
// before all the code in the component has run. for example:
// -  SWR is still trying to fetch data from the API, e.g. typically
//    a mutate() once the user has performed an action
// - jsdom complains the Window object is unexpectedly no longer
//   available
//
// to combat this, incorporate this component in the component you are
// testing as follows...
//
//   const { data: cluster, isValidating: isClusterValidating, mutate: mutateCluster } = useGetClustersId(clusterId);
//   const { data: org, isValidating: isOrgValidating, mutate: mutateOrg } = useGetOrganizationsId(organizationId);
//   <TestSWRIsFetching fetchStatusList={[isClusterValidating, isOrgValidating]} />
//
// the test like...
//
//   fireEvent.click(saveButton);
//   await findByTestId('swr-dormant');
//   expect(getByRole('dialog')).not.toBeInTheComponent();
//
// jest has strategies for testing async code, see: https://jestjs.io/docs/asynchronous
// however, none of these seem to fix this problem, hence this solution.
// this idea came about after some discussion about this problem. we
// accept it isn't ideal and a better solution may present itself in
// future. by making this a component, replacing it with a future
// solution should be straightforward.

function TestSWRIsFetching({
  fetchStatusList,
  loadingTestId = 'swr-fetching',
  loadedTestId = 'swr-dormant',
}: TestSWRIsFetchingProps) {
  return (
    <div
      aria-hidden="true"
      className="hidden"
      data-testid={
        fetchStatusList.some(value => !!value) ? loadingTestId : loadedTestId
      }
    />
  );
}

export default TestSWRIsFetching;
