import React from 'react';
import PropTypes from 'prop-types';

function CloudUIDrawerScrollContainer({ children }) {
  return <div className="pb-15">{children}</div>;
}

CloudUIDrawerScrollContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    .isRequired,
};

export default CloudUIDrawerScrollContainer;
