import PropTypes from 'prop-types';

export default PropTypes.shape({
  action: PropTypes.string.isRequired,
  actor: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    picture: PropTypes.string,
    username: PropTypes.string,
  }),
  context: PropTypes.shape({
    cluster: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      origin: PropTypes.string,
      version: PropTypes.string,
    }),
    credit: PropTypes.shape({
      id: PropTypes.string,
      expiration_date: PropTypes.number,
      original_amount: PropTypes.number,
      remaining_amount: PropTypes.number,
    }),
    organization: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    project: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    subscription: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      offer: PropTypes.string,
      offer_id: PropTypes.string,
      plan: PropTypes.string,
      plan_id: PropTypes.string,
      provider: PropTypes.string,
      reference: PropTypes.string,
      subscription_id: PropTypes.string,
      subscription_provider: PropTypes.string,
    }),
  }),
  created: PropTypes.string,
  id: PropTypes.string,
});
