import React from 'react';
import { useParams } from 'react-router-dom';
import { Automation } from '@crate.io/crate-gc-admin';
import SectionContainer from '../../../components/SectionContainer';
import GrandCentralWrapper from '../../../components/GrandCentralWrapper';
import { useAnalytics } from '../../../hooks';
import { USER_TRACKING_EVENTS } from '../../../constants/segment';

function ClusterAutomation() {
  const { clusterId, organizationId } = useParams();
  const { trackEvent } = useAnalytics();

  const onCreateJob = () => {
    trackEvent(USER_TRACKING_EVENTS.CLICKED_JOBSCHEDULER_CREATE);
  };

  const onEditJob = () => {
    trackEvent(USER_TRACKING_EVENTS.CLICKED_JOBSCHEDULER_EDIT);
  };

  const onDeleteJob = () => {
    trackEvent(USER_TRACKING_EVENTS.CLICKED_JOBSCHEDULER_DELETE);
  };

  const onCreatePolicy = () => {
    trackEvent(USER_TRACKING_EVENTS.CLICKED_TABLEPOLICIES_CREATE);
  };

  const onEditPolicy = () => {
    trackEvent(USER_TRACKING_EVENTS.CLICKED_TABLEPOLICIES_EDIT);
  };

  const onDeletePolicy = () => {
    trackEvent(USER_TRACKING_EVENTS.CLICKED_TABLEPOLICIES_REMOVE);
  };

  return (
    <GrandCentralWrapper clusterId={clusterId} organizationId={organizationId}>
      <SectionContainer>
        <Automation
          onCreateJob={onCreateJob}
          onEditJob={onEditJob}
          onDeleteJob={onDeleteJob}
          onCreatePolicy={onCreatePolicy}
          onEditPolicy={onEditPolicy}
          onDeletePolicy={onDeletePolicy}
        />
      </SectionContainer>
    </GrandCentralWrapper>
  );
}

export default ClusterAutomation;
