import { useGetUsersMe } from '../swrHooks';
import { environment } from '../constants/environment';

export default function useAnalytics() {
  const { data: user } = useGetUsersMe();

  const trackEvent = (message, props) => {
    try {
      window.analytics.track(message, {
        environment,
        event: message,
        email: user?.email,
        ...props,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  };

  const trackPageView = message => {
    try {
      window.analytics.page(message, {
        environment,
        email: user?.email,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  };

  return { trackEvent, trackPageView };
}
