import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import TestSWRIsFetching from '../../../components/TestSWRIsFetching';
import { useGetOrganizations, useGetUsersMe } from '../../../swrHooks';
import usePersistentStore from '../../../state/persistent';
import {
  createOrganization,
  organizationClustersList,
} from '../../../constants/paths';
import useMessage from '../../../hooks/useMessage';

function Onboarding() {
  const navigate = useNavigate();
  const location = useLocation();
  const { formatMessage } = useIntl();
  const { showLoadingMessage, showSuccessMessage } = useMessage();

  const { currentOrganizationId } = usePersistentStore();
  const { data: user, mutate: mutateUser } = useGetUsersMe();
  const {
    data: organizations,
    isValidating: isOrganizationsValidating,
    mutate: mutateOrganizations,
  } = useGetOrganizations();

  useEffect(() => {
    const redirectToOrganization = async targetOrganizationId => {
      navigate(
        `${organizationClustersList.build({
          organizationId: targetOrganizationId,
        })}${location.search}`,
      );
    };

    // wait for the user and organisations to load
    if (!user || !organizations) {
      return;
    }

    // if the user has organisations, redirect to one of them
    if (organizations.length > 0) {
      // redirect to the org stored in localstorage if it exists and is valid
      if (
        currentOrganizationId &&
        organizations.some(org => org.id === currentOrganizationId)
      ) {
        redirectToOrganization(currentOrganizationId);
        return;
      }

      // reset the organizationID in localstorage and
      // redirect to the first organization in the list
      redirectToOrganization(organizations[0].id);
      return;
    }

    // redirect to the create organization page
    navigate(createOrganization.path);
  }, [
    currentOrganizationId,
    formatMessage,
    navigate,
    location.search,
    mutateOrganizations,
    mutateUser,
    organizations,
    showLoadingMessage,
    showSuccessMessage,
    user,
  ]);

  // need this here to prevent the tests from ending before swr
  // has finished fetching data from the API
  return <TestSWRIsFetching fetchStatusList={[isOrganizationsValidating]} />;
}

export default Onboarding;
