import cidrRegex from 'cidr-regex';

export const isValidCIDR = cidr => {
  return cidr === '' ? true : cidrRegex({ exact: true }).test(cidr);
};

// give each range a unique key
export const normalizeWhitelist = list => {
  return list.map((ipRange, index) => ({
    cidr: ipRange.cidr.trim(),
    description: ipRange.description,
    key: `range${index}`,
    isValid: isValidCIDR(ipRange.cidr),
  }));
};
