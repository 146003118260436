import { Button, Text } from '@crate.io/crate-gc-admin';
import { Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import INPUT_SANITIZATION from '../../../../../constants/inputSanitization';
import { FILE_NAME_REGEXP } from 'src/constants/regex';
import { useState } from 'react';
import useSupportedFileTypes from '../../common/hooks/useSupportedFileTypes';
import FileDetailsFields from '../../common/FileDetailsForm';
import {
  CompressionOption,
  DEFAULT_FIELD_VALUES,
  FIELD_NAMES,
  FILE_COMPRESSION_OPTIONS,
  FormatOptionsValue,
  SupportedFileFormat,
} from '../../constants';
import { DEMO_DATA_FORM_VALUES } from '../constants';
import { CreateImportJobUrlBody } from './form';
import { CRATEDB_CLOUD_IMPORT_DATA_DOCS } from 'src/constants/links';
import ConstrainStepWidth from '../../../../../components/StepLayout/ConstrainStepWidth';

const getFormSchemaFromFieldValues = ({
  compression,
  format,
  url,
}: {
  compression: CompressionOption;
  format: FormatOptionsValue;
  url: string;
}) => ({
  [FIELD_NAMES.COMPRESSION_OPTIONS]: compression,
  [FIELD_NAMES.FORMAT_OPTIONS]: format,
  [FIELD_NAMES.URL_NAMESPACE]: {
    [FIELD_NAMES.URL]: url,
  },
});

export type SourceDetailsStepProps = {
  defaultValues: CreateImportJobUrlBody;
  setTableName: (tableName: string) => void;
  onNext: (result: CreateImportJobUrlBody) => void;
};

function SourceDetailsStep({
  defaultValues,
  setTableName,
  onNext,
}: SourceDetailsStepProps) {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<CreateImportJobUrlBody>();
  const { supportedFileFormats } = useSupportedFileTypes();

  // states
  const [usingDemoData, setUsingDemoData] = useState(false);

  const onFormFinish = (values: CreateImportJobUrlBody) => {
    onNext(values);
  };

  const handleUrlChange = (url: string) => {
    const fileMatch = url.match(FILE_NAME_REGEXP);
    const isUsingDemoData = url === DEMO_DATA_FORM_VALUES.url;

    const fileFormat = fileMatch?.groups?.format || '';
    const fileCompression = fileMatch?.groups?.compression || '';

    // if inferred file format of import has help available, show it.
    if ((supportedFileFormats as string[]).includes(fileFormat)) {
      form.setFieldsValue({
        [FIELD_NAMES.FORMAT_OPTIONS]: fileFormat as SupportedFileFormat,
        [FIELD_NAMES.COMPRESSION_OPTIONS]: fileCompression
          ? FILE_COMPRESSION_OPTIONS.GZIP
          : DEFAULT_FIELD_VALUES[FIELD_NAMES.COMPRESSION_OPTIONS],
      });
    }
    setUsingDemoData(isUsingDemoData);
  };

  const setDemoData = () => {
    // HANDLE SELECT TABLE DATA / SCHEMA EVOLUTION!!!
    setTableName(DEMO_DATA_FORM_VALUES.tableName);
    form.setFieldsValue(getFormSchemaFromFieldValues(DEMO_DATA_FORM_VALUES));
    setUsingDemoData(true);
  };

  return (
    <ConstrainStepWidth>
      <Form<CreateImportJobUrlBody>
        autoComplete="off"
        form={form}
        initialValues={{
          ...defaultValues,
        }}
        layout="vertical"
        name="import-data-from-url-source-form"
        id="import-data-from-url-source-form"
        aria-label="import-data-from-url-source-form"
        onFinish={onFormFinish}
        requiredMark="optional"
      >
        <Text pale className="mb-2">
          <FormattedMessage
            id="cluster.clusterImportFile.formattingRulesDocsText"
            values={{
              link: (
                <a
                  href={CRATEDB_CLOUD_IMPORT_DATA_DOCS}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="cluster.clusterImportFile.docsLinkPartial" />
                </a>
              ),
            }}
          />
        </Text>

        <div>
          {/* import url */}
          <Form.Item
            colon={false}
            label={
              <FormattedMessage id="cluster.clusterImportFile.sourceStep.urlLabel" />
            }
            name={[FIELD_NAMES.URL_NAMESPACE, FIELD_NAMES.URL]}
            rules={[INPUT_SANITIZATION.GENERIC_URL]}
          >
            <Input
              placeholder={formatMessage({
                id: 'cluster.clusterImportFile.sourceStep.urlPlaceholder',
              })}
              onChange={e => {
                handleUrlChange(e.target.value);
              }}
            />
          </Form.Item>

          <FileDetailsFields />

          {!usingDemoData && (
            <div>
              <FormattedMessage
                id="cluster.clusterImportFile.sourceStep.useDemoDataHelp"
                values={{
                  demoDataButton: (
                    <button
                      className="text-crate-blue"
                      onClick={setDemoData}
                      type="button"
                    >
                      <FormattedMessage id="cluster.clusterImportFile.sourceStep.useDemoDataPartial" />
                    </button>
                  ),
                }}
              />
            </div>
          )}

          {usingDemoData && (
            <div>
              <FormattedMessage
                id="cluster.clusterImportFile.sourceStep.demoDataExplanationText"
                values={{
                  link: (
                    <a
                      href="https://www.nyc.gov/site/tlc/about/tlc-trip-record-data.page"
                      target="_blank"
                      rel="noreferrer"
                    >
                      taxi trip dataset
                    </a>
                  ),
                  blog_link: (
                    <a
                      href="https://cratedb.com/blog/time-series-cratedb-cloud-sql-examples"
                      target="_blank"
                      rel="noreferrer"
                    >
                      this blog post
                    </a>
                  ),
                }}
              />
            </div>
          )}
        </div>

        <div className="mt-4 flex">
          <Button type={Button.types.SUBMIT}>
            <FormattedMessage id="common.next" />
          </Button>
        </div>
      </Form>
    </ConstrainStepWidth>
  );
}

export default SourceDetailsStep;
