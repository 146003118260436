import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Button, Text } from '@crate.io/crate-gc-admin';
import {
  useGetClustersId,
  useGetClustersIdAvailableupgrades,
  useGetClustersIdMetricsCrateunreplicatedtables,
} from '../../swrHooks';
import {
  getClusterAvailableUpgrades,
  getClusterHasUnreplicatedTables,
} from '../../utils/data/cluster';
import { CRATEDB_CLOUD_CRFREE_PLAN_DOCS } from '../../constants/links';
import { clusterManage } from '../../constants/paths';

function ClusterChecklist({ organizationId, clusterId }) {
  const COLOR_OPTIONS = { green: 1, amber: 2, red: 3 };
  const CHECKLIST_ICON = {
    1: <CheckCircleOutlined className="text-green-600" />,
    2: <ExclamationCircleOutlined className="text-orange-400" />,
    3: <WarningOutlined className="text-red-600" />,
  };
  const { data: cluster } = useGetClustersId(clusterId);
  const { data: upgrades } = useGetClustersIdAvailableupgrades(clusterId);

  const availableUpgrades = getClusterAvailableUpgrades(cluster, upgrades);

  const [showUnreplicatedTablesModal, setShowUnreplicatedTablesModal] =
    useState(false);
  const { data: unreplicatedTables } =
    useGetClustersIdMetricsCrateunreplicatedtables(clusterId);

  // note the use of <button>, not <Button>. The default styles in Button make
  // the notification much taller than it should be.
  const renderUnreplicatedNotificationContents = () => {
    return (
      <div>
        <Text className="pb-4">
          <FormattedMessage id="clusterChecklist.unreplicatedTablesModalIntroText" />
        </Text>
        <Text className="pb-1">
          <FormattedMessage
            id="clusterChecklist.unreplicatedTablesModalText"
            values={{ count: unreplicatedTables.data.result.length }}
          />
        </Text>
        <ul className="list-inside list-disc pb-4">
          {unreplicatedTables.data.result.map(result => (
            <li
              className="ml-4 pb-1"
              key={`unreplicated_table_${result.metric.table_name}`}
            >
              {result.metric.table_name}
            </li>
          ))}
        </ul>
        <Text>
          <FormattedMessage id="clusterChecklist.unreplicatedTablesModalLearnMoreText" />
          <a
            href={`https://cratedb.com/docs/crate/reference/en/${cluster?.crate_version}/general/ddl/replication.html#replication`}
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="clusterChecklist.unreplicatedTablesModalLearnMoreLink" />
          </a>
          .
        </Text>

        <div className="mt-4 flex justify-end">
          <Button
            onClick={() => setShowUnreplicatedTablesModal(false)}
            type={Button.types.SUBMIT}
            size={Button.sizes.SMALL}
          >
            <FormattedMessage id="common.ok" />
          </Button>
        </div>
      </div>
    );
  };

  const getChecklist = () => {
    const checklist = [];

    // upgrade available
    if (availableUpgrades?.length > 0) {
      checklist.push({
        key: 'upgrade_available',
        color: COLOR_OPTIONS.amber,
        greenLabel: null,
        redLabel: <FormattedMessage id="clusterChecklist.versionUpgradeAvailable" />,
        link: (
          <Link
            to={{
              pathname: clusterManage.build({
                organizationId,
                projectId: cluster.project_id,
                clusterId,
              }),
            }}
          >
            <FormattedMessage id="clusterChecklist.versionUpgradeLink" />
          </Link>
        ),
      });
    }

    // ip allowlist
    if (cluster) {
      checklist.push({
        key: 'ip_allowlist',
        color:
          cluster.ip_whitelist?.length > 0 ? COLOR_OPTIONS.green : COLOR_OPTIONS.red,
        greenLabel: (
          <FormattedMessage id="clusterChecklist.ipAllowlistSuccessText" />
        ),
        redLabel: <FormattedMessage id="clusterChecklist.ipAllowlistWarningText" />,
        link: (
          <Link
            to={{
              pathname: clusterManage.build({
                organizationId,
                projectId: cluster.project_id,
                clusterId,
              }),
            }}
          >
            <FormattedMessage id="clusterChecklist.readMoreLink" />
          </Link>
        ),
      });
    }

    // deletion protection
    if (cluster && cluster.product_name !== 'crfree') {
      checklist.push({
        key: 'deletion_protection',
        color: cluster.deletion_protected ? COLOR_OPTIONS.green : COLOR_OPTIONS.red,
        greenLabel: (
          <FormattedMessage id="clusterChecklist.deletionProtectionSuccessText" />
        ),
        redLabel: (
          <FormattedMessage id="clusterChecklist.deletionProtectionWarningText" />
        ),
        link: (
          <Link
            to={{
              pathname: clusterManage.build({
                organizationId,
                projectId: cluster.project_id,
                clusterId,
              }),
            }}
          >
            <FormattedMessage id="clusterChecklist.readMoreLink" />
          </Link>
        ),
      });
    }

    // unreplicated tables
    // if this cluster has more than one node and missing replicas
    if (cluster && getClusterHasUnreplicatedTables(cluster, unreplicatedTables)) {
      checklist.push({
        key: 'unreplicated_tables',
        color: COLOR_OPTIONS.amber,
        greenLabel: null,
        redLabel: <FormattedMessage id="clusterChecklist.unreplicatedTables" />,
        link: (
          <button
            className="cursor-pointer text-crate-blue"
            onClick={() => setShowUnreplicatedTablesModal(true)}
            type="button"
          >
            <FormattedMessage id="clusterChecklist.readMoreLink" />
          </button>
        ),
      });
    }

    // free cluster
    if (cluster && cluster.product_name === 'crfree') {
      checklist.push({
        key: 'crfree',
        color: COLOR_OPTIONS.amber,
        greenLabel: null,
        redLabel: <FormattedMessage id="clusterChecklist.crfreeText" />,
        link: (
          <a
            href={CRATEDB_CLOUD_CRFREE_PLAN_DOCS}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="clusterChecklist.whatDoesThisMeanLink" />
          </a>
        ),
      });
    }

    return checklist;
  };

  return (
    <>
      <div className="text-sm" data-testid="cluster-checklist">
        {getChecklist()
          .sort((a, b) => {
            // sort red items first, green last
            return b.color - a.color;
          })
          .map(checklistItem => (
            <div
              className="flex items-baseline pb-1.5 pl-2"
              key={`checklist_${checklistItem.key}`}
              data-testid={`checklist_${checklistItem.key}`}
            >
              <div className="w-6">{CHECKLIST_ICON[checklistItem.color]}</div>
              <div>
                {checklistItem.color === COLOR_OPTIONS.green
                  ? checklistItem.greenLabel
                  : checklistItem.redLabel}
              </div>
              {checklistItem.color !== COLOR_OPTIONS.green && (
                <div className="pl-2 text-xs">{checklistItem.link}</div>
              )}
            </div>
          ))}
      </div>

      <Modal
        closable={false}
        footer={null}
        onCancel={() => setShowUnreplicatedTablesModal(false)}
        open={showUnreplicatedTablesModal}
      >
        {showUnreplicatedTablesModal && renderUnreplicatedNotificationContents()}
      </Modal>
    </>
  );
}

ClusterChecklist.propTypes = {
  clusterId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
};

export default ClusterChecklist;
