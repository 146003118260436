const messages = {
  'showTemporaryCredentials.creationInProgressTitle': 'Your cluster is almost ready',
  'showTemporaryCredentials.credentialsHelp':
    'You can view and download your credentials below. This is the last time these credentials will be available to view. ' +
    'However, you can set a new password at any time in the cluster settings page.',
  'showTemporaryCredentials.copiedToClipboardText': 'Copied',
  'showTemporaryCredentials.username': 'Username',
  'showTemporaryCredentials.password': 'Password',
};

export default messages;
