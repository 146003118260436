const messages = {
  'grandCentralWrapper.or': 'or',
  'grandCentralWrapper.contactOurSupportTeam': 'contact our support team',
  'grandCentralWrapper.forAssistance': 'for assistance.',

  // IP Not in whitelist
  'grandCentralWrapper.ipNotInAllowlist': 'Service access restricted',
  'grandCentralWrapper.yourIpAddress': 'Your IP address',
  'grandCentralWrapper.notInClusterAllowList': "is not in the cluster's allow list.",
  'grandCentralWrapper.manageAllowList': 'Manage allow list',

  // Grand Central Unhealthy
  'grandCentralWrapper.serviceCurrentlyUnavailable':
    'Service temporarily unavailable',
  'grandCentralWrapper.ifIssuePersists': 'If the issue persists, please ',
  'grandCentralWrapper.contactSupportTeam': 'If the issue persists, please ',
};

export default messages;
