// the state in this file is not persisted to localStorage
// currently used for API error notifications only

import { ClusterId } from 'src/types';
import { create } from 'zustand';

export type NotificationType = 'error' | 'warn' | 'info';
type Notification = {
  type?: NotificationType;
  message: string;
  description?: string | React.ReactElement;
};

type SessionStore = {
  notification: Notification | null;
  clusterPasswords: Record<ClusterId, string>;
  setNotification: (
    type: NotificationType,
    message: string,
    description?: string,
  ) => void;
  clearNotification: () => void;
  setClusterPassword: (clusterId: ClusterId, password: string) => void;
  unsetClusterPassword: (clusterId: ClusterId) => void;
};

const initialState = {
  clusterPasswords: {},
  notification: null,
};

const useSessionStore = create<SessionStore>(set => ({
  ...initialState,

  clearNotification: () => {
    set({ notification: null });
  },

  setClusterPassword: (clusterId: ClusterId, password: string) => {
    set(state => ({
      clusterPasswords: { ...state.clusterPasswords, [clusterId]: password },
    }));
  },
  setNotification: (
    type: NotificationType,
    message: string,
    description?: string,
  ) => {
    set({ notification: { type, message, description } });
  },
  unsetClusterPassword: (clusterId: ClusterId) => {
    set(state => {
      const clusterPasswords = { ...state.clusterPasswords };
      delete clusterPasswords[clusterId];
      return { clusterPasswords };
    });
  },
}));

export default useSessionStore;
