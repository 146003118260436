import { MenuMode, MenuTheme } from 'antd/lib/menu';

function useThemedMenuProps(): {
  mode: MenuMode;
  currentURLPath: string;
  theme: MenuTheme;
} {
  return {
    mode: 'inline',
    currentURLPath: window.location.pathname,
    theme: 'dark',
  };
}

export default useThemedMenuProps;
