// the state in this file is persisted to localStorage
// automatically by zustand/middleware/persist

import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const initialState = {
  currentOrganizationId: null,
  sidemenuIsCollapsed: false,
};

export default create(
  persist(
    set => ({
      ...initialState,

      setCurrentOrganizationId: organizationId => {
        set({ currentOrganizationId: organizationId });
      },
      setSidemenuIsCollapsed: isCollapsed => {
        set({ sidemenuIsCollapsed: isCollapsed });
      },
    }),
    {
      name: 'cratedbconsole',
    },
  ),
);
