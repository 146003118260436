import PropTypes from 'prop-types';

export default PropTypes.shape({
  dc: PropTypes.shape({
    created: PropTypes.string.isRequired,
    modified: PropTypes.string.isRequired,
  }),
  email: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  notifications_enabled: PropTypes.bool,
  plan_type: PropTypes.number.isRequired,
});
