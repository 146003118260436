import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Modal, Select } from 'antd';
import { Button, Loader, Text } from '@crate.io/crate-gc-admin';
import SectionContainer from '../../../components/SectionContainer';
import TestEventHandler from '../../../components/TestEventHandler';
import {
  getClusterAsyncInProgress,
  getClusterAsyncUpgradeInProgress,
} from '../../../utils/data/cluster';
import { apiPut } from '../../../api';
import { useAnalytics } from '../../../hooks';
import {
  useGetClustersId,
  useGetClustersIdAvailableupgrades,
  useGetClustersIdOperations,
  useGetMetaCratedbversions,
} from '../../../swrHooks';
import { OPERATION_STATES, OPERATION_TYPES } from '../../../constants/defaults';
import {
  CRATEDB_DOCS_REFERENCE_BASE_PATH,
  CRATEDB_DOCS_RELEASE_NOTES_PATH,
} from '../../../constants/links';
import { USER_TRACKING_EVENTS } from '../../../constants/segment';

function UpgradeCluster() {
  const { formatMessage } = useIntl();
  const { clusterId } = useParams();
  const { trackEvent } = useAnalytics();
  const [showModal, setShowModal] = useState(false);
  const [lastClusterAsyncModified, setLastClusterAsyncModified] = useState(null);
  const [targetVersion, setTargetVersion] = useState(null);
  const { data: cluster, mutate: mutateCluster } = useGetClustersId(clusterId);
  const { data: clusterOperations } = useGetClustersIdOperations(clusterId);
  const { data: allCrateVersions } = useGetMetaCratedbversions();
  const { data: availableUpgrades, mutate: mutateAvailableUpgrades } =
    useGetClustersIdAvailableupgrades(clusterId);

  useEffect(() => {
    // this useEffect re-loads the available upgrades when a
    // cluster upgrade operation succeeds
    if (
      cluster &&
      cluster.last_async_operation.dc.modified !== lastClusterAsyncModified
    ) {
      setLastClusterAsyncModified(cluster.last_async_operation.modified);
      if (
        cluster.last_async_operation.type === OPERATION_TYPES.UPGRADE &&
        cluster.last_async_operation.status === OPERATION_STATES.SUCCEEDED
      ) {
        mutateAvailableUpgrades();
      }
    }
  }, [cluster, lastClusterAsyncModified, mutateAvailableUpgrades]);

  const asyncUpgradeInProgress = getClusterAsyncUpgradeInProgress(cluster);

  const getLatestHotfixForEachMinorVersion = versions => {
    return Object.values(
      versions.reduce(
        (prev, next) => ({
          ...prev,
          [`${next.major}.${next.minor}`]: next,
        }),
        {},
      ),
    );
  };

  const formatVersion = version => `v${version.replace(/nightly-/, '')}`;

  const getVersionObjectFromString = version => {
    let date;
    let v = version;
    if (/nightly-/.test(version)) {
      [, v, date] = version.split('-');
    }
    return {
      major: parseInt(v.split('.')[0], 10),
      minor: parseInt(v.split('.')[1], 10),
      hotfix: parseInt(v.split('.')[2], 10),
      date,
      version,
    };
  };

  const targetVersionDropdownOptions = useMemo(() => {
    if (!allCrateVersions || !availableUpgrades || !cluster) {
      return [];
    }

    const clusterVersion = getVersionObjectFromString(cluster.crate_version);

    // create a list of all the crate versions that can be upgraded to
    const upgradableVersions = getLatestHotfixForEachMinorVersion(
      availableUpgrades.crate_versions[cluster.channel],
    ).map(version => version.version);

    // create a sorted list of all possible crate versions
    const allVersions = getLatestHotfixForEachMinorVersion(
      allCrateVersions.crate_versions[cluster.channel],
    )
      .filter(version => {
        if (version.major !== clusterVersion.major)
          return version.major > clusterVersion.major;
        if (version.minor !== clusterVersion.minor)
          return version.minor > clusterVersion.minor;
        if (version.hotfix !== clusterVersion.hotfix)
          return version.hotfix > clusterVersion.hotfix;
        if (version.date !== null) {
          return version.date > clusterVersion.date;
        }
        return false;
      })
      .sort((a, b) => (a.version > b.version ? 1 : -1))
      .map(version => version.version);

    // update the target version if applicable
    if (!upgradableVersions.includes(targetVersion)) {
      setTargetVersion(upgradableVersions[upgradableVersions.length - 1]);
    }

    return allVersions.map(version => ({
      disabled: !upgradableVersions.includes(version),
      label: (
        <div>
          {formatVersion(version)}{' '}
          {cluster.channel !== 'stable' && (
            <span className="text-xs text-crate-border-dark">{cluster.channel}</span>
          )}
        </div>
      ),
      value: version,
    }));
  }, [allCrateVersions, availableUpgrades, cluster, targetVersion]);

  const getReleaseNotesURL = version =>
    `${CRATEDB_DOCS_REFERENCE_BASE_PATH}/latest/${CRATEDB_DOCS_RELEASE_NOTES_PATH}/${version}.html`;

  const renderLastUpgradeFailed = () => {
    return (
      <div className="mb-2 text-red-600">
        <FormattedMessage id="cluster.clusterManage.upgradeFailedText" />
      </div>
    );
  };

  const renderNoUpgradeAvailable = () => (
    <Text>
      <FormattedMessage
        id="cluster.clusterManage.noUpgradeAvailableText"
        values={{ version: cluster?.crate_version, channel: cluster?.channel }}
      />
      <a
        href={getReleaseNotesURL(cluster?.crate_version)}
        rel="noreferrer"
        target="_blank"
      >
        <FormattedMessage id="cluster.clusterManage.noUpgradeAvailableReleaseNotesText" />
      </a>
      .
    </Text>
  );

  const renderUpgradeInProgress = () => {
    // current version: cluster.crate_version

    // show a "your cluster is being upgraded" message
    let message = (
      <Text>
        <FormattedMessage id="cluster.clusterManage.upgradeInProgressText" />
      </Text>
    );

    // cluster and clusterOperations are polled separately from each
    // other: ensure we are referring to the same operation before
    // displaying the latest operation message
    if (
      clusterOperations?.operations.length > 0 &&
      cluster.last_async_operation.id === clusterOperations.operations[0].id &&
      clusterOperations?.operations[0].feedback_data?.message &&
      clusterOperations?.operations[0].non_sensitive_data?.target_version
    ) {
      message = (
        <>
          <Text>
            <FormattedMessage
              id="cluster.clusterManage.upgradeInProgressVersionText"
              values={{
                version:
                  clusterOperations.operations[0].non_sensitive_data.target_version,
              }}
            />
          </Text>
          <Text className="pt-1">
            {clusterOperations.operations[0].feedback_data.message}
          </Text>
        </>
      );
    }

    return <Loader color={Loader.colors.PRIMARY} message={message} />;
  };

  const renderUpgradeAvailable = () => (
    <div className="grid grid-cols-2 gap-x-4 lg:grid-cols-4">
      <div>
        <div className="text-[10px] font-bold uppercase text-crate-border-mid">
          <FormattedMessage id="cluster.clusterManage.currentVersionLabel" />
        </div>
        <div className="flex h-9 flex-col justify-center text-base">
          <div>
            {formatVersion(cluster.crate_version)}{' '}
            {cluster.channel !== 'stable' && (
              <span className="text-xs text-crate-border-dark">
                {cluster.channel}
              </span>
            )}
          </div>
        </div>
        <a
          className="text-xs"
          href={getReleaseNotesURL(cluster?.crate_version)}
          rel="noreferrer"
          target="_blank"
        >
          <FormattedMessage id="cluster.clusterManage.releaseNotesText" />
        </a>
      </div>
      <div>
        <div className="text-[10px] font-bold uppercase text-crate-border-mid">
          <FormattedMessage id="cluster.clusterManage.targetVersionLabel" />
        </div>
        <Select
          className="h-9 w-full lg:w-11/12"
          onChange={version => setTargetVersion(version)}
          options={targetVersionDropdownOptions}
          value={targetVersion}
        />
        <a
          className="text-xs"
          href={getReleaseNotesURL(targetVersion)}
          rel="noreferrer"
          target="_blank"
        >
          <FormattedMessage id="cluster.clusterManage.releaseNotesText" />
        </a>
      </div>
      <div className="col-span-2 mt-3">
        <Text className="pb-2">
          <FormattedMessage id="cluster.clusterManage.upgradeDescriptionText1" />
        </Text>
        <Text className="pb-2">
          <FormattedMessage id="cluster.clusterManage.upgradeDescriptionText2" />
        </Text>
        <Text>
          <FormattedMessage id="cluster.clusterManage.upgradeDescriptionText3" />
        </Text>
      </div>
    </div>
  );

  const upgradeCluster = async () => {
    const { success } = await apiPut(`/api/v2/clusters/${clusterId}/upgrade/`, {
      crate_version: targetVersion,
    });

    if (success) {
      trackEvent(USER_TRACKING_EVENTS.CLICKED_UPGRADE_TRIGGERED);
      mutateCluster();
      setShowModal(false);
    }
  };

  return (
    <>
      <SectionContainer
        actions={
          targetVersionDropdownOptions.length > 0 ? (
            <Button
              kind={Button.kinds.TERTIARY}
              onClick={() => setShowModal(true)}
              disabled={cluster.suspended || getClusterAsyncInProgress(cluster)}
            >
              <FormattedMessage id="cluster.clusterManage.upgradeButtonText" />
            </Button>
          ) : null
        }
        loading={!allCrateVersions || !availableUpgrades || !cluster}
        title={formatMessage({
          id: 'cluster.clusterManage.upgradeTitle',
        })}
      >
        {asyncUpgradeInProgress && renderUpgradeInProgress()}
        {!asyncUpgradeInProgress &&
          cluster?.last_async_operation.type === OPERATION_TYPES.UPGRADE &&
          cluster?.last_async_operation.status === OPERATION_STATES.FAILED &&
          renderLastUpgradeFailed()}
        {!asyncUpgradeInProgress &&
          targetVersionDropdownOptions.length > 0 &&
          renderUpgradeAvailable()}
        {!asyncUpgradeInProgress &&
          targetVersionDropdownOptions.length === 0 &&
          renderNoUpgradeAvailable()}
      </SectionContainer>

      <Modal
        closable={false}
        open={showModal}
        onCancel={() => setShowModal(false)}
        onOk={upgradeCluster}
        okText={formatMessage({
          id: 'cluster.clusterManage.upgradeButtonText',
        })}
      >
        <Text className="pb-2">
          <FormattedMessage
            id="cluster.clusterManage.upgradeModalText1"
            values={{
              version: (/^\d/.test(targetVersion) ? 'v' : '') + targetVersion,
            }}
          />
        </Text>
        <Text displayAs={Text.elements.p}>
          <FormattedMessage id="cluster.clusterManage.upgradeModalText2" />
        </Text>
      </Modal>

      <TestEventHandler fn={mutateCluster} />
    </>
  );
}

export default UpgradeCluster;
