import PropTypes from 'prop-types';

export default PropTypes.shape({
  address: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string,
    postal_code: PropTypes.string,
  }),
  email: PropTypes.string,
  name: PropTypes.string,
  tax_id: PropTypes.string,
  tax_id_type: PropTypes.string,
});
