import { Form, Input, Select } from 'antd';
import { Button, Label, Text } from '@crate.io/crate-gc-admin';
import { FormattedMessage, useIntl } from 'react-intl';
import { CreateIntegrationMongoDBBody } from './forms';
import { FIELD_NAMES, TYPE_MAPPING_OPTIONS } from '../../constants';
import INPUT_SANITIZATION from '../../../../../constants/inputSanitization';
import ConstrainStepWidth from 'src/components/StepLayout/ConstrainStepWidth';

export type TableStepProps = {
  defaultValues: CreateIntegrationMongoDBBody;
  onNext: (result: CreateIntegrationMongoDBBody) => void;
};

function TableStep({ defaultValues, onNext }: TableStepProps) {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<CreateIntegrationMongoDBBody>();

  const onFormFinish = (values: CreateIntegrationMongoDBBody) => {
    onNext(values);
  };

  return (
    <ConstrainStepWidth>
      <Form<CreateIntegrationMongoDBBody>
        autoComplete="off"
        form={form}
        initialValues={{
          ...defaultValues,
        }}
        layout="vertical"
        name="mongodb-integration-table-form"
        id="mongodb-integration-table-form"
        aria-label="mongodb-integration-table-form"
        onFinish={onFormFinish}
        requiredMark="optional"
      >
        <Form.Item<CreateIntegrationMongoDBBody>
          colon={false}
          label={
            <FormattedMessage id="cluster.clusterImportIntegration.tableStep.tableLabel" />
          }
          name={[FIELD_NAMES.DESTINATION_NAMESPACE, FIELD_NAMES.TABLE_NAME]}
          rules={[INPUT_SANITIZATION.TABLE_NAME]}
        >
          <Input
            placeholder={formatMessage({
              id: 'cluster.clusterImportIntegration.tableStep.tableNamePlaceholder',
            })}
          />
        </Form.Item>

        <div className="flex flex-col gap-2 lg:flex-row">
          <div>
            <Label>
              <FormattedMessage id="cluster.clusterImportIntegration.tableStep.sourceLabel" />
            </Label>
            <div className="flex gap-2">
              <div className="max-w-[100px]">
                <Form.Item
                  label={formatMessage({
                    id: 'cluster.clusterImportIntegration.tableStep.fieldLabel',
                  })}
                  className="mb-2"
                  required
                >
                  <Input value="record" disabled />
                </Form.Item>
              </div>

              <div className="max-w-[100px]">
                <Form.Item
                  label={formatMessage({
                    id: 'cluster.clusterImportIntegration.tableStep.typeLabel',
                  })}
                  className="mb-2"
                  required
                >
                  <Input value="BSON" disabled />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="mb-9 flex items-end">
            <Text className="!text-[50px]">→</Text>
          </div>

          <div>
            <Label>
              <FormattedMessage id="cluster.clusterImportIntegration.tableStep.targetLabel" />
            </Label>
            <div className="flex gap-2">
              <div className="max-w-[120px]">
                <Form.Item
                  label={formatMessage({
                    id: 'cluster.clusterImportIntegration.tableStep.columnLabel',
                  })}
                  className="mb-2"
                  required
                >
                  <Input value="document" disabled />
                </Form.Item>
              </div>

              <div className="w-[200px]">
                <Form.Item<CreateIntegrationMongoDBBody>
                  colon={false}
                  label={
                    <FormattedMessage id="cluster.clusterImportIntegration.tableStep.typeLabel" />
                  }
                  name={[
                    FIELD_NAMES.DESTINATION_NAMESPACE,
                    FIELD_NAMES.OBJECT_TYPE_STRATEGY,
                  ]}
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: 'cluster.clusterImportIntegration.tableStep.typeMappingRequiredText',
                      }),
                    },
                  ]}
                >
                  <Select
                    data-testid="type-mapping-select"
                    options={TYPE_MAPPING_OPTIONS}
                    placeholder={
                      <FormattedMessage id="cluster.clusterImportIntegration.tableStep.selectTypeMapping" />
                    }
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>

        <div className="flex">
          <Button type={Button.types.SUBMIT}>
            <FormattedMessage id="common.next" />
          </Button>
        </div>
      </Form>
    </ConstrainStepWidth>
  );
}

export default TableStep;
