import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { StatusLight } from '@crate.io/crate-gc-admin';
import { clusterPropType } from '../../models';
import {
  getClusterAsyncResumeInProgress,
  getClusterAsyncSuspendInProgress,
} from '../../utils/data/cluster';
import { CLUSTER_HEALTH_STATES, OPERATION_TYPES } from '../../constants/defaults';

const statusColorMap = {
  [CLUSTER_HEALTH_STATES.GREEN]: StatusLight.colors.GREEN,
  [CLUSTER_HEALTH_STATES.YELLOW]: StatusLight.colors.YELLOW,
  [CLUSTER_HEALTH_STATES.RED]: StatusLight.colors.RED,
  [CLUSTER_HEALTH_STATES.SUSPENDED]: StatusLight.colors.RED,
  [CLUSTER_HEALTH_STATES.UNKNOWN]: StatusLight.colors.GRAY,
  [CLUSTER_HEALTH_STATES.UNREACHABLE]: StatusLight.colors.RED,
};

const statusMessageMap = {
  [CLUSTER_HEALTH_STATES.GREEN]: 'clusterHealth.greenText',
  [CLUSTER_HEALTH_STATES.YELLOW]: 'clusterHealth.yellowText',
  [CLUSTER_HEALTH_STATES.RED]: 'clusterHealth.redText',
  [CLUSTER_HEALTH_STATES.UNREACHABLE]: 'clusterHealth.unreachableText',
  [CLUSTER_HEALTH_STATES.UNKNOWN]: 'clusterHealth.unknownText',
  [CLUSTER_HEALTH_STATES.SUSPENDED]: 'clusterHealth.suspendedText',
};

const statusTooltipMap = {
  [CLUSTER_HEALTH_STATES.GREEN]: 'clusterHealth.greenTooltipText',
  [CLUSTER_HEALTH_STATES.YELLOW]: 'clusterHealth.yellowTooltipText',
  [CLUSTER_HEALTH_STATES.RED]: 'clusterHealth.redTooltipText',
  [CLUSTER_HEALTH_STATES.UNREACHABLE]: 'clusterHealth.unreachableTooltipText',
  [CLUSTER_HEALTH_STATES.UNKNOWN]: 'clusterHealth.unknownTooltipText',
  [CLUSTER_HEALTH_STATES.SUSPENDED]: 'clusterHealth.suspendedTooltipText',
};

export const operationTypeMap = {
  [OPERATION_TYPES.ALLOWED_CIDR_UPDATE]: 'clusterHealth.cidrUpdateText',
  [OPERATION_TYPES.BACKUP_SCHEDULE_UPDATE]: 'clusterHealth.backupScheduleUpdateText',
  [OPERATION_TYPES.CHANGE_COMPUTE]: 'clusterHealth.changePlanText',
  [OPERATION_TYPES.CREATE]: 'clusterHealth.createClusterText',
  [OPERATION_TYPES.EXPAND_STORAGE]: 'clusterHealth.expandStorageText',
  [OPERATION_TYPES.PASSWORD_UPDATE]: 'clusterHealth.passwordUpdateText',
  [OPERATION_TYPES.RESTORE_SNAPSHOT]: 'clusterHealth.restoreSnapshot',
  [OPERATION_TYPES.SCALE]: 'clusterHealth.scaleText',
  [OPERATION_TYPES.SUSPEND]: 'clusterHealth.suspendText',
  [OPERATION_TYPES.UPGRADE]: 'clusterHealth.upgradeText',
};

function ClusterHealth({ cluster }) {
  const getStatusMessage = () => {
    if (cluster.health.running_operation) {
      if (getClusterAsyncSuspendInProgress(cluster)) {
        return <FormattedMessage id="clusterHealth.clusterBeingSuspended" />;
      }

      if (getClusterAsyncResumeInProgress(cluster)) {
        return <FormattedMessage id="clusterHealth.clusterBeingResumed" />;
      }

      return (
        <FormattedMessage id={operationTypeMap[cluster.health.running_operation]} />
      );
    }

    return (
      <FormattedMessage
        id="clusterHealth.messageText"
        values={{
          status: <FormattedMessage id={statusMessageMap[cluster.health.status]} />,
          lastSeen: cluster.health.last_seen ? (
            <FormattedMessage
              id="clusterHealth.lastSeenText"
              values={{
                relativeTime: moment.utc(cluster.health.last_seen).fromNow(),
              }}
            />
          ) : (
            ''
          ),
        }}
      />
    );
  };

  return (
    <StatusLight
      color={statusColorMap[cluster.health.status]}
      message={getStatusMessage()}
      pulse={!!cluster.health.running_operation}
      tooltip={
        cluster.health.running_operation ? null : (
          <FormattedMessage id={statusTooltipMap[cluster.health.status]} />
        )
      }
    />
  );
}

ClusterHealth.propTypes = {
  cluster: clusterPropType.isRequired,
};

export default ClusterHealth;
