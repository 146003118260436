import PropTypes from 'prop-types';
import { SUBSCRIPTION_PROVIDER, SUBSCRIPTION_STATES } from '../constants/defaults';

export default PropTypes.shape({
  active: PropTypes.bool.isRequired,
  dc: PropTypes.shape({
    created: PropTypes.string.isRequired,
    modified: PropTypes.string.isRequired,
  }),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  offer_id: PropTypes.string.isRequired,
  plan_id: PropTypes.string,
  subscription_id: PropTypes.string,
  billing_model: PropTypes.oneOf(['payg', 'contract', 'trial']),
  state: PropTypes.oneOf(Object.values(SUBSCRIPTION_STATES)),
  provider: PropTypes.oneOf(Object.values(SUBSCRIPTION_PROVIDER)),
  provisioned: PropTypes.bool.isRequired,
  reference: PropTypes.string,
});
