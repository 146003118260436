const messages = {
  'clusterCredentials.intro1':
    'Please note your cluster login credentials below. This is the last time these credentials will be available to view. However, you can set a new password at any time in the ',
  'clusterCredentials.intro2': 'cluster manage',
  'clusterCredentials.intro3': ' tab.',
  'clusterCredentials.haveYouCopiedYourPassword':
    'Have you made a note of your cluster password?',
};

export default messages;
