const isBrowserFeatureCompliant = () => {
  if (!('flat' in Array.prototype) || !('flatMap' in Array.prototype)) {
    return false;
  }

  if (!Object.entries) {
    return false;
  }

  // put future checks here, return false when a test fails
  //
  //

  // all tests pass
  return true;
};

export default isBrowserFeatureCompliant;
