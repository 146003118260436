import React from 'react';
import AdvancedTimeSeriesArticle from './articles/AdvancedTimeSeries';
import FullTextNetflixArticle from './articles/FullTextNetflix';
import ObjectTypeMarketingArticle from './articles/ObjectTypeMarketing';
import TimeSeriesWeatherArticle from './articles/TimeSeriesWeather';

export const LEARN_TAGS = {
  fulltext: 'Full text',
  geo: 'Geo-spatial',
  log: 'Log analysis',
  object: 'Objects',
  timeseries: 'Time-series',
  vector: 'Vectors',
};

export const LEARN_ARTICLES = [
  {
    title: null,
    description: null,
    articles: [
      {
        dataType: 'Time-Series data',
        title: 'Analyzing Weather Data',
        time: 3,
        tags: ['timeseries'],
        component: <TimeSeriesWeatherArticle />,
      },
      {
        dataType: 'Full-Text search',
        title: 'Exploring the Netflix Catalog',
        time: 1,
        tags: ['fulltext'],
        component: <FullTextNetflixArticle />,
      },
      {
        dataType: 'JSON data',
        title: 'Analyzing Marketing Data',
        time: 2,
        tags: ['object'],
        component: <ObjectTypeMarketingArticle />,
      },
    ],
  },
  {
    title: 'Advanced Tutorials',
    description:
      'In this section, discover advanced solutions that merge features from our basic tutorials, tailored to expand your skills and improve your CrateDB experience.',
    articles: [
      {
        dataType: 'Time-Series and Meta data',
        title: 'Analyzing Device Readings with Metadata Integration',
        time: 6,
        tags: ['timeseries'],
        component: <AdvancedTimeSeriesArticle />,
      },
    ],
  },
];
