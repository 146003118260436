import React from 'react';
import { useParams } from 'react-router-dom';
import { Code, Heading, Text } from '@crate.io/crate-gc-admin';
import OneClickImport from '../OneClickImport';
import { useGetClustersId } from '../../../../swrHooks';
import {
  renderAnchor,
  renderAnchorLink,
  renderBullet,
  renderSQL,
} from '../articleComponents';
import { useAnalytics } from '../../../../hooks';
import { TUTORIALS } from '../../../../constants/defaults';
import { USER_TRACKING_EVENTS } from '../../../../constants/segment';

const IMPORT_DATA_POST = [
  {
    destination: {
      create_table: true,
      table: 'netflix_catalog',
      table_definition: [
        {
          name: 'show_id',
          type: 'TEXT',
          extra: 'PRIMARY KEY',
        },
        {
          name: 'type',
          type: 'TEXT',
        },
        {
          name: 'title',
          type: 'TEXT',
        },
        {
          name: 'director',
          type: 'TEXT',
        },
        {
          name: '"cast"',
          type: 'ARRAY(TEXT)',
        },
        {
          name: 'country',
          type: 'TEXT',
        },
        {
          name: 'date_added',
          type: 'TIMESTAMP',
        },
        {
          name: 'release_year',
          type: 'TEXT',
        },
        {
          name: 'rating',
          type: 'TEXT',
        },
        {
          name: 'duration',
          type: 'TEXT',
        },
        {
          name: 'listed_in',
          type: 'ARRAY(TEXT)',
        },
        {
          name: 'description',
          type: 'TEXT INDEX using fulltext',
        },
      ],
    },
    compression: 'gzip',
    format: 'json',
    type: 'url',
    url: {
      url: 'https://github.com/crate/cratedb-datasets/raw/main/cloud-tutorials/data_netflix.json.gz',
    },
  },
];

function FullTextNetflixArticle() {
  const { trackEvent } = useAnalytics();
  const { clusterId } = useParams();
  const { data: cluster } = useGetClustersId(clusterId);

  const trackRunQuery = id => {
    trackEvent(USER_TRACKING_EVENTS.RUN_QUERY_ADMIN_UI, {
      query: `${TUTORIALS.NETFLIX_FULLTEXT_SEARCH} - ${id}`,
    });
  };

  return (
    <div className="flex items-start" data-testid="article-full-text-netflix">
      <div>
        {renderAnchor('introduction')}
        <Text className="pb-2">
          CrateDB is an exceptional choice for handling complex queries and
          large-scale data sets. One of its standout features is its full-text search
          capabilities, built on top of the powerful Lucene library. This makes it a
          great fit for organizing, searching, and analyzing extensive datasets.
        </Text>
        <Text className="pb-2">
          In this tutorial, we will explore how to manage a dataset of Netflix
          titles, making use of CrateDB Cloud’s full-text search capabilities. Each
          entry in our imaginary dataset will have the following attributes:
        </Text>
        <ol className="list-decimal pb-2 pl-10">
          {renderBullet('show_id', 'a unique identifier for each show or movie.')}
          {renderBullet(
            'type',
            'specified whether the title is a movie, TV show, or another format.',
          )}
          {renderBullet('title', 'the title of the movie or show.')}
          {renderBullet('director', 'the name of the director.')}
          {renderBullet('cast', 'an array listing the cast members.')}
          {renderBullet('country', 'the country where the title was produced.')}
          {renderBullet(
            'date_added',
            'a timestamp indicating when the title was added to the catalog.',
          )}
          {renderBullet('release_year', 'the year the title was released.')}
          {renderBullet('rating', 'the content rating (e.g., PG, R, etc.).')}
          {renderBullet(
            'duration',
            'the duration of the title in minutes or seasons.',
          )}
          {renderBullet(
            'listed_in',
            'an array containing genres that the title falls under.',
          )}
          {renderBullet(
            'description',
            'a textual description of the title, indexed using full-text search.',
          )}
        </ol>
        <Text className="pb-2">
          To begin, let’s create the schema for this dataset:
        </Text>

        {renderAnchor('create-table')}
        <OneClickImport
          buttonContents={
            <>
              <div className="leading-snug">Import Netflix data</div>
              <div className="text-xs leading-snug opacity-80">
                8,806 rows, 5.8MiB
              </div>
            </>
          }
          cluster={cluster}
          importPOST={IMPORT_DATA_POST}
          manualContent={
            <>
              {/* creating the table */}
              <Heading className="pb-2 pt-4" level={Heading.levels.h4}>
                Creating the table
              </Heading>
              <Text className="pb-2">
                CrateDB uses SQL, a powerful and familiar language for database
                management. To store the data, create a table with columns tailored
                to the dataset using the <Code>CREATE TABLE</Code> command.
                Importantly, you will also take advantage of CrateDB’s full-text
                search capabilities by setting up a full-text index on the
                description column. This will enable you to perform complex textual
                queries later on.
              </Text>
              {renderSQL(
                cluster,
                `CREATE TABLE "netflix_catalog" (
    "show_id" TEXT PRIMARY KEY,
    "type" TEXT,
    "title" TEXT,
    "director" TEXT,
    "cast" ARRAY(TEXT),
    "country" TEXT,
    "date_added" TIMESTAMP,
    "release_year" TEXT,
    "rating" TEXT,
    "duration" TEXT,
    "listed_in"  ARRAY(TEXT),
    "description" TEXT INDEX using fulltext
);`,
                () => trackRunQuery(1),
              )}
              <Text className="pb-2">
                Run the above SQL command in CrateDB to set up your table. With the
                table ready, you’re now set to insert the dataset.
              </Text>

              {/* inserting data */}
              {renderAnchor('insert-data')}
              <Heading className="pb-2 pt-4" level={Heading.levels.h4}>
                Inserting data
              </Heading>
              <Text className="pb-2">
                Now, insert data into the table you just created, by using the
                <Code>COPY FROM</Code> SQL statement.
              </Text>
              {renderSQL(
                cluster,
                `COPY netflix_catalog
FROM 'https://github.com/crate/cratedb-datasets/raw/main/cloud-tutorials/data_netflix.json.gz'
WITH (format = 'json', compression='gzip');`,
                () => trackRunQuery(2),
              )}
              <Text className="pb-2">
                Run the above SQL command in CrateDB to import the dataset. After
                this commands finishes, you are now ready to start querying the
                dataset.
              </Text>
            </>
          }
          successMessage={<div>Netflix data imported</div>}
          tutorialTrackingName={TUTORIALS.NETFLIX_FULLTEXT_SEARCH}
        />

        {/* sample full-text search queries */}
        {renderAnchor('analyze-data')}
        <Heading className="pb-2 pt-4" level={Heading.levels.h4}>
          Using Full-text search
        </Heading>
        <Text className="pb-2">
          Start with a basic <Code>SELECT</Code> statement on all columns, and limit
          the output to display only 10 records, in order to quickly explore a few
          samples worth of data.
        </Text>
        {renderSQL(
          cluster,
          `SELECT *
FROM netflix_catalog
LIMIT 10;`,
          () => trackRunQuery(3),
        )}
        <Text className="pb-2">
          CrateDB Cloud’s full-text search can be leveraged to find specific entries
          based on text matching. In this query, you are using the <Code>MATCH</Code>{' '}
          function on the <Code>description</Code> field to find all movies or TV
          shows that contain the word “love”. The results can be sorted by relevance
          score by using the synthetic <Code>_score</Code> column.
        </Text>
        {renderSQL(
          cluster,
          `SELECT title, description
FROM netflix_catalog
WHERE MATCH(description, 'love')
ORDER BY _score DESC
LIMIT 10;`,
          () => trackRunQuery(4),
        )}
        <Text className="pb-2">
          While full-text search is incredibly powerful, you can still perform more
          traditional types of queries. For example, to find all titles directed by
          “Kirsten Johnson”, and sort them by release year, you can use:
        </Text>
        {renderSQL(
          cluster,
          `SELECT title, release_year
FROM netflix_catalog
WHERE director = 'Kirsten Johnson'
ORDER BY release_year DESC;`,
          () => trackRunQuery(5),
        )}
        <Text className="pb-2">
          This query uses the conventional <Code>WHERE</Code> clause to find movies
          directed by Kirsten Johnson, and the <Code>ORDER BY</Code> clause to sort
          them by their release year in descending order.
        </Text>
        <Text className="pb-2">
          Through these examples, you can see that CrateDB Cloud offers you a wide
          array of querying possibilities, from basic SQL queries to advanced
          full-text searches, making it a versatile choice for managing and querying
          your datasets.
        </Text>
      </div>
      <div className="hidden min-w-[250px] pl-8 lg:block">
        {renderAnchorLink('top', 'Introduction')}
        {renderAnchorLink('create-table', 'Creating the table')}
        {renderAnchorLink('analyze-data', 'Analyzing data')}
      </div>
    </div>
  );
}

export default FullTextNetflixArticle;
