import PropTypes from 'prop-types';
import { BACKUP_LOCATIONS } from '../constants/defaults';

export default PropTypes.shape({
  organizationName: PropTypes.string,
  projectSettings: PropTypes.shape({
    projectId: PropTypes.string,
    projectName: PropTypes.string,
    backupLocation: PropTypes.oneOf([
      BACKUP_LOCATIONS.DEFAULT,
      BACKUP_LOCATIONS.CUSTOM,
    ]),
    backupSettings: PropTypes.shape({
      bucketName: PropTypes.string,
      accessKeyId: PropTypes.string,
      secretAccessKey: PropTypes.string,
    }),
  }),
  clusterName: PropTypes.string,
  userName: PropTypes.string,
  password: PropTypes.string,
  isNewProject: PropTypes.bool,
});
