import {
  CompressionOption,
  FIELD_NAMES,
  SINK_DEFAULT_FIELD_VALUES,
  SinkDetailsStepForm,
  SupportedFileFormat,
} from '../../constants';

export type CreateImportJobAzureBody = {
  type: 'azureblob';
  [FIELD_NAMES.AZURE_NAMESPACE]: {
    [FIELD_NAMES.AZURE_CONTAINER_NAME]: string;
    [FIELD_NAMES.AZURE_BLOB_NAME]: string;
    [FIELD_NAMES.AZURE_SECRET_ID]: string;
  };
  [FIELD_NAMES.COMPRESSION_OPTIONS]: CompressionOption;
  [FIELD_NAMES.FORMAT_OPTIONS]?: SupportedFileFormat;
} & SinkDetailsStepForm;

export const AZURE_SOURCE_FORM_DEFAULT_VALUES: CreateImportJobAzureBody = {
  type: 'azureblob',
  [FIELD_NAMES.AZURE_NAMESPACE]: {
    [FIELD_NAMES.AZURE_CONTAINER_NAME]: '',
    [FIELD_NAMES.AZURE_BLOB_NAME]: '',
    [FIELD_NAMES.AZURE_SECRET_ID]: '',
  },
  [FIELD_NAMES.COMPRESSION_OPTIONS]: 'none',
  [FIELD_NAMES.FORMAT_OPTIONS]: undefined,
  ...SINK_DEFAULT_FIELD_VALUES,
};
