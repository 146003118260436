import { Cluster } from 'src/types';

const rubyCrateRubyCodeSnippet = (cluster: Cluster) => `require 'crate_ruby'

client = CrateRuby::Client.new(servers=["${cluster.fqdn.slice(
  0,
  -1,
)}:4200"], username: "${cluster.username}", password: "<PASSWORD>", ssl: true)
result = client.execute("SELECT name FROM sys.cluster")
p result.to_a`;

export default rubyCrateRubyCodeSnippet;
