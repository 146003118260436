import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Button, Loader, Text } from '@crate.io/crate-gc-admin';
import ConfirmSuspendResume from '../../../components/ConfirmSuspendResume';
import SectionContainer from '../../../components/SectionContainer';
import { useGetClustersId } from '../../../swrHooks';
import {
  getClusterAsyncInProgress,
  getClusterAsyncResumeInProgress,
  getClusterAsyncSuspendInProgress,
} from '../../../utils/data/cluster';

function SuspendResume() {
  const { formatMessage } = useIntl();
  const { clusterId } = useParams();
  const { data: cluster } = useGetClustersId(clusterId);
  const [showModal, setShowModal] = useState(false);

  if (!cluster) {
    return <SectionContainer loading testId="suspend-resume-loading" />;
  }

  if (!cluster.allow_suspend) {
    return (
      <SectionContainer
        title={<FormattedMessage id="cluster.clusterManage.suspendClusterTitle" />}
      >
        <Text>
          <FormattedMessage id="cluster.clusterManage.clusterSuspendDisallowedText" />
        </Text>
      </SectionContainer>
    );
  }

  if (getClusterAsyncResumeInProgress(cluster)) {
    return (
      <SectionContainer
        title={<FormattedMessage id="cluster.clusterManage.resumeClusterTitle" />}
      >
        <Loader
          color={Loader.colors.PRIMARY}
          message={formatMessage({
            id: 'cluster.clusterManage.clusterBeingResumedText',
          })}
        />
      </SectionContainer>
    );
  }

  if (getClusterAsyncSuspendInProgress(cluster)) {
    return (
      <SectionContainer
        title={<FormattedMessage id="cluster.clusterManage.suspendClusterTitle" />}
      >
        <Loader
          color={Loader.colors.PRIMARY}
          message={formatMessage({
            id: 'cluster.clusterManage.clusterBeingSuspendedText',
          })}
        />
      </SectionContainer>
    );
  }

  return (
    <>
      <SectionContainer
        actions={
          <Button
            kind={Button.kinds.TERTIARY}
            onClick={() => setShowModal(true)}
            disabled={!cluster || getClusterAsyncInProgress(cluster)}
            warn
          >
            <FormattedMessage
              id={
                cluster.suspended
                  ? 'cluster.clusterManage.resumeClusterButton'
                  : 'cluster.clusterManage.suspendClusterButton'
              }
            />
          </Button>
        }
        title={
          <FormattedMessage
            id={
              cluster.suspended
                ? 'cluster.clusterManage.resumeClusterTitle'
                : 'cluster.clusterManage.suspendClusterTitle'
            }
          />
        }
      >
        <Text>
          <FormattedMessage
            id={
              cluster.suspended
                ? 'cluster.clusterManage.resumeClusterText'
                : 'cluster.clusterManage.suspendClusterText'
            }
          />
        </Text>
      </SectionContainer>
      <ConfirmSuspendResume
        cluster={cluster}
        suspend={!cluster.suspended}
        isVisible={showModal}
        setIsVisible={setShowModal}
      />
    </>
  );
}

export default SuspendResume;
