import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Heading, Loader, SubmitButtonGroup } from '@crate.io/crate-gc-admin';
import {
  AWSSubscriptionButton,
  AzureSubscriptionButton,
  BankCardButton,
  GCPSubscriptionButton,
  BankTransferButton,
} from '../../../../components/PaymentMethods';
import CustomerForm from '../../../../components/CustomerForm';
import PaymentEntryForm from '../../../../components/PaymentEntryForm';
import {
  useGetOrganizationsIdCustomer,
  useGetOrganizationsIdPaymentmethods,
  useGetOrganizationsIdSubscriptions,
  useGetStripeOrganizationsIdCards,
} from '../../../../swrHooks';
import {
  PAYMENT_METHODS_TYPE,
  SUBSCRIPTION_PROVIDER,
} from '../../../../constants/defaults';
import isEU from '../../../../utils/isEu';

function AddSubscription({ setShowAddSubscriptionModal }) {
  const { organizationId } = useParams();
  const [currentStep, setCurrentStep] = useState(0);

  const { data: customer } = useGetOrganizationsIdCustomer(organizationId);
  const { data: paymentMethods } =
    useGetOrganizationsIdPaymentmethods(organizationId);
  const { mutate: mutateBankCards } =
    useGetStripeOrganizationsIdCards(organizationId);
  const { mutate: mutateSubscriptions } =
    useGetOrganizationsIdSubscriptions(organizationId);

  const unAvailableSubscriptionTypes = (paymentMethods || [])
    .filter(method => method.is_setup)
    .map(method => method.type);

  const bankTransferSubscription = (paymentMethods || []).find(
    method => method.type === SUBSCRIPTION_PROVIDER.STRIPE_BANK_TRANSFER,
  );

  const steps = [
    <Loader align={Loader.alignment.CENTER} color={Loader.colors.PRIMARY} />,
    <CustomerForm
      onCancel={() => setShowAddSubscriptionModal(false)}
      onSuccess={() => setCurrentStep(2)}
    />,
    <>
      <Heading className="mb-4" level="h3">
        <FormattedMessage id="organization.addSubscription.addNewPaymentMethod" />
      </Heading>
      <div className="space-y-2">
        <BankCardButton
          disabled={unAvailableSubscriptionTypes.includes(
            PAYMENT_METHODS_TYPE.STRIPE,
          )}
          onClick={() => setCurrentStep(3)}
          paymentMethod={{ is_setup: false, type: PAYMENT_METHODS_TYPE.STRIPE }}
        />

        <AWSSubscriptionButton
          disabled={unAvailableSubscriptionTypes.includes(PAYMENT_METHODS_TYPE.AWS)}
          onClick={() => {}}
          paymentMethod={{
            is_setup: unAvailableSubscriptionTypes.includes(
              PAYMENT_METHODS_TYPE.AWS,
            ),
            type: PAYMENT_METHODS_TYPE.AWS,
          }}
        />
        <AzureSubscriptionButton
          disabled={unAvailableSubscriptionTypes.includes(
            PAYMENT_METHODS_TYPE.AZURE,
          )}
          onClick={() => {}}
          paymentMethod={{
            is_setup: unAvailableSubscriptionTypes.includes(
              PAYMENT_METHODS_TYPE.AZURE,
            ),
            type: PAYMENT_METHODS_TYPE.AZURE,
          }}
        />
        <GCPSubscriptionButton
          disabled={unAvailableSubscriptionTypes.includes(PAYMENT_METHODS_TYPE.GCP)}
          onClick={() => {}}
          paymentMethod={{
            is_setup: unAvailableSubscriptionTypes.includes(
              PAYMENT_METHODS_TYPE.GCP,
            ),
            type: PAYMENT_METHODS_TYPE.AZURE,
          }}
        />

        {customer?.address?.country && isEU(customer.address.country) && (
          <BankTransferButton
            disabled={unAvailableSubscriptionTypes.includes(
              PAYMENT_METHODS_TYPE.STRIPE_BANK_TRANSFER,
            )}
            isAvailable={
              bankTransferSubscription
                ? bankTransferSubscription.is_available
                : false
            }
            onClick={() => {}}
            onSubmitCallback={success => {
              if (success) {
                setShowAddSubscriptionModal(false);
                mutateSubscriptions();
              }
            }}
            paymentMethod={{
              is_setup: false,
              type: PAYMENT_METHODS_TYPE.STRIPE_BANK_TRANSFER,
            }}
          />
        )}
      </div>
    </>,
    // Credit Card
    <>
      <Heading className="mb-4" level="h3">
        <FormattedMessage id="organization.addSubscription.addNewCreditOrDebitCard" />
      </Heading>
      <PaymentEntryForm
        footer={
          <div>
            <SubmitButtonGroup
              confirmLabel={<FormattedMessage id="common.save" />}
              onCancel={() => setShowAddSubscriptionModal(false)}
            />
          </div>
        }
        onSubmitCallback={success => {
          if (success) {
            mutateSubscriptions();
            mutateBankCards();
            setShowAddSubscriptionModal(false);
          }
        }}
      />
    </>,
  ];

  useEffect(() => {
    if (customer && paymentMethods && currentStep === 0) {
      setCurrentStep(customer.email ? 2 : 1);
    }
  }, [currentStep, customer, paymentMethods]);

  return steps[currentStep];
}

AddSubscription.propTypes = {
  setShowAddSubscriptionModal: PropTypes.func.isRequired,
};

export default AddSubscription;
