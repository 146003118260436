import PropTypes from 'prop-types';
import { CLUSTER_HEALTH_STATES, OPERATION_TYPES } from '../constants/defaults';

export default PropTypes.shape({
  channel: PropTypes.string.isRequired,
  crate_version: PropTypes.string.isRequired,
  backup_schedule: PropTypes.string,
  dc: PropTypes.shape({
    created: PropTypes.string.isRequired,
    modified: PropTypes.string.isRequired,
  }),
  fqdn: PropTypes.string.isRequired,
  hardware_specs: PropTypes.shape({
    cpus_per_node: PropTypes.number.isRequired,
    disk_size_per_node_bytes: PropTypes.number.isRequired,
    disk_type: PropTypes.string,
    heap_size_bytes: PropTypes.number.isRequired,
    memory_per_node_bytes: PropTypes.number.isRequired,
  }),
  health: PropTypes.shape({
    last_seen: PropTypes.string,
    running_operation: PropTypes.oneOf([...Object.keys(OPERATION_TYPES), '']),
    status: PropTypes.oneOf(Object.keys(CLUSTER_HEALTH_STATES)),
  }),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  num_nodes: PropTypes.number.isRequired,
  origin: PropTypes.string.isRequired,
  product_name: PropTypes.string.isRequired,
  product_tier: PropTypes.string.isRequired,
  product_unit: PropTypes.number.isRequired,
  project_id: PropTypes.string.isRequired,
  suspended: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  deletion_protected: PropTypes.bool.isRequired,
  ip_restrictions: PropTypes.arrayOf(
    PropTypes.shape({
      cidr: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ),
  gc_available: PropTypes.bool,
});
