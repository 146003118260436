import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from '@crate.io/crate-gc-admin';
import { OPERATION_STATES } from '../../../../constants/defaults';
import { exportJobPropType } from '../../../../models';

function ExportJobsListActions({ exportJob, onDelete, onShowDetails }) {
  const [isDeleting, setIsDeleting] = useState(false);
  const handleCancelExportJob = () => {
    setIsDeleting(true);
    onDelete(exportJob);
  };

  switch (exportJob.status) {
    case OPERATION_STATES.SENT:
    case OPERATION_STATES.REGISTERED:
    case OPERATION_STATES.IN_PROGRESS:
      return (
        <Button
          kind={Button.kinds.TERTIARY}
          size={Button.sizes.SMALL}
          className="text-crate-blue"
          loading={isDeleting}
          onClick={handleCancelExportJob}
        >
          <FormattedMessage id="common.cancel" />
        </Button>
      );
    case OPERATION_STATES.FAILED:
      return (
        <Button
          kind={Button.kinds.TERTIARY}
          size={Button.sizes.SMALL}
          className="text-crate-blue"
          onClick={() => onShowDetails(exportJob)}
        >
          <FormattedMessage id="cluster.clusterExport.showDetailsButton" />
        </Button>
      );
    default:
      return exportJob.download_url ? (
        <div className="flex items-center text-neutral-300">
          <Button
            kind={Button.kinds.TERTIARY}
            size={Button.sizes.SMALL}
            className="text-crate-blue"
            onClick={() => onShowDetails(exportJob)}
          >
            <FormattedMessage id="cluster.clusterImport.showDetailsButton" />
          </Button>
          <span className="mx-2">|</span>
          <a className="text-crate-blue" href={exportJob.download_url}>
            <FormattedMessage id="common.download" />
          </a>
        </div>
      ) : (
        <Button
          kind={Button.kinds.TERTIARY}
          size={Button.sizes.SMALL}
          className="text-crate-blue"
          loading={isDeleting}
          onClick={handleCancelExportJob}
        >
          <FormattedMessage id="cluster.clusterExport.removeExportButton" />
        </Button>
      );
  }
}

ExportJobsListActions.propTypes = {
  exportJob: exportJobPropType.isRequired,
  onDelete: PropTypes.func.isRequired,
  onShowDetails: PropTypes.func.isRequired,
};

export default ExportJobsListActions;
