import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import { Text } from '@crate.io/crate-gc-admin';

function RichRadioInput({
  ariaControls,
  checked,
  description,
  disabled,
  disabledDescription,
  onClick,
  title,
  value,
}) {
  return (
    <div className="mb-6 flex flex-1 items-center rounded-lg border border-neutral-300 px-6 py-4">
      <Radio
        aria-controls={ariaControls}
        checked={checked}
        disabled={disabled}
        onClick={onClick}
        value={value}
      >
        <div className="sr-only">{title}</div>
      </Radio>
      <div>
        <Text
          className={cx('font-bold', 'block', {
            'text-neutral-400': disabled,
          })}
        >
          {title}
        </Text>
        <Text pale>
          {disabled && disabledDescription ? disabledDescription : description}
        </Text>
      </div>
    </div>
  );
}

RichRadioInput.propTypes = {
  ariaControls: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  description: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  disabledDescription: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

RichRadioInput.defaultProps = {
  ariaControls: null,
  disabled: false,
  disabledDescription: null,
};

export default RichRadioInput;
