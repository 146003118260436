import PropTypes from 'prop-types';

export default PropTypes.shape({
  cluster_id: PropTypes.string.isRequired,
  concrete_indices: PropTypes.arrayOf(PropTypes.string),
  created: PropTypes.string.isRequired,
  dc: PropTypes.shape({
    created: PropTypes.string.isRequired,
    modified: PropTypes.string.isRequired,
  }),
  project_id: PropTypes.string.isRequired,
  repository: PropTypes.string.isRequired,
  snapshot: PropTypes.string.isRequired,
  tables: PropTypes.arrayOf(PropTypes.string),
});
