import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, FormattedMessage } from 'react-intl';
import messages from '../../rootMessages';

function FeatureDetectProvider({ featuresSupported, render }) {
  return featuresSupported ? (
    render()
  ) : (
    <IntlProvider locale="en" messages={messages.en}>
      <div className="mx-auto mt-56 flex max-w-[404px] flex-col rounded-sm bg-neutral-200 p-6 text-center">
        <div className="mb-4">
          <FormattedMessage id="featureDetectionProvider.descriptionError" />
        </div>
        <div>
          <FormattedMessage id="featureDetectionProvider.suggestionHelp" />
        </div>
      </div>
    </IntlProvider>
  );
}

FeatureDetectProvider.propTypes = {
  featuresSupported: PropTypes.bool.isRequired,
  render: PropTypes.func.isRequired,
};

export default FeatureDetectProvider;
