import cx from 'classnames';

export type TextDividerProps = { className?: string; text: string };

function TextDivider({ className = '', text }: TextDividerProps) {
  const dividerClasses = cx('relative', className);

  return (
    <div className={dividerClasses}>
      <div className="border-t" />
      <div className="absolute -top-2.5 w-full text-center">
        <span className="bg-white px-3">{text}</span>
      </div>
    </div>
  );
}

export default TextDivider;
