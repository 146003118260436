import { FileAddOutlined, FileTextOutlined } from '@ant-design/icons';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { cn } from 'src/utils';
import { File as CloudFile } from 'src/types';
import FileObject, { FileErrorInfo } from '../FileObject/FileObject';

export type FileInputProps = {
  file: CloudFile | File | null;
  accept?: React.InputHTMLAttributes<HTMLInputElement>['accept'];
  error?: FileErrorInfo;
  uploadProgress?: number;
  fileDetailsMessage?: React.ReactElement;
  className?: string;
  onChange: (file: File) => void;
  onRemove: () => void;
};

function FileInput({
  file,
  accept,
  error,
  uploadProgress,
  fileDetailsMessage,
  className,
  onChange,
  onRemove,
}: FileInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDraggingFile, setIsDraggingFile] = useState(false);

  const handleDrag: React.DragEventHandler = event => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setIsDraggingFile(true);
    } else if (event.type === 'dragleave') {
      setIsDraggingFile(false);
    }
  };

  const handleDrop: React.DragEventHandler = event => {
    event.preventDefault();
    event.stopPropagation();
    setIsDraggingFile(false);
    onChange(event.dataTransfer.files[0]);
  };

  const handleBrowseForFileButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileInputChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    onChange(event.target.files![0]);
  };

  return !file ? (
    <form
      onDragEnter={handleDrag}
      onDrop={handleDrop}
      className={cn(
        'flex ',
        'flex-col',
        'h-full',
        'items-center',
        'justify-center',
        'p-4',
        'relative',
        'rounded',
        'text-center',
        'transition',
        {
          'bg-crate-body-background': isDraggingFile,
          'border-2': !isDraggingFile,
          'border-neutral-300': !isDraggingFile,
          'border-dashed': !isDraggingFile,
        },
        className,
      )}
    >
      <div
        className="
                  flex
                  h-12
                  w-12
                  items-center
                  justify-center
                  rounded-full
                  border
                  border-neutral-200
                  bg-white
                "
      >
        {isDraggingFile ? (
          <FileAddOutlined className="text-xl text-crate-blue" />
        ) : (
          <FileTextOutlined className="text-xl text-crate-blue" />
        )}
      </div>

      <div className="flex items-center justify-center">
        <input
          className="!hidden"
          data-testid="input-file-upload"
          onChange={handleFileInputChange}
          ref={inputRef}
          type="file"
          accept={accept}
        />
        <div className="text-base font-bold text-neutral-500">
          <FormattedMessage id="cluster.clusterImportFile.sourceDetailsStep.fileUploadUIHelpText" />
          <button
            className="ml-1 text-crate-blue"
            type="button"
            onClick={handleBrowseForFileButtonClick}
          >
            <FormattedMessage id="cluster.clusterImportFile.sourceDetailsStep.fileUploadUIButton" />
          </button>
        </div>
      </div>
      {fileDetailsMessage}
      {isDraggingFile && (
        <div
          className="absolute inset-0 rounded border-2 border-crate-blue"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </form>
  ) : (
    <FileObject
      attachedFile={file}
      error={error}
      onRemove={onRemove}
      progress={uploadProgress}
    />
  );
}

export default FileInput;
