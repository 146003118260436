import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallow } from 'zustand/shallow';
import NotificationAside from '../../../components/NotificationAside';
import ProductOption from './ProductOption';
import StorageOption from './StorageOption';
import { clusterPropType, productPropType } from '../../../models';
import {
  EDGE_PRODUCT_NAME,
  FREE_TIER_CLUSTER_PRODUCT_NAME,
} from '../../../constants/defaults';
import showStorageWarning from '../showStorageWarning';
import useDeployStore from '../state';
import { sortPlans } from '../../../utils/plan';
import { CLOUD_GROUPS } from '../../../constants/deploy';

const defaultStorageOptions = (minBytes, maxBytes) => {
  const options = [];
  let storage = minBytes;
  while (storage <= maxBytes) {
    options.push(storage);
    storage *= 2;
  }
  return options;
};

function CloudConfiguration({ clusters, products }) {
  const { formatMessage } = useIntl();
  const { Option } = Select;

  // state
  const {
    cloudGroup,
    cloudNodeCount,
    cloudProduct,
    cloudStorage,
    deployRegion,
    setCloudNodeCount,
    setCloudProduct,
    setCloudStorage,
  } = useDeployStore(state => state, shallow);

  const orgHasFreeCluster = clusters?.some(
    cluster => cluster.product_name === FREE_TIER_CLUSTER_PRODUCT_NAME,
  );

  // filter the list of products to the ones available for cloud
  // exclude crfree if this org already has a crfree cluster
  const cloudProducts =
    products &&
    products
      .filter(
        product =>
          !product.deprecated &&
          product.kind === 'cluster' &&
          product.offer === 'stripe' &&
          product.region === '_default_' &&
          product.name !== EDGE_PRODUCT_NAME &&
          product.tags.includes(cloudGroup) &&
          !(orgHasFreeCluster && product.name === FREE_TIER_CLUSTER_PRODUCT_NAME),
      )
      .sort(sortPlans);

  const availableStorageOptions = useMemo(() => {
    const retrieveOptions = (minBytes, maxBytes, currentBytes) => {
      // This function produces a list of <option>s for storage values,
      // i.e. 32GiB, 64GiB, 128Gib, 256Gib, etc.
      //
      // It is possible, however, that the currentBytes value does not
      // exist in this list, e.g. a non-standard storage value set using
      // croud. In which case, that number is added to the list in the
      // appropriate location.

      // push the minBytes-maxBytes range into an array
      const options = defaultStorageOptions(minBytes, maxBytes);

      // if currentBytes is not null and it isn't already in the options
      // array, add it and sort the list
      if (currentBytes && !options.includes(currentBytes)) {
        options.push(currentBytes);
        options.sort((a, b) => a - b); // reminder: sort() sorts as strings by default, not numbers
      }

      return options;
    };

    if (!cloudProduct || !deployRegion) {
      return [];
    }
    return retrieveOptions(
      cloudProduct.specs.storage_minimum_bytes || cloudProduct.specs.storage_bytes,
      cloudProduct.specs.storage_maximum_bytes || cloudProduct.specs.storage_bytes,
      cloudStorage,
    );
  }, [cloudProduct, cloudStorage, deployRegion]);

  // set default product if one not set
  useEffect(() => {
    if (!cloudProduct && cloudProducts) {
      setCloudProduct(cloudProducts[0]);
    }
  }, [cloudProduct, cloudProducts, setCloudProduct]);

  // set default product when group changes
  useEffect(() => {
    if (cloudProduct && !cloudProduct.tags.includes(cloudGroup)) {
      setCloudProduct(cloudProducts[0]);

      if (cloudGroup === CLOUD_GROUPS.SHARED) {
        setCloudNodeCount(1);
      } else if (cloudGroup === CLOUD_GROUPS.DEDICATED) {
        setCloudNodeCount(3);
      }
    }
  }, [cloudGroup, cloudProduct, cloudProducts, setCloudNodeCount, setCloudProduct]);

  // set valid storage value when product changes
  useEffect(() => {
    if (cloudProduct) {
      const storageOptions = defaultStorageOptions(
        cloudProduct.specs.storage_minimum_bytes || cloudProduct.specs.storage_bytes,
        cloudProduct.specs.storage_maximum_bytes || cloudProduct.specs.storage_bytes,
      );

      if (!storageOptions.includes(cloudStorage)) {
        setCloudStorage(storageOptions[0]);
      }
    }
  }, [availableStorageOptions, cloudProduct, cloudStorage, setCloudStorage]);

  // set valid node count when product changes
  useEffect(() => {
    if (cloudProduct) {
      const validNumberOfNodes = cloudProduct.scaling.map(
        scaleUnit => scaleUnit.nodes,
      );

      if (!validNumberOfNodes.includes(cloudNodeCount)) {
        setCloudNodeCount(validNumberOfNodes[0]);
      }
    }
  }, [cloudNodeCount, cloudProduct, setCloudNodeCount]);

  const renderNodeCount = () => {
    if (cloudNodeCount && cloudProduct.scaling.length === 1) {
      return null;
    }

    return (
      <Form.Item
        label={
          <FormattedMessage id="deploy.managedConfiguration.numberOfNodesLabel" />
        }
      >
        <>
          <Select
            value={cloudNodeCount}
            onChange={value => setCloudNodeCount(value)}
            options={cloudProduct.scaling.map(scaleUnit => ({
              label: `x${scaleUnit.nodes}`,
              value: scaleUnit.nodes,
            }))}
          />
          <div className="pt-0.5 text-right text-xs opacity-50">
            <FormattedMessage id="deploy.managedConfiguration.numberofNodesHelp" />
          </div>
        </>
      </Form.Item>
    );
  };

  const renderProducts = () => (
    <Form.Item
      label={<FormattedMessage id="deploy.managedConfiguration.nodeComputeSize" />}
    >
      <div className="border-b border-crate-border-light">
        {cloudProducts.map(product => (
          <ProductOption
            key={`product_${product.name}`}
            product={product}
            region={deployRegion}
            isSelected={product.name === cloudProduct.name}
          />
        ))}
      </div>
    </Form.Item>
  );

  const renderStorage = () => {
    return (
      <Form.Item
        label={<FormattedMessage id="deploy.managedConfiguration.storagePerNode" />}
      >
        {cloudStorage && availableStorageOptions.length === 1 && (
          <StorageOption
            bytes={availableStorageOptions[0]}
            product={cloudProduct}
            region={deployRegion}
          />
        )}
        {cloudStorage && availableStorageOptions.length > 1 && (
          <>
            <Select
              value={cloudStorage}
              onChange={value => setCloudStorage(value)}
              data-testid="cloud-storage-selector"
            >
              {availableStorageOptions.map(bytes => (
                <Option key={`storage_${bytes}`} value={bytes}>
                  <StorageOption
                    bytes={bytes}
                    product={cloudProduct}
                    region={deployRegion}
                  />
                </Option>
              ))}
            </Select>
            <div className="pt-0.5 text-right text-xs opacity-50">
              <FormattedMessage id="deploy.managedConfiguration.storagePerNodeHelp" />
            </div>
          </>
        )}
      </Form.Item>
    );
  };

  const renderStorageWarning = () => {
    if (
      !cloudProduct ||
      !cloudStorage ||
      !showStorageWarning(
        cloudStorage,
        cloudProduct.specs.cpu_cores,
        cloudProduct.specs.ram_bytes,
      )
    ) {
      return null;
    }

    return (
      <div className="-mt-4 mb-4">
        <NotificationAside
          type={NotificationAside.types.WARN}
          message={formatMessage({
            id: 'deploy.managedConfiguration.storageWarning',
          })}
        />
      </div>
    );
  };

  if (!products || !cloudProduct) {
    return null;
  }

  return (
    <>
      {renderProducts()}
      {renderStorage()}
      {renderStorageWarning()}
      {renderNodeCount()}
    </>
  );
}

CloudConfiguration.propTypes = {
  clusters: PropTypes.arrayOf(clusterPropType),
  products: PropTypes.arrayOf(productPropType),
};

CloudConfiguration.defaultProps = {
  clusters: null,
  products: null,
};

export default CloudConfiguration;
