// AWS Marketplace
export const AWS_MARKETPLACE_DEV_OFFER =
  'https://aws.amazon.com/marketplace/pp/B088P25WGF';

export const AWS_MARKETPLACE_OFFER =
  'https://aws.amazon.com/marketplace/pp/B089M4B1ND/?ref=_ptnr_console-subscription-overview';

export const AWS_MARKETPLACE_SUBSCRIPTIONS =
  'https://us-east-1.console.aws.amazon.com/marketplace/home#/subscriptions';

// Azure Marketplace
export const AZURE_MARKETPLACE_OFFER =
  'https://azuremarketplace.microsoft.com/en-us/marketplace/apps/crate.cratedbcloud?tab=Overview';

export const AZURE_MARKETPLACE_SUBSCRIPTIONS =
  'https://portal.azure.com/#blade/HubsExtension/BrowseResourceBlade/resourceType/Microsoft.SaaS%2Fresources';

// GCP Marketplace
export const GCP_MARKETPLACE_OFFER =
  'https://console.cloud.google.com/marketplace/product/cratedb-public/cratedb-gcp';

export const GCP_MARKETPLACE_SUBSCRIPTIONS =
  'https://console.cloud.google.com/marketplace/orders';

const CRATEDB_CLOUD_DOCS_REFERENCE_BASE_PATH = 'https://cratedb.com/docs/cloud';

export const CRATEDB_DOCS_REFERENCE_BASE_PATH =
  'https://cratedb.com/docs/crate/reference/en';

export const CRATEDB_DOCS_RELEASE_NOTES_PATH = 'appendices/release-notes';

export const CRATEDB_COMMUNITY = 'https://community.cratedb.com';

export const CRATEDB_CONSOLE_DOCUMENTATION =
  'https://cratedb.com/docs/cloud/en/latest/';

export const CRATEDB_CLOUD_CLONE_FAILURE_DOCS = `${CRATEDB_CONSOLE_DOCUMENTATION}cluster/backups.html#failed-cloning`;

export const CRATEDB_CLOUD_IMPORT_FILE_DOCS = `${CRATEDB_CONSOLE_DOCUMENTATION}cluster/import.html#import-from-file`;

export const CRATEDB_CLOUD_IMPORT_S3_DOCS = `${CRATEDB_CONSOLE_DOCUMENTATION}cluster/import.html#import-from-private-s3-bucket`;

export const CRATEDB_CLOUD_IMPORT_AZURE_DOCS = `${CRATEDB_CONSOLE_DOCUMENTATION}cluster/import.html#import-from-azure-blob-storage-container`;

export const CRATEDB_CLOUD_IMPORT_DATA_DOCS = `${CRATEDB_CONSOLE_DOCUMENTATION}cluster/import.html#import-from-url`;

export const CRATEDB_EDGE_TUTORIALS_DOCUMENTATION = `${CRATEDB_CLOUD_DOCS_REFERENCE_BASE_PATH}/tutorials/en/latest/edge/index.html`;

export const ORGANIZATION_PROMETHEUS_METRICS_API_DOCS =
  '/api/docs/#/Organizations/get_api_v2_organizations__organization_id__metrics_prometheus_';

export const CRATEDB_CLOUD_METRICS_TUTORIAL_DOCS =
  'https://community.cratedb.com/t/monitoring-cratedb-cloud-clusters/1397';

export const CRATEDB_CLOUD_CRFREE_PLAN_DOCS = 'https://cratedb.com/lp-crfree';

export const JSON_LINES_DOCS_LINK = 'https://jsonlines.org/';

export const CRATEDB_SUPPORT = 'https://support.cratedb.com';

export const CRATEDB_ACADEMY = 'https://learn.cratedb.com';
