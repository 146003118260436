import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Form, Input } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import {
  Button,
  CopyToClipboard,
  SubmitButtonGroup,
} from '@crate.io/crate-gc-admin';
import SectionContainer from '../../../components/SectionContainer';
import { useGetClustersId } from '../../../swrHooks';
import { getClusterAsyncInProgress } from '../../../utils/data/cluster';
import { generateSecurePassword } from '../../../utils';
import { apiPatch } from '../../../api';
import useSessionStore from '../../../state/session';

function ClusterCredentials() {
  const { formatMessage } = useIntl();
  const { clusterId } = useParams();
  const clusterPasswords = useSessionStore(state => state.clusterPasswords);
  const setClusterPassword = useSessionStore(state => state.setClusterPassword);
  const { data: cluster, mutate: mutateCluster } = useGetClustersId(clusterId);
  const asyncInProgress = getClusterAsyncInProgress(cluster);
  const [isEditing, setIsEditing] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [form] = Form.useForm();
  const password = Form.useWatch('password-input', form);

  const generatePassword = () => {
    form.setFieldsValue({
      'password-input': generateSecurePassword(),
    });
  };

  const handleSave = async formValues => {
    if (!updateInProgress && !asyncInProgress) {
      setUpdateInProgress(true);

      const { success } = await apiPatch(`/api/v2/clusters/${clusterId}/`, {
        password: formValues['password-input'],
      });

      if (success) {
        mutateCluster();

        // update the cluster password if it exists in the session store
        if (clusterId in clusterPasswords) {
          setClusterPassword(clusterId, formValues['password-input']);
        }
      }

      setIsEditing(false);
      form.setFieldsValue({ 'password-input': '' });
      setUpdateInProgress(false);
    }
  };

  const renderButtons = () => {
    if (!cluster || updateInProgress) return null;

    if (isEditing) {
      return (
        <SubmitButtonGroup
          confirmLabel={<FormattedMessage id="common.save" />}
          form="cluster-password-form"
          onCancel={() => {
            form.setFieldsValue({ 'password-input': '' });
            setIsEditing(false);
          }}
        />
      );
    }

    return (
      <Button
        kind={Button.kinds.TERTIARY}
        onClick={() => setIsEditing(true)}
        disabled={cluster.suspended || asyncInProgress}
      >
        <FormattedMessage id="common.edit" />
      </Button>
    );
  };

  const validateClusterPassword = (rule, value) => {
    if (value === '') {
      return Promise.reject(
        formatMessage({
          id: 'cluster.clusterCredentials.warnEnterClusterPasswordError',
        }),
      );
    }
    if (value.length < 24) {
      return Promise.reject(
        formatMessage(
          { id: 'cluster.clusterCredentials.passwordTooShortError' },
          { num: 24 - value.length },
        ),
      );
    }
    return Promise.resolve();
  };

  return (
    <SectionContainer
      actions={renderButtons()}
      loading={updateInProgress}
      title={formatMessage({
        id: 'cluster.clusterCredentials.clusterCredentialsSectionTitle',
      })}
    >
      <Form
        form={form}
        id="cluster-password-form"
        name="cluster-password-form"
        layout="vertical"
        onFinish={handleSave}
      >
        <div className="flex items-center gap-4">
          <div className="basis-1/3">
            <div className="h-6">
              <FormattedMessage id="common.username" />
            </div>
            {cluster && <div className="h-12 pt-1.5">{cluster.username}</div>}
          </div>
          <div className="basis-2/3">
            <div className="flex h-6 items-start justify-between">
              <div>
                <FormattedMessage id="common.password" />
              </div>
              {isEditing && (
                <button
                  className="text-crate-blue"
                  onClick={generatePassword}
                  type="button"
                >
                  <FormattedMessage id="cluster.clusterCredentials.autoGeneratePasswordButton" />
                </button>
              )}
            </div>
            <div className="h-12 pt-0.5">
              {cluster && isEditing && (
                <Form.Item
                  hasFeedback
                  name="password-input"
                  initialValue=""
                  rules={[{ validator: validateClusterPassword }]}
                >
                  <Input.Password
                    data-testid="password-input"
                    placeholder={formatMessage({
                      id: 'cluster.clusterCredentials.enterNewPasswordPlaceholder',
                    })}
                    addonAfter={
                      <CopyToClipboard textToCopy={password || ''}>
                        <span className="text-crate-blue">
                          <CopyOutlined
                            title={formatMessage({ id: 'common.copy' })}
                          />
                        </span>
                      </CopyToClipboard>
                    }
                  />
                </Form.Item>
              )}
              {cluster && !isEditing && (
                <div className="pt-1.5">
                  &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
                </div>
              )}
            </div>
          </div>
        </div>
      </Form>
    </SectionContainer>
  );
}

export default ClusterCredentials;
