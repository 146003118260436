import { Route, Routes, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import cx from 'classnames';

import { accountRoutes } from '../../constants/routes';
import SideMenu from '../SideMenu/SideMenu';
import TopBar from '../TopBar';
import { useAnalytics } from '../../hooks';
import usePersistentStore from '../../state/persistent';

function AccountRouter() {
  const location = useLocation();
  const { trackPageView } = useAnalytics();
  const {
    currentOrganizationId: organizationId,
    setSidemenuIsCollapsed,
    sidemenuIsCollapsed,
  } = usePersistentStore();

  const styles = cx(
    'min-h-screen',
    'relative',
    'transition-all',
    'ease duration-200',
    'pt-12',
    {
      'ml-20': sidemenuIsCollapsed,
      'ml-56': !sidemenuIsCollapsed,
    },
  );

  useEffect(() => {
    trackPageView({ message: 'Page view' });
  }, [location.pathname, trackPageView]);

  // when the app loads, set the menu to be collapsed
  // if the browser width < 500
  useEffect(() => {
    if (window.innerWidth < 500) {
      setSidemenuIsCollapsed(true);
    }
  }, [setSidemenuIsCollapsed]);

  return (
    <>
      <SideMenu organizationId={organizationId} />
      <TopBar sideMenuCollapsed={sidemenuIsCollapsed} />
      <main className={styles}>
        <Routes>
          {accountRoutes
            .flatMap(route => (route.children ? [route, ...route.children] : route))
            .map(route => (
              <Route
                element={
                  <div
                    className={cx('relative', {
                      'px-4 md:px-10': !route.fullscreen,
                      'pb-2': !route.fullscreen,
                      'pt-4': !route.fullscreen,
                    })}
                    style={{
                      minHeight: route.fullscreen
                        ? 'calc(100vh - 50px)'
                        : 'calc(100vh - 80px)',
                    }}
                  >
                    <route.component />
                  </div>
                }
                exact
                key={route.path.path}
                path={`${route.path.path.replace('/account/', '')}${
                  route.withSubroutes ? '/*' : ''
                }`}
              />
            ))}
        </Routes>
      </main>
    </>
  );
}

export default AccountRouter;
